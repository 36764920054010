import { Injectable } from '@angular/core';

import { OrderUpdationService } from './order-updation.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AssignOrderUpdatesService {

  CUSTOMERKEY = "CustomerTab";
  NORMALTAXKEY = "NormalTax";

  constructor(
    private orderUpdationService : OrderUpdationService,
    private localService : LocalStorageService,
    private appService:AppService
  ) { }


  getCurrencyLabels(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_CURRENCIES).subscribe(
      resp => {
        if(resp.success === 1){
            let resData = resp.result.data;
            this.orderUpdationService.orderUpdationObject.order_currency_code = resData.currency_code;
            this.orderUpdationService.orderUpdationObject.base_currency_code = resData.currency_code;
            this.orderUpdationService.orderUpdationObject.currency_exchange_rate = parseFloat(resData.static_conversion_rate);       
        }
        else{
        }
      },
      err => {
        
      })
  }

  getOrderStatusLabels(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_ORDERSTATUS).subscribe(
      resp => {
        if(resp.success === 1){
          if(resp.result.data.length > 0){
            let resData = resp.result.data;
            resData.forEach(element => {
              if(element.system_label === 'Awaiting Payment'){
                this.orderUpdationService.orderUpdationObject.status = element.custom_label;
                this.orderUpdationService.new_value.status = element.custom_label;
                this.orderUpdationService.orderUpdationObject.status_id= element.id;
              }
              if(element.system_label === 'Pending'){
                this.orderUpdationService.old_value.status = element.custom_label;
              }
              if(element.system_label === 'Confirmed'){
                this.orderUpdationService.new_value1.status = element.custom_label;
                this.orderUpdationService.confirmationId = element.id;
              }
            });
          }          
        }
        else{
        }
      },
      err => {
        
      })
  }

  updateCustomerdetails(userId, phone_number, user_name){
    this.orderUpdationService.orderUpdationObject.customer_id = userId;
    this.orderUpdationService.orderUpdationObject.customer_name = user_name;
    this.orderUpdationService.orderUpdationObject.phone_number = phone_number;
  }

  updateOutletDetails(outletObj){
    this.orderUpdationService.orderUpdationObject.outlet_id = outletObj.id;
    this.orderUpdationService.orderUpdationObject.outlet_name = outletObj.name;
    this.orderUpdationService.orderUpdationObject.outlet_latitude = outletObj.latitude;
    this.orderUpdationService.orderUpdationObject.outlet_longitude = outletObj.longitude;
  }

  updatepaymentdetails(paymentObj, payId){
    this.orderUpdationService.orderUpdationObject.payment_status = 'Pending' ;
    this.orderUpdationService.orderUpdationObject.payment_method = paymentObj.settings.title;
    this.orderUpdationService.orderUpdationObject.payment_method_id = payId;

    let reqData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": Number(paymentObj.order_status_flow_id)
        }
      }
    }

    this.appService.postApiCall(reqData, ENDPOINTS.ORDER_STATUS_FLOW).subscribe(
      resp => {
          if(resp.success === 1){
            let orderFlowObj = {
              name:"",
              title:"",
              statuses:[]
            }
            let resData = resp.result.data[0];
            orderFlowObj.name = resData.name;
            orderFlowObj.title = resData.title;
            if(resData.statuses.length > 0){
              resData.statuses.forEach(element => {
                let orderObj = {
                  name:"",
                  title:"",
                  status_id:"",
                  status_name:"",
                  status_system_label:"",
                  status_custom_label:"",
                  process:"",
                  sequence:0,
                  sms_notification:false,
                  email_notification:false,
                  push_notification:false
                };
                orderObj.status_id = element.status_id;
                orderObj.status_name = element.status_name;
                orderObj.status_system_label = element.system_label;
                orderObj.status_custom_label = element.custom_label;
                orderObj.process = element.process;
                orderObj.sequence = element.sequence;
                orderObj.sms_notification = element.sms_notification;
                orderObj.email_notification = element.email_notification;
                orderObj.push_notification = element.push_notification;
                orderFlowObj.statuses.push(orderObj);
              });
              // console.log("orderflows",orderFlowObj)
              this.orderUpdationService.orderUpdationObject.status_flow = orderFlowObj;
            }
            
          }
        },
        err => {
          
        })
  }

  getEmailSubscription(){
    if(this.localService.get(this.CUSTOMERKEY)){
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
                "phone_number": JSON.parse(this.localService.get(this.CUSTOMERKEY)).Mobile.toString()
            }
        }
      }    
      this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
        resp => {
            if(resp.success === 1){
              if(resp.result.data.length > 0){
                let respData = resp.result.data[0]
                if(respData.has_also_subscribed){
                  this.orderUpdationService.orderUpdationObject.is_email_opt_in = respData.has_also_subscribed;
                }
              }
            }
          },
          err => {
            
          })
        }
  }

  getItemDetails(){
    if(this.localService.get('cart')){
      let cartDetails:any = JSON.parse(this.localService.get('cart'));
      this.orderUpdationService.orderUpdationObject.items_total = cartDetails.length;
      let itemsQuantity = 0;
      cartDetails.forEach(element => {
        itemsQuantity = itemsQuantity + element.quantity;
      });
      if(itemsQuantity > 0){
        this.orderUpdationService.orderUpdationObject.items_total_quantity = itemsQuantity;
      }
    }
  }

  getProductData(updateObj){
    if(this.localService.get('cart')){
      let cartDetails:any = JSON.parse(this.localService.get('cart'));
      if(this.localService.get(this.NORMALTAXKEY)){
        let normalTax = Number(this.localService.get(this.NORMALTAXKEY))
        let productObject = []
        if(cartDetails.length > 0){
          cartDetails.forEach(cart => {
            let productObj = {
              product_id:"",
              product_name:"",
              image_source:"",
              price:0,
              sku:"",
              quantity:0,
              price_inc_tax:0,
              price_ex_tax:0,
              discount_percentage:0,
              discount_amount:0,
              tax_percentage:0,
              tax_amount:0,
              total_amount:0,
              variant:{
                variant_id:"",
                variant_name:"",
                options:{
                  option_key:"",
                  option_value:""
                }
              }
            }
            productObj.product_id = cart.product_id.toString();
            productObj.product_name = cart.product_name;
            productObj.image_source = cart.image_source;
            productObj.price = cart.cost;
            productObj.sku = cart.sku;
            productObj.quantity = cart.quantity;
            productObj.price_ex_tax = Number(cart.cost) * Number(cart.quantity);
            productObj.price_inc_tax = (Number(cart.cost) * Number(cart.quantity) * normalTax)/100
            productObj.price_inc_tax = (Math.round((productObj.price_inc_tax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
            if(updateObj.displayName !== ""){
              productObj.discount_percentage = updateObj.percentage;
              let discamount = (updateObj.percentage * productObj.price_ex_tax)/100
              productObj.discount_amount = (Math.round((discamount + Number.EPSILON) * 100) / 100);
              productObj.tax_percentage = normalTax;
              productObj.tax_amount = ((productObj.price_ex_tax - productObj.discount_amount) * normalTax)/100;
              productObj.tax_amount = (Math.round((productObj.tax_amount + Number.EPSILON) * 100) / 100);
              productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount - productObj.discount_amount;
            }
            else{
              productObj.tax_percentage = normalTax;
              productObj.tax_amount = (productObj.price_ex_tax * normalTax)/100;
              productObj.tax_amount = (Math.round((productObj.tax_amount + Number.EPSILON) * 100) / 100);
              productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
            }
            productObject.push(productObj);
          });
          this.orderUpdationService.orderUpdationObject.products = productObject;
        }
      }
    }
    if(this.localService.get('session_id')){

      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "session_id":this.localService.get('session_id')
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GETALLCARTITEMS).subscribe(
        resp => {
            if(resp.success === 1){
              this.orderUpdationService.orderUpdationObject.cart_id = resp.result.data[0].id.toString();
            }
          },
          err => {
            
      })
    }
  }

  getShippindDetails(shiftObj){
    this.orderUpdationService.shippingAddressObj.first_name = shiftObj.first_name;
    this.orderUpdationService.shippingAddressObj.last_name = shiftObj.last_name;
    this.orderUpdationService.shippingAddressObj.company_name = shiftObj.company_name;
    this.orderUpdationService.shippingAddressObj.entire_location = shiftObj.entire_location;
    this.orderUpdationService.shippingAddressObj.address1 = shiftObj.address1;
    this.orderUpdationService.shippingAddressObj.landmark = shiftObj.landmark;
    this.orderUpdationService.shippingAddressObj.city = shiftObj.city;
    this.orderUpdationService.shippingAddressObj.country = shiftObj.country;
    this.orderUpdationService.shippingAddressObj.country_code = shiftObj.country_code;
    this.orderUpdationService.shippingAddressObj.state = shiftObj.state;
    this.orderUpdationService.shippingAddressObj.zipcode = shiftObj.zipcode;
    this.orderUpdationService.shippingAddressObj.email = shiftObj.email;
    this.orderUpdationService.shippingAddressObj.phone_number = shiftObj.phone_number;
    this.orderUpdationService.shippingAddressObj.latitude = shiftObj.latitude;
    this.orderUpdationService.shippingAddressObj.longitude = shiftObj.longitude;
    this.orderUpdationService.orderUpdationObject.shipping_addresses = [this.orderUpdationService.shippingAddressObj]
  }

  removeShippingObj(){
    this.orderUpdationService.orderUpdationObject.shipping_addresses = [];
  }

  getOrderDevliveryInfo(deliveryInfo){
    this.orderUpdationService.orderUpdationObject.delivery_information = deliveryInfo;
  }

  getDeliverydetails(instructions, notes){
    this.orderUpdationService.orderUpdationObject.delivery_instructions = [{
      delivery_instruction:instructions
    }];
    this.orderUpdationService.orderUpdationObject.customer_comment = notes;
  }

  getShippingMethods(deliveryMethod, deliveryCharge){
    this.orderUpdationService.shippingMethodObj.name = deliveryMethod;
    this.orderUpdationService.shippingMethodObj.delivery_charge = deliveryCharge.toString();
    this.orderUpdationService.orderUpdationObject.shipping_method = this.orderUpdationService.shippingMethodObj;
  }

  getderivedCostDetails(derivedCostObj){
    this.orderUpdationService.orderUpdationObject.derived_cost = derivedCostObj.delivery;
    this.orderUpdationService.orderUpdationObject.base_cost = derivedCostObj.base;
    this.orderUpdationService.orderUpdationObject.order_taxes = derivedCostObj.order_rates;
  }

  getOrderSummary(orderSummaryObj){
    this.orderUpdationService.orderUpdationObject.order_summary = orderSummaryObj;
  }

  getShippingCarriers(shippingObj){
    this.orderUpdationService.orderUpdationObject.shipping_carrier = shippingObj;
  }

}
