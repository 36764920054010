import { Component, OnInit } from '@angular/core';
import {AppService} from '../../app.service';
import {CartService} from '../../cart-page/cart-page.service';

import {Router} from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {ENDPOINTS, ORG_DETAILS} from '../../app.config';
import { createElementCssSelector } from '@angular/compiler';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html'
})
export class MiniCartComponent implements OnInit {

  constructor(public appService : AppService,private cartService : CartService,private datePipe: DatePipe,private router : Router) { }

  ngOnInit(): void {
    
  }

  removeItem(index)
  {
    this.appService.cartProducts.results.splice(index,1);
    this.cartService.updateCartItem(this.appService.cartProducts.results);
  }

  

  goToCheckout() {

    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }

    this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
      resp => {
        if (resp.success === 1 && resp.result.data.length > 0) {

          var today = moment();

          var acceptanceTiming = resp.result.data[0].order_acceptance_timing;

          var timeAvailable: boolean;

          if (acceptanceTiming === []) {
            this.router.navigate(['/checkout-new'])
            return
          }
          else {
            acceptanceTiming.forEach(element => {
              let from = moment(today.format('YYYY-MM-DD') + " " + element.from,"YYYY-MM-DD hh:mm A")
              let to = moment(today.format('YYYY-MM-DD') + " " + element.to,"YYYY-MM-DD hh:mm A")

              if (today >= from && today <= to) {
                timeAvailable = true
                return
              }
            });
          }
          if (timeAvailable === true) {
            this.router.navigate(['/checkout-new'])
          }
          else {
            this.router.navigate(['/cart'],{ state: { shop: false } })
          }

        }
        else
        {
          this.router.navigate(['/cart'])
        }
      },
      err => {
        this.router.navigate(['/cart'])
      })



  }

}
