import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AmazingTimePickerService } from 'amazing-time-picker';
import {ENDPOINTS, ORG_DETAILS} from '../app.config';
import {AppService} from '../app.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { NavbarService} from '../navbar/navbar.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';

@Component({
  selector: 'app-gift-meal',
  templateUrl: './gift-meal.component.html'
})
export class GiftMealComponent implements OnInit {


  Default1: FormGroup;
  validateForm : boolean = false ;
  showAlerts :boolean = false;
  acceptanceTiming : any;
  restaurentAddressList : any;
  timeslot : any;
  slots : any[]=[];
  giftMealServices: any[]=[];
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };


  datePickerConfig = {
    format : "yyyy-MM-DD",
    min : moment().format("YYYY-MM-DD")
  }

  @ViewChild('dayPicker') datePicker: DatePickerComponent;



  constructor(private formBuilder : FormBuilder,private router: ActivatedRoute,private routerUrl : Router,private timePick : AmazingTimePickerService, private apiService : AppService,private alertService : AlertsService,
    private navbarService: NavbarService,
    private datePipe : DatePipe) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.Default1= new FormGroup({name: this.formBuilder.control("",[Validators.required,Validators.minLength(3),Validators.maxLength(80),Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),email_address: this.formBuilder.control("",[Validators.required,Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$"),Validators.maxLength(50)]),mobile_number: this.formBuilder.control("",[Validators.required,Validators.minLength(8),Validators.maxLength(15),Validators.pattern("[0-9]*")]),date: this.formBuilder.control("",[Validators.required,]),time: this.formBuilder.control("",[Validators.required,]),no_of_persons: this.formBuilder.control("",[Validators.required,Validators.minLength(1),Validators.maxLength(2),Validators.pattern("[0-9]*")]),address: this.formBuilder.control("",[Validators.required,Validators.minLength(5),Validators.maxLength(80),]),})


  this.getServices();

  this.getAcceptanceTimings()
  }

  get D1(){
    return this.Default1.controls
    }

    getServices(){
      let formDataJson = {
        domain_name : ORG_DETAILS.DOMAIN_NAME,
        user_id : ORG_DETAILS.USER_ID,
        extras:{
          find:{

          }
        }
      }

      this.apiService.postApiCall(formDataJson,ENDPOINTS.GETALL_GIFTMEALSETTINGS).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {

              resp.result.data[0].services.forEach(element => {
                if(element.is_display_store == true){
                  this.giftMealServices.push(element);
                }
              });

            }
            else if (resp.success === 0) {
              if (resp.message) {
                this.alertService.error(resp.message, this.options);
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              this.alertService.error(resp.message, this.options);

            }
            else {
              this.alertService.error('Something bad happened. Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            this.alertService.error('Session Time Out! Please login Again', this.options)
            this.routerUrl.navigate([`/login-new`], { skipLocationChange: false });
          }
          else if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }

    saveForm()
    {
      this.showAlerts = true;

      let currentData = new Date()

      let date = currentData.getDate() < 10 ? "0"+ (currentData.getDate()).toString() : (currentData.getDate()).toString()

      let month = currentData.getMonth() + 1 < 10 ? "0" + (currentData.getMonth()+ 1).toString() : (currentData.getMonth()+ 1).toString()

      let hour = currentData.getHours() < 10 ? "0"+ (currentData.getHours()).toString() : (currentData.getHours()).toString()

      let minute = currentData.getMinutes() < 10 ? "0"+ (currentData.getMinutes()).toString() : (currentData.getMinutes()).toString()

      let currentTime = hour + ":" + minute

      //TODAY DATE
      let today = currentData.getFullYear().toString() + "-" + month + "-" +  date

      //FIVE DAY FROM CURRENT DATE
      var fiveDay = this.datePipe.transform(new Date(new Date().getTime()+(5*24*60*60*1000)),"yyyy-MM-dd")

      if (this.Default1.value.date === "" )
      {
        this.Default1.controls.date.setErrors({"required": true});
        this.validateForm = true;
        return
      }
      //CHECKING 5 DAY WITH SELECTED DATE
      else if(this.Default1.value.date < fiveDay)
      {
        this.Default1.controls.date.setErrors({"previous_date": true});
        this.validateForm = true;
        return
      }
      else
      {
        this.Default1.controls.date.setErrors(null)
      }


        if(this.Default1.valid)
        {
            this.validateForm = false;

            let data = this.Default1.value
            let timeZone = data['time'].split(' ')[1]
            data['time']=data['time'].split(' ')[0];


            if(timeZone.toLowerCase() == 'am')
            {
              if(Number(data['time'].split(':')[0]) == 12)
              {
                data['time'] = '00:'+data['time'].split(':')[1]
               // data['time'] = '00:'+data['time'].split(' ')[0]
              }
            }
            else
            {
              if(Number(data['time'].split(':')[0]) !=12)
              {
                var pmHour = Number(data['time'].split(':')[0]) + 12
                data['time'] = pmHour.toString()+':'+data['time'].split(':')[1];
              }
              else
              {

                data['time'] = '12'+":"+data['time'].split(':')[1];
              }
            }

            data["payment_date"] = today
            data["payment_mode"]  =  'Internet Banking'
            data['payment_status'] =  'Unpaid'
            data['total_amount'] = 0
            data['visiting_date'] = data['date']
            data['status'] = 'Booked'

            this.giftMealServices.forEach((ele)=>
            {
              data['total_amount'] += Number(ele.default_price)
              ele.remarks = ""
            })

            data['services'] = this.giftMealServices

            let formDataJson = {
              // domain_name: this.authTokenService.getDomain(),
              // user_id: this.authTokenService.getUserId(),
              domain_name : ORG_DETAILS.DOMAIN_NAME,
              user_id : ORG_DETAILS.USER_ID,

              payload: {
                giftmeal_creation : data
              },
              extras: {
                find: {

                }
              }
            }

            this.apiService.postApiCall(formDataJson,ENDPOINTS.GIFT_MEAL_CREATE).subscribe(
              resp => {
                if (resp) {
                  if (resp.success === 1 && resp.status_code === 200) {
                    window.scrollTo(0,0);
                    this.alertService.success("Gift Meal Added!",this.options);
                    this.cancelForm();
                  }
                  else if (resp.success === 0) {
                    if (resp.message) {
                      this.alertService.error(resp.message, this.options);
                    }
                  }
                  else if (resp.message && resp.status_code !== 200) {
                    this.alertService.error(resp.message, this.options);

                  }
                  else {
                    this.alertService.error('Something bad happened. Please try again!', this.options);
                  }
                }
              },
              err => {
                if (err.error.statusCode === 403) {
                  this.alertService.error('Session Time Out! Please login Again', this.options)
                  this.routerUrl.navigate([`/login-new`], { skipLocationChange: false });
                }
                else if (err.error.error.message) {
                  this.alertService.error(err.error.error.message, this.options)
                }
                else {
                  this.alertService.error('Something bad happened. Please try again!', this.options);
                }
              }
            )


        }
        else
        {
            this.validateForm = true;
        }
    }

    pickTime()
    {
      const amazingTimePicker = this.timePick.open({
        time: this.Default1.value.time,
        theme:'light',
        arrowStyle: {
          background:'#afacac',
        }
      });

      amazingTimePicker.afterClose().subscribe(time=>
        {
          this.Default1.controls.time.setValue(time)
        })
    }

    cancelForm()
    {
      this.Default1.reset({name: "",
      email_address: "",
      mobile_number: "",
      date: "",
      time: "",
      no_of_persons: "",
      address: ""})
    }

    getAcceptanceTimings(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
      this.apiService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
        resp => {
          if(resp.success === 1 && resp.result.data.length > 0){

            this.acceptanceTiming = resp.result.data[0]

            this.getOutletData()
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }

    // PROCESSING METHODS
    getOutletData(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
      this.apiService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
        resp => {
          if(resp.success === 1){
            this.restaurentAddressList = resp.result.data[0]
            this.timeslot = Number(this.restaurentAddressList.time_slot.substring(0,2))

            this.findSlots();
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }


    findSlots() {

        let date = moment().format("YYYY-MM-DD")

        this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
          this.slots = [];
          var from = moment(date + " " + shift.from ,"YYYY-MM-DD hh:mm a");
          var to = moment(date + " " + shift.to,"YYYY-MM-DD hh:mm a");
          while (from <= to) {
          this.slots.push(from.format("hh:mm a"));

          from = from.add(this.timeslot, 'minutes');
          }
        })

      }

}
