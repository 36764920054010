import { Component, OnInit, Input, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-featured-collections',
  templateUrl: './featured-collections.component.html',

})
export class FeaturedCollectionsComponent implements OnInit {
  @Input() collections: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  
  ngOnInit(): void {
  }
  getClass(index) {
    return ((index === 0 || index === 1)) ? 'col-md-6 ' : 'col-lg-3 col-md-2';
  }
  categoryListing(category){
    this.router.navigate([`/listing`], {queryParams: { terms:[category], type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
  }
}
