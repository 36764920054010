import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AppService } from '../app.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { NavbarService} from '../navbar/navbar.service';

@Component({
  selector: 'ecommerce-user-forgot-password',
  templateUrl: './user-forgot-password.component.html'
})
export class UserForgotPasswordComponent implements OnInit {

  // CUSTOMER TAB
  guestForm: FormGroup;
  displayForm: FormGroup;
  otpLoginForm: FormGroup;
  passwordLoginForm: FormGroup;

  validateGuestForm: boolean = false;
  vaidateotpLoginForm: boolean = false;
  validatepasswordLoginForm: boolean = false;

  freezeGuest: boolean = false;
  enable_orderdetails: boolean = true;
  enable_shipping: boolean = true;
  otpLogin: boolean = true;
  showOtpLogin: boolean = false;
  showPswd: Boolean = false;
  showpasswordSetupForm: boolean = false;

  customerForm: string = "guestForm";
  currentTab: string = "customer";
  userRegistartionForm: FormGroup;
  validateForm: boolean = false;
  showalertmsg: boolean = true;

  passdata: any;

  Password: any;
  password: any;
  formDataJson: any;
  queryParams: any;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  userRegistartionFormData: any;

  countryCodes = [
    { 'name': "IN +91", 'value': "+91" }
  ]

  userMobileNumber : string;
  otpResendDuration : number;
  oldNumber : string;

  constructor(
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localService: LocalStorageService,
    private navbarService: NavbarService,
    ) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');

    this.guestForm = new FormGroup({
      guest_mobile: this.formBuilder.control('', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}"), Validators.required])
    })

    this.otpLoginForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    })

    this.passwordLoginForm = new FormGroup({
      otpp: this.formBuilder.control('', [Validators.required]),
      passwordp: this.formBuilder.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPswd: this.formBuilder.control('', [Validators.required])
    }, { validators: UserForgotPasswordComponent.checkPswd })



    this.displayForm = new FormGroup({
      display_email: this.formBuilder.control('', [])
    })
    this.userRegistartionForm = new FormGroup({
      first_name: this.formBuilder.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(40), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      last_name: this.formBuilder.control('', [Validators.maxLength(40), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      email: this.formBuilder.control('', [Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$")]),
      country_code: this.formBuilder.control('+91'),
      phone_number: this.formBuilder.control('', [Validators.maxLength(15), Validators.pattern("^[+]*[(]{0,1}[0-9]{5}[)]{0,1}[-\s\./0-9]*$"), Validators.required]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)])
    })

    if (this.localService.get('UserName')) {
      this.router.navigate(['/']);
    }
  }

  get f() { return this.guestForm.controls; }

  get f4() {
    return this.otpLoginForm.controls;
  }

  get f5() {
    return this.passwordLoginForm.controls;
  }
  // CUSTOMER TAB
  static checkPswd(group: AbstractControl): { [key: string]: boolean } {
    let pass = group.get('passwordp').value;
    let confirmPass = group.get('confirmPswd').value;
    return pass === confirmPass ? null : { passwdNotMatch: true }
  }

  validpasswd(): void {
    if (this.passwordLoginForm.hasError('passwdNotMatch')) {
      this.passwordLoginForm.controls['confirmPswd'].setErrors({ 'incorrect': true })
    }
  }

  saveGuest() {
    if (this.guestForm.valid) {
      let mobileNumber = this.guestForm.value.guest_mobile
      this.validateGuestForm = false;

      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "phone_number": this.guestForm.value.guest_mobile.toString()
          }
        }
      }
      // API CALL TO FIND EXISTING/NEW USER
      this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
        resp => {
          console.log(resp)
          if (resp.result.data.length > 0) {
            if (resp.result.data[0].password) {
              this.showOtpLogin = true;
              this.freezeGuest = true;
            }
            else{
              this.alertService.error('Password not set!', this.options)
              return
            }
            this.showpasswordSetupForm = true;
            

             if(this.oldNumber !== this.guestForm.value.guest_mobile.toString())  
             {
              this.sendOtptoUser(mobileNumber);
              
             }
              else if (this.otpResendDuration  && this.otpResendDuration < new Date().getTime())
              {
              this.sendOtptoUser(mobileNumber);
              }
              else
              {
                window.scrollTo(0,0);
                this.alertService.error("OTP already sent to this number "+ this.userMobileNumber, this.options)
              }
              this.userMobileNumber = this.guestForm.value.guest_mobile;
          }
          else{
            this.showpasswordSetupForm = false;
            this.alertService.error('Mobile number not registered, please register and login', this.options)
            return
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    }
    else {
      this.validateGuestForm = true;
    }
  }

  editGuest() {
    this.freezeGuest = false;
    this.otpLogin = true;
    this.showOtpLogin = false;
    this.showpasswordSetupForm = false;
    this.oldNumber = this.guestForm.value.guest_mobile.toString();
    this.clearForms();
  }
  sendOtptoUser(mobileNo) {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "organization_id": ORG_DETAILS.ORGANIZATION_ID,
      "store_id": ORG_DETAILS.STORE_ID,
      "data": {
        "otp": {
          "countrycode": "+91",
          "mobile": mobileNo,
          "otppurpose": "login"
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.SEND_OTP).subscribe(
      resp => {
        this.showOtpLogin = true;
        this.freezeGuest = true;
        this.otpLogin=false;
        //set resend duration
        if(resp && resp.success === 1)
        {
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
        }

      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  signout() {
    this.changeCustomForm('guestForm');
    this.validateGuestForm = false;
    this.guestForm.reset();
    this.showOtpLogin = false;
    this.freezeGuest = false;
    this.enable_shipping = false;
    this.clearForms();
    document.getElementById('cancelBtn').click();
  }

  showPassword() {
    this.showPswd = !this.showPswd;
  }

  changeLoginMethod(methodType) {
    this.clearForms();
    if (methodType === "otp") {
      this.otpLogin = true;
    }
    else if (methodType === "password") {
      this.otpLogin = false;
    }
  }

  resendCode() {

    this.showalertmsg = true;

    if(this.otpResendDuration === undefined)
    {
      this.sendOtptoUser(this.guestForm.value.guest_mobile);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else if(this.otpResendDuration < new Date().getTime())
    {
      this.sendOtptoUser(this.guestForm.value.guest_mobile);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else
    {
      window.scrollTo(0,0);
      this.alertService.error("OTP already sent to this number "+ this.userMobileNumber, this.options)
    }
  }

  passwordLogin() {
    if (this.passwordLoginForm.valid) {
      this.validatepasswordLoginForm = false;
      this.showalertmsg = true;
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "organization_id": ORG_DETAILS.ORGANIZATION_ID,
        "store_id": ORG_DETAILS.STORE_ID,
        "data": {
          "otp": {
            "userotp": this.passwordLoginForm.controls.otpp.value,
            "mobile": this.guestForm.controls.guest_mobile.value
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
        resp => {
          if (resp.success === 1) {
            this.createUser({
              "type": 'passwordLogin',
              "phone": this.guestForm.controls.guest_mobile.value,
              "password": this.passwordLoginForm.controls.passwordp.value,
            })
            this.alertService.success(resp.message, this.options)
          }
          else {
            console.log(resp.message)
            this.alertService.error(resp.message, this.options)
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    }
    else {
      this.validatepasswordLoginForm = true;
    }
  }
  createUser(userData: any) {
    this.showalertmsg = true;
    let requestData: any;
    if (userData.type === 'passwordLogin') {
      requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          update:
          {
            "password": userData.password
          }
        },
        extras: {
          find: {
            username: userData.phone.toString(),
          }
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.RESET_PASSWORD).subscribe(
      resp => {
        console.log(resp)
        if (resp.success === 1) {
          this.alertService.success('Password Updated', this.options)
          this.router.navigate([`/login-new`], { state: { result: 'Password Updated' }, relativeTo: this.activatedRoute, skipLocationChange: false });

        }
        else {
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
          // console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  signUp() {
    if (this.userRegistartionForm.valid) {
      this.validateForm = false;

      this.userRegistartionFormData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          customer_creation: this.userRegistartionForm.value
        }
      }

      this.appService.postApiCall(this.userRegistartionFormData, ENDPOINTS.CUSTOMER_CREATE).subscribe(resp => {
        if (resp) {
          if (resp.success == 1) {
            this.alertService.success(resp.message, this.options);
            this.router.navigate([`/login-new`], { state: { result: resp.message }, relativeTo: this.activatedRoute, skipLocationChange: false });
          }
          else {
            this.alertService.warn(resp.message, this.options);
          }
        }
      },
        err => {
          if (err.error.message) {
            this.alertService.error(err.error.message, this.options);
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      )
    }
    else {
      this.validateForm = true;
    }
  }

  clearForms() {
    this.otpLoginForm.reset();
    this.passwordLoginForm.reset();
    this.vaidateotpLoginForm = false;
    this.validatepasswordLoginForm = false;
  }

  changeCustomForm(customForm: string) {
    this.customerForm = customForm;
    if (customForm === 'guestForm') {
      this.clearForms();
    }
  }
}