import { Injectable } from '@angular/core';

import { ENDPOINTS,ORG_DETAILS } from '../app.config';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';
import { RegistrationOfferInitiationService } from './registration-offer-initiation.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationOfferApiService {

  registrationOffer = {
    available:false,
    inidividual:false,
    business:false,
    product_ids:[]
  }

  selectedAddress:any;
  distance:any;

  productDetails = [];

  deliverDetails = {
    name:"",
    charge:0
  }

  flatRate = {
    display:"",
    type: "",
    rate:0
  }

  BUSINESSUSER_LABEL = "BUSINESS CUSTOMER";
  INDIVIDUALUSER_LABEL = "INDIVIDUAL CUSTOMER";

  constructor(
    private checkoutApiService: CheckoutApiService,
    private registrationOfferInitiationService: RegistrationOfferInitiationService
  ) { }

  getRegistrationOffer(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.REGISTRATION_OFFER).then(
        respData1 =>{
          let respData:any = respData1;
          if(respData.success){
            var registrationOffer = {
              available:false,
              inidividual:false,
              business:false,
              product_ids:[]
            }
            let res:any = respData;
            if(res.data.length > 0){
              let registrationData = res.data[0];
              if(registrationData.has_usage_limit){
                registrationOffer.available = true;
              }
              if(registrationData.customertype.length > 0){
                registrationData.customertype.forEach(element => {
                  if(element.toUpperCase() === this.INDIVIDUALUSER_LABEL){
                    registrationOffer.inidividual = true;
                  }
                  if(element.toUpperCase() === this.BUSINESSUSER_LABEL){
                    registrationOffer.business = true;
                  }
                });
              }
              if(registrationData.new_registration_discount.choose_free_product.select_free_product.length > 0){
                registrationData.new_registration_discount.choose_free_product.select_free_product.forEach(product => {
                  registrationOffer.product_ids.push(product.id)
                  this.registrationOffer = registrationOffer;
                  this.getProductDetails(this.registrationOffer.product_ids);
                });
              }
              resolve(registrationOffer);
            }
          }
        }
      )
    })
  }

  getOutletDetails(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_OUTLETS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let outletData: any = res.data[0];
            resolve({latitude:outletData.latitude, longitude: outletData.longitude, enabled:outletData.enable_distance_radius, distance:outletData.distance_radius})
            // resolve({latitude:outletData.latitude, longitude: outletData.longitude, enabled:false, distance:""})
          }
        }
      )
    })
  }

  getRestaurentdetailsforShipping(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.OUTLET).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.length > 0){
              resolve (res.data[0])
            }
          }
        }
      )
    })
  }

  getProductDetails(productList){
    return new Promise((resolve, reject) => {
      this.productDetails = [];
      productList.forEach(element => {
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras":{
            "find":{
              "id":element
            },
            "pagination":false,
            "paginationDetails":{
              "limit":0,
              "pageSize":2
            },
            "sorting":true,
            "sortingDetails":{
              "sortfield":"","sortorder":1
            }
          }
        }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.PRODUCT_DETAILS, requestData).then(
          respData => {
            let res:any = respData;
            if(res.success){
              this.productDetails.push(res.data[0])
            }
          }
        )
      });
      resolve(this.productDetails)
    })
  }

  getShippingCarrier(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETSHIPPINGCARRIERS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.data[0])
          }
        }
      )
    })
  }

  getEcommSettings(eid){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id": Number(eid)
          }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_PAYMENTSETTINGS, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.data[0]);
          }
        }
      )
    })
  }

  getPickdropcharges(deliverObj){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload": {
          "delivery_rate": deliverObj
        }
      }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.PICKANDDROP, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){
          resolve(Number(res.data.deliveryCharge))
        }
        else{
          resolve("");
        }
      })
    })
  }

  getDelhiveryCharges(weight, oPin, dPin){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload": {
            "invoice_charge": {
                "md": "S",
                "cgm":  weight,
                "o_pin": oPin,
                "ss": "DTO",
                "d_pin": dPin
            }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.DELHIVERY_CHARGES, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.data[0].total_amount)
          }
          else{
            resolve("");
          }
        }
      )
    })				
  }

  getDunzoCharges(deliveryObj){
    return new Promise((resolve, reject) => {
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          delivery_quote: {
            pickup_details: [
              {
                lat: deliveryObj.pickup.latitude,
                lng: deliveryObj.pickup.longitude,
                reference_id: deliveryObj.oid
              }
            ],
            optimised_route:true,
            drop_details: [
              {
                lat: deliveryObj.drop.latitude,
                lng: deliveryObj.drop.longitude,
                reference_id: deliveryObj.oid
              }
            ]
          }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.DUNZO_CHARGES, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.estimated_price != undefined){
              resolve(res.data.estimated_price)
            }
          }
          else{
            resolve("");
          }
        }
      )
    })
  }

  getFlatRateCharges(){
    return new Promise((resolve, reject) =>{
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_SHIPMENTMETHODS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let shippingmethodDetails:any = res.data[0];
            if(shippingmethodDetails.flat_rate.is_enabled){
              resolve({diaplayName: shippingmethodDetails.flat_rate.display_name, type: shippingmethodDetails.flat_rate.type, rate: Number(shippingmethodDetails.flat_rate.settings.rate)})
            }
            
          }
        }
      )
    })
  }

  saveCustomerAddress(customerAddress){
    return new Promise((resolve, reject) => {
      this.getOutletDetails().then(
        respDatao => {
          let reso:any = respDatao;
          this.getShippingCarrier().then(
            respData => {
              let res:any = respData;
              this.getEcommSettings(res.trackable_integrator_reference_id).then(
                respData1 => {
                  let res1:any = respData1;
                  if(res.carrier_name.toUpperCase() === res1.title.toUpperCase() && res.carrier_name.toUpperCase() === "PICKDROP"){
                    this.deliverDetails.name = res.carrier_name;
                    let deliverObj = {
                      "sendFrom": "Chennai",
                      "pickupLatitude": reso.latitude,
                      "pickupLongitude": reso.longitude,
                      "dropLatitude": this.selectedAddress.latitude.toString(),
                      "dropLongitude": this.selectedAddress.longitude.toString(),
                      "distance":this.distance
                    }
                    this.getPickdropcharges(deliverObj).then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res2 !== ""){
                          this.deliverDetails.charge = res2;
                          resolve("PICKDROP");
                        }
                        else{
                          this.getFlatRateCharges().then(
                            respData3 => {
                              let res3:any = respData3;
                              this.flatRate.display = res3.diaplayName
                              this.flatRate.type = res3.type
                              this.flatRate.rate = res3.rate
                              resolve("FLATRATE");
                            }
                          )
                        }
                      })
                  }
                  else if(res.carrier_name.toUpperCase() === res1.title.toUpperCase() && res.carrier_name.toUpperCase() === "DUNZO"){
                    this.deliverDetails.name = res.carrier_name;
                    this.getRestaurentdetailsforShipping().then(
                      respData1 =>{
                        let res1:any = respData1;
                        let dunzoObj = {
                          oid: "12", //NEED TO CHANGE LATER
                          pickup:{
                            latitude: res1.latitude,
                            longitude: res1.longitude,
                          },
                          drop:{
                            latitude: this.selectedAddress.latitude,
                            longitude: this.selectedAddress.longitude,
                          }
                        }
                        this.getDunzoCharges(dunzoObj).then(
                          drespData => {
                            let dres:any = drespData;
                            if(dres !== ""){
                              this.deliverDetails.charge = dres;
                              resolve("DUNZO")
                            }
                            else{
                              this.getFlatRateCharges().then(
                                respData3 => {
                                  let res3:any = respData3;
                                  this.flatRate.display = res3.diaplayName
                                  this.flatRate.type = res3.type
                                  this.flatRate.rate = res3.rate
                                  resolve("FLATRATE");
                                }
                              )
                            }
                          })
                      }
                    )
                  }
                  else if(res.carrier_name.toUpperCase() === res1.title.toUpperCase() && res.carrier_name.toUpperCase() === "DELHIVERY"){
                    this.deliverDetails.name = res.carrier_name;
                    this.getRestaurentdetailsforShipping().then(
                      respData1 =>{
                        let res1:any = respData1;
                        let ozipcode = res1.zipcode;
                        let dzipcode = this.selectedAddress.zipcode;
                        let weight = "500";
                        this.getDelhiveryCharges(weight, ozipcode, dzipcode).then(
                          dhrespData => {
                          let dhres:any = dhrespData;
                          if(dhres !== ""){
                            this.deliverDetails.charge = dhres;
                            resolve("DELHIVERY");
                          }
                      else{
                        this.getFlatRateCharges().then(
                          respData3 => {
                            let res3:any = respData3;
                            this.flatRate.display = res3.diaplayName
                            this.flatRate.type = res3.type
                            this.flatRate.rate = res3.rate
                            resolve("FLATRATE");
                          }
                        )
                      }
                    }
                  )}
                    )
                  }
                  else{
                    this.getFlatRateCharges().then(
                      respData3 => {
                        let res3:any = respData3;
                        this.flatRate.display = res3.diaplayName
                        this.flatRate.type = res3.type
                        this.flatRate.rate = res3.rate
                        resolve("FLATRATE");
                      }
                    )
                  }
                }
              )
              
            }
          )
        }
      )
      
    })
  }

  clearFreeSample(){
    this.registrationOfferInitiationService.clearOrderObj();
  
    this.selectedAddress = undefined;
    this.distance = undefined;
  
    this.deliverDetails = {
      name:"",
      charge:0
    }
  
    this.flatRate = {
      display:"",
      type: "",
      rate:0
    }
  }

  clearOnChange(){
    this.registrationOffer = {
      available:false,
      inidividual:false,
      business:false,
      product_ids:[]
    }
  
    this.selectedAddress = undefined;
    this.distance = undefined;
  
    this.productDetails = [];
  
    this.deliverDetails = {
      name:"",
      charge:0
    }
  
    this.flatRate = {
      display:"",
      type: "",
      rate:0
    }
  }
  

}
