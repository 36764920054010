import { Component, OnInit } from '@angular/core';
import { NavbarService} from '../navbar/navbar.service';

@Component({
  selector: 'app-static-pages',
  templateUrl: './static-pages.component.html'
})
export class StaticPagesComponent implements OnInit {

  constructor(    private navbarService: NavbarService,
    ) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');

  }

}
