import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { LocalStorageService } from '../auth-service/local-storage.service'
import { AlertsService } from '../shared/alerts/alerts.service';
import { NavbarService} from '../navbar/navbar.service';

// FOR CHECKOUT
import { CustomerService } from '../checkout/customer.service';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  // CUSTOMER TAB
  guestForm: FormGroup;
  displayForm: FormGroup;
  otpLoginForm: FormGroup;
  passwordLoginForm: FormGroup;
  newPasswordLoginForm: FormGroup;

  validateGuestForm: boolean = false;
  vaidateotpLoginForm: boolean = false;
  validatepasswordLoginForm: boolean = false;
  validatenewPasswordLoginForm: boolean = false;

  freezeGuest: boolean = false;
  enable_orderdetails: boolean = true;
  enable_shipping: boolean = true;
  otpLogin: boolean = true;
  showOtpLogin: boolean = false;
  showPswd: Boolean = false;
  showpasswordSetupForm: boolean = true;
  newPasswordSetUp: boolean =false;

  customerForm: string = "guestForm";
  currentTab: string = "customer";
  userRegistartionForm: FormGroup;
  showalertmsg: boolean = true;
  userID: any;
  userName: any;
  domainName: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  showLgnPswd: boolean = false;
  resultMessage: any;
  backToUrl = "/";

  userMobileNumber : string;
  otpResendDuration : number;
  oldNumber : string;

  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    private localService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private navbarService: NavbarService,
    private customerService:CustomerService  //CHECKOUT
  ) {
    if (this.router.getCurrentNavigation() != undefined && this.router.getCurrentNavigation() != null) {
      if (this.router.getCurrentNavigation().extras.state) {
        this.resultMessage = this.router.getCurrentNavigation().extras.state.result;
      }
    }
  }

  ngOnInit(): void {

    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.activatedRoute.queryParamMap.subscribe(params => {
      // console.log(params['params'].url);
      if (params['params'].url) {
        this.backToUrl = params['params'].url;
      }
    })
    this.guestForm = new FormGroup({
      guest_mobile: this.formBuilder.control('', [ Validators.pattern("^[+]*[(]{0,1}[0-9]{10}"), Validators.required])
    })

    this.otpLoginForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    })

    this.passwordLoginForm = new FormGroup({
      passwordp: this.formBuilder.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
    })
    this.newPasswordLoginForm = new FormGroup({
      otpp: this.formBuilder.control('', [Validators.required]),
      passwordp: this.formBuilder.control('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPswd: this.formBuilder.control('', [Validators.required])
    }, { validators: UserLoginComponent.checkPswd })



    this.displayForm = new FormGroup({
      display_email: this.formBuilder.control('', [])
    })

    if (this.localService.get('UserName')) {
      this.router.navigate(['/']);
    }
  }

  get f() { return this.guestForm.controls; }

  get f4() {
    return this.otpLoginForm.controls;
  }

  get f5() {
    return this.passwordLoginForm.controls;
  }
  get f6() {
    return this.newPasswordLoginForm.controls;
  }

  // CUSTOMER TAB
  static checkPswd(group: AbstractControl): { [key: string]: boolean } {
    let pass = group.get('passwordp').value;
    let confirmPass = group.get('confirmPswd').value;
    return pass === confirmPass ? null : { passwdNotMatch: true }
  }

  validpasswd(): void {
    if (this.newPasswordLoginForm.hasError('passwdNotMatch')) {
      this.newPasswordLoginForm.controls['confirmPswd'].setErrors({ 'incorrect': true })
    }
  }

  saveGuest() {
    if (this.guestForm.valid) {
      let mobileNumber = this.guestForm.value.guest_mobile
      this.validateGuestForm = false;

      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "phone_number": this.guestForm.value.guest_mobile.toString()
          }
        }
      }
      // API CALL TO FIND EXISTING/NEW USER
      this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
        resp => {
          this.showOtpLogin = true;
          this.freezeGuest = true;
          this.showpasswordSetupForm = true;
          //this.sendOtptoUser(mobileNumber);

          if (resp.result.data.length > 0) {
            if (resp.result.data[0].password) {
              this.newPasswordSetUp = false;
            }
            else{
              this.newPasswordSetUp = true;

            }

            this.userID=resp.result.data[0].id;
            if(resp.result.data[0].first_name)
            {this.userName=resp.result.data[0].first_name;}
            this.customerService.setCustomerData(this.guestForm.value.guest_mobile,resp.result.data[0].id)

            //get entered mobile number
            if(resp.result.data[0].password && resp.result.data[0].password !== "")
            {
              this.otpLogin = false;
            }
            else
            {
             if(this.userMobileNumber !== this.guestForm.value.guest_mobile.toString())  
             {
              this.sendOtptoUser(mobileNumber);
              
             }
              else if (this.otpResendDuration  && this.otpResendDuration < new Date().getTime())
              {
              this.sendOtptoUser(mobileNumber);
              }
              else
              {
                window.scrollTo(0,0);
                this.alertService.error("OTP already sent to this number "+ this.userMobileNumber, this.options)
              }
              this.otpLogin = true;
            }
            this.userMobileNumber = this.guestForm.value.guest_mobile.toString();
          }
          else{
            this.newPasswordSetUp = true;
            this.userMobileNumber = this.guestForm.value.guest_mobile.toString();
            this.otpLogin = false;
            this.sendOtptoUser(mobileNumber);
            this.oldNumber = this.userMobileNumber;
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    }
    else {
      this.validateGuestForm = true;
    }
  }

  editGuest() {
    this.freezeGuest = false;
    this.otpLogin = true;
    this.showOtpLogin = false;
    this.showpasswordSetupForm = true;
    this.newPasswordSetUp = false;
    this.oldNumber = this.guestForm.value.guest_mobile.toString();
    this.clearForms();
  }

  showPassword() {
    this.showPswd = !this.showPswd;
  }

  changeLoginMethod(methodType) {
    this.clearForms();
    if (methodType === "otp") {
      this.otpLogin = true;
      
      if(this.oldNumber !== undefined && this.oldNumber !== this.userMobileNumber)
      {
        
        this.sendOtptoUser(this.guestForm.value.guest_mobile)
        this.oldNumber = this.guestForm.value.guest_mobile.toString();
      }
      else if(this.otpResendDuration === undefined)
      {
        
        this.sendOtptoUser(this.guestForm.value.guest_mobile)
      }
      else if(this.otpResendDuration < new Date().getTime())
      {
        
        this.sendOtptoUser(this.guestForm.value.guest_mobile)
      }
      
    }
    else if (methodType === "password") {
      this.otpLogin = false;
    }
  }

  resendCode() {
    this.showalertmsg = true;

    if(this.otpResendDuration === undefined)
    {
      this.sendOtptoUser(this.guestForm.value.guest_mobile);
      window.scrollTo(0,0);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else if(this.otpResendDuration < new Date().getTime())
    {
      this.sendOtptoUser(this.guestForm.value.guest_mobile);
      window.scrollTo(0,0);
      this.alertService.success('OTP has been sent Successfully!', this.options);
    }
    else
    {
      window.scrollTo(0,0);
      this.alertService.error("OTP already sent to this number "+ this.userMobileNumber, this.options)
    }
  }
  sendOtptoUser(mobileNo) {
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "organization_id": ORG_DETAILS.ORGANIZATION_ID,
      "store_id": ORG_DETAILS.STORE_ID,
      "data": {
        "otp": {
          "countrycode": "+91",
          "mobile": mobileNo,
          "otppurpose": "login"
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.SEND_OTP).subscribe(
      resp => {
        this.showOtpLogin = true;
        this.freezeGuest = true;

        //set resend duration
        if(resp && resp.success === 1)
        {
          this.otpResendDuration = new Date().getTime() + 5*60*1000;
        }

      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  OTPLogin() {
    if (this.otpLoginForm.valid) {
      this.showalertmsg = true;
      this.vaidateotpLoginForm = false;
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "organization_id": ORG_DETAILS.ORGANIZATION_ID,
        "store_id": ORG_DETAILS.STORE_ID,
        "data": {
          "otp": {
            "userotp": this.otpLoginForm.controls.otp.value,
            "mobile": this.guestForm.controls.guest_mobile.value
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
        resp => {
          if (resp.success === 1) {
              this.otpLogging();
              window.scrollTo(0,0);
              this.alertService.success(resp.message, this.options)

          }
          else {
            window.scrollTo(0,0);
            this.alertService.error(resp.message, this.options)
          }
        },
        err => {
          if (err.error.error.message) {
            window.scrollTo(0,0);
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })

    }
    else {
      this.vaidateotpLoginForm = true;
    }

  }
  otpLogging(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "organization_id": ORG_DETAILS.ORGANIZATION_ID,
      "store_id": ORG_DETAILS.STORE_ID,
      "username": this.guestForm.controls.guest_mobile.value.toString(),
    }
    this.appService.postApiCall(requestData, ENDPOINTS.OTP_LOGIN).subscribe(
      resp => {
        if (resp.success === 1) {
          window.scrollTo(0,0);
          this.alertService.success(resp.message, this.options)
          this.customerService.setCustomerData(this.guestForm.value.guest_mobile, resp.user.id)
          this.alertService.success(resp.message, this.options)

          this.localService.set('UserId', resp.user.id);
          this.localService.set('domainName', resp.user.domain_name);
          this.appService.loggedin_user = true;
          this.appService.userLoggedIn = true;
          if(this.userName){
            this.localService.set('UserName', this.userName);
            this.appService.userName = this.userName;
          }
            else{
              this.localService.set('UserName', 'New User');
              this.appService.userName = 'New User';
            }
            setTimeout(() => {
              this.router.navigateByUrl(this.backToUrl);
            }, 1000);  
        }
        else {
          window.scrollTo(0,0);
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if (err.error.error.message) {
          window.scrollTo(0,0);
          this.alertService.error(err.error.error.message, this.options)
          // console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  passwordLogin() {
    if (this.passwordLoginForm.valid) {
      this.validatepasswordLoginForm = false;
      this.showalertmsg = true;
      this.createUser({
        "type": 'passwordLogin',
        "phone": this.guestForm.controls.guest_mobile.value,
        "password": this.passwordLoginForm.controls.passwordp.value,
      })
    }
    else {
      this.validatepasswordLoginForm = true;
    }
  }
  newPasswordLogin() {
    if (this.newPasswordLoginForm.valid) {
      this.validatenewPasswordLoginForm = false;
      this.showalertmsg = true;
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "organization_id": ORG_DETAILS.ORGANIZATION_ID,
        "store_id": ORG_DETAILS.STORE_ID,
        "data": {
          "otp": {
            "userotp": this.newPasswordLoginForm.controls.otpp.value,
            "mobile": this.guestForm.controls.guest_mobile.value
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
        resp => {
          if (resp.success === 1) {
            let  requestData;
            if(this.userID){
            requestData = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "organization_id": ORG_DETAILS.ORGANIZATION_ID,
              "store_id": ORG_DETAILS.STORE_ID,
              "username":this.guestForm.value.guest_mobile.toString(),
              "password":this.newPasswordLoginForm.value.passwordp,
              "id":this.userID
            }   
            }
            else{
              requestData = {
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "organization_id": ORG_DETAILS.ORGANIZATION_ID,
                "store_id": ORG_DETAILS.STORE_ID,
                "username":this.guestForm.value.guest_mobile.toString(),
                "password":this.newPasswordLoginForm.value.passwordp,
                }
            }
            this.newPasswordSetup(requestData)
            
          }
          else {
            console.log(resp.message)
            window.scrollTo(0,0);
            this.alertService.error(resp.message, this.options)
          }
        },
        err => {
          if (err.error.error.message) {
            window.scrollTo(0,0);
            this.alertService.error(err.error.error.message, this.options)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    }
    else {
      this.validatenewPasswordLoginForm = true;
    }
  }
  newPasswordSetup(request){
    this.showalertmsg=true
    this.appService.postApiCall(request, ENDPOINTS.OTP_LOGIN).subscribe(
      resp => {
        console.log(resp.message)
        if (resp.success === 1) {
          window.scrollTo(0,0);
          this.alertService.success('Password Set', this.options)
          this.localService.set('UserId', resp.user.id);
          this.localService.set('domainName', resp.user.domain_name);
          this.customerService.setCustomerData(this.guestForm.controls.guest_mobile.value, this.userID)
          this.appService.loggedin_user = true;
          this.appService.userLoggedIn = true;
          if(this.userName){
            this.localService.set('UserName', this.userName);
            this.appService.userName = this.userName;
          }
            else{
              this.localService.set('UserName', 'New User');
              this.appService.userName = 'New User';
            }       
            setTimeout(() => {
              this.router.navigateByUrl(this.backToUrl);
            }, 1000);           

        }
        else {
          window.scrollTo(0,0);
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if (err.error.error.message) {
          window.scrollTo(0,0);
          this.alertService.error(err.error.error.message, this.options)
          // console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  createUser(userData: any) {
    this.showalertmsg = true;
    let requestData: any;
    if (userData.type === 'otpLogin') {
      requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "organization_id": ORG_DETAILS.ORGANIZATION_ID,
        "store_id": ORG_DETAILS.STORE_ID,
        "username": userData.phone.toString(),
        "otp": this.otpLoginForm.controls.otp.value,
      }
    }
    else if (userData.type === 'passwordLogin') {
      requestData = {
        "username": userData.phone.toString(),
        "password": userData.password
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.LOGIN).subscribe(
      resp => {
        if (resp.success === 1) {
          this.customerService.setCustomerData(this.guestForm.value.guest_mobile, resp.user.id)
          window.scrollTo(0,0);
          this.alertService.success(resp.message, this.options)
          this.localService.set('UserId', resp.user.id);
          this.localService.set('domainName', resp.user.domain_name);
          this.appService.loggedin_user = true;
          this.appService.userLoggedIn = true;
          if(this.userName){
            this.localService.set('UserName', this.userName);
            this.appService.userName = this.userName;
          }
            else{
              this.localService.set('UserName', 'New User');
              this.appService.userName = 'New User';
            }
            window.scrollTo(0,0);
            this.alertService.success(resp.message, this.options)
            this.router.navigateByUrl(this.backToUrl);

        }
        else {
          window.scrollTo(0,0);
          this.alertService.error(resp.message, this.options)
        }
      },
      err => {
        if(err.message){
          window.scrollTo(0,0);
          this.alertService.error('Invalid Password', this.options)
          return 
        }
        else if (err.error.error.message) {
          window.scrollTo(0,0);
          this.alertService.error(err.error.error.message, this.options)
          // console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  clearForms() {
    this.otpLoginForm.reset();
    this.passwordLoginForm.reset();
    this.vaidateotpLoginForm = false;
    this.validatepasswordLoginForm = false;
  }

  changeCustomForm(customForm: string) {
    this.customerForm = customForm;
    if (customForm === 'guestForm') {
      this.clearForms();
    }
  }
  ngAfterViewInit(): void {
    this.alertService.clear();
    if (this.resultMessage !== "") {
      window.scrollTo(0,0);
      this.alertService.success(this.resultMessage, this.options);
      this.cdr.detectChanges();
    }
  }
  showLoginPassword() {
    this.showLgnPswd = !this.showLgnPswd;
  }
}