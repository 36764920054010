import { Injectable } from '@angular/core';

import { OrderInitiationService } from './order-initiation.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AssignOrderInitialsService {

  CUSTOMERKEY = "CustomerTab";
  NORMALTAXKEY = "NormalTax";

  constructor(
    private orderInitiationService : OrderInitiationService,
    private localService : LocalStorageService,
    private appService:AppService
  ) { }

  assignCustomerData(){
    if(this.localService.get(this.CUSTOMERKEY)){
      let customerId = JSON.parse(this.localService.get(this.CUSTOMERKEY)).userId;
      this.orderInitiationService.orderInitialObject.customer_id = customerId;
    }
  }

  getOrderStatusLabels(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_ORDERSTATUS).subscribe(
      resp => {
        if(resp.success === 1){
          if(resp.result.data.length > 0){
            let resData = resp.result.data;
            resData.forEach(element => {
              if(element.system_label === 'Pending'){
                this.orderInitiationService.orderInitialObject.status = element.custom_label;
                this.orderInitiationService.new_value.status = element.custom_label;
                this.orderInitiationService.orderInitialObject.status_id= element.id;
                console.log(this.orderInitiationService.orderInitialObject.status, this.orderInitiationService.orderInitialObject.status_id)
                return;
              }
            });
          }          
        }
        else{
        }
      },
      err => {
        
      })
  }

  getCurrencyLabels(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_CURRENCIES).subscribe(
      resp => {
        if(resp.success === 1){
            let resData = resp.result.data;
            this.orderInitiationService.orderInitialObject.order_currency_code = resData.currency_code;
            this.orderInitiationService.orderInitialObject.base_currency_code = resData.currency_code;
            this.orderInitiationService.orderInitialObject.currency_exchange_rate = parseFloat(resData.static_conversion_rate);       
        }
        else{
        }
      },
      err => {
        
      })
  }

  getEmailSubscription(){
    if(this.localService.get(this.CUSTOMERKEY)){
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
                "phone_number": JSON.parse(this.localService.get(this.CUSTOMERKEY)).Mobile.toString()
            }
        }
      }    
      this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
        resp => {
            if(resp.success === 1){
              if(resp.result.data.length > 0){
                let respData = resp.result.data[0]
                if(respData.has_also_subscribed){
                  this.orderInitiationService.orderInitialObject.is_email_opt_in = respData.has_also_subscribed;
                }
              }
            }
          },
          err => {
            
          })
        }
  }

  getItemDetails(){
    if(this.localService.get('cart')){
      let cartDetails:any = JSON.parse(this.localService.get('cart'));
      this.orderInitiationService.orderInitialObject.items_total = cartDetails.length;
      let itemsQuantity = 0;
      cartDetails.forEach(element => {
        itemsQuantity = itemsQuantity + element.quantity;
      });
      if(itemsQuantity > 0){
        this.orderInitiationService.orderInitialObject.items_total_quantity = itemsQuantity;
      }
      if(this.localService.get('session_id')){

        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "session_id":this.localService.get('session_id')
            }
          }
        }
        this.appService.postApiCall(requestData, ENDPOINTS.GETALLCARTITEMS).subscribe(
          resp => {
              if(resp.success === 1){
                this.orderInitiationService.orderInitialObject.cart_id = resp.result.data[0].id.toString();
              }
            },
            err => {
              
        })
      }
    }
  }

  getProductData(){
    if(this.localService.get('cart')){
      let cartDetails:any = JSON.parse(this.localService.get('cart'));
      if(this.localService.get(this.NORMALTAXKEY)){
        let normalTax = Number(this.localService.get(this.NORMALTAXKEY))
        let productObject = []
        if(cartDetails.length > 0){
          cartDetails.forEach(cart => {
            let productObj = {
              product_id:"",
              product_name:"",
              sku:"",
              quantity:0,
              image_source:"",
              price:0,
              price_inc_tax:0,
              price_ex_tax:0,
              discount_percentage:0,
              discount_amount:0,
              tax_percentage:0,
              tax_amount:0,
              total_amount:0,
              variant:{
                variant_id:"",
                variant_name:"",
                options:{
                  option_key:"",
                  option_value:""
                }
              }
            }
            productObj.product_id = cart.product_id.toString();
            productObj.product_name = cart.product_name;
            productObj.sku = cart.sku;
            productObj.quantity = cart.quantity;
            productObj.image_source = cart.image_source;
            productObj.price = cart.cost;
            productObj.price_ex_tax = Number(cart.cost) * Number(cart.quantity);
            productObj.price_inc_tax = ((Number(cart.cost) * Number(cart.quantity) * normalTax))/100
            productObj.price_inc_tax = (Math.round((productObj.price_inc_tax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
            productObj.tax_percentage = normalTax;
            let producttax = (productObj.price_ex_tax * normalTax)/100
            productObj.tax_amount = (Math.round((producttax + Number.EPSILON) * 100) / 100);
            productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
            productObject.push(productObj);
          });
          this.orderInitiationService.orderInitialObject.products = productObject;
        }
      }
    }
  }

  getIntialOrderValues(){
    // this.getOrderStatusLabels();
    this.getProductData();
    this.assignCustomerData();
    // this.getCurrencyLabels();
    this.getEmailSubscription();
    this.getItemDetails();
  }
  
}
