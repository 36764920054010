<div class="earthy-card" style="cursor: pointer;">
<div class="earthy-image">
    <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
        <a (click)="sendProductDetails(product.id)"><img  [src]='product.assets[0].image_source' class="img-fluid"></a>
    </div>
    <div *ngIf="product.assets.length==0 || !product.assets[0].image_source">
        <a (click)="sendProductDetails(product.id)"><img src='../../../../../assets/images/ITRSample.png' class="img-fluid"></a>
    </div>
</div>
<div class="earthy-description text-center" style="transform: translate3d(0px, 0px, 0px);">
    <div class="earthy-cart-btn">
        <button *ngIf="product.type === 'Variable'" class="btn btn-primary earthy-btn" a (click)="sendProductDetails(product.id)">SELECT OPTIONS</button>
        <button *ngIf="product.type !== 'Variable'" class="btn btn-primary earthy-btn" a (click)="sendProductDetails(product.id)">ADD TO CART</button>
    </div>
    <div class="earthy-product-title">
        {{product.name | titlecase | productBoxOne : product.name}}
    </div>
    <div class="earthy-worthy-price">
        ₹{{product.pricing.raw.price_range? product.pricing.raw.price_range:product.pricing.raw.price + '.00'}}
    </div>
</div>
</div>