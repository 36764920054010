<!--Mobile Menu-->



<div *ngIf="configs.title != 'Tams' && configs.title != 'Murgasen'">
  <div class="container">
      <div class="row my-4">
          <div class="col-12 col-lg-7">
              <div class="flex-container">
                  <div class="text-left inq-logo-st">
                      <img [src]="logoImage" class="logo-image custom-pointer" height="50" alt="Logo"
                          [routerLink]="['/']">
                  </div>
                  <div class="inq-sec-h-fld-st ">
                      <form [formGroup]="searchForm" class="search-bar">
                          <input type="text" class="form-control global-search" placeholder="what are you looking for?"
                              formControlName="search_term" (keyup)="getPreList($event)">
                          <span style="padding-top: 10px;" (click)="savePreList1()"><i class="fa fa-search"></i></span>
                          <div *ngIf="preList.length != 0">
                              <div class="autocomplete-items">
                                  <div *ngFor="let preItem of preList">
                                      <li class="titleproper" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>

          </div>

          <div class="col-12 col-lg-5" id="navigtion">
              <div class="row">
                  <div class="col-12 col-lg-9">
                      <div class="flex-container-large">
                          <div class="ineer-box d-flex" style="margin-right: 5%;">
                              <div class="inner-review" *ngIf="configs.title != 'Tams'">
                                  <img src="assets/images/vendor/reviews.png" alt="Reviews">
                                  <p>9.2</p>
                              </div>
                              <div class="inner-star-icon" *ngIf="configs.title != 'Tams'">
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star unchecked"></span>
                                  <span class="pl-1" *ngIf="configs.title != 'Tams'"><small> Kiyoh</small></span>

                                  <div class="line">
                                      <h4 class="">894</h4>
                                      <small>reviews</small>
                                  </div>
                              </div>
                          </div>
                          <div class="ineer-box d-flex">
                              <div class="inner-chef">
                                  <img src="assets/images/vendor/login.svg" *ngIf="configs.title != 'Tams'" alt="Reviews">
                                  <span *ngIf="configs.title == 'Tams'" class="fa fa-user" style="color : #777;font-size: 50px;"></span>

                              </div>
                              <div class="log-prt pl-1" *ngIf="!appService.userLoggedIn">
                                  <a href="#" href="javascript:;" (click)="logIn()"
                                      style="text-decoration: none !important;">
                                      <span class="lnr lnr-user">
                                      </span> Login
                                  </a>

                                  <!-- <div *ngIf="configs.title != 'Tams'">
                                  <p>Food Pro</p>
                                  <p>Food Lover</p>
                              </div> -->
                              </div>
                              <div class="log-prt pl-1" *ngIf="appService.userLoggedIn">
                                  <div class="dropdown">
                                      <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                          style="text-decoration: none !important;"><span class="lnr lnr-user">
                                          </span>
                                          <span class="text">{{this.appService.userName}}</span>
                                      </a>
                                      <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                          <a class="logout-cutom dropdown-item" routerLink="my-orders">My Orders</a>
                                          <a class="logout-cutom dropdown-item" href="#" (click)="logOut()">Logout</a>
                                      </div>
                                  </div>
                                  <!-- <div *ngIf="configs.title != 'Tams'">
                                  <p>Food Pro</p>
                                  <p>Food Lover</p>
                              </div> -->
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg-3 mt-xs-5 " id="secondpart">
                      <div class="ineer-box d-flex account-popup">
                          <div class="inner-cart" routerLink="/cart">
                              <img src="assets/images/vendor/cart.svg" alt="Cart" style="min-height: 40px !important; min-width:60px !important; padding-right: 10px !important;">
                              <p style="font-size: small;">{{appService.totalCartItems}}</p>
                          </div>
                          <app-mini-cart></app-mini-cart>
                          <span class="show-subtotal" style="margin-top:10px"><i class="fa fa-rupee"></i> {{appService.cartSubTotal}}</span>
                      </div>
                      <!-- <div class="flex-container-large">

                          <div class="ineer-box d-flex" id="reward-prt">
                              <div class="inner-chef">
                                  <img src="assets/images/vendor/reward.svg" alt="Reviews">

                              </div>
                              <div class="log-prt pl-1 ">
                                  <p>610</p>
                                  <p>REWARDS</p>
                                  <p>POINTS</p>
                              </div>
                          </div>
                          <div class="ineer-box d-flex account-popup">
                              <div class="inner-cart">
                                  <img src="assets/images/vendor/cart.svg" alt="Cart">
                                  <p style="font-size: small;">{{appService.totalCartItems}}</p>
                              </div>
                              <app-mini-cart></app-mini-cart>
                              <span class="show-subtotal" *ngIf="this.appService.showSubTotal"><i class="fa fa-rupee"></i> {{appService.cartSubTotal}}</span>
                          </div>

                      </div> -->
                  </div>
              </div>
              <!-- <div class="flex-container-large">
                  <div class="ineer-box d-flex">
                      <div class="inner-review">
                          <img  src="assets/images/vendor/reviews.png" alt="Reviews">
                          <p>9.2</p>
                      </div>
                      <div class="inner-star-icon">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star unchecked"></span>
                          <span class="pl-1"><small> Kiyoh</small></span>

                          <div class="line">
                              <h4 class="">894</h4>
                              <small>reviews</small>
                          </div>
                      </div>
                  </div>
                  <div class="ineer-box d-flex">
                      <div class="inner-chef">
                          <img  src="assets/images/vendor/login.svg" alt="Reviews">

                      </div>
                      <div class="log-prt pl-1">
                          <p>Login</p>
                          <p>Food Pro</p>
                          <p>Food Lover</p>
                      </div>
                  </div>
                  <div class="ineer-box d-flex">
                      <div class="inner-chef">
                          <img  src="assets/images/vendor/reward.svg" alt="Reviews">

                      </div>
                      <div class="log-prt pl-1">
                          <p>610</p>
                          <p>REWARDS</p>
                          <p>POINTS</p>
                      </div>
                  </div>
                  <div class="ineer-box d-flex">
                      <div class="inner-cart">
                          <img  src="assets/images/vendor/cart.svg" alt="Cart">
                          <p>2</p>
                      </div>
                  </div>
              </div> -->

          </div>
      </div>
  </div>
</div>
<div *ngIf="configs.title == 'Tams'">
  <div class="container">
      <div class="row my-3">
          <div class="col-1">
              <div>
                <img [src]="logoImage" style="margin-left: 25px !important;" class="logo-image custom-pointer" height="70" alt="Logo" [routerLink]="['/']">
              </div>
          </div>
          <div class="col-8">
              <div class="row">
                  <div class="col-3">
                      <!-- <p class="text-center text-primary mb-5" style="margin-top: 12px !important;font-size: 1.2rem;font-weight: 500;">Tams Homely Foods</p> -->
                  </div>
                  <div class="col-9 inq-sec-h-fld-st" style="margin-left: 0px !important;">
                      <form [formGroup]="searchForm" class="search-bar">
                          <input type="text" class="form-control global-search" placeholder="what are you looking for?"
                              formControlName="search_term" (keyup)="getPreList($event)">
                          <span style="padding-top: 10px;" (click)="savePreList1()"><i class="fa fa-search"></i></span>
                          <div *ngIf="preList.length != 0">
                              <div class="autocomplete-items">
                                  <div *ngFor="let preItem of preList">
                                      <li class="titleproper" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="col-2" style="margin-left: 20px !important;">
              <div class="d-flex justify-content-start">
                  <div style="padding-top: 15px !important; padding-right: 15px !important;">
                      <div class="d-flex justify-content-start">
                          <div>
                              <i class="fa fa-user tams-user-icon" style="font-size: 20px !important; padding: 5px 9px !important;" aria-hidden="true"></i>
                          </div>
                          <div>
                              <span class="log-prt" *ngIf="!appService.userLoggedIn" style="cursor: pointer; width: 150px !important;" (click)="logIn()">LOGIN</span>
                              <span class="log-prt" *ngIf="appService.userLoggedIn">
                                  <div class="dropdown">
                                      <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                          style="text-decoration: none !important;"><span class="lnr lnr-user">
                                          </span>
                                          <span class="text">{{this.appService.userName}}</span>
                                      </a>
                                      <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                          <a class="logout-cutom dropdown-item" routerLink="my-orders">My Orders</a>
                                          <a class="logout-cutom dropdown-item" href="#" (click)="logOut()">Logout</a>
                                      </div>
                                  </div>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div style="padding-top: 15px !important">
                      <div class="flex-container-large" style="widows: 150px !important;">
                          <div class="ineer-box d-flex account-popup">
                              <div class="inner-cart">
                                  <img src="assets/images/vendor/cart.svg" alt="Cart" style="min-height: 35px !important;">
                                  <p style="font-size: small; left:19px !important">{{appService.totalCartItems}}</p>
                              </div>
                              <app-mini-cart></app-mini-cart>
                              <span class="show-subtotal d-flex" *ngIf="this.appService.showSubTotal" style="display: flex; padding-left: 40px !important; padding-top:5px !important;"><i class="fa fa-rupee" style="padding-top: 2px !important;"></i> {{appService.cartSubTotal}}</span>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div *ngIf="configs.title == 'Murgasen'">
  <div class="row">
      <div class="col-sm-2" style="float: right;">
          <img [src]="logoImage" class="logo-header-image custom-pointer" alt="Logo" [routerLink]="['/']">
      </div>
      <div class="col-sm-6" style="margin-top: 10px;">
          <div class="inq-sec-h-fld-st" style="margin-left: 0px !important;">
              <form [formGroup]="searchForm" class="search-bar">
                  <input type="text" class="form-control global-search" placeholder="what are you looking for?"
                      formControlName="search_term" (keyup)="getPreList($event)">
                  <span style="padding-top: 10px;" (click)="savePreList1()"><i class="fa fa-search"></i></span>
                  <div *ngIf="preList.length != 0">
                      <div class="autocomplete-items">
                          <div *ngFor="let preItem of preList">
                              <li class="titleproper" (click)="savePreList(preItem)">{{preItem.name}}</li>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      <div class="col-sm-2" style="margin-top: 20px;">
          <div class="row" style="float: right;">
              <i class="fa fa-user fa-2x header-avatar" aria-hidden="true" (click)="logIn()">
              </i>
              <span *ngIf="!appService.userLoggedIn" class="signin-signup" (click)="logIn()"> SignIn / SignUp</span>
                  <span *ngIf="appService.userLoggedIn" class="signin-signup">
                      <div class="dropdown">
                          <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                              style="text-decoration: none !important;"><span class="lnr lnr-user">
                              </span>
                              <span class="text signin-signup">{{this.appService.userName}}</span>
                          </a>
                          <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="logout-cutom dropdown-item signin-signup" routerLink="my-account">My Account</a>
                              <a class="logout-cutom dropdown-item signin-signup" routerLink="my-orders">My Orders</a>
                              <a class="logout-cutom dropdown-item signin-signup" href="#" (click)="logOut()">Logout</a>
                          </div>
                      </div>
                  </span>
          </div>
      </div>
      <div class="col-sm-2" style="margin-top: 20px;">
          <li class="header-cart account-popup" style="padding-left: 20px;">
              <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                  <span class="fa fa-shopping-cart" style="font-size:25px; color: #494949">
                      <span class="counting">
                          <span class="count-number">{{appService.totalCartItems}}</span>
                      </span>
                  </span>
                   <span class="menu-title" style="padding-left: 5px;color: #494949;">
                      Cart
                  </span>
              </a>
              <app-mini-cart></app-mini-cart>
          </li>
      </div>
  </div>
</div>




<!--Flip Flop Start-->



<!--Flip Flop Ends-->
