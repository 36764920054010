import { Injectable } from '@angular/core';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DeliverInfoService {

  deliveryInfo = {
    orderType:"",
    restaurent:"",
    preferredType:"",
    preferredDate:"",
    preferredTime:"",
  }

  restaurentData = {

  }

  selectedRestaurent = {
    
  }

  DELIVERYINFOKEY = "DeliveryTab";
  RESTAURENTKEY = "RestaurentData";
  SELECTEDRESTAURENTKEY = "SlectedRestaurent";

  constructor(
    private localService : LocalStorageService
  ) { }

    // GET DELIVERY INFO
    getDeliveryInfo(){
      if(this.localService.get(this.DELIVERYINFOKEY)){
        let deliver_data = JSON.parse(this.localService.get(this.DELIVERYINFOKEY));
        this.deliveryInfo.orderType = deliver_data.orderType;
        this.deliveryInfo.restaurent = deliver_data.restaurent;
        this.deliveryInfo.preferredType = deliver_data.preferredType;
        this.deliveryInfo.preferredDate = deliver_data.preferredDate;
        this.deliveryInfo.preferredTime = deliver_data.preferredTime;
        return this.deliveryInfo;
      }
      else{
        return "";
      }
    }

    // GET RESTAURENT DATA
    getRestaurentData(){
      if(this.localService.get(this.RESTAURENTKEY)){
        this.restaurentData = JSON.parse(this.localService.get(this.RESTAURENTKEY));
        return this.restaurentData;
      }
      else{
        return "";
      }
    }

    // GET SELECTED RESTAURENT
    getSlectedRestaurentData(){
      if(this.localService.get(this.SELECTEDRESTAURENTKEY)){
        this.selectedRestaurent = JSON.parse(this.localService.get(this.SELECTEDRESTAURENTKEY));
        return this.selectedRestaurent;
      }
      else{
        return "";
      }
    }

    // SET DELIVERY DATA
    setDeliveryInfo(deliveryData){
      if(this.deliveryInfo){
        this.deliveryInfo = deliveryData;
        this.localService.set(this.DELIVERYINFOKEY, JSON.stringify(this.deliveryInfo))
      }
    }

    // SET RESTAURENT DATA
    setRestaurentData(resturentData){
      if(resturentData){
        this.restaurentData = resturentData;
        this.localService.set(this.RESTAURENTKEY, JSON.stringify(this.restaurentData))
      }
    }

    // SET SELECTED RESTAURENT
    setSelectedRestaurentData(resturentData){
      if(resturentData){
        this.selectedRestaurent = resturentData;
        this.localService.set(this.SELECTEDRESTAURENTKEY, JSON.stringify(this.selectedRestaurent))
      }
    }

    // REMOVE DELIVERY INFO
    removeDeliveryInfo(){
      if(this.localService.get(this.DELIVERYINFOKEY)){
        this.localService.remove(this.DELIVERYINFOKEY);
      }
    }

    // REMOVE RESTAURENT INFO
    removeRestaurentInfo(){
      if(this.localService.get(this.RESTAURENTKEY)){
        this.localService.remove(this.RESTAURENTKEY);
      }
    }

    // REMOVE SLECTED RESTAURENT DATA
    removeSlectedRestaurentInfo(){
      if(this.localService.get(this.SELECTEDRESTAURENTKEY)){
        this.localService.remove(this.SELECTEDRESTAURENTKEY);
      }
    }
}
