<app-alerts *ngIf="showalertmsg"></app-alerts>
<div class="forgot-page">
  <div class="box">
    <div class="row">
      <div class="col-md-7">
        <h2 class="heading mb-3">Change Password!</h2>
      </div>
      <div class="col-md-5">
        <div class="text-center mt-1">
          <strong>  <a class="link-style" routerLink="/login-new">Sign In</a>
          </strong>
        </div>
      </div>
    </div>
    <!-- <p class="custom"><small>Enter the mobile number associated with your account.</small></p> -->
    <div *ngIf="customerForm === 'guestForm'">
      <!-- <div class="row mt-4 inq-order-row-st"> -->
      <div class="col-md-12">
        <form [formGroup]="guestForm" class="otp-form">
          <div class="form-group">
            <input type="number" class="form-control" formControlName="guest_mobile" placeholder="Mobile Number"
              [class.is-invalid]="validateGuestForm && f.guest_mobile.errors" [readonly]="freezeGuest">
              <small class="help-text">Enter the mobile number associated with your account.</small>
            <div class="invalid-feedback" *ngIf="f.guest_mobile.errors">
              <div *ngIf="f.guest_mobile.errors.required">Please enter the Mobile Number.</div>
              <div *ngIf="f.guest_mobile.errors.maxlength">Please enter a valid Mobile Number</div>
              <div *ngIf="f.guest_mobile.errors.minlength">Please enter a valid Mobile Number</div>
              <div *ngIf="f.guest_mobile.errors.pattern">Please enter a valid Mobile Number</div>
            </div>
          </div>
        </form>
        <div class="inq-mch-con-btn-st d-flex justify-content-end">
          <button *ngIf="!freezeGuest" class="btn btn-primary inq-btn-st" (click)="saveGuest()">CONTINUE</button>
          <button *ngIf="freezeGuest" class="btn btn-primary inq-btn-st" (click)="editGuest()">EDIT</button>
        </div>
        
      </div>
      <!-- </div> -->
      <div *ngIf="showpasswordSetupForm">
        <div class="mt-2" [class]="showOtpLogin ? 'reveal-if-active' : 'hide-if-inactive'">
          
          <div
            [class]="!otpLogin ? 'reveal-if-active col-md-12 custome-nospace' : 'hide-if-inactive col-md-12  custome-nospace'">
            <form [formGroup]="passwordLoginForm" class="otp-form">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="otpp" placeholder="Enter OTP"
                  [class.is-invalid]="validatepasswordLoginForm && f5.otpp.errors">
                <div class="invalid-feedback" *ngIf="f5.otpp.errors">
                  <div *ngIf="f5.otpp.errors.required">Please enter the OTP.</div>
                  <div *ngIf="f5.otpp.errors.incorrect">Please enter the valid OTP</div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input [type]="showPswd ? 'text' : 'password'" class="form-control" formControlName="passwordp"
                    placeholder="New Password" [class.is-invalid]="validatepasswordLoginForm && f5.passwordp.errors">
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="showPassword()"><i
                        [class]="showPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
                  </div>
                  <div class="invalid-feedback" *ngIf="f5.passwordp.errors">
                    <div *ngIf="f5.passwordp.errors.required">Please add New Password.</div>
                    <div *ngIf="f5.passwordp.errors.minlength">Password atleast should have 6 charachters</div>
                    <div *ngIf="f5.passwordp.errors.maxlength">Password should not have more than 20 charachters</div>
                  </div>
                </div>
                <small class="help-text">Password must be at least 6 characters.</small>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" formControlName="confirmPswd" placeholder="Confirm Password"
                  [class.is-invalid]="validatepasswordLoginForm && f5.confirmPswd.errors" (blur)="validpasswd()">
                <div class="invalid-feedback" *ngIf="f5.confirmPswd.errors">
                  <div *ngIf="f5.confirmPswd.errors.required">Please enter Confirm Password.</div>
                  <div *ngIf="f5.confirmPswd.errors.incorrect">Confirm password should match with New Password</div>
                </div>
              </div>
            </form>
            <div>
              <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="passwordLogin()">Change Password</button>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <p class="pl-4">Didn't get the code? <strong><a class="loginLink" (click)=resendCode()>Resend Code</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>