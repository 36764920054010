import { Injectable } from '@angular/core';
import {ORG_DETAILS} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class OrderUpdationService {

  orderUpdationObject = {
    customer_id : "",
    customer_name : "",
    phone_number : "",
    cart_id : "",
    outlet_id : "",
    outlet_name : "",
    outlet_latitude: "",
    outlet_longitude: "",
    status : "",
    system_label : "Awaiting Payment",
    status_id : "",
    is_digital_order : false,
    is_manual_order : false,
    payment_status : "Pending",
    payment_method : "",
    payment_method_id : "",
    order_currency_code : "",
    base_currency_code : "",
    currency_exchange_rate : 0,
    is_email_opt_in : false,
    order_source : "Web",
    order_reference : ORG_DETAILS.DOMAIN_NAME,
    order_reference_id : "",
    shipping_address_count : 1,
    items_total : 0,
    items_total_quantity : 0,
    geo_info : {},
    store_credit_amount : 0,
    gift_certificate_amount : 0,
    coupon_code : "",
    coupon_discount : 0,
    refunded_amount : 0,
    products : [],
    shipping_addresses : [],
    billing_address: {},
    delivery_instructions : [],
    delivery_information : {},
    shipping_carrier: {},
    shipping_method : {},
    deliveries : [],
    customer_comment : "",
    notes : "",
    base_cost : {},
    derived_cost : {},
    order_taxes : [],
    order_summary: {},
    status_flow : {}
  }

  old_value = {
    status: "",
    system_label: "Pending"
  }

  new_value = {
      status: "",
      system_label: "Awaiting Payment"
  }

  new_value1 = {
    status: "",
    system_label: "Confirmed"
  }

  confirmationId:any;

  shippingAddressObj = {
    first_name:"",
    last_name:"",
    company_name:"",
    entire_location:"",
    address1:"",
    landmark:"",
    city:"",
    country:"",
    country_code:"",
    state:"",
    zipcode:"",
    email:"",
    phone_number:"",
    latitude:"",
    longitude:""
  }

  shippingMethodObj = {
    name:"",
    delivery_charge:""
  }

  baseCostObj = {
    base_shipping_cost : 0,
    shipping_cost_tax : 0,
    shipping_cost_tax_class_id : 0,
    base_handling_cost : 0,
    base_handling_cost_type : "",
    handling_cost_tax : 0,
    handling_cost_tax_class_id : 0,
    base_wrapping_cost : 0,
    wrapping_cost_tax : 0,
    wrapping_cost_tax_class_id : 0
  }

  deliveryCostObject = {
    subtotal_ex_tax:0,
    subtotal_inc_tax:0,
    shipping_cost_ex_tax:0,
    shipping_cost_inc_tax:0,
    handling_cost_ex_tax:0,
    handling_cost_inc_tax:0,
    wrapping_cost_ex_tax:0,
    wrapping_cost_inc_tax:0,
    subtotal_tax:0,
    discount_amount:0,
    total_ex_tax:0,
    total_inc_tax:0
  }

  OrderTaxObject = {
    tax_rate_id:"",
    tax_class_id:"",
    tax_class_name:"",
    type_of_tax:0,
    rate:0,
    priority:0,
    priority_amount:0,
    line_amount:0,
    order_product_id:0,
    line_item_type:0
  }

  statusFlowStatusObj = {
    name:"",
    title:"",
    status_id:"",
    status_name:"",
    status_system_label:"",
    status_custom_label:"",
    process:"",
    sequence:0,
    sms_notification:false,
    email_notification:false,
    push_notification:false
  }
  constructor() { }
}
