<app-alerts *ngIf="showmsg"></app-alerts>
<section class="container">
    <p class="logo-header mt-2">Table Booking</p>
    <!-- <p>Book your table here</p> -->
    <br>
    <div class="row">
        <div class="col-sm-7">
            <form [formGroup]="tableBookingForm" class="gift-form" novalidate>
                <!-- Banner name -->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="name" class="form-label">Name <span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" id="name"
                            [class.is-invalid]="f.name.invalid && validateForm" formControlName="name" required>
                        <div class="invalid-feedback" *ngIf="f.name.errors">
                            <div *ngIf="f.name.errors.required">
                                Name is required.
                            </div>
                            <div *ngIf="f.name.errors.maxlength">
                                Name can't be more than 80 characters long.
                            </div>
                            <div *ngIf="f.name.errors.pattern">
                                Name is invalid.
                            </div>
                        </div>
                    </div>
                </div>

                <!--Content-->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="content" class="form-label">Email Address </label>
                    </div>
                    <div class="col-md-7">
                        <input type="email" class="form-control" id="email_address"
                            [class.is-invalid]="(f.email_address.invalid && validateForm)"
                            formControlName="email_address" maxlength="50" email>
                        <div class="invalid-feedback" *ngIf="f.email_address.errors">
                            <div *ngIf="f.email_address.errors.maxlength">
                                Seneder Email can't be more than 50 characters long.
                            </div>
                            <div *ngIf="f.email_address.errors.email || f.email_address.errors.pattern">
                                Email must be a valid email address
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Show On Page -->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="mobile_number" class="form-label">Mobile Number<span
                                class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" id="mobile_number"
                            [class.is-invalid]="f.mobile_number.invalid && validateForm" formControlName="mobile_number"
                            required>
                        <div class="invalid-feedback" *ngIf="f.mobile_number.errors">
                            <div *ngIf="f.mobile_number.errors.required">
                                Mobile Number is required.
                            </div>
                            <div *ngIf="f.mobile_number.errors.maxlength">
                                Mobile Number can't be more than 15 characters long.
                            </div>
                            <div *ngIf="f.mobile_number.errors.pattern">
                                Mobile Number is invalid.
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Banner Location -->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="location" class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <!-- <input type="date" class="form-control" id="date"
                            [class.is-invalid]="f.date.invalid  && validateForm" formControlName="date" [min]="mindeliveryDate" required> -->
                            <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && f.date.invalid" ></dp-date-picker>
                        <div class="invalid-feedback" *ngIf="f.date.errors">
                            <div *ngIf="f.date.errors.required">
                                Date is required.
                            </div>
                            <div *ngIf="f.date.errors.previous_date">Previous date is not allowed.</div>

                        </div>
                    </div>
                </div>

                <!--Valid from-->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="number" class="form-label">Time<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input type="text" class="form-control" formControlName="time"
                            [class.is-invalid]="validateForm && f.time.errors" (click)="pickTime()"
                            placeholder="HH:MM" />
                        <div class="invalid-feedback" *ngIf="f.time.errors">
                            <div *ngIf="f.time.errors.required"> Time is required.</div>
                            <div *ngIf="f.time.errors.previous_time">Can not book for past timings.</div>
                            <div *ngIf="f.time.errors.unavailable_time">Booking available only between {{start_time}}
                                and
                                {{end_time}}.</div>
                        </div>
                    </div>
                </div>

                <!--Valid To-->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="no_of_persons" class="form-label">No.Of Persons <span class="required">*</span> </label>
                    </div>
                    <div class="col-md-7">
                        <input type="number" class="form-control" id="no_of_persons"
                            [class.is-invalid]="f.no_of_persons.invalid && validateForm" min=1
                            formControlName="no_of_persons">
                        <div class="invalid-feedback" *ngIf="f.no_of_persons.errors">
                            <div *ngIf="f.no_of_persons.errors.max">
                                Cannot accomodate.
                            </div>
                            <div *ngIf="f.no_of_persons.errors.min">
                                Number of Persons can't be less than 1.
                            </div>
                            <div *ngIf="f.no_of_persons.errors.required">
                                Number of Persons is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                      <label for="add_outlet" class="form-label">Add Outlet<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                      <select class="custom-select" id="add_outlet"
                        [class.is-invalid]="f.add_outlet.invalid  && validateForm" formControlName="add_outlet" (change)="handleOutlet($event)">
                        <option value="">Please Select</option>
                        <option *ngFor="let outlet of outletList;" [value]="outlet.name">{{outlet.name}}</option>
                      </select>
                      <div class="invalid-feedback" *ngIf="f.add_outlet.errors">
                        <div *ngIf="f.add_outlet.errors.required">Please select the outlet.</div>
                    </div>
                    <div *ngIf="addOutlet===false">Table Booking Is Not Allowed In This Outlet.Please Select Some Other Outlet</div>
                    </div>
                  </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="optional_message" class="form-label">Optional Message</label>
                    </div>
                    <div class="col-md-7">
                        <textarea type="text" class="form-control" id="optional_message"
                            [class.is-invalid]="f.optional_message.invalid  && validateForm"
                            formControlName="optional_message"></textarea>
                        <div class="invalid-feedback" *ngIf="f.optional_message.errors">
                            <div *ngIf="f.optional_message.errors.maxlength">
                                Optional Message can't be more than 1000 characters long.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary mb-1 buttons" (click)="saveForm()">RESERVE</button>
                </div>
            </form>
            
        </div>
        <div class="col-md-5">
            <div class="card1" style="border: none !important;">
                <h2 class="card-txt">Reservation Discounts</h2>
                <li style="color:#4d4d4d !important; margin-bottom: 10px;">Restaurant Timings: {{restaurentTimings}} (all days).</li>
                <ol style="color: #4d4d4d; padding-left: 10px;" >
                    <li><span class="card-txt">1. </span>Those reserving a table before 5 hrs in advance, will be eligible for 5% off on their dining bill. Table reservation must be paid for with a minimum amount of Rs.500.</li>
                    <li><span class="card-txt">2. </span>Those reserving a table before a day in advance, will be eligible for 5% off on their dining ball. Table reservation must be paid for with a minimum amount of Rs.500.</li>
                </ol>
            </div>
        </div>
    </div>
</section>