
                <!---------sidebar-->
                <app-alerts *ngIf="showAlerts"></app-alerts>

                <div class="container inq-mt-st">
                    <section class="row">
                        <div class="col-md-3">
                            <div class="inq-menu-left-st">
                                <!-----------tams------>
                                <div *ngIf="config.title != 'Haven'">
                                                <ul *ngFor="let category of allCategories;let i = index" [class.active]="selectedIndex === i"
                                    (click)="setIndex(i)">

                                    <li [class.active]="active === category.name || categoryName===category.name" class="active"
                                        (click)="getProductByFilter(category);">
                                        {{category.name}}
                                    </li>
                                    <div *ngIf="categoryName===category.name">
                                        <ul *ngFor="let subCategory of subCategory2;let i = index">
                                            <li [class.active]="active === subCategory.name"
                                                (click)="getProductByFilter1(subCategory.name)">{{subCategory.name}}
                                            </li>
                                        </ul>
                                    </div>
                                </ul>
                                </div>
                                <!----------haven---->
                                <div *ngIf="config.title == 'Haven'">

                                <ul *ngFor="let category of allCategories;let i = index" [class.active]="selectedIndex === i"
                                (click)="setIndex(i)">

                                <li [class.active]="active === category.name || categoryName===category.name" class="active"
                                    (click)="getProductByFilter(category);">
                                    <img [src]=" category.category_icon ? category.category_icon : 'assets/images/vendor/payment/food/canape.png'" class="mb-2" width="18" height="21">
                                    {{category.name}}
                                </li>
                                <div *ngIf="categoryName===category.name">
                                    <ul *ngFor="let subCategory of subCategory2;let i = index">
                                        <li [class.active]="active === subCategory.name"
                                            (click)="getProductByFilter1(subCategory.name)">{{subCategory.name}}
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                            </div>
                            <!------>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="inq-menu-right-st">
                                <!----------breadcrumb----->
                <h2></h2>
                         <!------------tams----->
                                <div class="inq-title-st" *ngIf="config.title != 'Haven'">
                                    <h3 *ngIf="subCategory!==undefined" class="mb-4">{{category1}} </h3>
                                    <h3 *ngIf="subCategory===undefined" class="mb-4">{{category}} </h3>
                                    <h4 *ngIf="subCategory!==undefined">{{subCategory}}</h4>
                                    <p>{{totalProductLength}} ITEMS</p>
                                </div>
                                <!-----------haven----->
                                <div class="inq-title-st" *ngIf="config.title == 'Haven'">
                                    <h3 *ngIf="subCategory!==undefined" class="mb-4">{{category1}} <img
                                            [src]="categoryIcon ? categoryIcon : 'assets/images/vendor/payment/starter.png'" class="pb-2" height="50" width="50"></h3>
                                    <h3 *ngIf="subCategory===undefined" class="mb-4">{{category}} <img
                                            [src]="categoryIcon ? categoryIcon : 'assets/images/vendor/payment/starter.png'" class="pb-2" height="50" width="50"></h3>
                                    <h4 *ngIf="subCategory!==undefined">{{subCategory}}</h4>
                                    <p>{{totalProducts}} ITEMS</p>
                                </div>
                                <!-- ---------Prodict TAMS listing-->
                                <div *ngIf="totalLength>0 && config.title == 'Tams'" class="thumb">
                                    <div *ngFor="let product of productList ;let i = index">
                                        <div class="row inq-pro-sec-st listProduct">
                                            <div class="product-list-cards" style="color: lightgray;">
                                                <div>
                                                    <!------------tams img------>
                                                    <img [src]="product.assets[0].image_source" id="img-list"
                                                                        (click)="productDetails(product.id)"
                                                                        onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                                        alt="{{ product.name}}" class="product-list-image">
                                                </div>
                                                <div class="product-list-description">
                                                    <div class="d-flex justify-content-between">
                                                        <div>
                                                            <p class="product-list-tile">{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</p>
                                                        </div>
                                                        <div>
                                                            <p class="product-list-price"  *ngIf="!product.showPricse">
                                                                ₹{{product.variant.option_values[0].pricing.raw.price}}.00</p>
                                                                <p class="product-list-price"*ngIf="product.showPricse && product.product_type=='Variable'" >₹{{product.pricee}}.00</p>
                                                                <!-- <p class="product-list-price"*ngIf="product.showPricse && product.product_type =='Simple'" >₹{{product.pricing.raw.price}}.00</p> -->

                                                                <!-- <p class="product-list-price"*ngIf="!product.showPricse">₹{{product.price}}.00</p> -->
                                                                <!-- <p class="product-list-price"*ngIf="!product.showPricse" style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:5px !important">&nbsp;  ₹{{product.pricing.raw.price_range.split('-')[1] + '.00'}}</p> -->
                                                                <!-- <p class="product-list-price"*ngIf="product.showPricse && (product.product_type=='Variable')" >₹{{product.pricee}}.00</p> -->
                                                                <p class="product-list-price"*ngIf="product.showPricse &&( product.product_type =='Simple' ||product.product_type =='Variation')" >₹{{product.pricing.raw.price}}.00</p>
                                                                <!-- <p class="product-list-price"  *ngIf="product.product_type=='Simple' ||product.product_type =='Variation' "style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price + '.00'}}</p>  -->
                                                                <p class="product-list-price"  *ngIf="product.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price_range_from}}-{{product.pricing.raw.price_range_to}}</p>
                                                        </div>
                                                    </div>

                                                    <!----------variants------>
                            <app-shared-variants  [product]="product" [products]="products" (selectEmitter)="showPrice($event)"></app-shared-variants>


                            <!-- <div>

                                <label class='note'  style="font-size: 11px !important; font-weight: 500 !important;color: black !important;">Note: Please select option to add item in
                                    cart</label>

                            </div> -->

                                                    <!---------------------reviews---------------->
                                                    <div class="d-flex justify-content-between mt-1 review-section" style="padding-top: 15px;">
                                                        <div class="inner-star-icon pd-1">
                                                            <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <a routerLink="/product-review/{{product.id}}" style="font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                                        </div>
                                                        <div>
                                                            <!-- <a class="product-list-cart">ADD TO CART +</a> -->
                                                             <!--------- button---------->

                                                    <!-------------Add to cart-->
                                                    <button class=" cartButton" style="font-size: small;font-weight: bold;width:80px;background-color: transparent !important;border:1px solid #09404b !important;color: #09404b !important;background-color:grey;"
                                                    (click)="addToCarts(product)"
                                                   *ngIf="(product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  )">ADD
                                                   <span> <i class="fa fa-shopping-cart"></i></span></button>
                                               <!---------view cart button-->
                                               <div *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                               <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                               <div class=" viewCart"   style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;"   *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >


                                                   <span>
                                                       <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                           style="font-size:12px;cursor:pointer;"></i></span>
                                                   <span class="inq-sp-2"
                                                       style="color: white;">{{cart.quantity}}</span>
                                                   <span>
                                                       <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                           style="font-size:12px;cursor:pointer;"></i></span>
                                              </div>

                                               </div>
                                               </div>


                                                        </div>
                                                    </div>

                                                    <!-- <div class="d-flex justify-content-end mt-1">
                                                        <a class="product-list-cart">ADD TO CART +</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    </div>
                                    <!--------------------------------------------------------->
                                    <!---------------haven listing------------------->
                                    <div *ngIf="config.title == 'Haven'">
                                    <div class="row mt-4 inq-pro-sec-st" *ngFor="let product of products;let i = index">
                                        <div class="col-md-8">
                                            <div class="col-md-10 inq-fo-des-in-st">
                                                <div class="inq-dis-sec-st">
                                                    <div class="inq-ft-st mb-1 row">
                                                        <div class="col-md-1" id="veg-img-prt" *ngIf="fields[0].visibility">
                                                            <!-- <img src="assets/images/veg.svg" alt="veg-items"> -->
                                                        </div>

                                                        <div class="col-md-11 inq-pro-col-le-st">
                                                            <!-- <h5 *ngIf="fields[1].visibility">{{product.name}}</h5> -->
                                                            <h5 *ngIf="fields[1].visibility" style="cursor: pointer;" (click)="productDetails(product.id)">{{metaDataMapService.findData(product, fields[1].nestedinfo)}}</h5>
                                                            <div class="inner-star-icon">
                                                            <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                            <a routerLink="/product-review/{{product.id}}"> {{product && product.reviews ? product.reviews.total_reviews : 0}} customer reviews</a>
                                                        </div>
                                                            <span *ngIf="fields[3].visibility"><strong><i
                                                                        class="fa fa-rupee"></i>{{metaDataMapService.findData(product, fields[3].nestedinfo)}}</strong></span>
                                                                        <p style="padding-top: 5px !important;">{{product.description.description}}</p>

                                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="inq-fo-im-st ">
                                                <div
                                                    *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                                    <img [src]="product.assets[0].image_source" alt="..."
                                                        class="img-fluid product-image"  style="cursor: pointer;" (click)="productDetails(product.id)">
                                                </div>
                                                <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                                    <img src="assets/images/No_Image_old.png"  style="cursor: pointer;" (click)="productDetails(product.id)" class="img-fluid product-image">
                                                </div>
                                                <div *ngIf="appService.cartProducts.results.length > 0">

                                                    <div *ngIf="cartData.includes(product.id)">
                                                        <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                            <div class="add-cart1" *ngIf="cart.product_id===product.id">
                                                                <div class="inq-cart-bw-st">
                                                                    <span>
                                                                        <i class="fa fa-minus" (click)="decreaseItem(i)"
                                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                                    <span class="inq-sp-2"
                                                                        style="color: white;">{{cart.quantity}}</span>
                                                                    <span>
                                                                        <i class="fa fa-plus" (click)="increaseItem(i)"
                                                                            style="font-size:12px;cursor:pointer;"></i></span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="inq-cart-bw-st add-cart" *ngIf="!cartData.includes(product.id)"
                                                        (click)="addToCart(product)">
                                                        <span class="inq-sp-1"></span>
                                                        <span class="inq-sp-2 inq-secondary">ADD</span>
                                                        <span class="inq-sp-3"></span>

                                                    </div>

                                                </div>
                                                <div *ngIf="appService.cartProducts.results.length === 0">
                                                    <div class="inq-cart-bw-st add-cart" (click)="addToCart(product)">
                                                        <span class="inq-sp-1"></span>
                                                        <span class="inq-sp-2 inq-secondary">ADD</span>
                                                        <span class="inq-sp-3"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="inq-pro-hr-st">
                                    </div>
                                    </div>
                                       <!-------------->


                                </div>
                                <!---------------------------------------->
                                <div *ngIf="totalLength<1">
                                    <img src="assets/images/noresult.jpeg" class="img-fluid1">
                                </div>
                            </div>


                    </section>
                </div>
