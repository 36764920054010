import { Injectable } from '@angular/core';
import { LocalStorageService } from '../auth-service/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CheckoutLocalstorageService {

  constructor(
    private localService : LocalStorageService
  ) { }

  CUSTOMERKEY = "CustomerTab";
  DELIVERYINFOKEY = "DeliveryTab";
  RESTAURENTKEY = "RestaurentData";
  SELECTEDRESTAURENTKEY = "SlectedRestaurent";
  NOTESKEY = "OrderNotes";
  INSTRUCTIONSKEY = "Instrcutions";
  ORDERIDKEY = 'orderId';
  ADDRESSKEY = "addressTab";
  


  clearLocalCheckout(){
    // CUSTOMER DATA
    // if(this.localService.get(this.CUSTOMERKEY)){
    //   this.localService.remove(this.CUSTOMERKEY);
    // }
    // DELIVERY INFO DATA
    if(this.localService.get(this.DELIVERYINFOKEY)){
      this.localService.remove(this.DELIVERYINFOKEY);
    }
    if(this.localService.get(this.RESTAURENTKEY)){
      this.localService.remove(this.RESTAURENTKEY);
    }
    if(this.localService.get(this.SELECTEDRESTAURENTKEY)){
      this.localService.remove(this.SELECTEDRESTAURENTKEY);
    }
    if(this.localService.get(this.NOTESKEY)){
      this.localService.remove(this.NOTESKEY);
    }
    if(this.localService.get(this.INSTRUCTIONSKEY)){
      this.localService.remove(this.INSTRUCTIONSKEY);
    }
    if(this.localService.get(this.ORDERIDKEY)){
      this.localService.remove(this.ORDERIDKEY);
    }
    if(this.localService.get("cart")){
      this.localService.remove("cart");
    }
    if(this.localService.get(this.ADDRESSKEY)){
      this.localService.remove(this.ADDRESSKEY);
    }
  }

  clearLogLocalCheckout(){
    // CUSTOMER DATA
    if(this.localService.get(this.CUSTOMERKEY)){
      this.localService.remove(this.CUSTOMERKEY);
    }
    // DELIVERY INFO DATA
    if(this.localService.get(this.DELIVERYINFOKEY)){
      this.localService.remove(this.DELIVERYINFOKEY);
    }
    if(this.localService.get(this.RESTAURENTKEY)){
      this.localService.remove(this.RESTAURENTKEY);
    }
    if(this.localService.get(this.SELECTEDRESTAURENTKEY)){
      this.localService.remove(this.SELECTEDRESTAURENTKEY);
    }
    if(this.localService.get(this.NOTESKEY)){
      this.localService.remove(this.NOTESKEY);
    }
    if(this.localService.get(this.INSTRUCTIONSKEY)){
      this.localService.remove(this.INSTRUCTIONSKEY);
    }
    if(this.localService.get(this.ORDERIDKEY)){
      this.localService.remove(this.ORDERIDKEY);
    }
    if(this.localService.get(this.ADDRESSKEY)){
      this.localService.remove(this.ADDRESSKEY);
    }
  }

  clearMasterdata(){
    if(this.localService.get('HandlingTax')){
      this.localService.remove('HandlingTax')
    }
    if(this.localService.get('NormalTax')){
      this.localService.remove('NormalTax')
    }
    if(this.localService.get('ShippingTax')){
      this.localService.remove('ShippingTax')
    }
    if(this.localService.get('TAXIDS')){
      this.localService.remove('TAXIDS')
    }
    if(this.localService.get('ORDERTAXLABELS')){
      this.localService.remove('ORDERTAXLABELS')
    }
  }
}
