import { Injectable } from '@angular/core';

import { CheckoutSettingsService } from './checkout-settings.service';
import { OrderSummaryOrderService } from './order-summary/order-summary-order.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutCalculationsService {

  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABEL = "FIXED AMOUNT";
  INCLUDINGTAX_LABEL = "INCLUDING TAX";

  orderTotal = 0;

  constructor(
    private checkoutSettingsService : CheckoutSettingsService,
    private orderSummaryOrderService: OrderSummaryOrderService
  ) { }

  calculateSubTotal(productList){
    return new Promise((resolve, reject) => {
      let subTotal = 0;
      productList.forEach(product => {
        subTotal = subTotal + (product.cost * product.quantity)
      });
      this.orderSummaryOrderService.orderObj.subTotal = subTotal;
      resolve(subTotal);
    })
  }

  calculateHandlingFee(subtotal, discount, handlingType, handlingRate){
    return new Promise((resolve, reject) => {
      this.orderSummaryOrderService.orderObj.handlingCostType = handlingType
      if(handlingType.toUpperCase() === this.PERCENTAGE_LABEL){
        let handlingFee = 0;
        handlingFee = (handlingRate * (subtotal-discount))/100;
        this.orderSummaryOrderService.orderObj.handlingFee = Math.round((handlingFee + Number.EPSILON) * 100) / 100;
        resolve(Math.round((handlingFee + Number.EPSILON) * 100) / 100)
      }
      else{
        this.orderSummaryOrderService.orderObj.handlingFee = handlingRate;
        resolve(handlingRate);
      }
    })
  }

  calculateProductTax(productTaxList){
    return new Promise((resolve, reject) => {
      let productTax = 0;
      if(this.checkoutSettingsService.taxCalculationSettings.catalog_prices.toUpperCase() === this.INCLUDINGTAX_LABEL){
        this.orderSummaryOrderService.orderObj.productTax = 0;
        resolve(0)
      }
      else{
        productTaxList.forEach(product => {
          productTax = productTax + ((product.quantity * product.price * product.taxrate)/100)
        });
        this.orderSummaryOrderService.orderObj.productTax = Math.round((productTax + Number.EPSILON) * 100) / 100;
        resolve(Math.round((productTax + Number.EPSILON) * 100) / 100)
      }
    })
  }

  calculateHandlingFeeTax(handlingFee, handlingTaxRate){
    return new Promise ((resolve, reject) => {
      let handlingTax = (handlingFee * handlingTaxRate)/100;
      this.orderSummaryOrderService.orderObj.handlingFeeTax = Math.round((handlingTax + Number.EPSILON) * 100) / 100;
      resolve(Math.round((handlingTax + Number.EPSILON) * 100) / 100)
    })
  }

  calculateShippingTax(shippingAmount, shiipingTaxRate, is_shipping){
    return new Promise ((resolve, reject) => {
      if(is_shipping){
        let shippingTax = (shippingAmount * shiipingTaxRate)/100;
        this.orderSummaryOrderService.orderObj.shippingChargesTax = Math.round((shippingTax + Number.EPSILON) * 100) / 100;
        resolve(Math.round((shippingTax + Number.EPSILON) * 100) / 100)
      }
      else{
        this.orderSummaryOrderService.orderObj.shippingChargesTax = 0;
        resolve(0)
      }
    })
  }

  calculateDiscountTax(discountAmount, discountTaxRate, isDicountTax){
    return new Promise ((resolve, reject) => {
      if(isDicountTax){
        let discountTax = (discountAmount * discountTaxRate)/100;
        this.orderSummaryOrderService.orderObj.discountTax = Math.round((discountTax + Number.EPSILON) * 100) / 100
        resolve(Math.round((discountTax + Number.EPSILON) * 100) / 100)
      }
      else{
        this.orderSummaryOrderService.orderObj.discountTax = 0;
        resolve(0)
      }
    })
  }

  calculateTotalTax(productTaxList, handlingFee, handlingTaxRate, discountAmount, shippingAmount, isDicountTax, isShippingTax, shiipingTaxRate, discountTaxRate){
    return new Promise((resolve, reject) => {
      this.calculateProductTax(productTaxList).then(
        respData => {
          let res:any = respData;
          this.calculateHandlingFeeTax(handlingFee, handlingTaxRate).then(
            respData1 => {
              let res1:any = respData1;
              this.calculateShippingTax(shippingAmount, shiipingTaxRate, isShippingTax).then(
                respData2 => {
                  let res2:any = respData2;
                  this.calculateDiscountTax(discountAmount, discountTaxRate, isDicountTax).then(
                    respData3 =>{
                      let res3:any = respData3;
                      this.orderSummaryOrderService.orderObj.totalTax = res + res1 + res2 + res3;
                      // resolve(res + res1 + res2 + res3);
                      resolve(Math.round((res + res1 + res2 + res3 + Number.EPSILON) * 100) / 100)
                    }
                  )
                }
              )
            })
        })
    })
  }

  calculateTotal(subTotal, discount, handlingFee, shipping, tax){
    this.orderTotal = Math.round(subTotal - discount + handlingFee + shipping + tax);
    return(Math.round(subTotal - discount + handlingFee + shipping + tax))
  }

  calculateDiscount(subTotal, disocunt){
    return new Promise((resolve, reject) => {
      let discount = (disocunt * subTotal)/100;
      resolve(Math.round((discount + Number.EPSILON) * 100) / 100)
    })
  }
}
