import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TableBookingService } from './table-booking.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { NavbarService} from '../navbar/navbar.service';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';

@Component({
  selector: 'app-table-booking',
  templateUrl: './table-booking.component.html'
})
export class TableBookingComponent implements OnInit {


  tableBookingForm: FormGroup;
  validateForm: boolean = false;
  queryParams: any;
  showmsg: boolean = false;
  date: any;
  today: any;
  public addOutlet = true;
  outletList = [];

  restaurentTimings:any = "";

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  datePickerConfig = {
    format : "yyyy-MM-DD",
    min : moment().format("YYYY-MM-DD")
  }
  @ViewChild('dayPicker') datePicker: DatePickerComponent;
  customerList = [];

  editPage: boolean = false;
  mindeliveryDate: any = "";
  show_on_page_list: any = ["Home Page", "Category Page", "Search Results"];
  location_list: any = ["Top", "Bottom"];
  start_time: any;
  end_time: any;


  constructor(
    private formBuilder: FormBuilder,
    private tableBookingService: TableBookingService,
    private alertService: AlertsService,
    private router: Router,
    private appService: AppService,
    private atp: AmazingTimePickerService,
    private localService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private navbarService: NavbarService,
    ) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    var dtToday = new Date();
    var month: any = dtToday.getMonth() + 1;
    var day: any = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();
    this.mindeliveryDate = year + '-' + month + '-' + day;
    this.tableBookingForm = new FormGroup({
      name: this.formBuilder.control('', [Validators.required, Validators.maxLength(80), Validators.pattern("^[a-zA-Z][a-z A-Z]*$")]),
      email_address: this.formBuilder.control('', [Validators.maxLength(40), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      mobile_number: this.formBuilder.control('', [Validators.required, Validators.maxLength(15), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$")]),
      date: this.formBuilder.control('', [Validators.required]),
      time: this.formBuilder.control('', [Validators.required]),
      optional_message: this.formBuilder.control('', [Validators.maxLength(1000)]),
      no_of_persons: this.formBuilder.control('', [Validators.required, Validators.max(99),Validators.min(1)]),
      add_outlet:this.formBuilder.control('', [Validators.required]),
      is_active: this.formBuilder.control(true)
    })
    this.getOutletData();
  }
  saveForm() {
    this.showmsg=true
      let bookedTime=moment(this.tableBookingForm.value.time, 'hh:mmA').format('HH:mm')
      let startTime=moment(this.start_time, 'hh:mm a').format('HH:mm')
      let endTime=moment(this.end_time, 'hh:mm a').format('HH:mm')
    if (bookedTime <= startTime || bookedTime >= endTime)
        {
          this.tableBookingForm.controls.time.setErrors({"unavailable_time" : true})
          this.validateForm = true;
          return
        }
    let currentData = new Date()

      let date = currentData.getDate() < 10 ? "0"+ (currentData.getDate()).toString() : (currentData.getDate()).toString()

      let month = currentData.getMonth() + 1 < 10 ? "0" + (currentData.getMonth()+ 1).toString() : (currentData.getMonth()+ 1).toString()

      let hour = currentData.getHours() < 10 ? "0"+ (currentData.getHours()).toString() : (currentData.getHours()).toString()

      let minute = currentData.getMinutes() < 10 ? "0"+ (currentData.getMinutes()).toString() : (currentData.getMinutes()).toString()
      
      let currentTime = hour + ":" + minute
      
      //TODAY DATE
      let today = currentData.getFullYear().toString() + "-" + month + "-" +  date

      if (this.tableBookingForm.value.date === "" )
      {
        this.tableBookingForm.controls.date.setErrors({"required": true});
        this.validateForm = true;
        return
      }
      //CHECKING TODAY WITH SELECTED DATE
      else if(this.tableBookingForm.value.date < today)
      {
        this.tableBookingForm.controls.date.setErrors({"previous_date": true});
        this.validateForm = true;
        return
      }
      else
      {
        this.tableBookingForm.controls.date.setErrors(null)
      }
      
        
      if (this.tableBookingForm.value.date === today)
      {
        if (this.tableBookingForm.value.time <= currentTime)
        {
          this.tableBookingForm.controls.time.setErrors({"previous_time" : true})
          this.validateForm = true;
          return
        }
        else
        {
          this.tableBookingForm.controls.time.setErrors(null)
        }
      }
      if (this.tableBookingForm.value.date > today)
      {
          this.tableBookingForm.controls.time.setErrors(null)
      }
    if (this.tableBookingForm.valid && this.addOutlet === true) {
      this.validateForm = false;
      var formData = {}
      var formDataJson;
      formData["name"] = this.tableBookingForm.value.name;
      formData["email_address"] = this.tableBookingForm.value.email_address;
      formData["mobile_number"] = this.tableBookingForm.value.mobile_number;
      formData["date"] = this.tableBookingForm.value.date;
      formData["time"] = this.tableBookingForm.value.time;
      formData["no_of_persons"] = this.tableBookingForm.value.no_of_persons;
      formData["add_outlet"] = this.tableBookingForm.value.add_outlet;
      formData["optional_message"] = this.tableBookingForm.value.optional_message;
      formData['is_active'] =  this.tableBookingForm.value.is_active
      formData['status'] = 'New'
      formDataJson = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        user_id: ORG_DETAILS.USER_ID,
        payload: {
          tablebooking_creation: formData
        }
      }
      // MAKE A SERVICE CALL HERE...
      this.appService.postApiCall(formDataJson, ENDPOINTS.ADD_TABLEBOOKING).subscribe(
        resp => {
          if (resp) {
            if (resp.success === 1 && resp.status_code === 200) {
              this.alertService.success('Request Received', this.options);
              this.tableBookingForm.reset();
              window.scrollTo({top: 0, behavior: 'smooth'});

            }
            else if (resp.success === 0) {
              if (resp.message) {
                this.alertService.error(resp.message, this.options);
                window.scrollTo({top: 0, behavior: 'smooth'});
              }
            }
            else if (resp.message && resp.status_code !== 200) {
              this.alertService.error(resp.message, this.options);
              window.scrollTo({top: 0, behavior: 'smooth'});
            }
            else {
              this.alertService.error('Something bad happened. Please try again!', this.options);
              window.scrollTo({top: 0, behavior: 'smooth'});
            }
          }
        },
        err => {
          if (err.error.statusCode === 403) {
            this.alertService.error('Session Time Out! Please login Again', this.options)
            this.router.navigate([`/login-new`], { skipLocationChange: false });
          }
          else if (err.error.message) {
            this.alertService.error(err.error.message, this.options)
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
          else if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
          else {
            this.alertService.error('Something bad happened. Please try again!', this.options);
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
        }
      )
        ;
    }
    else {
      this.validateForm = true;
    }
  }
  getOutletData(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
      resp => {
        if(resp.success === 1){
          this.outletList = resp.result.data;
          let open_time=resp.result.data[1].open_time
          let close_time=resp.result.data[1].close_time
          this.start_time=moment(open_time, 'hh:mmA').format('hh:mm a')
          this.end_time=moment(close_time, 'hh:mmA').format('hh:mm a')
          this.restaurentTimings = resp.result.data[0].open_time + " to "+resp.result.data[0].close_time;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }
  pickTime() {
    const amazingTimePicker = this.atp.open({
      time: this.tableBookingForm.value.time,
      theme: 'light',
      arrowStyle: {
        background: '#afacac',
      }
    });

    amazingTimePicker.afterClose().subscribe(time => {
      this.tableBookingForm.controls.time.setValue(time)
    })
  }
  get f() { return this.tableBookingForm.controls; }

  handleOutlet(evt) {
    console.log("evt.target.value"+evt.target.value)
    if (evt.target.value === "") {
      this.addOutlet = true
    }
    else {
      this.outletList.forEach((element)=>
      {
        if (element.name == evt.target.value)
        {
         if(element.allow_table_booking === 'No'){
          this.addOutlet = false
         } else if(element.allow_table_booking === 'Yes'){
          this.addOutlet = true
         }
        }
      })
    }
  }

}

