import { Component, OnInit, NgZone } from '@angular/core';
// import { AuthTokenService } from '../auth-service/auth-token.service';
import { LocalStorageService } from '../auth-service/local-storage.service'
import { Router, ActivatedRoute } from '@angular/router'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavbarService } from './navbar.service';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { CheckoutLocalstorageService } from '../checkout/checkout-localstorage.service';
import { CheckoutLocalStorageService as  CheckoutLocalStorageServiceNew} from '../checkout-new/checkout-local-storage.service';
import {config as configuration} from '../../configs/config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  searchForm: FormGroup;
  preList = [];
  emptyResult: boolean = false;
  userLoggedIn: boolean = false;
  productOrList: boolean;
  productId: any;
  searchTerm: any;
  allCategories: any;
  resultMessage: any;

  logoImage:any = "";
  
  configs = configuration;

  constructor(config: NgbDropdownConfig,
    // private authService: AuthTokenService,
    private localService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public appService: AppService,
    private formBuilder: FormBuilder,
    private navbarService: NavbarService,
    private checkoutLocalstorageService: CheckoutLocalstorageService,
    private checkoutLocalStorageService1: CheckoutLocalStorageServiceNew
    ) {
    config.placement = 'bottom-right'
  }

  ngOnInit(): void {
    this.getLogo();
    this.searchForm = new FormGroup({
      search_term: this.formBuilder.control('')
    })
    if (this.localService.get("UserName") != 'undefined' && this.localService.get("UserName")) {
      //The front-end should change when this is called.
      this.appService.userLoggedIn = true;
      this.appService.userName = this.localService.get("UserName");
    }
    else {
      this.appService.userLoggedIn = false;
      this.appService.userName = "New User";
    }
    // TO CLEAR THE SEARCH TERM FROM OTHER EVENTS
    this.navbarService.currentData.subscribe(
      currentData => {
        if (currentData === 'clearSearchTerm') {
          this.closePrelist();
        }
      }
    )

  }
  getPreList(evt) {
    if(evt.keyCode === 13){
      this.savePreList1()
      return
    }
    let searchData = this.searchForm.value.search_term;
    if (searchData.length >= 2) {
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
            "search": searchData
          }
        }
      }
      this.appService.postApiCall(reqData, ENDPOINTS.GET_PRELIST).subscribe(
        resp => {
          this.preList = resp.result.data;
          if (this.preList.length == 0) {
            this.emptyResult = true;
          }
          else {
            this.emptyResult = false;
          }
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    }
    else {
      this.preList = [];
    }
  }

  savePreList(val) {
    console.log(val);
    if (val.type == 'Product Name') {
      this.getCategory(val)
    }
    else {
      this.sendProductDetails(val);
    }
  }

  savePreList1() {
    if(this.searchForm.controls['search_term'].value==""){
      return
    }
    if(this.preList.length==0){
      this.router.navigate([`/product`], { queryParams: { terms: ["",""], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
      return;
    }
    var searchVal1 = this.searchForm.controls['search_term'].value;
    if (searchVal1) {
    //   this.sendProductDetails(this.preList[0]);
    // }
    if (this.preList[0].type == 'Product Name') {
      this.getCategory(this.preList[0])
    }
    else {
      this.sendProductDetails(this.preList[0]);
    }
  }
  }
  sendProductDetails(searchTerm) {
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm.name
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }

    // MAKE AN API CALL
    this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RRESULTS).subscribe(
      resp => {
        let respData = resp.result.data;
        this.preList = [];
        console.log(respData)
        this.searchForm.controls['search_term'].setValue(searchTerm.name);
        this.emptyResult = false;
        this.router.navigate([`/product`], { queryParams: { terms: [searchTerm.name, searchTerm.type], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });

      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  getCategory(searchTerm) {
    let req_search_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm.name
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1, "pagesize": 20
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": 1
        }
      }
    }


    this.appService.postApiCall(req_search_data, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
      resp => {
        let respData = resp.result.data[0];
        this.preList = [];
        console.log(respData)

        this.searchForm.controls['search_term'].setValue(searchTerm.name);
        this.emptyResult = false;
        this.router.navigate([`/product`], { queryParams: { terms: [searchTerm.name, searchTerm.type], category: [respData.parentcategories[0].name], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  setClass(navLocation: string) {
    let listOfNavs = document.getElementsByClassName('nav-item');
    for (let i = 0; i < listOfNavs.length; i++) {
      listOfNavs[i].classList.remove('active');
    }
    document.getElementById(navLocation).classList.add("active");

  }
  closePrelist() {
    this.searchForm.controls['search_term'].setValue('');
    this.preList = [];
  }
  logIn() {
    this.router.navigate([`/login-new`], { queryParams: { url: this.router.url }, relativeTo: this.route, skipLocationChange: false });
  }
  logOut() {
    this.appService.userLoggedIn = false;
    this.appService.userName = "";
    this.appService.loggedin_user = false;
    this.userLoggedIn = this.appService.loggedin_user;
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('expireTime');
    this.localService.remove('UserName');
    this.localService.remove('UserId');
    this.localService.remove('domainName');
    this.localService.remove('rexpireTime');
    this.checkoutLocalstorageService.clearLogLocalCheckout();
    this.router.navigate(["/"]);
    this.checkoutLocalStorageService1.clearDataonLogout();
    this.checkoutLocalStorageService1.removeOrderId();
  }

  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }

}
