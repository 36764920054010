import { Component, OnInit } from '@angular/core';
import { HomeSliders } from '../shared-two/slider/slider';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',

})
export class BannerComponent implements OnInit {

  constructor() { }
  public HomePageBannerSlider: any =  HomeSliders;
  ngOnInit(): void {
  }

}
