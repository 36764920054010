import { Component, OnInit } from '@angular/core';
import { Base64 } from 'js-base64';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService} from '../navbar/navbar.service';
import { AlertsService } from '../shared/alerts/alerts.service';

import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-order-cancellation',
  templateUrl: './order-cancellation.component.html'
})
export class OrderCancellationComponent implements OnInit {

  orderId:any;
  orderNumber:any="";
  reason:any = "";
  showalertmsg:boolean = false;

  // ALERT SERVICE SETTINGS
  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  constructor(
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private alertService: AlertsService,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    // this.showalertmsg = true;
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    if(this.route.snapshot.queryParamMap){
      this.orderId = Number(this.route.snapshot.queryParamMap.get('id'));
      this.reason = this.route.snapshot.queryParamMap.get('reason');
      // this.alertService.error(this.reason, this.options)
      this.getAllOrderDetails();
    }
  }

  getAllOrderDetails() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "id": this.orderId
        }
      }
    }
    this.appService.postApiCall(requestBody, ENDPOINTS.GETORDERDETAILS).subscribe(
      resp => {
        var orderData = resp.result.data[0];
        this.orderNumber = orderData.order_number
      },
      err => {
        if (err.error.message) {
          console.log(err.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      });
  }

}
