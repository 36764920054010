import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../auth-service/local-storage.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerAddressService {

  SELECTEDRESTAURENTKEY = "SlectedRestaurent";
  CUSTOMERKEY = "CustomerTab";
  ADDRESSKEY = "addressTab";

  private data = new BehaviorSubject('');
  currentData = this.data.asObservable()

  constructor(
    private localService:LocalStorageService
  ) { }

  updateAdminFormEvent(item: any) {
    this.data.next(item);
  }

  clearEvent() {
    this.data = new BehaviorSubject('');
    this.currentData = this.data.asObservable();
  }

  getOutLetData(){
    if(this.localService.get(this.SELECTEDRESTAURENTKEY)){
      let selected_restaurent = JSON.parse(this.localService.get(this.SELECTEDRESTAURENTKEY))
    let oulet = {
      name:selected_restaurent.name,
      longitude:selected_restaurent.longitude,
      latitude:selected_restaurent.latitude,
      enable_distance_radius:selected_restaurent.enable_distance_radius,
      distance_radius:selected_restaurent.distance_radius,
    }
    return oulet;
    }
    else{
      return {
        name:"",
        longitude:"",
        latitude:"",
        enable_distance_radius:"",
        distance_radius:"",
      }
    }
  }

  getCustomerData(){
    if(this.localService.get('UserId')){
      return Number(JSON.parse(this.localService.get('UserId')))
    }
    else{
      return 0;
    }
  }

  setSelectedAddress1(addressObj){
    this.localService.set(this.ADDRESSKEY, JSON.stringify(addressObj))
  }

  getSelectedAddress(){
    if(this.localService.get(this.ADDRESSKEY)){
      return JSON.parse(this.localService.get(this.ADDRESSKEY));
    }
    else{
      return "";
    }
  }

  getPickdropCall(){
    if(this.localService.get('PickDropCall')){
      return this.localService.get('PickDropCall');
    }
    else{
      return "";
    }
  }

}
