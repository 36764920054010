import { Injectable } from '@angular/core';
import { RegistrationOfferOrderService } from './registration-offer-order.service';
import { RegistrationOfferInitiationService } from './registration-offer-initiation.service';
import { RegistrationOfferSettingsService } from './registration-offer-settings.service';
import { RegistrationOfferApiService } from './registration-offer-api.service';
import { ENDPOINTS,ORG_DETAILS } from '../app.config';
import { CheckoutApiService } from '../checkout-new/checkout-api.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationOfferInitiationApiService {

  constructor(
    private registrationOfferOrderService: RegistrationOfferOrderService,
    private registrationOfferInitiationService: RegistrationOfferInitiationService,
    private registrationOfferSettingsService: RegistrationOfferSettingsService,
    private registrationOfferApiService: RegistrationOfferApiService,
    private checkoutApiService: CheckoutApiService,
  ) { }

  setOrderDetails(){
    return new Promise((resolve, reject) =>{
      this.registrationOfferSettingsService.getTaxSettings().then(
        respData => {
          let res:any = respData;
          if(res){
            this.registrationOfferSettingsService.getTaxRates().then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  this.registrationOfferOrderService.setDeliveryDetails().then(
                    respData3 => {
                      let res3:any = respData3;
                      if(res3){
                        this.registrationOfferOrderService.setCurrencyLabels().then(
                          respData4 => {
                            let res4:any = respData4;
                            if(res4){
                              this.registrationOfferOrderService.getOrderStatus().then(
                                respData5 => {
                                  let res5:any = respData5;
                                  if(res5){
                                    this.registrationOfferOrderService.setShippingAddress(this.registrationOfferApiService.selectedAddress).then(
                                      respData6 => {
                                        let res6:any = respData6;
                                        if(res6){
                                          this.registrationOfferOrderService.setRemainingShippingDetails().then(
                                            respData7 => {
                                              let res7:any = respData7;
                                              if(res7){
                                                this.registrationOfferOrderService.setProductDetails().then(
                                                  respData8 => {
                                                    let res8:any = respData8;
                                                    if(res8){
                                                      this.registrationOfferOrderService.setOrderDetails().then(
                                                        respData9 => {
                                                          let res9:any = respData9;
                                                          if(res9){
                                                            resolve(true);
                                                          }
                                                        }
                                                      )
                                                    }
                                                  }
                                                )
                                              }
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          }
                        )
                      }
                    }
                  )
                }
              }
            )
          }
        }
      )
    })
  }
  
  initiateSampleOrder(){
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload": {
          "order": this.registrationOfferInitiationService.orderObj,
          "new_value":this.registrationOfferInitiationService.new_value,
          "old_value":this.registrationOfferInitiationService.old_value,
        },
        "extras": {
          "find": {
            "id": ""
          }
        }
      }
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, requestData).then(
        respData => {
          let res:any = respData;
          if(res.success){
            this.registrationOfferApiService.clearFreeSample();
            resolve(res.data[0].order_number);
          }
        }
      )
    })
  }
}
