import { Injectable } from '@angular/core';

import { CheckoutApiService } from './checkout-api.service';
import { ENDPOINTS } from '../app.config';
 
@Injectable({
  providedIn: 'root'
})
export class CheckoutSettingsService {

  taxClassLabels = {
    shipping_label: "",
    product_label: "",
    handlingfee_label:""
  }
  taxCalculationSettings = {
    catalog_prices:"",
    shipping_prices:"",
    apply_discount_on_prices:""
  }

  shippingTax = {
    rate:0,
    name:"",
    id:0
  }

  productTax = {
    rate:0,
    name:"",
    id:0
  }

  handlingFeeTax = {
    rate:0,
    name:"",
    id:0
  }

  initialHanglingFee = {
    delivery:true,
    name:"",
    type:"",
    rate:0
  }

  deliveryType = {
    delivery:"",
    pickupin_store:""
  }

  // SYSTEM LABELS WITH RAW VALUES
  taxclasses:any = null;
  shippingMethods:any = null;


  constructor(
    private checkoutApiService: CheckoutApiService
  ) { }

  getTaxSettings(){
    return new Promise ((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.TAXSETTINGS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data[0].class){
              let classLabels = res.data[0].class;
              this.taxClassLabels.shipping_label = classLabels.tax_class_shipping;
              this.taxClassLabels.product_label = classLabels.tax_class_product;
              this.taxClassLabels.handlingfee_label = classLabels.tax_class_handling_fee;
            }
            if(res.data[0].calculation){
              let calculationSettings = res.data[0].calculation;
              this.taxCalculationSettings.catalog_prices = calculationSettings.catalog_prices !== "" ? calculationSettings.catalog_prices : "excluding tax";
              this.taxCalculationSettings.shipping_prices = calculationSettings.shipping_prices !== "" ? calculationSettings.shipping_prices : "excluding tax";
              this.taxCalculationSettings.apply_discount_on_prices = calculationSettings.apply_discount_on_prices !== "" ? calculationSettings.apply_discount_on_prices : "excluding tax";
            }
            resolve(true);
          }
          else{
            resolve(false);
          }
        }
      )
    }

    )
  }

  getTaxRates(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_TAXZONES).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data[0].tax_rates){
              this.taxclasses = res.data[0].tax_rates;
              res.data[0].tax_rates[0].tax_class_rates.forEach(taxRate => {
                if(taxRate.tax_class_name === this.taxClassLabels.product_label){
                  this.productTax.name = taxRate.tax_class_name;
                  this.productTax.rate = taxRate.tax_class_rate_percent;
                  this.productTax.id = taxRate.tax_class_id;
                }
                if(taxRate.tax_class_name === this.taxClassLabels.shipping_label){
                  this.shippingTax.name =  taxRate.tax_class_name;
                  this.shippingTax.rate = taxRate.tax_class_rate_percent;
                  this.shippingTax.id = taxRate.tax_class_id;
                }
                if(taxRate.tax_class_name === this.taxClassLabels.handlingfee_label){
                  this.handlingFeeTax.name =  taxRate.tax_class_name;
                  this.handlingFeeTax.rate = taxRate.tax_class_rate_percent;
                  this.handlingFeeTax.id = taxRate.tax_class_id;
                }
              });
              if(this.handlingFeeTax.name !== ""){
                resolve(true);
              }
            }
          }
        }
      )
    }

    )
  }

  getShippingSettings(){
    return new Promise((resolve, reject) =>
    {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_SHIPMENTMETHODS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let shipmentData = this.shippingMethods = res.data[0];
            if(shipmentData.delivery_details){
              if(shipmentData.delivery_details.no_deliveries_only_pickup){
                this.initialHanglingFee.delivery = false;
                this.deliveryType.pickupin_store = shipmentData.pickup_in_store.display_name;
                this.initialHanglingFee.name = shipmentData.pickup_in_store.handling_fees.display_name;
                this.initialHanglingFee.type = shipmentData.pickup_in_store.handling_fees.fee_type;
                this.initialHanglingFee.rate = shipmentData.pickup_in_store.handling_fees.handling_fee === "" ? 0 : Number(shipmentData.pickup_in_store.handling_fees.handling_fee);
              }
              else{
                if(shipmentData.flat_rate.is_enabled){
                  this.initialHanglingFee.delivery = true;
                  this.deliveryType.delivery = shipmentData.delivery_details.display_name;
                  this.initialHanglingFee.name = shipmentData.flat_rate.handling_fees.display_name;
                  this.initialHanglingFee.type = shipmentData.flat_rate.handling_fees.fee_type;
                  this.initialHanglingFee.rate = shipmentData.flat_rate.handling_fees.handling_fee === "" ? 0 : shipmentData.flat_rate.handling_fees.handling_fee;
                }
              }
            }
            resolve(true);
          }
          else{
            resolve(false)
          }
        }
      )
    })
  }



}
