<section>
    <div class="profile-page">
      <button *ngIf="showEdit" class="btn btn-primary" id="edit" style="float: right;" (click)="onedit()">Edit</button>  
      <h2 class="heading">Profile Details</h2>
      <form class="customer-registartion-form" [formGroup]="userRegistartionForm" novalidate>
        <div class="form-group">
          <label for="first_name" class="form-label">First name</label>
          <input type="text" class="form-control" formControlName="first_name"
            [class.is-invalid]="f.first_name.invalid">
          <div class="invalid-feedback" *ngIf="f.first_name.errors">
            <div *ngIf="f.first_name.errors.minlength">
               First name can't be less than 3 characters long.
            </div>
            <div *ngIf="f.first_name.errors.maxlength">
              First name can't be more than 40 characters long.
            </div>
            <div *ngIf="f.first_name.errors.pattern">
              First name is invalid.
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <label for="last_name" class="form-label">Last name</label>
          <input type="text" class="form-control" formControlName="last_name"
            [class.is-invalid]="f.last_name.invalid">
          <div class="invalid-feedback" *ngIf="f.last_name.errors">
            <div *ngIf="f.last_name.errors.maxlength">
              Last name can't be more than 40 characters long.
            </div>
            <div *ngIf="f.last_name.errors.pattern">
              Last name is invalid.
            </div>
          </div>
        </div> -->

        <!--Email-->
        <div class="form-group">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" formControlName="email"
            [class.is-invalid]="f.email.invalid">
          <div class="invalid-feedback" *ngIf="f.email.errors">
            <div *ngIf="f.email.errors.pattern">
              Enter correct Email Id.
            </div>
            <div *ngIf="f.email.errors.maxlength">
              Email can't be more than 50 characters long.
            </div>
          </div>
        </div>

        <!--phone number-->
        <div class="form-group">
          <label for="phone_number" class="form-label">Mobile number</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <select class="custom-select" id="country_code"
                [class.is-invalid]="f.country_code.invalid" formControlName="country_code">
                <option *ngFor="let code of countryCodes" value="{{ code.value }}">{{ code.name }}</option>
              </select>
            </div>
            <p class="pad"></p>
            <input type="text" class="form-control" placeholder="Mobile number" id="phone_number"
              [class.is-invalid]="f.phone_number.invalid && validateForm" (change)="changeMobile()" formControlName="phone_number" >
            <div class="invalid-feedback" *ngIf="f.phone_number.errors">
              <div *ngIf="f.phone_number.errors.maxlength">
                Mobile number can't be more than 10 characters long.
              </div>
              <div *ngIf="!f.phone_number.errors.maxlength && f.phone_number.errors.pattern">
                Mobile number is invalid.
              </div>
              <div *ngIf="f.phone_number.errors.required">
                Mobile number is required
              </div>
            </div>
          </div>
        </div>

        <label class="form-label">Gender</label>
        <!--  -->

        <div class="form-group row">
          <div class="col-md-6">
            <input type="text" class="form-control text-center {{m}}" value="Male"  name="male" (click)="genderChange($event)" [disabled]="disable" readonly>
          </div>
        
          <div class="col-md-6">
            <input type="text" class="form-control text-center {{fm}}" value="Female"  name="female" (click)="genderChange($event)"  [disabled]="disable" readonly>
          </div>
        </div>

      <br>
        <div *ngIf="hide">
          <div class="form-group row">
            <div class="col-md-6">
              <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="formCheck()">Save Changes</button>
            </div>
          
            <div class="col-md-6">
              <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="reset()">Cancel</button>
            </div>
          </div>
          <!-- <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="formCheck()">Save Changes</button> -->
        </div>
        
         <!--<p class="custom">Already have an account? <a class="link-style" routerLink="/login">Sign In &nbsp;<i class="fa fa-angle-right"></i></a></p> -->
      </form>
    
      <div *ngIf="otpForm">
      <form [formGroup]="otpLoginForm" class="otp-form">
        <div class="form-group">
          <input type="text" class="form-control" formControlName="otp" placeholder="Enter OTP"
            [class.is-invalid]="vaidateotpLoginForm && f4.otp.errors">
          <div class="invalid-feedback" *ngIf="f4.otp.errors">
            <div *ngIf="f4.otp.errors.required">Please enter the OTP.</div>
            <div *ngIf="f4.otp.errors.incorrect">Please enter the valid OTP</div>
          </div>
        </div>
      </form>
      <div class="mt-3 mb-3" >
        <p class="pl-4">Didn't get the code? <strong><a class="custom-pointer" (click)=resendCode()>Resend Code</a>
          </strong>
        </p>
      </div>

      <div class="form-group row">
        <div class="col-md-6">
          <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="otpType=='mobile'? OTPLogin() : (otpType=='email' ? verifyOTPEmail() : '')">Confirm</button>
        </div>
      
        <div class="col-md-6">
          <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="reset()">Cancel</button>
        </div>
      </div>
      <!-- <div style="align-items: center;">
        <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="OTPLogin()">Confirm</button>
        <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="OTPLogin()">Cancel</button>
      </div> -->
    </div>
    
    </div>


  <!-- </div> -->

</section>