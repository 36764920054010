<div *ngIf = "activateOrder === 'success'" class="order-confirm-page">
    <app-alerts *ngIf="showalertmsg"></app-alerts>
    <section class="order-confirm">

        <i class="fa fa-check-circle" style="font-size: 60px;color: #90ba4c;"></i>
    
        <span class="thanks">We have received your Order</span>
        <span class="order-info">A copy of your receipt has been sent to your mail.</span>
        <span>Your Order Number is <b>{{orderNumer}}</b>.</span>
        <br>
    </section>
    <hr>
    <br>
    <section>
        <div class="row">
            <div class="row col-md-8">
                <div class="col-sm-1">
                </div>
                <div class="col-sm-5">
                    <div class="card">
                        <br>
                        <br>
                        <h5>Delivery Details</h5>
                        <div *ngIf="shippingAddress !== '' && this.paymentMethod !== 'Pay In Store'">
                            <div class="row">
                                <div class="col">
                                    {{shippingAddress.name}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    {{shippingAddress.address1}}
                                    {{shippingAddress.landmark}}
                                    {{shippingAddress.entire_location}},
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <div class="card">
                        <br>
                        <br>
                        <h5>Payment Information</h5>
                        <div class="row">
                            <div class="col">
                                Payment Method : {{this.paymentMethod}}
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- <div class="col-sm-1">
                </div> -->
            </div>
            <br>
            <div class="row col-md-3 box">
                <div class="col-lg-12">
                    <h5 class="text-left inq-cart-ord-ti-st">Order Summary</h5>
                    <div class="row  inq-order-inner-p-st">
                        <table style="width: 100%;" class="table table-striped">
                            <ng-container>
                                <tr>
                                    <td>SubTotal</td>
                                    <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderSummary.sub_total}}</td>
                                </tr>
                                <tr *ngIf="orderSummary.shipping_cost > 0">
                                    <td>Delivery Charge</td>
                                    <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderSummary.shipping_cost}}</td>
                                </tr>
                                <tr>
                                    <td>{{orderSummary.handling_display}}</td>
                                    <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderSummary.packing_cost}}</td>
                                </tr>
                                <tr *ngIf="orderSummary.discount_display !== ''">
                                    <td>{{orderSummary.discount_display}}</td>
                                    <td class="text-right">- {{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}{{orderSummary.discount_amount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td class="text-right">{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}} {{orderSummary.tax}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td class="text-right"><strong>{{CONFIG.title === 'LuckMe' ||  CONFIG.title === 'Murgasen' ? 'SR ' : '₹'}}
                                            {{orderSummary.order_total_amount}}</strong>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<div *ngIf="activateOrder === ''" style="min-height: 30vh; display: flex; justify-content: center; margin-top:100px">
    <h2>Please wait, Your data is loading...</h2>
</div>

<div *ngIf="activateOrder === 'failure'" style="min-height: 30vh; display: flex; justify-content: center; margin-top:100px">
    <h3>The order you are trying is invalid please go back to the checkout page.</h3>
</div>