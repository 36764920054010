<div class="filter-top">
    <div class="filter-clear">
        <h5 class="mt-2">Filter</h5>
        <span class="btn btn-sm" (click)="clearAll()" style="cursor: pointer;font-size: 14px;color:#dc3545">Clear All</span>
    </div>
</div>
<div *ngIf="categoriesList.length>0" class="layerd-navigation" >
    <div   class="filter-collections-group">
    <h5 class="sub-heading">Categories</h5>
    <ng-container>
        <div class="sublist-font" *ngFor = "let category of categoriesList; index as i" style="margin-bottom: 10px;">
            <div class="wrapper" style="margin-left: 10px;">
                <!-- <li><a [class.active]="active === category.name" class="sub-list font-weight-bolder" (click)="checkedevt(category.name, category.name)">{{category.name | titlecase | productDetailsPipe}}<span *ngIf="category.subcategories"> <i class="fa fa-angle-down arrow-size"></i> </span></a></li> -->
                <li><a [class.active]="active === category.name" class="sub-list font-weight-bolder" (click)="checkedevt(category.name, category.name)">{{category.name | titlecase | productDetailsPipe: category.name}}<span *ngIf="category.subcategories"> <i class="fa fa-angle-down arrow-size"></i> </span></a></li>

                <div *ngIf="category.subcategories" class="wrapper">
                    <!-- FIRST LEVEL SUBCATEGORIES -->
                    <div *ngFor = "let subcategory of category.subcategories; index as j">
                        <div class="wrapper">
                            <li (click)="checkedevt(category.name+' > '+subcategory.name, subcategory.name)"><a [class.active]="active === subcategory.name" class="sub-list">{{subcategory.name | titlecase | productDetailsPipe: subcategory.name}}</a></li>
                        </div>
                        <div *ngIf="subcategory.subcategories" class="wrapper">
                            <!-- SECOND LEVEL SUBCATEGORIES -->
                            <div class="wrapper">
                            <div *ngFor = "let subcategory2 of subcategory.subcategories; index as k">                                
                                <li (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name, subcategory2.name)"><a [class.active]="active === subcategory2.name" class="sub-list">{{subcategory2.name | titlecase | productDetailsPipe: subcategory2.name}}</a></li>
                                <!-- THIRD LEVEL SUBCATEGORY -->
                                <div *ngIf="subcategory2.subcategories;" class="wrapper">
                                    <div class="wrapper">
                                        <div *ngFor = "let subcategory3 of subcategory2.subcategories; index as l">
                                            <div class="wrapper">
                                                <li (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name, subcategory3.name)"><a [class.active]="active === subcategory3.name" class="sub-list">{{subcategory3.name | titlecase | productDetailsPipe: subcategory3.name}}</a></li>
                                                <!-- FOURTH LEVEL SUBCATEGORY -->
                                            <div class="wrapper">
                                                <div *ngFor = "let subcategory4 of subcategory3.subcategories; index as m">
                                                    <div class="wrapper">
                                                        <li><a [class.active]="active === subcategory4.name" class="sub-list" (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name, subcategory4.name)">{{subcategory4.name | titlecase | productDetailsPipe: subcategory4.name}}</a></li>
                                                        <!-- FIFTH LEVEL SUBCATEGORIES -->
                                                    <div class="wrapper">
                                                        <div *ngFor = "let subcategory5 of subcategory4.subcategories;index as n">
                                                            <li><a  [class.active]="active === subcategory5.name" class="sub-list" (click)="checkedevt(category.name+' > '+subcategory.name+' > '+subcategory2.name+' > '+subcategory3.name+' > '+subcategory4.name+' > '+ subcategory5.name, subcategory5.name)">{{subcategory5.name | titlecase | productDetailsPipe: subcategory5.name}}</a></li>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>                        
                                            
                                        </div>
                                    </div>
                                </div>                                                        
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </ng-container>
    </div>
</div>

<div *ngIf="filters">
    <div *ngIf="filters.length != 0" class="layerd-navigation" >
        <div  class="filter-collections-group">
        <h5 class="sub-heading">Brands</h5>
        <ng-container *ngFor="let facet of filters">
       
        <div class="collection-collapse-block" [class.open]="collapse" *ngIf="facet">
            
            <div class="pannel-title">
             
             
        
                    <label  class="checkbox-container" style="text-transform: uppercase; font-size:12px !important;">
                        
                        <input 
                        type="checkbox"
                        [id]='facet.name'                            
                        (click)="appliedFilter($event,facet)"
                          />

                          &nbsp;{{ facet.name }}  <span class="count"></span>
                        <span class="checkmark"></span>
                      </label>
              
             
            </div>
          
          </div>
        </ng-container>
    </div>
        
    </div>
</div>


<!-- <div class="layerd-navigation" >
    <div  class="filter-collections-group">
    <h5 class="sub-heading">Availability</h5>
    <div class="collection-collapse-block" [class.open]="collapse">
        <div class="pannel-title">
            <label  class="checkbox-container" style="text-transform: uppercase; font-size:12px !important;">
                            
                <input 
                type="checkbox" [(ngModel)]="values1"
                id='outOfStock'    
                (change)="onChange($event,'Include Out of Stock')"         
                [value]=""             
                 />
                 &nbsp;Include Out of Stock
                <span class="checkmark"></span>
              </label>
        </div>
        </div>
</div>
    
</div>
 -->

<!-- <div class="layerd-navigation" >
    <div  class="filter-collections-group">
    <h5 class="sub-heading">Availability</h5>
    <div class="collection-collapse-block" [class.open]="collapse">
        <div class="pannel-title">
            <label  class="checkbox-container" style="font-size: 14px !important;">
                <input 
                type="checkbox"  [(ngModel)]="values2"
                id='day90'
                (change)="onChange($event,'Last 90 days')"   
                (click)="changeCheckecd($event,'Last 90 days')"            
                [value]=""  
                checked        
                 />
                 &nbsp;Last 90 days
                <span class="checkmark"></span>
              </label>

              
        </div>
        </div>
</div> -->
    
<!-- </div> -->



<div class="layerd-navigation" >
    <div class="filter-collections-group">
        <div class="collection-collapse-block border-0" >
            <h5 class="collapse-block-title sub-heading">Availability</h5>
            <div class="collection-collapse-block-content">
                <div class="collection-brand-filter">
                    <div>
                        <label  class="checkbox-container" style="text-transform: uppercase; font-size:12px !important;">
                            
                            <input 
                            type="checkbox" [(ngModel)]="values1"
                            id='outOfStock'    
                            (change)="onChange($event,'Include Out of Stock')"         
                            [value]=""             
                             />
                             &nbsp;Include Out of Stock
                            <span class="checkmark"></span>
                          </label>   
                    </div>
                    <div class="collection-collapse-block border-0" >
                    <h5 class="collapse-block-title sub-heading" style="margin-left:-30px">New Arrivals</h5>
                    <div>
                        <label  class="checkbox-container" style="font-size: 14px !important;">
                            <input 
                            type="checkbox"  [(ngModel)]="values2"
                            id='day90'
                            (change)="onChange($event,'Last 90 days')"   
                            (click)="changeCheckecd($event,'Last 90 days')"            
                            [value]=""  
                            checked        
                             />
                             &nbsp;Last 90 days
                            <span class="checkmark"></span>
                          </label>   
                    </div>
                    <div class="mt-2">
                        <label  class="checkbox-container" style="font-size: 14px !important;">
            
                            <input (change)="onChange($event,'Last 30 days')"  
                            (click)="changeCheckecd($event,'Last 30 days')"
                            type="checkbox"  [(ngModel)]="values3"
                            id='day30'               
                            [value]="" 
                                      
                            />
                            &nbsp;Last 30 days
                            <span class="checkmark"></span>
                          </label>   
                    </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    </div>

    <!-- <div class="layerd-navigation" >
        <div class="filter-collections-group">
            <div class="collection-collapse-block border-0" >
                <h5 class="collapse-block-title sub-heading">Price</h5>

                <input type="range" min="0" max="10000" />
            </div>
        </div>
    </div> -->
    

    

      
