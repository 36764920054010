import { Injectable } from '@angular/core';
import {ORG_DETAILS} from '../app.config';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrderInitiationService {

  ORDERIDKEY = 'orderId'

  orderInitialObject = {
    customer_id : "",
    customer_name : "",
    phone_number : "",
    cart_id : "",
    outlet_id : "",
    outlet_name : "",
    outlet_latitude: "",
    outlet_longitude: "",
    status : "",
    system_label : "Pending",
    status_id : "",
    is_digital_order : false,
    is_manual_order : false,
    payment_status : "Pending",
    payment_method : "",
    payment_method_id : "",
    order_currency_code : "",
    base_currency_code : "",
    currency_exchange_rate : 0,
    is_email_opt_in : false,
    order_source : "Web",
    order_reference : ORG_DETAILS.DOMAIN_NAME,
    order_reference_id : "",
    shipping_address_count : 1,
    items_total : 0,
    items_total_quantity : 0,
    geo_info : {},
    store_credit_amount : 0,
    gift_certificate_amount : 0,
    coupon_code : "",
    coupon_discount : 0,
    refunded_amount : 0,
    products : [],
    shipping_addresses : [],
    billing_address: {},
    delivery_instructions : [],
    delivery_information : {},
    shipping_carrier: {},
    shipping_method : {},
    deliveries : [],
    customer_comment : "",
    notes : "",
    base_cost : {},
    derived_cost : {},
    order_taxes : [],
    order_summary: {},
    status_flow : {}
  }

  old_value = {
    status: "",
    system_label: ""
  }
  new_value = {
      status: "",
      system_label: "Pending"
  }

  orderId:any;

  constructor(
    private localService : LocalStorageService,
  ) { }

  setOrderId(orderId){
    this.orderId = orderId;
    this.localService.set(this.ORDERIDKEY, orderId)
  }

  getOrderId(){
    if(this.localService.get(this.ORDERIDKEY)){
      return this.localService.get(this.ORDERIDKEY);
    }
    else {
      return "";
    }
  }
}
