import { Injectable } from '@angular/core';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  customerData = {
    userId:"",
    Mobile:"",
    userName:""
  }

  CUSTOMERKEY = "CustomerTab"

  constructor(
    private localService : LocalStorageService
  ) { }

  // GET CUSTOMER DATA FROM LOCAL STORAGE
  getCustomerData():any{
    if(this.localService.get(this.CUSTOMERKEY)){
      let customer_data = JSON.parse(this.localService.get(this.CUSTOMERKEY));
      this.customerData.userId = customer_data.userId;
      this.customerData.Mobile = customer_data.Mobile;
      if(this.localService.get('UserName')){
        if(this.localService.get('UserName') === 'New User'){
          this.customerData.userName = "";
        }
        else{
          this.customerData.userName = this.localService.get('UserName')
        }        
      }
      else{
        this.customerData.userName = "";
      }
      return this.customerData;
    }
    else{
      return "";
    }
  }

  // SET CUSTOMER DATA TO LOCAL STORAGE
  setCustomerData(Mobile,userId){
    if(Mobile && userId){
      this.customerData.Mobile = Mobile;
      this.customerData.userId = userId;
      this.localService.set(this.CUSTOMERKEY, JSON.stringify(this.customerData))
    }
  }

  // REMOVE CUSTOMER DATA FROM LOCAL STORAGE
  removeCustomerData(){
    if(this.localService.get(this.CUSTOMERKEY)){
      this.localService.remove(this.CUSTOMERKEY);
    }
  }
  
}
