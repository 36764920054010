<br>
<app-alerts *ngIf="showalertmsg"></app-alerts>
<section class="container order-details-page">
    <p class="logo-header text-primary">Order Details</p>
    <div class="row">
        <h5 class="col-md-10">
            Ordered on {{orderedOn}} <span class="verticalBar">&#124;</span>
            Order# {{orderId}} <span class="verticalBar">&#124;</span>
            {{orderStatus}}
        </h5>
        <button class="btn btn-md btn-primary col-md-1" routerLink='/my-orders'>Back</button>
    </div>

</section>
<section class="container p-3 my-3 border">
    <div class="row ">
        <div class="row col-md-9 ">
            <div class="col-sm-1">
            </div>
            <div class="col-sm-5">
                <br>
                <br>
                <h5>Delivery Details</h5>
                <div *ngIf="summaryShippingInfo==undefined">
                    Details not found.
                </div>
                <div class="row" *ngIf="summaryShippingInfo!=undefined">
                    <div class="col">
                        {{summaryShippingInfo.first_name}} {{summaryShippingInfo.last_name}},
                    </div>
                </div>
                <div class="row" *ngIf="summaryShippingInfo!=undefined">
                    <div class="col">
                        {{summaryShippingInfo.entire_location}}
                        <!-- {{summaryShippingInfo.phone}},
                        {{summaryShippingInfo.address1}},
                        <br> -->
                        <!-- {{summaryShippingInfo.city}},
                        {{summaryShippingInfo.state}},
                        {{summaryShippingInfo.country}},
                        {{summaryShippingInfo.zipcode}} -->
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <br>
                <br>
                <h5>Payment Information</h5>
                <div class="row" *ngIf="(payment_information| json) != ({} | json)">
                    <div class="col">
                        {{payment_information}}
                    </div>
                </div>
                <div *ngIf="!payment_information || (payment_information| json) == ({} | json)">
                    Details not found.
                </div>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="order-traccking-st col-sm-11">
                <div class="row">
                    <div class="col-12 col-md-12 hh-grayBox">
                        <div class="row d-flex justify-content-start">
                            <div class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p>Order Confirmed<br><span>{{orderDateTime}}</span></p>
                            </div>
                            <div *ngIf="orderCancelled" class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p>{{ordercancelledLabel}}<br><span>{{cancelledTime}}</span></p>
                            </div>
                            <div *ngFor = "let track of trackDetails" class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p>{{track.custom_status}}<br><span>{{track.created_date}}</span></p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <section class="container p-3 my-3 border" style="width: 80%; margin-left: 4%;">
                
                <div class="inq-menu-right-st">
                    <div class="row mt-4 inq-pro-sec-st" *ngFor="let product of productListArray;let i = index">
                        <div class="col-md-8">
                            <div class="col-md-10 inq-fo-des-in-st">
                                <div class="inq-dis-sec-st">
                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt">
                                            <img src="assets/images/vendor/veg.svg" alt="veg-items">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-10 inq-pro-col-le-st">
                                            <h5>{{product.name}} ({{productList[productList.length-i-1].quantity}})</h5>
                                            <span><strong><i class="fa fa-rupee"></i>{{product.pricing.raw.price}}</strong></span>
                                            <!-- <span class="ml-5"><img class="inq-pro-rating-st" src="assets/images/vendor/rating.svg" alt="rating"> <strong class="inq-primary mr-1"> 5 Points</strong></span> -->
                                        </div>
                                    </div>
                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <!-- <div class="col-md-10 inq-pro-col-le-st">
                                            <p [innerHTML] = "product.description.description"></p>

                                        </div> -->
                                    </div>

                                    <div class="inq-ft-st mb-1 row">
                                        <div class="col-md-1" id="veg-img-prt">
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-10 inq-pro-col-le-st">

                                            <button class="btn btn-sm btn-primary col-md-5" routerLink='/add-review/{{id}}/{{product.id}}/{{i}}'><i class="fa fa-pencil"></i> Write a review</button>
                                            <!-- <button class="btn btn-sm btn-primary col-md-5" routerLink='/add-review/{{id}}/{{i}}'><i class="fa fa-pencil"></i> Write a review</button> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2">
                            <div class="inq-fo-im-st ">
                                <img *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source"
                                    [src]="product.assets[0].image_source" onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';" alt="..." class="img-fluid product-image">
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </section>
            
        </div>

        <br>
        <div class="row col-md-3 " style="border-left: 1px solid #cdcdcd">
            <div class="col-sm-12 ">
                <br>
                <br>
                <h5 class="text-left inq-cart-ord-ti-st">Order Summary</h5>
                <div class="row  inq-order-inner-p-st">
                    <table style="width: 100%;" class="table table-striped">
                        <ng-container>
                            <tr>
                                <td>SubTotal</td>
                                <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.sub_total}}</td>
                            </tr>
                            <tr *ngIf="orderdetails.shipping_cost > 0">
                                <td>Delivery Charge</td>
                                <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.shipping_cost}}</td>
                            </tr>
                            <tr>
                                <td>{{orderdetails.handling_display}}</td>
                                <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.packing_cost}}</td>
                            </tr>
                            <tr *ngIf="orderdetails.discount_display !== ''">
                                <td>{{orderdetails.discount_display}}</td>
                                <td class="text-right">- <i class="fa fa-rupee"></i>{{orderdetails.discount_amount}}
                                </td>
                            </tr>
                            <tr>
                                <td>Tax</td>
                                <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.tax}}</td>
                            </tr>
                            <tr>
                                <td><strong>Total</strong></td>
                                <td class="text-right"><strong><i class="fa fa-rupee"></i>
                                        {{orderdetails.order_total_amount}}</strong>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <button class="btn btn-md btn-primary col-md-2" style="margin-left: 4%;" routerLink='/add-review/{{id}}'>Write Review</button> -->
</section>
<br>


