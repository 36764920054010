import { Injectable } from '@angular/core';
import { AES, enc, mode } from 'crypto-js';
import * as crypto from 'crypto-js';

import { OrderInitiationValuesService } from './order-initiation-values.service';
import { OrderInitiationService } from './order-initiation.service';
import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutLocalStorageService } from '../checkout-local-storage.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import {AppService} from '../../app.service';

@Injectable({
  providedIn: 'root'
})
export class OrderInitiationApiService {

  constructor(
    private orderInitiationValuesService: OrderInitiationValuesService,
    private checkoutApiService: CheckoutApiService,
    private orderInitiationService: OrderInitiationService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private appService: AppService
  ) {
   }

  getorderInitationObject(){
    return new Promise((resolve, reject) => {
      this.orderInitiationValuesService.getCustomerDetails().then(
        respData => {
          let res:any = respData;
          if(res){
            this.orderInitiationValuesService.getOrderStatus().then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  this.orderInitiationValuesService.getCurrencyLabels().then(
                    respData2 => {
                      let res2:any = respData2;
                      if(res2){
                        this.orderInitiationValuesService.getProductDetails().then(
                          respData3 => {
                            let res3:any = respData3;
                            this.orderInitiationValuesService.getCartId().then(
                              respData4 => {
                                let res4:any = respData4;
                                this.orderInitiation().then(
                                  respData => {
                                    let res:any = respData;
                                    if(res){
                                      resolve(true);
                                    }
                                  }
                                )
                              })
                          })
                      }
                    })
                }
              })
          }
        })
    })
  }

  orderInitiation(){
    return new Promise((resolve, reject) => {
      // ENCRYPTION
            // var now = new Date();
            // var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
            // console.log("Expiry TimeStamp", utc_now.getTime())
            // let _key = enc.Utf8.parse(this.appService.apkkey);
            let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
            let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
            let encrypted =AES.encrypt (
            JSON.stringify({
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "timestamp": new Date().getTime(),
              "payload": {
                "order": this.orderInitiationService.orderObj,
                "new_value":this.orderInitiationService.new_value,
                "old_value":this.orderInitiationService.old_value,

              },
              "extras": {
                "find": {
                  "id": ""
                }
              }
            }), _key, {
                iv: _iv,
                format: crypto.format.Hex,
                mode: crypto.mode.CTR,
                padding: crypto.pad.NoPadding
            }).toString();
            let requestData = {
              payload: encrypted
            }
            this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, requestData).then(
              respData => {
                let res:any = respData;
                if(res.success){
                  let OrderId = res.data[0].id;
                  this.checkoutLocalStorageService.setOrderId({orderId:OrderId})
                  resolve(true);
                }
              }
            )

    })

  }
}
