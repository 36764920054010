import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../auth-service/local-storage.service';
import { CheckoutShippingEventsService } from '../checkout-shipping/checkout-shipping-events.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerLoginStorageService {

  CUSTOMERDATALABEL = "CustomerData";
  CUSTOMEROBJ = "CustomerObject";

  constructor(
    private localService: LocalStorageService,
    private checkoutShippingEventsService: CheckoutShippingEventsService
  ) { }

  // SET THE DATA
  setCustomerData(customerData){
    if(customerData){
      this.localService.set(this.CUSTOMERDATALABEL,JSON.stringify(customerData));
    }
  }

  // GET THE DATA
  getCustomerData(){
    if(this.localService.get(this.CUSTOMERDATALABEL)){
      return JSON.parse(this.localService.get(this.CUSTOMERDATALABEL))
    }
    else{
      return null;
    }
  }

  // REMOVE DATA
  removeCustomerData(){
    if(this.localService.get(this.CUSTOMERDATALABEL)){
      this.localService.remove(this.CUSTOMERDATALABEL)
    }
  }

  // SET CUSTOMER
  setCustomerObject(customer){
    if(customer){
      this.localService.set(this.CUSTOMEROBJ,JSON.stringify(customer));
      this.checkoutShippingEventsService.updateAdminFormEvent("getAddress");
    }
  }

  // GET CUSTOMER
  getCustomer(){
    if(this.localService.get(this.CUSTOMEROBJ)){
      return JSON.parse(this.localService.get(this.CUSTOMEROBJ))
    }
    else{
      return null;
    }
  }

  // REMOVE DATA
  removeCustomer(){
    if(this.localService.get(this.CUSTOMEROBJ)){
      this.localService.remove(this.CUSTOMEROBJ)
    }
  }
}
