import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutShippingStorageService {

  SHIPPINGADDRESS_LABEL = "Shipping Address";
  SAMEASSHIPPING_LABEL = "SameAShipping";
  BILLINGADDRESS_LABEL = "Billing Address";

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  // SET SHIPPING ADDRESS
  setShippingAddress(shippingAddress){
    this.localStorageService.set(this.SHIPPINGADDRESS_LABEL, JSON.stringify(shippingAddress));
  }

  // SET SAME AS BILLING ADDRESS
  setSameasBillingAddress(sameasBilling){
    this.localStorageService.set(this.SAMEASSHIPPING_LABEL, JSON.stringify(sameasBilling));
  }

  // SET BILLING ADDRESS
  setBillingAddress(billingAddress){
    this.localStorageService.set(this.BILLINGADDRESS_LABEL, JSON.stringify(billingAddress));
  }

  // GET SHIPPING ADDRESS
  getShippingAddress(){
    if(this.localStorageService.get(this.SHIPPINGADDRESS_LABEL)){
      return JSON.parse(this.localStorageService.get(this.SHIPPINGADDRESS_LABEL))
    }
    else{
      return null;
    }
  }

  // GET SAME AS BILLING ADDRESS
  getSameasBillingAddress(){
    if(this.localStorageService.get(this.SAMEASSHIPPING_LABEL)){
      return JSON.parse(this.localStorageService.get(this.SAMEASSHIPPING_LABEL))
    }
    else{
      return null;
    }
  }

  // GET BILLING ADDRESS
  getBillingAddress(){
    if(this.localStorageService.get(this.BILLINGADDRESS_LABEL)){
      return JSON.parse(this.localStorageService.get(this.BILLINGADDRESS_LABEL))
    }
    else{
      return null;
    }
  }

  // REMOVE SHIPPING ADDRESS
  removeShippingAddress(){
    if(this.localStorageService.get(this.SHIPPINGADDRESS_LABEL)){
      this.localStorageService.remove(this.SHIPPINGADDRESS_LABEL);
    }
  }

  // REMOVE SAME AS BILLING ADDRESS
  removeSameasBillingAddress(){
    if(this.localStorageService.get(this.SAMEASSHIPPING_LABEL)){
      this.localStorageService.remove(this.SAMEASSHIPPING_LABEL);
    }
  }

  // REMOVE BILLING ADDRESS
  removeBillingAddress(){
    if(this.localStorageService.get(this.BILLINGADDRESS_LABEL)){
      this.localStorageService.remove(this.BILLINGADDRESS_LABEL);
    }
  }
}
