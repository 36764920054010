<div class="homepage-banner-slider rounder-corner-nav rotate-dot">
    <owl-carousel-o [options]="HomePageBannerSlider">
        <ng-template carouselSlide>
            <div class="banner-content">
                <img  class="img-fluid earthy-worthy-carousel" src="assets/images/earthyworthy/earthyworthy-banner.png" />
                
            </div>
            
        </ng-template>  

        <ng-template carouselSlide>
            <div class="banner-content">
                <img  class="img-fluid earthy-worthy-carousel" src="assets/images/earthyworthy/earthyworthy-banner.png" />
            </div>
        </ng-template> 

        <ng-template carouselSlide>
            <div class="banner-content">
                <img  class="img-fluid earthy-worthy-carousel"src="assets/images/earthyworthy/earthyworthy-banner.png" />
            </div>
        </ng-template>
 </owl-carousel-o>
</div>