<section>
<!-- cancel -->
<app-alerts></app-alerts>
<!-- <h2 class="aboutus-title text-center">CONTACT US</h2> -->
<div class="row">
    <div class="col-md-3 offset-md-9">
        <div style="float: right; margin-right: 15px; margin-bottom: 5px !important;margin-top: 15px !important;">
            <p style="font-size: 12px !important;"><i class="fa fa-envelope pr-1"></i>{{contactEmail}}</p>
        </div>
        <br>
        
    </div>
</div>
<div class="row">
    <div class="col-md-3 offset-md-9">
        <div style="float: right; margin-right: 15px; margin-bottom: 10px !important;">
            <p style="font-size: 12px !important;"><i class="fa fa-phone pr-1"></i>{{contactPhone}}</p>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content custom-model">
        <div>
        <button id="modal_close_button" type="button" class="btn btn-md btn-secondary" data-dismiss="modal" aria-label="Close" (click)="cancelData()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
        <div class="modal-header custom-model-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">Write Query</h5>
        </div>
        <div class="modal-body custom-model-body">
            <form  [formGroup]="contactUsForm" novalidate>
                <textarea formControlName="queries" class="form-control" [class.is-invalid]="validateForm && f.queries.errors">
                </textarea>
                <div class="invalid-feedback" *ngIf="f.queries.errors">
                    <div *ngIf="f.queries.errors.required">Please enter the queries.</div>
                    <div *ngIf="f.queries.errors.minlength">queries should be greater than 3 characters.</div>
                </div>
            </form>
        </div>
  
        <div class="modal-footer custom-model-footer">
           <button style="display: none;" id="close_btn" type="button" class="btn btn-md btn-primary" data-dismiss="modal"></button> 
          <button type="button" class="btn btn-md btn-primary"  data-dismiss="modal" (click)="cancelData()">Cancel</button>
          <button type="button" id="cancel_btn" class="btn btn-md btn-primary" (click)="sendData()">Submit Query</button>
        </div>
  
      </div>
    </div>
  </div>
  
    <form class="" style="margin-top: 20px;margin-left:30px;margin-bottom: 30px !important;">
        <div class="row">
            <div class="col-md-3">
                <h4>HELP CENTER</h4>
                <small class="form-text text-muted text-muted-color">We are here to help you</small>
            </div>

            <div class="col-md-8" >
                <div class="row" style="justify-content: space-between;background-color: white;border:4px solid rgb(233, 233, 233); padding: 15px 0px;">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-2">
                                <img  class="logo-image custom-pointer earth-worthy-logo" style="height: 40px;width:50px;" src="assets/images/order.png" />
                            </div>
                            <div class="col-md-10">
                                <h6>TRACK, CANCEL, RETURN/EXCHANGE</h6>
                                <small class="form-text text-muted text-muted-color">Manage your purchases</small>
                            </div>

                        </div>
                        
                    
                    </div>
                    <div class="col-md-2" ><button class="btn btn-md btn-primary" style="margin-top: 5px;padding: 5px 20px !important;" (click)="showOrder()">ORDERS</button></div>

                </div>
            </div>
        </div>

        <hr>
        
        
        <div class="row" style="margin-top: 25px !important;" >
            <div class="col-md-3" style="background-color: white;border-right:0.2px solid lightgray;">
                <h6>SELECT QUERY TYPE</h6>
                <a class="contact-active" style="cursor: pointer; padding-top: 10px !important;" [class.active]="show" (click)="showOrderQuery()">Order Related Queries</a>
                <br>
                <a class="contact-active" style="cursor: pointer;" [class.active]="otherQuery" data-toggle="modal" data-target="#exampleModalCenter" (click)="showOtherQuery()">Other Issues</a>
            </div>
            <div class="col-md-8">
                
                <div *ngIf="loggedOut">
                    <main role="main">
                        <div class="container">
                            <div style="text-align: center;background-color: white;border:4px solid rgb(233, 233, 233); padding: 15px 10px;">
                                <small style="margin-top: 5px ;" class="form-text text-muted text-muted-color">Please Log in, if you have queries related to your recent purchases</small>
                                <button style="margin-top: 5px ;margin-bottom: 10px;padding: 5px 20px !important;" routerLink="/login-new" class="btn btn-md btn-primary">LOG IN</button>
                            </div>
                        </div>
                    </main>
                </div>
                <div *ngIf="show">
                    <main role="main">
                        <div class="container">
                            <br>
                            <h4 class="text-primary">My Orders</h4>
                            <div class="my-orders">
                                <div class="box-orders" style="text-align: center;" *ngIf='!data.length'>
                                    <h5>Details not found.</h5>
                                </div>
                                <div style="overflow-x:auto;">
                                    <table class="table box-orders"  *ngIf='data.length'>
                                        <thead>
                                            <tr>
                                                <th>Order ID</th>
                                                <th>Order Date</th>
                                                <th>Total Price</th>
                                                <th>Order Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let list of data;let i=index">
                                                <td id={{list.id}}>{{list.id}}</td>
                                                <td>{{list.created_on}}</td>
                                                <td>₹{{list.order_review.order_summary.order_total_amount}}</td>
                                                <td>{{list.status}}</td>
                                                <td>
                                                    <button class="btn btn-md btn-primary mb-1"
                                                       (click)="setOrderId(list.id)" data-toggle="modal" data-target="#exampleModalCenter" >Write query</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div  class="col-md-8" style="float: right;overflow: hidden;">
                                        <app-pagination [totalRecords]="total_count" [recordsPerPage]="5"
                                            (onPageChange)="displayActivePage($event)"></app-pagination>
                                    </div>

                                </div>
                    
                                <!-- <div class="row" *ngIf='total_count > 5'>
                                    <div class="col-md-12 ml-auto mr-auto">
                                        
                                    </div>
                                </div> -->
<!-- 
                                <textarea *ngIf="writeQuery" class="form-control"></textarea>
                                <button  *ngIf="writeQuery" style="float: right;margin-top: 5px;" class="btn btn-md btn-primary">Submit</button> -->
                            </div>
                        </div>
                    
                    </main>
                </div>
                <!-- <div *ngIf="otherQuery">
                    <main role="main">
                        <div class="container">
                            <textarea class="form-control"></textarea>
                            <button class="btn btn-md btn-primary" style="float: right;margin-top: 5px;">Submit</button>
                        </div>
                    </main>
                </div> -->
            </div>
        </div>
        
</form>
</section>