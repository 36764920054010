import { Component, OnInit,AfterViewInit } from '@angular/core';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import {CartService} from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import * as metadata from '../../assets/data/default/product-listing.json'
import { MetaDataMapService } from '../meta-data-map.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-produt-qr-listing',
  templateUrl: './produt-qr-listing.component.html',
})
export class ProdutQRListingComponent implements OnInit {

  fields = metadata.groups[0].fields;
  public productsApiData:any;
  public products=[];
  productList=[];
  innnerhtmlData: any;
  respData:any;
  public products1=[];
  displayVideo:any
  public cartItems=[];
  public cartItems1=[];
  public totalNumberOfProducts;
  public totalLength;
  totalProducts:any = 0;
  selectedIndex: number = null;
  public active;
  showStorage:boolean=false;
  public cartData=[];
  public cartQuantity=[];
  public searchCategory;
  modalVideo:any
  showAlerts = false;
  modal : boolean =false;
  displayImage:any
  logoImage:any;
  instructionDesc:any;
  copyRightText:any = "";
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  showVideo : boolean = false;
  showImage : boolean = true;
  showPrepration:boolean = false;

  constructor(
    public appService:AppService,
    private router: Router,
    private route: ActivatedRoute,
    private cartService : CartService,
    private localService: LocalStorageService,
    private alertService : AlertsService,
    private sanitizer: DomSanitizer,
    public metaDataMapService: MetaDataMapService
  ) { }

  ngOnInit(){
    this.getAllProducts();
    this.getLogo();
   

   

  }
  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }

   //GET ALL PRODUCTS
   getAllProducts(){

      var req_product_data = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": true,
          "paginationDetails": {
            "limit":(event),
            "pagesize": 20
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "id",
            "sortorder": -1
          }
        }
      }
  
   this.appService.postApiCall(req_product_data, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
     resp => {
       
       this.productsApiData = resp;
       this.products = this.productsApiData.result.data;
      //  this.totalNumberOfProducts = this.productsApiData.result.total;
      this.productList=[];
       
       this.products.forEach(element => {
     
        if(element.product_type != 'Variation'){
         this.productList.push(element)
         this.totalNumberOfProducts=this.productList.length;
             }
            
            })
       this.products.forEach(element=>{
       if(element.hasOwnProperty('additional_info')&& element.additional_info.how_to_store != "" && element.additional_info.how_to_store !== undefined ){
        element["showStorage"]=true;
       }
       else{
        element["showStorage"]=false;
       }

       })

       this.products.forEach(element=>{
        if(element.hasOwnProperty('description')&& element.description.instruction_description != "" && element.description.instruction_description !== undefined ){
         element["showPrepration"]=true;
        }
        else{
         element["showPrepration"]=false;
        }
 
        })
        this.products.forEach(element => {
          if(element.hasOwnProperty('videos')&& element.videos[0].link != "" && element.videos[0].link !== undefined){
           element["showVideo"]=true;
           element['videos'][0]['safeLink'] = this.sanitizer.bypassSecurityTrustResourceUrl(element.videos[0].link)
          }
          else{
           element["showVideo"]=false;
   
          }
          });
 

       this.totalLength = this.productsApiData.result.total ? this.productsApiData.result.total : 0;
     },
     err => {
       if (err.error.error.message) {
         console.log(err.error.error.message)
       }
       else{
         console.log('Something bad happened; Please try again!')
       }
     })
 }

 videoPreview(i){
    this.modalVideo=this.sanitizer.bypassSecurityTrustResourceUrl(i[0].link)
 }
setIndex(index: number) {
  this.selectedIndex = index;
}
productDetails(id){
  this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
}

}
