<!DOCTYPE html>
<html>
  <head><title>Terms and condition</title></head>
  <body>
    <div class="paddingTB60">
      <div class="container-fluid text-center">
          
          <h3>Terms and Condition</h3>
        <hr>
        </div>
    
    <div style="margin-left: 5px;" id= "terms-content"></div>
    </div>
  </body>
</html>


<!--  -->