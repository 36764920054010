<section>
        <div class="jumbotron jumbotron-fluid">
            <div class="container">
                <div class="text-center">
                    <h1>Checkout</h1>
                    <div class=""><span class="customer-breadcrumb" routerLink="">Home</span>
                        <span class="customer-breadcrumb" routerLink="/cart"> <i class="fa fa-long-arrow-right px-2"></i> Cart</span>
                        <span class="inq-primary"> <i class="fa fa-long-arrow-right px-2"></i> <span
                                class="inq-fw-st">Checkout</span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <div class="col-md-12 inq-order-sec-st">
                        <ul class="nav nav-tabs inq-cart-ul-st mt-2" id="myTab" role="tablist">
        
                            <li class="nav-item {{Initial}}">
                                <a class="nav-link" id="customer-tab" data-toggle="tab" href="#customer" role="tab"
                                    aria-controls="customer" aria-selected="true" (click)="toggleShow(0 ,'101')">Customer</a>
                            </li>
                            <li class="nav-item {{nextOne}}">
                                <a class="nav-link" id="delivery-tab" data-toggle="tab" href="#delivery" role="tab"
                                    aria-controls="delivery" aria-selected="false" (click)="toggleShow(0 , '102')" [class.disable-btn]="!enable_deliveytab">Delivery Information</a>
                            </li>
                            <li class="nav-item {{nextTwo}}">
                                <a class="nav-link" id="shipping-tab" data-toggle="tab" href="#shipping" role="tab"
                                    aria-controls="shipping" aria-selected="false" (click)="toggleShow(0 ,'103')" [class.disable-btn]="!enable_shippingtab">Shipping Address</a>
                            </li>
                            <!-- <li class="nav-item {{nextTwo}}">
                                <a class="nav-link" id="billing-tab" data-toggle="tab" href="#billing" role="tab"
                                    aria-controls="billing" aria-selected="false" (click)="toggleShow(0 ,'103')">Billing Address</a>
                            </li>
                            <li class="nav-item {{nextThree}}">
                                <a class="nav-link" id="shipMethods-tab" data-toggle="tab" href="#shipMethods" role="tab"
                                    aria-controls="shipMethods" aria-selected="false" (click)="toggleShow('Shipping' , '104')">Shipping
                                    Methods</a>
                            </li> -->
                            <li class="nav-item {{nextThree}}">
                                <a class="nav-link" id="orderDetails-tab" data-toggle="tab" href="#orderDetails" role="tab"
                                    aria-controls="orderDetails" aria-selected="false" (click)="toggleShow('Order' , '104')" [class.disable-btn]="!enable_ordertab">Order Details</a>
                            </li>
                            <li class="nav-item {{nextFour}}">
                                <a class="nav-link" id="payment-tab" data-toggle="tab" href="#paymentDetails" role="tab"
                                    aria-controls="paymentDetails" aria-selected="false" (click)="toggleShow('Order' , '105')" [class.disable-btn]="!enable_paymenttab">Payment</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
        
                            <!-- customer -->
                            <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                                <div class="col-md-12 mt-3">
                                    <div *ngIf="customerForm === 'guestForm'">
                                        <div class="row mt-4 inq-order-row-st"> 
                                                <div class="col-md-6">
                                                    <form [formGroup]= "guestForm">
                                                    <app-alerts *ngIf="showalertmsg1"></app-alerts>
                                                    <div class="form-group">
                                                        <input type="number" class="form-control" formControlName="guest_mobile" placeholder="Mobile Number" [class.is-invalid] = "validateGuestForm && f.guest_mobile.errors" [readonly]="freezeGuest"> 
                                                        <div class="invalid-feedback" *ngIf="f.guest_mobile.errors">
                                                            <div *ngIf="f.guest_mobile.errors.required">Please enter the Mobile Number.</div>
                                                            <!-- <div *ngIf="f.guest_mobile.errors.maxlength">Please enter a valid Mobile Number</div>
                                                            <div *ngIf="f.guest_mobile.errors.minlength">Please enter a valid Mobile Number</div> -->
                                                            <div *ngIf="f.guest_mobile.errors.pattern">Please enter a 10 digit Mobile Number</div>
                                                        </div>                                            
                                                    </div>                                       
                                                </form>
                                                <div class="inq-mch-con-btn-st d-flex justify-content-end">
                                                    <button *ngIf="!freezeGuest" class="btn btn-primary inq-btn-st" (click)="saveGuest()" [disabled]="continueSpinner"><span *ngIf="continueSpinner" class="spinner-border spinner-border-sm"></span> CONTINUE</button>
                                                    <button *ngIf="freezeGuest" class="btn btn-primary inq-btn-st" (click)="editGuest()">EDIT</button>
                                                </div>
                                                </div>                                
                                        </div>
                                        <div class="mt-2" [class]="showOtpLogin ? 'reveal-if-active' : 'hide-if-inactive'">
                                            <div class="btn-group col-md-6 custome-nospace" role="group">
                                                <button type="button" class="btn btn-secondary custom-brn-grp" [class.btn-primary]="!otpLogin" [class.btn-secondary]="otpLogin" (click)="changeLoginMethod('password')">Password Login</button>
                                                <button type="button" class="btn custom-brn-grp" [class.btn-primary]="otpLogin" [class.btn-secondary]="!otpLogin" (click)="changeLoginMethod('otp')">OTP Login</button>
                                            </div>
                                            <div [class]="otpLogin ? 'reveal-if-active col-md-6 custome-nospace' : 'hide-if-inactive col-md-6  custome-nospace'">
                                                <form [formGroup]= "otpLoginForm" class="otp-form">  
                                                    <!-- <app-alerts *ngIf="showalertmsg1"></app-alerts>                                  -->
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" formControlName="otp" placeholder="Enter OTP" [class.is-invalid] = "vaidateotpLoginForm && f4.otp.errors"> 
                                                        <div class="invalid-feedback" *ngIf="f4.otp.errors">
                                                            <div *ngIf="f4.otp.errors.required">Please enter the OTP.</div>
                                                            <div *ngIf="f4.otp.errors.incorrect">Please enter the valid OTP</div>
                                                        </div> 
                                                        <small class="help-text">Please enter the OTP that has been sent to your Mobile</small>                                           
                                                    </div>                                       
                                                </form>
                                                <div>
                                                    <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="OTPLogin()">Login</button>
                                                </div>
                                            </div>
                                            <div [class]="!otpLogin ? 'reveal-if-active col-md-6 custome-nospace' : 'hide-if-inactive col-md-6  custome-nospace'">
                                                <div *ngIf="showpasswordSetupForm">
                                                    <form [formGroup]= "passwordLoginForm" class="otp-form">
                                                        <app-alerts *ngIf="showalertmsg1"></app-alerts>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" formControlName="otpp" placeholder="Enter OTP" [class.is-invalid] = "validatepasswordLoginForm && f5.otpp.errors"> 
                                                            <div class="invalid-feedback" *ngIf="f5.otpp.errors">
                                                                <div *ngIf="f5.otpp.errors.required">Please enter the OTP.</div>
                                                                <div *ngIf="f5.otpp.errors.incorrect">Please enter the valid OTP</div>
                                                            </div>
                                                            <small class="help-text">Please enter the OTP that has been sent to your Mobile</small>                                            
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <input [type]="showPswd ? 'text' : 'password'" class="form-control"
                                                                formControlName="passwordp" placeholder="New Password" [class.is-invalid] = "validatepasswordLoginForm && f5.passwordp.errors">
                                                                <div class="input-group-append">
                                                                  <span class="input-group-text" (click)="showPassword()"><i [class]="showPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
                                                                </div>
                                                                <div class="invalid-feedback" *ngIf="f5.passwordp.errors">
                                                                    <div *ngIf="f5.passwordp.errors.required">Please add New Password.</div>
                                                                    <div *ngIf="f5.passwordp.errors.minlength">Password atleast should have 6 charachters</div>
                                                                    <div *ngIf="f5.passwordp.errors.maxlength">Password should not have more than 20 charachters</div>
                                                                </div>
                                                              </div>
                                                            <small class="help-text">Password must be at least 6 characters.</small>
                                                                                                        
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="password" class="form-control" formControlName="confirmPswd" placeholder="Confirm Password" [class.is-invalid] = "validatepasswordLoginForm && f5.confirmPswd.errors" (blur)="validpasswd()"> 
                                                            <div class="invalid-feedback" *ngIf="f5.confirmPswd.errors">
                                                                <div *ngIf="f5.confirmPswd.errors.required">Please enter Confirm Password.</div>
                                                                <div *ngIf="f5.confirmPswd.errors.incorrect">Confirm password should match with New Password</div>
                                                            </div>                                            
                                                        </div>                                       
                                                    </form>
                                                    <div>
                                                        <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="passwordLogin()">Login</button>
                                                    </div>
                                                </div>
                                                <div *ngIf="!showpasswordSetupForm">
                                                    <form [formGroup]= "loginForm" class="otp-form">
                                                        <!-- <app-alerts *ngIf="showalertmsg1"></app-alerts> -->
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <input [type]="showPswd ? 'text' : 'password'" class="form-control"
                                                                formControlName="passwordl" placeholder="Password" [class.is-invalid] = "validateLoginForm && f1.passwordl.errors">
                                                                <div class="input-group-append">
                                                                  <span class="input-group-text" (click)="showPassword()"><i [class]="showPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
                                                                </div>
                                                            </div>                                        
                                                        </div>
                                                    </form>
                                                    <div>
                                                        <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="verifyPasswordLogin()">Login</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3 mb-3">
                                                <p class="pl-4">Didn't get the code? <strong><a class="loginLink" (click)=resendCode()>Resend Code</a> </strong></p>
                                            </div>
                                        </div>
                                        </div>
                                        <div *ngIf="customerForm === 'displayForm'">
                                            <div class="row mt-4 inq-order-row-st"> 
                                                <div class="col-md-6">
                                                    <form [formGroup]= "displayForm">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" formControlName="display_email"  readonly>                                         
                                                    </div>                                       
                                                </form>
                                                </div>                                
                                        </div>
                                        <div class="mt-3 mb-3">
                                            <button class="btn btn-primary inq-btn-st" (click)="changeTabClick('delivery-tab')">CONTINUE</button>
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <!-- delivery information tab -->
                            <div class="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
                                <div class="col-md-12">
                                    <div class="row mt-3 inq-order-row-st">
                                        <div class="col-md-6">
                                            <app-alerts *ngIf="deliveryAlert"></app-alerts>
                                            <form [formGroup]= "deliveryInformationForm">                                        
                                                <div class="form-group">
                                                        <div class="row">
                                                            <div class="form-check col-md-4">
                                                                <input class="form-check-input" id="exampleRadios1" type="radio" formControlName="orderType" value="delivery" [checked]="orderTypes === 'delivery'" [attr.disabled]="readOnlyDelivery === true ? '' : null">
                                                                <label class="form-check-label" for="exampleRadios1">
                                                                  Delivery
                                                                </label>
                                                            </div>
                                                            <div class="form-check col-md-4">
                                                                <input class="form-check-input" type="radio" formControlName="orderType" id="exampleRadios2" value="takeaway" [checked]="orderTypes === 'takeaway'" [attr.disabled]="readOnlyDelivery === true ? '' : null">
                                                                <label class="form-check-label" for="exampleRadios2">
                                                                  Takeaway
                                                                </label>
                                                            </div>
                                                        </div>                                                
                                                </div>
                                                <div class="form-group mt-1">
                                                    <div *ngIf="!readOnlyDelivery">
                                                        <select class="custom-select" formControlName="outlet" [class.is-invalid] = "validatedeliveryInformationForm && f8.outlet.errors" (change)="assignSelectdAddress($event)"> 
                                                            <option value="" selected [disabled]="readOnlyDelivery">Select Restaurent Location</option>
                                                            <option *ngFor="let outlet of restaurentAddressList" [value]="outlet.id" [disabled]="readOnlyDelivery">{{outlet.name}}</option>
                                                          </select>
                                                          <div class="invalid-feedback" *ngIf="f8.outlet.errors">
                                                            <div *ngIf="f8.outlet.errors.required">Please select the restaurent location.</div>
                                                            </div>
                                                    </div>
                                                    <div *ngIf="readOnlyDelivery">
                                                        <input class="form-control" [value]="selectedRestarent.name" readonly>
                                                    </div>
                                                </div>
                                                <div class="form-group mt-1">
                                                    <select class="custom-select" formControlName="preferredTime" [class.is-invalid] = "validatedeliveryInformationForm && f8.preferredTime.errors" (change)="getPreferredTimings($event)" >
                                                        <option value="" [disabled]="readOnlyDelivery">Select Preferred Delivery Timings</option> 
                                                        <option value="now" [disabled]="readOnlyDelivery">Now</option>
                                                        <option value="later" [disabled]="readOnlyDelivery">Later</option>
                                                      </select>
                                                      <div class="invalid-feedback" *ngIf="f8.preferredTime.errors">
                                                        <div *ngIf="f8.preferredTime.errors.required">Please select the Preferred Delivery timings </div>
                                                        <div *ngIf="f8.preferredTime.errors.incorrect">Delivery is not available now</div>
                                                    </div>
                                                </div>
                                                <div [class]="preferredTimings === 'later' ? 'reveal-if-active' : 'hide-if-inactive'">
                                                    <div class="form-group">
                                                        <!-- <input type="date" class="form-control" formControlName="deliverDate" placeholder="Preferred Delivery Date" [class.is-invalid] = "validatedeliveryInformationForm && f8.deliverDate.errors" [readonly]="readOnlyDelivery" (change)="dateChanged()" [min]="mindeliveryDate"> -->
                                                        <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  placeholder="Please select the date" (onChange)="dateChanged()" formControlName="deliverDate" #dayPicker [class.is-invalid] = "validatedeliveryInformationForm && f8.deliverDate.errors"  [disabled]="readOnlyDelivery"></dp-date-picker>
                                                        <small class="help-text">Select your preferred Date to deliver the Food.</small>
                                                          <div class="invalid-feedback" *ngIf="f8.deliverDate.errors">
                                                            <div *ngIf="f8.deliverDate.errors.incorrect">Please select the Preferred Delivery Date </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <select class="custom-select" formControlName="deliverTime" [class.is-invalid] = "validatedeliveryInformationForm && f8.deliverTime.errors">
                                                            <option value="" [disabled]="readOnlyDelivery">Select Preferred time slot</option> 
                                                            <!-- <option value="11:30AM" [disabled]="readOnlyDelivery">11:30AM</option>
                                                            <option value="12:30PM" [disabled]="readOnlyDelivery">12:30PM</option> -->
                                                            <option *ngFor="let slot of slotRange" value="{{slot}}" [disabled]="readOnlyDelivery">{{slot}}</option>
                                                          </select>
                                                          <div class="invalid-feedback" *ngIf="f8.deliverTime.errors">
                                                            <div *ngIf="f8.deliverTime.errors.incorrect">Please select the Preferred time slot</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>                                  
                                        </div>
                                        <!-- <div class="col-md-6"> -->
                                            
                                        <!-- </div> -->
                                        <div class="col-md-12">
                                            
                                            <div class="text-left mt-3 inq-mch-con-btn-st">
                                                <button class="btn btn-secondary inq-btn-st mr-3" (click)="changeTbz('customer-tab')">BACK</button>
                                                <button *ngIf="!readOnlyDelivery" class="btn btn-primary inq-btn-st" (click)="saveDeliveryInfo()" >CONTINUE</button>
                                                <button *ngIf="readOnlyDelivery" class="btn btn-primary inq-btn-st" (click)="enableEditDeliveyInfo()" >EDIT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- shipping -->
                            <div class="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="shipping-tab">
                                <div class="col-md-12 ">
                                    <br><br>
                                    <app-shipping-address (notify)='onNotify($event)'></app-shipping-address>
                                </div>
                                <!-- <div class="col-md-12">
                                    <div class="text-left mt-5 inq-mch-con-btn-st">
                                        <button class="btn btn-secondary inq-btn-st mr-3">BACK</button>
                                        
                                        <button class="btn btn-primary inq-btn-st">CONTINUE</button>
                                    </div>
                                </div> -->
                            </div>
                            <!-- billing -->
                            <div class="tab-pane fade" id="billing" role="tabpanel" aria-labelledby="billing-tab">
                                <div class="col-md-12">
                                    <div class="row mt-3 inq-order-row-st">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="First Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Company Name">
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option>Select Country</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="City">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Phone">
                                            </div>
        
        
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Last Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Address">
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option>Select State</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Postal Code">
                                            </div>
        
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mt-1">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="bill_ship">
                                                    <label class="form-check-label" for="bill_ship">Same shipping and billing
                                                        address</label>
                                                </div>
                                            </div>
                                            <div class="text-left mt-5 inq-mch-con-btn-st">
                                                <button class="btn btn-secondary inq-btn-st mr-3">BACK</button>
                                                <button class="btn btn-primary inq-btn-st">CONTINUE</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- shipping methods -->
                            <div class="tab-pane fade" id="shipMethods" role="tabpanel" aria-labelledby="shipMethods-tab">
                                <div class="col-md-12">
                                    <p class="mt-3 font-bold">Select a preferred shipping method to use on this order</p>
                                    <div class="row inq-order-row-st">
                                        <div class="col-md-6">
                                            <h5 class="mt-3 font-bold-two">Flat Rate</h5>
                                            <div class="">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flatrate" id="flatRate"
                                                        value="250" checked>
                                                    <label class="form-check-label font-bold-two" for="flatRate">
                                                        Flat Rate <i class="fa fa-rupee"></i> 250.00
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h5 class="mt-3 font-bold">UPS Shipping</h5>
                                            <div class="">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="upsrate1" id="upsrate1"
                                                        value="499">
                                                    <label class="form-check-label font-bold-two" for="upsrate1">
                                                        24 hours <i class="fa fa-rupee"></i> 499.00
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="upsrate2" id="upsrate2"
                                                        value="200">
                                                    <label class="form-check-label font-bold-two" for="upsrate2">
                                                        48 hours <i class="fa fa-rupee"></i> 200.00
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="upsrate3" id="upsrate3"
                                                        value="99">
                                                    <label class="form-check-label font-bold-two" for="upsrate3">
                                                        72 hours <i class="fa fa-rupee"></i> 99.00
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-left mt-3 inq-mch-con-btn-st">
                                        <button class="btn btn-secondary inq-btn-st mr-3">BACK</button>
                                        <button class="btn btn-primary inq-btn-st">CONTINUE</button>
                                    </div>
                                </div>
        
                            </div>
                            <!-- order details -->
                            <div class="tab-pane fade  " id="orderDetails" role="tabpanel" aria-labelledby="orderDetails-tab">
                                <div class="col-md-12">
                                    <div class="mt-2">
                                        <app-alerts *ngIf="OrderMsg"></app-alerts>
                                    </div>                           
                                    <div class="inq-table-layout-st mt-3 inq-order-row-st">
                                        <table class="table table-striped inq-cart-fitems-st inq-table-st">
                                            <thead>
                                                <tr>
                                                    <th style="width: 65%;">ITEMS</th>
                                                    <th style="width: 12%;">PRICE</th>
                                                    <th style="width: 11%;">QTY</th>
                                                    <th style="width: 12%;">SUB TOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let product of updatedCartData; index as i;">
                                                    <td>
                                                        <div class=" row">
                                                            <div class="col-md-3">
                                                                <div class="inq-fimg-st">
                                                                    <img *ngIf="product.image_source !== ''" [src]="product.image_source" alt="food item image">
                                                                    <img *ngIf="product.image_source === ''" src="assets/images/No_Image_old.png">
                                                                </div>
                                                
                                                            </div>
                                                            <div class="col-md-9">
                                                                <div class="inq-fimg-mt1-st">
                                                                    <p>{{product.category}}</p>
                                                                    <h4>{{product.product_name}}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                    </td>
                                                    <td>
                                                        <div class="inq-fimg-mt2-st">
                                                            <p class="inq-amt-st"><i class="fa fa-rupee"></i> {{product.cost}}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="add-cart1">
                                                            <div class="inq-fimg-mt2-st">
                                                                <p class="inq-amt-st fa-style">
                                                                    <span><i style="cursor: pointer;" (click)="updatingCarts(i, 'sub')" class="fa fa-minus mr-2"></i></span><span
                                                                        class="inq-amt-st"> {{product.quantity}} </span><i style="cursor: pointer;" (click)="updatingCarts(i, 'add')"
                                                                        class="fa fa-plus ml-2"></i><span></span>
                                                                </p>
                                                            </div>
                                                        </div>                                               
                                                    </td>
                                                    <td>
                                                        <div class="inq-fimg-mt2-st">
                                                            <p class="inq-amt-st l"><i class="fa fa-rupee"></i> {{product.cost * product.quantity}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
        
                                        </table>
                                    </div>
                                    <hr>
                                    <form [formGroup]= "orderInstructionsForm">
                                        <div class="row mt-5">
                                            <div class="col-md-12 inq-mc-ordn-st">
                                                <h5>
                                                    Delivery Instructions
                                                </h5>
                                                <p>Please write your delivery instructions </p>
                                                <div class="form-group">
                                                    <textarea class="form-control" formControlName='orderInstructions' [class.is-invalid] = "validateorderInstructionsForm && f9.orderInstructions.errors" (input)="getOrderNotes('instructions')"></textarea>
                                                    <div class="invalid-feedback" *ngIf="f9.orderInstructions.errors">
                                                        <div *ngIf="f9.orderInstructions.errors.maxlength">Delivery Instructions should not have more than 1000 charachters</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-5">
                                            <!-- <div class="col-md-6">
                                                <h5>
                                                    Coupon Code
                                                </h5>
                                                <p>Enter code to receive discounts </p>
                                                <div class="inq-news-fld-st ">
                                                    <input type="text" class="form-control" value="HAVEN50">
                                                    <div class="ind-input-abs">
                                                        <img src="assets/images/payment/green.png"  class="tick-image" height="30">
                                                        <span class="text-uppercase">REMOVE</span>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-md-12 inq-mc-ordn-st">
                                                <h5>
                                                    Order Notes & Summary
                                                </h5>
                                                <p>Please write notes of your order </p>
                                                <div class="form-group">
                                                    <textarea class="form-control" formControlName='orderComments' [class.is-invalid] = "validateorderInstructionsForm && f9.orderComments.errors" rows="10" (input)="getOrderNotes('notes')"></textarea>
                                                    <div class="invalid-feedback" *ngIf="f9.orderComments.errors">
                                                        <div *ngIf="f9.orderComments.errors.maxlength">Delivery Note should not have more than 1000 charachters</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    
                                    <!-- <div class="row mt-5 inq-mc-ordn-st">
                                        <div class="col-md-12">
                                            <h5>
                                                Payment Methods
                                            </h5>
                                            <p>Please select a preferred payment method to use this order </p>
                                            <div class="mt-2 inq-cart-pay-st img-size">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                        id="paypal" value="paypal">
                                                    <img src="assets/images/payment/paypal.png" height="18">
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                        id="Braintree" value="Braintree">
                                                    <img src="assets/images/payment/brain.png" height="18">
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                        id="stripe" value="stripe">
                                                    <img src="assets/images/payment/stripe.png" height="18">
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                        id="COD" value="COD">
                                                    <label class="form-check-label codlable" for="COD">COD</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                        id="instamojo" value="instamojo">
                                                    <img src="assets/images/payment/insta.png" height="18">
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                        id="HyperPay" value="HyperPay">
                                                    <img src="assets/images/payment/hyper.png" height="18">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <app-alerts *ngIf="showalertmsg1"></app-alerts>
                                    <div class="text-left mt-5 inq-mch-con-btn-st">
                                        <button class="btn btn-secondary inq-btn-st mr-3" (click)="changeTbz('shipping-tab1')">BACK</button>
                                        <button class="btn btn-primary inq-btn-st" (click)="changeTbz('payment-tab')" [disabled]="paymentSpinner"><span *ngIf="paymentSpinner" class="spinner-border spinner-border-sm"></span>CONTINUE</button>
                                    </div>
                                    <!-- <div class="row mt-5">
                                        <div class="col-md-6 inq-mc-ordn1-st ">
                                            <h5>
                                                Redeem Rewards Points
                                            </h5>
                                            <p>You have <img src="assets/images/payment/favorites.png"> <span class="reward">
                                                    610 Reward Points </span></p>
                                            <p>You can apply maximum <img src="assets/images/payment/favorites.png"> <span
                                                    class="text-bold"> 505 Points</span> for this order </p>
                                            <p>Every <img src="assets/images/payment/favorites.png"> <span class="text-bold"> 10
                                                    points</span> can be redeemed for <i class="fa fa-rupee"></i>5.00 </p>
                                            <div class="inq-news-fld-st ">
                                                
                                                <input type="text" class="form-control" value="200">
                                                <div class="ind-input-abs">
                                                    <img src="assets/images/payment/green.png"  class="tick-image" height="30">
                                                    <span class="text-uppercase">REMOVE</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <!-- Payment details -->
                            <div class="tab-pane fade" id="paymentDetails" role="tabpanel" aria-labelledby="payment-tab">
                                <div class="col-md-12 mt-3">
                                    <div class="row mt-4 inq-order-row-st"> 
                                        <div class="col-md-6">
                                            <form [formGroup]= "paymentMethodsForm">                                        
                                                <div class="form-group">
                                                    <div class="form-check">
                                                        <input class="form-check-input" id="exampleRadios1" type="radio" formControlName="paymentMethod" value="online" (click)="getPaymentMethod('online')" [checked]="selectedpaymentMethod === 'online'">
                                                        <label class="form-check-label">
                                                            <div class="payment-option-card ml-3 mt-1" [class.payment-option-active]="selectedpaymentMethod === 'online'">
                                                                <div class="payment-option-title">Pay Online</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div *ngIf="pickInStoreValue.displayName !== '' && orderTypes !== 'delivery'">
                                                        <div class="form-check mt-3">
                                                            <input class="form-check-input" id="exampleRadios1" type="radio" formControlName="paymentMethod" value="payinstore" (click)="getPaymentMethod('payinstore')" [checked]="selectedpaymentMethod === 'payinstore'">
                                                            <label class="form-check-label">
                                                                <div class="payment-option-card ml-3 mt-1" [class.payment-option-active]="selectedpaymentMethod === 'payinstore'">
                                                                    <div class="payment-option-title">{{pickInStoreValue.displayName}}</div>
                                                                    <div class="payment-option-instructions">{{pickInStoreValue.instructions}}</div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="orderTypes === 'delivery'">
                                                        <div class="form-check mt-3">
                                                            <input class="form-check-input" id="exampleRadios1" type="radio" formControlName="paymentMethod" value="cod" (click)="getPaymentMethod('cod')" [checked]="selectedpaymentMethod === 'cod'">
                                                            <label class="form-check-label">
                                                                <div class="payment-option-card ml-3 mt-1" [class.payment-option-active]="selectedpaymentMethod === 'cod'">
                                                                    <div class="payment-option-title">Cash on Delivery</div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>                                           
                                                </div>
                                            </form>
                                            <div class="text-left mt-5 inq-mch-con-btn-st">
                                                <button class="btn btn-secondary inq-btn-st mr-3" (click)="changeTbz('orderDetails-tab')">BACK</button>
                                                <button class="btn btn-primary inq-btn-st" (click)="processOrder()" [disabled]="paymentSpinner"><span *ngIf="paymentSpinner" class="spinner-border spinner-border-sm"></span>PLACE ORDER</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 inq-order-sec2-st">
                    <div class="mt-2">
                        <h5 class="text-left inq-cart-ord-ti-st">Order Summary</h5>
                        <div class="row inq-order-inner-p-st">
                            <table style="width: 100%;" class="table table-striped">
                                <ng-container>
                                <tr>
                                    <td>SubTotal</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.subTotal}}</td>
                                </tr>
                                <tr *ngIf="handlingFee.displayName !== ''">
                                    <td>{{handlingFee.displayName}}</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i> {{handlingFee.amount}}</td>
                                </tr>
                                <tr *ngIf="deliveryChrageData.amount !== 0 && enable_shippingtab">
                                    <td>{{deliveryChrageData.display}}</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i> {{deliveryChrageData.amount}}</td>
                                </tr>
                                <tr *ngIf="enable_shippingtab">
                                    <td>Tax</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.taxes}}</td>
                                </tr>
                                <tr *ngIf="!enable_shippingtab">
                                    <td>Tax</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i> {{orderdetails.tax + deliveryChrageData1.tax}}</td>
                                </tr>                       
                                <tr *ngIf="discountData.displayName !==''">
                                    <td class="inq-primary">{{discountData.displayName}}</td>
                                    <td class="text-right inq-primary">- <i class="fa fa-rupee"></i>{{discountData.amount}}</td>
                                </tr>
                                <tr *ngIf="orderdetails.total > 0 && enable_shippingtab">
                                    <td><strong>Total</strong></td>
                                    <td class="text-right"><strong><i class="fa fa-rupee"></i> {{orderdetails.total + deliveryChrageData.amount}}</strong></td>
                                </tr>
                                <tr *ngIf="orderdetails.total > 0 && !enable_shippingtab">
                                    <td><strong>Total</strong></td>
                                    <td class="text-right"><strong><i class="fa fa-rupee"></i> {{orderdetails.total}}</strong></td>
                                </tr>
                            </ng-container>
                            </table>
                        </div>
                    </div>
                    <!-- <div class="mt-2" >
                        <div class="row inq-order-inner-p-st">
                            <table style="width: 100%;" class="table table-striped">
                                <ng-container  *ngIf="shipping">
                                    <tr>
                                        <td>SubTotal</td>
                                        <td class="text-right"><i class="fa fa-rupee"></i>735.00</td>
                                    </tr>
                                    <tr>
                                        <td>Tax</td>
                                        <td class="text-right"><i class="fa fa-rupee"></i>150.00</td>
                                    </tr>
                                <tr>
                                    <td>Flat Rate Shipping</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>150.00</td>
                                </tr>
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td class="text-right"><strong><i class="fa fa-rupee"></i>1135.00</strong></td>
                                </tr>
                            </ng-container>
        
                            <ng-container *ngIf="orderDetails">
                                <tr>
                                    <td>SubTotal</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>735.00</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>150.00</td>
                                </tr>
                                <tr>
                                    <td>Flat Rate Shipping</td>
                                     <td class="text-right"><i class="fa fa-rupee"></i>150.00</td>
                                 </tr>
                                 <tr>
                                     <td class="inq-primary">Coupon Discount</td>
                                     <td class="text-right inq-primary"><i class="fa fa-rupee"></i>-50.00</td>
                                 </tr>
                                 <tr>
                                     <td class="inq-primary">Reward Points</td>
                                     <td class="text-right inq-primary"><i class="fa fa-rupee"></i>-100.00</td>
                                 </tr>
                                 <tr>
                                     <td><strong>Total</strong></td>
                                     <td class="text-right"><strong><i class="fa fa-rupee"></i>985.00</strong></td>
                                 </tr>
                            </ng-container>
                            </table>
                        </div>
                    </div> -->
                    <!-- <div class="mt-2">
                        <div class="row inq-order-inner-p-st">
                            <table style="width: 100%;" class="table table-striped">
                                <tr>
                                    <td>SubTotal</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>735.00</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>150.00</td>
                                </tr>
                                 <tr>
                                   <td>Flat Rate Shipping</td>
                                    <td class="text-right"><i class="fa fa-rupee"></i>150.00</td>
                                </tr>
                                <tr>
                                    <td class="inq-primary">Coupon Discount</td>
                                    <td class="text-right inq-primary"><i class="fa fa-rupee"></i>-50.00</td>
                                </tr>
                                <tr>
                                    <td class="inq-primary">Reward Points</td>
                                    <td class="text-right inq-primary"><i class="fa fa-rupee"></i>-100.00</td>
                                </tr>
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td class="text-right"><strong><i class="fa fa-rupee"></i>985.00</strong></td>
                                </tr>
                            </table>
                        </div>
                    </div> -->
                    <div class="mt-2" style="width: 100%;margin-bottom: 25px;">
                        <div [class]="selectedRestarent !== '' || shippingAddress !== ''? 'reveal-if-active' : 'hide-if-inactive'">
                            <div class="shipping-container">
                                <div [class]="selectedRestarent !== '' ? 'reveal-if-active' : 'hide-if-inactive'">
                                    <div *ngIf="selectedRestarent !== ''" class="d-flex justify-content-between">
                                        <div>
                                            <strong class="sub-heading pt-1 pb-2">Deliver from {{selectedRestarent.name}}:</strong>
                                        <div class="address-lines">
                                            <p class="address-font">{{selectedRestarent.address1}},</p>
                                            <p class="address-font">{{selectedRestarent.city}}</p>
                                            <p class="address-font">{{selectedRestarent.state}} - {{selectedRestarent.zipcode}}, {{selectedRestarent.country}}</p>
                                        </div>
                                        </div>
                                        <div>
                                            <a style="cursor: pointer;" (click)="changeTbz('delivery-tab')">Change</a>
                                        </div>
                                    </div>
                                    <div class="address-divider"></div>
                                </div>                   
                                <div [class]="shippingAddress !== '' && enable_shippingtab ? 'reveal-if-active' : 'hide-if-inactive'">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <strong class="sub-heading pt-1 pb-2">Your Food deliver To:</strong>
                                            <div class="address-lines">
                                                <p class="address-font">{{shippingAddress.first_name}}</p>
                                                <p *ngIf="shippingAddress.address1 !== ''" class="address-font">{{shippingAddress.address1}}</p>
                                                <p *ngIf="shippingAddress.landmark !== ''" class="address-font">{{shippingAddress.landmark}}</p>
                                                <p class="address-font">{{shippingAddress.entire_location}}</p>
                                                <!-- <p class="address-font">Oldmahabalipuram Rd, Thoraipakkam,</p>
                                                <p class="address-font">Chennai, TamilNadu - 60096, India</p> -->
                                            </div>
                                        </div>
                                        <div>
                                            <a style="cursor: pointer;" (click)="changeTbz('shipping-tab')">Change</a>
                                        </div>
                                    </div>
                                </div>
                                                                                                                                          
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div [class]="deliveryInfo.orderType !== '' ? 'reveal-if-active' : 'hide-if-inactive'">
                            <div class="shipping-container">
                                <strong class="sub-heading pt-1">Delivery Information</strong>
                                <div class="mt-1">
                                    <div class="form-check col-md-4 cutome-deliverylabel">
                                        <input class="form-check-input" id="exampleRadios1" type="radio" checked>
                                        <label class="form-check-label" for="exampleRadios1">
                                          {{deliveryInfo.orderType|titlecase}}
                                        </label>
                                    </div>
                                    <div><span><b>Preferred Time : </b></span><span class="address-font">{{deliveryInfo.preferredType|titlecase}}</span></div>
                                    <div *ngIf="deliveryInfo.preferredType !== 'now'"><span><b>Preferred Slot : </b></span><span class="address-font">{{deliveryInfo.preferredDate + '' + deliveryInfo.preferredTime}}</span></div>
                                    <h3 *ngIf="bannerMessage !== ''" class="rainbow rainbow_text_animated mt-2" [innerHTML]="bannerMessage"></h3>
                                </div>
                            </div>
                        </div>                
                    </div>
                    <!-- <div class="mt-2" *ngIf="DeliveryInformation">
                        <div class="row">
                            <div class="col-md-12  inq-cart-dinfo-st ">
        
                                <h6 class="text-center">Delivery Information</h6>
        
                                <div class="col-md-12 inq-cart-ddinfo-st">
                                    <div class="row">
                                        <div class="col-md-6 inq-pl0-st">
                                            <div class="inq-pribg-st">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="delivery">
                                                    <label class="form-check-label" for="delivery">Delivery</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 inq-pr0-st">
                                            <div class="inq-secbg-st">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="takeaway">
                                                    <label class="form-check-label" for="takeaway" id="take">Take Away</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form>
                                    <div class="form-group ">
                                        <select class="form-control">
                                            <option>Pre-order 5hrs before</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <i class="fa fa-calendar-week"></i>
                                        <label>  Delivery Date</label>
                                        <input type="date" class="form-control" value="25-05-2021">
                                    </div>
                                    <div class="form-group">
                                        
                                        <label> <i class="fa fa-clock"></i>Delivery Time</label>
                                        <small>(12.00 PM - 03.30 PM and 07.00 - 10.00 PM only )</small>
                                        <input type="time" class="form-control" type="time" id="myTime" value="10:00">
                                    </div>
                                    <div class="form-group">
                                        <label>Delivery Time</label>
                                        <input type="text" class="form-control" value="Sankaran Avenue, Velachery Bypass">
                                    </div>
                                    <div class="form-group">
                                        <label>Delivery Area</label>
                                        <input type="text" class="form-control" value="VGP Babu Nagar,Medavakkam">
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
        
        
                    </div> -->
                </div>
            </div>
            <div *ngIf="slidesStore.length !== 0">
                <div class="mt-5">
                    <h4>Customers Also Selected</h4>
                    <app-alerts *ngIf="showalertmsg3"></app-alerts>
                    <owl-carousel-o [options]="customOptions">
                        <ng-container  *ngFor="let slide of slidesStore">
                           <ng-template carouselSlide>
                               <div >
                                   <div class="cardBox">
                                       <div class="customer-card">
                                           <div class="front">
                                               <div id="innerpartFront">
                                                   <img *ngIf="slide.img !== ''" [src]="slide.img" class="img-fluid">
                                                   <img *ngIf="slide.img === ''" src="assets/images/No_Image_old.png" class="img-fluid">
                                                       <h5 class="text-bold ">{{slide.item}}</h5>
                                                       <p class="text-center"> {{slide.type}}</p>
                                               </div>
                                           </div>
                                           <div class="back">
                                               <div id="innerpart">
                                                   <h4 class="text-bold  textblack1">{{slide.backtitle}}</h4>
                                                   <p class="textblack">{{slide.backpara}}</p>
                                                   <h5 class="text-center textblack"> {{slide.backtype}}</h5>
                                                   <p class="textbrrown">&#x20b9; {{slide.price}}</p>
                                                   <p class="inq-bg-primary py-1 text-white">{{slide.points}}</p>
                                                   <button class="mt-2" *ngIf="existingCartProduts.indexOf(slide.id) === -1" (click)="updatetoCart(slide.id)">{{slide.button}}</button>
                                                   <div *ngIf="existingCartProduts.indexOf(slide.id) > -1">
                                                    <div class="d-flex justify-content-center pt-2">
                                                        <div class="add-cart2">
                                                            <span class="inq-sp-1" >
                                                                <i class="fa fa-minus mr-2"  style="font-size:12px;" (click)="updateRelatedProducts(slide.id, 'sub')"></i></span>
                                                                <span class="inq-sp-2" style="color: white;">{{costAndProductId[slide.id]}}</span>
                                                                <span class="inq-sp-3">
                                                                <i class="fa fa-plus ml-2"  style="font-size:12px;" (click)="updateRelatedProducts(slide.id, 'add')"></i></span>
                                                        </div>
                                                    </div>  
                                                </div>
                                               </div>
                                           </div>
                                       </div>
                                       <!--tams Card-->
                                       <div class="tams-card">
                                        <img class="card-img-top" height="185" [src]="slide.img" alt="Card image cap">
                                        <div class="card-body text-center">
                                            <h5 class="text-bold">{{slide.item}}</h5>
                                            <p class="textbrrown">&#x20b9; {{slide.price}}</p>
                                            <button class="btn add-cart-btn" (click)="updatetoCart(slide.id)"
                                            *ngIf="existingCartProduts.indexOf(slide.id) === -1">{{slide.button}}</button>
                                                <div class="mt-2" *ngIf="existingCartProduts.indexOf(slide.id) > -1">
                                                        <div class="added-to-cart">
                                                            <div >
                                                                <p >
                                                                    <span><i class="fa fa-minus mr-2 minus custom-pointer" (click)="updateRelatedProducts(slide.id, 'sub')"></i></span>
                                                                    <span class="inq-amt-st">{{costAndProductId[slide.id]}}</span><i class="fa fa-plus ml-2 plus custom-pointer"
                                                                    (click)="updateRelatedProducts(slide.id, 'add')"></i><span></span>
                                                                </p>
                                                            </div>
                                                        </div> 
                                                    </div>
                                        </div>
                                    </div>
                                       <!-- tams Card-->
                                   </div>
                               </div>
                           </ng-template>
                        </ng-container>
                       
                    
                       </owl-carousel-o>
            
                </div>
            </div>
            <!-- PAYMENT -->
        <div style="visibility: hidden; width: 0px;height: 0px;">
            <form ngNoForm [action]="paymentSettingData.PaymentgatewayURL" name="payuform" method="POST" target="_self">
                <input type="hidden" name="timezone" [value]="paymentSettingData.timezone" />
                <input type="hidden" name="authenticateTransaction" [value]="paymentSettingData.authenticateTransaction" />
                <input size="50" type="text" name="txntype" value="sale"/>
                <input size="50" type="text" name="txndatetime" [(ngModel)]="formattedDate" />
                <input size="50" type="text" name="hash" [(ngModel)]="hash1"/>
                <input  type="hidden" type="text" name="currency" [value]="paymentSettingData.currency" />
                <input size="50" type="text" name="mode" [value]="paymentSettingData.mode" />
                <input size="50" type="text" name="storename" [value]="paymentSettingData.storename" />
                <!-- <input size="50" type="text" name="chargetotal" value="1.00" /> -->
                <input *ngIf="!enable_shippingtab" size="50" type="text" name="chargetotal" [value]="orderdetails.total" />
                <input *ngIf="enable_shippingtab" size="50" type="text" name="chargetotal" [value]="orderdetails.total + deliveryChrageData.amount" />
                <input id="" name="oid" size="20" [value]="paymentSettingData.orderId" type="text">
                <input size="50" type="text" name="phone" [value]="paymentSettingData.MobieNo" />
                <!-- <input size="50" type="text" name="firstname" value="raghyl" /> -->
                <td>
                    <select name="language">
                        <option value="de_DE">Deutsch</option>
                        <option value="en_EN" selected>English</option>
                    </select>
                </td> -->
                <input type="hidden" name="hash_algorithm" [value]="paymentSettingData.hash_algorithm"/>
                <input type="hidden" name="responseSuccessURL" [value]="paymentSettingData.responseSuccessURL"/>
                <input type="hidden" name="responseFailURL" [value]="paymentSettingData.responseFailURL"/>  
                <button id="icicibtn"  type="submit" class="btn btn-danger">Submit</button>
            </form>
        </div>
        </div>
</section>