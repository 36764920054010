<div class="page-home">
  <app-banner></app-banner>

  <div class="container">
    <section class="tab-product featured-collections m-0">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="content-title">
            <div class="title-box">
              <h2 class="title main-title">
               Explore Our Classifed Categories
              </h2>
              <h4 class="sub-title submain-title">Save Time in search of Suppliers</h4>
            </div>
          </div>
          <app-featured-collections
            [collections]="featuredCollections"
          ></app-featured-collections>
        </div>
      </div>
    </section>
  </div>
  <div *ngIf="recommededProducts.length != 0">
    <div class="container">
      <section class="featured-recommendations pb-50">
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="content-title">
              <div class="title-box">
                <h2 class="title main-title">Our Collections & Featured Design</h2>
                <h4 class="sub-title submain-title">
                  Passion for Designing
                </h4>
              </div>
            </div>
  
            <owl-carousel-o [options]="ProductSliderConfig" class="slider rounder-corner-nav">
              <ng-container
                *ngFor="let product of recommededProducts; let i = index"
              >
                <ng-template carouselSlide>
                    <app-product-box-one [product]="product"></app-product-box-one>                    
                </ng-template>                 
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </section>
    </div>
  </div>
 
  <!-- <div class="container-fluid best-products">
    <div class="container">
      <div class="title">
        <h1 class="main-title">Best Of Tyres</h1>
      </div>
      <app-best-products></app-best-products>
    </div>
  </div> -->
  <!----->
  
</div>
