import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { ProductDetailsMainSlider, ProductDetailsThumbSlider } from '../shared/data/slider';
import * as metadata from '../../assets/data/default/product-details.json';
import { MetaDataMapService } from '../meta-data-map.service'
import { CartService } from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { AlertsService } from '../shared/alerts/alerts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import {config} from 'src/configs/config';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { zip } from 'rxjs';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class ProductDetailsComponent implements AfterViewInit {

  config=config;
  imageNotFound=config.noImageAvailable
  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  public activeSlide: any = 0;
  queryParams: any;
  newProductDetails: any;
  activeMenu: string
  currentRating = 0;
  dataMap = {};
  price: any;
  sizeVariable: any;
  respData: any;
  showPrice = false;
  checkVariantInCart: any;
  isValid = false;
  groups = [];
  isDisabled: boolean = false;
  showSize = false;
  variantName: any;
  variants = [];
  priceOfVariant: any;
  showHint: any;
  generalInfo:boolean=false;
  addCart = false;
  descriptionData: any;
  innnerhtmlData: any;
  additionData:any;
  isActive:any;
  public cartData = [];
  productType: any;
  showAlerts = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  tableHead=[];
  bulletPoints;
  tableData=[];
  shortDescription:any;
  validateForm: boolean;
  mainImagePreview: any
  showImage: boolean = false;
  showVideo: boolean = false;
  image_preview: boolean = false;
  showInstruction: boolean =false;
  video_preview: boolean = false;
  reviews="0"
  imageAssets=[];
  availableLocation:boolean=false;
  notAvailableLocation:boolean=false;
  customOptions: OwlOptions = {
    autoplay: false,
    loop: false,
    navSpeed: 300,
    stagePadding: 130,
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      10: {
        items: 2
      },
      20: {
        items: 3
      },


    },
    nav: true,
  }
  instructionDesc:any;
  ImageVideoPreview = [];
  mainImage: any
  mainViewVideo: any;
  displayURL: any;
  attributeTitle:any;
  showData:boolean=false;
  productId:any;
  detailForm:FormGroup;
  numPattern = /^[0-9]*$/;
  showSpinner:boolean = false;
  locationName:string;
  courierObj:any;
  courierName:any;
  estdDelivery:any;
  showStorage:boolean=false

  
  constructor(
    public appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public metaDataMapService: MetaDataMapService,
    private cartService: CartService,
    private localService: LocalStorageService,
    private alertService: AlertsService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,


  ) { }
  ngAfterViewInit(): void {
    this.changeTab(this.groups[5].fields[0].labelname)

  }
  staticAttributes = [
    {
      attr_name: "Category",
      attr_value: "-"
    },
    {
      attr_name: "Brand",
      attr_value: "-"
    },
    {
      attr_name: "SKU",
      attr_value: "-"
    },
    {
      attr_name: "Sale Price",
      attr_value: "-"
    },
    {
      attr_name: "Stock Status",
      attr_value: "-"
    }
  ]
ngOnInit(): void {

  this.detailForm = new FormGroup({
    zipcode: this.formBuilder.control('',[Validators.minLength(6),Validators.maxLength(6),Validators.pattern(this.numPattern,)])
  })
    this.cartService.updateCartItem(this.appService.cartProducts.results)
    this.groups = metadata['default'].groups;
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.queryParams = this.activatedRoute.snapshot;
      this.productId=this.queryParams.params['id'];
      if (this.queryParams.params['id']) {
        let requestBody = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "id": Number(this.queryParams.params['id'])
            },
            "pagination": false,
            "paginationDetails": {
              "limit": 0,
              "pageSize": 2
            },
            "sorting": true,
            "sortingDetails": {
              "sortfield": "",
              "sortorder": 1
            }
          }
        }
        this.getProductDetailsById(requestBody)
        }
    })
    for (let i = 0; i < this.appService.cartProducts.results.length; i++) {
    this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
  }
  get f(){
    return this.detailForm.controls;
  }
  imagePreview(i,index) {
    this.image_preview = true;
    this.video_preview = false;
    this.mainImagePreview = i.image_source;
    this.isActive=index
  }
  videoPreview(videoUrl) {
    this.video_preview = true;
    this.image_preview = false;
    this.mainViewVideo = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl.link + '?autoplay=1')

  }
  finndBroken() {
    this.newProductDetails.assets[0].image_source = this.imageNotFound

  }
  increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    return
  }
  tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

decreaseItem(index)
{
  if(this.appService.cartProducts.results[index].quantity === 1)
  {
      this.appService.cartProducts.results.splice(index,1);
      this.cartData = [];
      for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
        this.cartData.push(this.appService.cartProducts.results[i].product_id);
      }
      this.appService.totalCartItems = this.cartService.totalCartItems();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      return
      
  }
  let tempArray = this.appService.cartProducts.results;
  tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

  onConfirm(items) {
    this.sizeVariable = items;
    this.isValid = true
    this.showPrice = true
    this.isDisabled = false;
    this.activeMenu = items.option
    this.checkVariantInCart = items.id
    this.priceOfVariant = items.pricing.raw.price


  }
  changeTab(tab) {
    document.getElementById("nav-" + tab + "tab").setAttribute("class", "nav-item nav-link active")
    document.getElementById("nav-" + tab).setAttribute("class", "tab-pane fade show active")

    this.groups[5].fields.forEach(element => {
      if (element.labelname != tab) {
        document.getElementById("nav-" + element.labelname + "tab").setAttribute("class", "nav-item nav-link")
        document.getElementById("nav-" + element.labelname).setAttribute("class", "tab-pane fade")
      }
    });
  }
  productListing(){
		this.router.navigate([`/productListing`], { queryParams: {term:'', type: 'last90days' }, relativeTo: this.activatedRoute, skipLocationChange: false });

    }

    checkServiceAvailablity(){

    if(this.detailForm.valid){
    this.showSpinner=true;
    let zipcode = this.detailForm.controls.zipcode.value;
     let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "courier_serviceability": {
            "pickup_postcode": 600005,
            "delivery_postcode": zipcode,
            "cod": 0,
            "weight": 1
        }
        }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.DELIVERY_AVAILABILITY).subscribe(
      resp=>{
        if(resp){
          if(resp.success == 1){
            if(resp.result.data.status == 200){
            let recommended_id = resp.result.data.data.recommended_courier_company_id;
          
            resp.result.data.data.available_courier_companies.forEach(element => {
              if(element.courier_company_id == recommended_id && element.blocked == 0){
                this.courierObj = element;
                this.courierName = this.courierObj.courier_name;
                this.estdDelivery = this.courierObj.etd;
                this.showSpinner=false;
                this.availableLocation = true;
                this.notAvailableLocation = false;
                return;
                
                
              }
            
            });
          }else{
            this.showSpinner=false;
            this.notAvailableLocation = true;
            this.availableLocation=false;
          }
          }else{
            this.showSpinner=false;
            this.notAvailableLocation = true;
            this.availableLocation=false;
          }
        }
      },
      err => {
        console.log("ERROR:", err);
        if (err.error.message) {
          console.log(err.error.message);
        }
        else {
          console.log(err.error.message);
        }
      }
    )}else{
      this.notAvailableLocation = false;
      this.availableLocation=false;
      this.showSpinner=false;
      this.validateForm=true;
     
    }
}
    checkZipcode(){
      
      if(this.detailForm.valid){
        this.showSpinner=true;
    let zipcode = this.detailForm.controls.zipcode.value;
     let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "service_availability": {
        "filter_codes": zipcode
        }
        }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.SERVICE_AVAILABILITY).subscribe(
      
      resp=>{
        
        
        if(resp){
         
          console.log(resp)
          if(resp.success == 1){
      
            
            if(resp.result.data && resp.result.data.delivery_codes && resp.result.data.delivery_codes.length>0){
              resp.result.data.delivery_codes.forEach(element => {
              if(element.postal_code.pin == zipcode && element.postal_code.repl == 'Y'){
                this.locationName=element.postal_code.inc;
                this.showSpinner=false;
                this.availableLocation = true;
                this.notAvailableLocation = false;
              }else{
                this.showSpinner=false;
                this.notAvailableLocation = true;
                this.availableLocation=false;
              }
              });
            
              

            }else{
              this.showSpinner=false;
              this.notAvailableLocation = true;
              this.availableLocation=false;
            }
          }
        }
      },
      err => {
        console.log("ERROR:", err);
        if (err.error.message) {
          console.log(err.error.message);
        }
        else {
          console.log(err.error.message);
        }
      }
    )
      }else{
        this.notAvailableLocation = false;
        this.availableLocation=false;
        this.showSpinner=false;
        this.validateForm=true;
       
      }
    }
  getProductDetailsById(requestBody: any) {
    // document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.appService.postApiCall(requestBody, ENDPOINTS.PRODUCT_DETAILS).subscribe(
      resp => {
        if (resp) {
          console.log(resp)

            if (resp.success == 1) {
          this.newProductDetails = resp.result.data[0];
            this.imageAssets=resp.result.data[0].assets;
            

            if(this.newProductDetails.assets[0].image_source == ''){
              this.mainImage=this.imageNotFound;
              this.mainImagePreview=this.imageNotFound;
            }
            else{
            this.mainImage = this.newProductDetails.assets[0].image_source;

            }

            if(this.newProductDetails.videos!=undefined && this.newProductDetails.videos!=""){
            this.newProductDetails.videos.forEach(element => {
              let tempVariableVideo = element
              // tempVariableVideo["type"]="video"
              tempVariableVideo["safeLink"] = this.sanitizer.bypassSecurityTrustResourceUrl(element.link)
              this.ImageVideoPreview.push(tempVariableVideo)
            });
          }
            this.respData = resp.result.data[0].description.description
            let shortDesc = resp.result.data[0].description.short_desc.replace(/\\n/g, '');
            this.shortDescription=shortDesc.replace(/\\s/g, '');
            this.instructionDesc = resp.result.data[0].description.instruction_description;
            this.bulletPoints = resp.result.data[0].description.bullet_points;
            this.innnerhtmlData = this.respData.replace(/\\n/g, '');
            this.innnerhtmlData = this.innnerhtmlData.replace(/\\s/g, '')
            this.additionData=resp.result.data[0].description.bullet_points;
          //to check producttype is varaible//
            if (this.newProductDetails.product_type == "Variable" || this.newProductDetails.type == "Variable" )  {
              this.showSize = true;
              this.variantName = this.newProductDetails.variant !=undefined? this.newProductDetails.variant.name : "" ;
              this.showHint = true;
              this.isDisabled = true;
            }
            else {
              this.showSize = false;
              this.showHint = false;
              this.isDisabled = false
            }
          //to get options from variants//
        if (  resp.result.data[0].variant!=undefined && resp.result.data[0].variant.option_values.length > 0) {
         this.variants = resp.result.data[0].variant.option_values;
        }
          //reviews//
            this.currentRating=this.newProductDetails.reviews !=undefined ? this.newProductDetails.reviews.average: "0";
            this.reviews=this.newProductDetails.reviews !=undefined ? this.newProductDetails.reviews.total_reviews:"0";
            
            if( this.newProductDetails.additional_info !=undefined &&  this.newProductDetails.additional_info !=""){
              this.showData=true;
            }else{
              this.showData=false;
            }
            if( this.newProductDetails.additional_info.how_to_store !=undefined &&  this.newProductDetails.additional_info.how_to_store !=""){
              this.showStorage=true;
            }else{
              this.showStorage=false;
            }
            if(this.newProductDetails.attributes!=undefined && this.newProductDetails.attributes[0].section_title!=undefined){
            this.attributeTitle = this.newProductDetails.attributes[0].section_title;
            this.newProductDetails.attributes[0].header_name.split("-").forEach(element => {
              this.tableHead.push(element)
            });
          
          this.tableHead.push(this.newProductDetails.attributes[0].header_value)
          this.newProductDetails.attributes.forEach(element => {
            
            element.attr.forEach(element => {
              let tempArray=[]
              element.attr_name.split("/").forEach(elementTwo => {
                tempArray.push(elementTwo)
              });
              tempArray.push(element.attr_value)
              this.tableData.push(tempArray)
            });
          //  element.attr[0].attr_name.split("/").forEach(elementtwo => {
          //    tempArray.push(elementtwo)

          //  });
          //  tempArray.push(element.attr[0].attr_value)
          //  this.tableData.push(tempArray)
          });

        }
          this.descriptionData = this.newProductDetails.categories[0].categories;
          this.productType = resp.result.data[0].type;
              
            if(this.newProductDetails.hasOwnProperty('description')&& this.newProductDetails.description.instruction_title != "" && this.newProductDetails.description.instruction_description !== undefined ){
                this.showInstruction=true;
              }
              else{
                this.showInstruction=false;
              }
              if (this.newProductDetails.pricing.raw.price == "") {
              this.price = this.newProductDetails.pricing.raw.price_range
            }
            else {
              this.price = this.newProductDetails.pricing.raw.price
            }
            this.setStaticAttributes();
          }
          else {
            console.log(resp.message);
          }
        }
      },
      err => {
        console.log("ERROR:", err);
        if (err.error.message) {
          console.log(err.error.message);
        }
        else {
          console.log(err.error.message);
        }
      }
    )
  }
setStaticAttributes() {
    if (this.newProductDetails.categories) {
      if (this.newProductDetails.categories.length > 0) {
        this.staticAttributes[0].attr_value = this.newProductDetails.categories[0].categories;
        this.descriptionData = this.newProductDetails.categories[0].categories;

      }
    }
    if (this.newProductDetails.brand) {
      this.staticAttributes[1].attr_value = this.newProductDetails.brand.name;
    }
    this.staticAttributes[2].attr_value = this.newProductDetails.sku;
  }
  addToCarts(item)
{
  this.cartService.addToCart(item);
  for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
  this.cartData.push(this.appService.cartProducts.results[i].product_id);
  }

}
 addToCart() {
  if (this.showSize) {
  if (this.newProductDetails.hasOwnProperty('variant')) {
        // item.pricing.raw.price=this.priceOfVariant
        let cartdetails = this.sizeVariable;
        cartdetails.brand = { name: "" }
        cartdetails.categories = [];
        cartdetails.assets = this.newProductDetails.assets
        cartdetails.name = this.newProductDetails.name + ' - ' + this.sizeVariable.option
        cartdetails.parentcategories = this.newProductDetails.parentcategories;
        this.cartService.addToCart(cartdetails)
      }
      else {
        this.cartService.addToCart(this.newProductDetails)
      }
    }
    else {
      this.cartService.addToCart(this.newProductDetails);
      }
    this.addCart = true
    for (let i = 0; i < this.appService.cartProducts.results.length; i++) {
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
}
}
