<section class="order-confirm" >
    <i class="fa fa-times-circle" style="font-size: 60px;color: rgba(0, 0, 0, 0.185);"></i>
    <span class="thanks">Oops! Payment Failed</span>
    <div *ngIf="this.reason !== ''" class="pt-3 pb-1">
        <div class="alert alert-danger" role="alert">
            {{this.reason}}
          </div>
    </div>
    <span class="order-info mt-2">Payment for Order Number <b>{{orderNumber}}</b> could not be processed.</span>
    <span>Please try again.</span>
        
    <div class="d-flec justify-content-center mt-3" style="padding-bottom: 20px;">
        <button class="btn btn-secondary inq-btn-st mr-4" routerLink='/'><b>Back to Home</b></button>
        <button class="btn btn-primary inq-btn-st" routerLink='/checkout-new'>Try Again</button>        
    </div>
</section>
