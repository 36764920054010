import { Component, OnInit } from '@angular/core';

import { ProductSlider } from '../shared/data/slider';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import {HeaderService} from '../header/header-two/header.service';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
})
export class HomeTwoComponent implements OnInit {
  public ProductSliderConfig: any = ProductSlider;
  featureProductsApiData:any;
  recommededProducts:any = []
	 featuredCollections = [{
			image: 'assets/images/earthyworthy/designer.jpg',
			title: 'DESIGNER',
      subtitle: false,
      link: '/listing'
		},
    {

			image:'assets/images/earthyworthy/kidswear.jpg',
			title: 'KIDS WEAR',
      subtitle: false,
      link: '/listing'
		},
		{
			image: 'assets/images/earthyworthy/ethnic.jpg',
			title: 'ETHINIC',
      subtitle: false,
      link: '/listing'
		},
		{
			image:  'assets/images/earthyworthy/westernwear.jpg',
			title: 'WESTERN WEAR',
      subtitle: false,
      link: '/listing'
		},
		{
			image: 'assets/images/earthyworthy/winter.jpg',
			title: 'WINTER WEAR',
      subtitle: false,
      link: '/listing'
		},
		{
			image:  'assets/images/earthyworthy/T-shirt.jpg',
			title: 'T-shirt',
      subtitle: false,
      link: '/listing'
		},
  ];


  // recommededProducts = [
  //   {
  //     name: '1U3352 Unik Teeth Yellow ITR',
  //     price: '3,648',
  //     image: 'assets/images/product-1.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'Breaker Parts ITR',
  //     price: '2,620',
  //     image: 'assets/images/product-2.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'OTR Pattern-Siteking-HD',
  //     price: '3,900',
  //     image: 'assets/images/product-3.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'Breaker Parts ITR',
  //     price: '2,620',
  //     image: 'assets/images/product-2.png',
  //     link: '/listing'
  //   }, {
  //     name: '1U3352 Unik Teeth Yellow ITR',
  //     price: '3,648',
  //     image: 'assets/images/product-1.png',
  //     link: '/listing'
  //   },
  //   {
  //     name: 'OTR Pattern-Siteking-HD',
  //     price: '3,900',
  //     image: 'assets/images/product-3.png',
  //     link: '/listing'
  //   },
  // ]
	constructor(
    private appService:AppService,
    private headerService : HeaderService

  ) {}
	ngOnInit(): void {
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    // GET THE FEATURE PRODUCTS
    var req_feature_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 300
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(req_feature_product_data, ENDPOINTS.GET_FEATURED_PRODUCTS).subscribe(
      resp => {
        this.featureProductsApiData = resp;
        this.recommededProducts = this.featureProductsApiData.result.data;
        if(this.recommededProducts.length > 0){
          if(this.recommededProducts.length > 0 && this.recommededProducts.length < 3){
            if(this.recommededProducts.length === 1){
              this.recommededProducts.push("");
              this.recommededProducts.push("");
            }
            else if(this.recommededProducts.length === 2){
              this.recommededProducts.push("");
            }
          }
        }
        // console.log(this.recommededProducts)
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })

    }

}
