import { Injectable } from '@angular/core';

import { RegistrationOfferSettingsService } from './registration-offer-settings.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterOfferCalculationsService {

  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABEL = "FIXED AMOUNT";
  INCLUDINGTAX_LABEL = "INCLUDING TAX";

  orderTotal = 0;

  constructor(
    private registrationOfferSettingsService: RegistrationOfferSettingsService
  ) { }

  calculateSubTotal(productList){
    return new Promise((resolve, reject) => {
      let subTotal = 0;
      productList.forEach(product => {
        subTotal = subTotal + (product.pricing.raw.cost * 1)
      });
      resolve(subTotal);
    })
  }

  calculateProductTax(productTaxList){
    return new Promise((resolve, reject) => {
      let productTax = 0;
      if(this.registrationOfferSettingsService.taxCalculationSettings.catalog_prices.toUpperCase() === this.INCLUDINGTAX_LABEL){
        resolve(0)
      }
      else{
        productTaxList.forEach(product => {
          productTax = productTax + ((product.quantity * product.price * product.taxrate)/100)
        });
        resolve(Math.round((productTax + Number.EPSILON) * 100) / 100)
      }
    })
  }

  calculateHandlingFee(subtotal, discount, handlingType, handlingRate){
    return new Promise((resolve, reject) => {
      if(handlingType.toUpperCase() === this.PERCENTAGE_LABEL){
        let handlingFee = 0;
        handlingFee = (handlingRate * (subtotal-discount))/100;
        resolve(Math.round((handlingFee + Number.EPSILON) * 100) / 100)
      }
      else{
        resolve(handlingRate);
      }
    })
  }

  calculateHandlingFeeTax(handlingFee, handlingTaxRate){
    return new Promise ((resolve, reject) => {
      let handlingTax = (handlingFee * handlingTaxRate)/100;
      resolve(Math.round((handlingTax + Number.EPSILON) * 100) / 100)
    })
  }

}
