<section>
    <div class="container-fluid bg-login">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 col-md-10 login-card">
                    <div class="row">
                        <div class="col-md-5 detail-part text-center">
                            <h3>Log In</h3>
                            <p class="login-sub-text">Get access to your Orders, <br>Wishlist and Recommendations</p>
                            <img src="assets/images/tams/login_img.png">
                        </div>
                        <div class="col-md-7 logn-part">
                            <form class="login-form">
                                <div class="input-group">
                                    <input type="text" required>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Enter Mobile Number</label>
                                  </div>                                  
                                  <div class="input-group">
                                    <input [type]="showPswd ? 'text' : 'password'" required>
                                    <div class="input-group-append">
                                        <span class="input-group-text" (click)="showPassword()"><i
                                            [class]="showPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
                                      </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                    <label>Enter Password</label>
                                  </div>
                                  <div class="d-flex justify-content-around">
                                    <div></div>
                                    <div>
                                        <p class="forgot-pswd">Forgot password?</p>
                                    </div>
                                  </div>
                                  <div style="width: 85%;">
                                    <button type="button" class="btn btn-primary btn-block">Login</button>
                                    <p class="text-center mt-2 mb-2 login-sub-text1">OR</p>
                                    <button type="button" class="btn btn-secondary btn-block">Request OTP</button>
                                    <p class="registration text-center">New to here? Create an account</p>
                                  </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
