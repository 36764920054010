import { Injectable } from '@angular/core';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CalculationsService {

  constructor(
    private localService: LocalStorageService
  ) {

   }

  // CALCULATE HANDLING FEES
  calculateSubtotal(productList):any{
      let subtotal = 0;
      productList.forEach(product => {
        subtotal = subtotal + (product.cost * product.quantity)
      });
      return subtotal
    }

  // CALCULATE DISCOUNT
  calculateDiscount(dicountObj, orderObj):any{
    if(dicountObj.displayName !== "" && dicountObj.percentage > 0){
      dicountObj.amount = (dicountObj.percentage * orderObj.subTotal)/100;
      dicountObj.amount = Math.round((dicountObj.amount + Number.EPSILON) * 100) / 100
      return dicountObj;
    }
    else{
      return dicountObj;
    }
  }

  // CALCULATE HANDLING FEE
  calculateHandlingFee(handlingObj, orderObj, discountObj){
    let resultantSubtotal = orderObj.subTotal;
    if(discountObj.displayName !== ""){
      resultantSubtotal = orderObj.subTotal - discountObj.amount;
    }
    let handlingObject = handlingObj
    if(handlingObj.type === 'Percentage'){
      handlingObject.amount = (resultantSubtotal * handlingObj.value)/100
      return Math.round((handlingObject.amount + Number.EPSILON) * 100) / 100
    }
    else{
      return handlingObject.amount = handlingObj.value
    }
  }

  caluculateTotalTax(taxObj, orderObj, handlingFeeObj, discountObj){
    let resultantSub = orderObj.subTotal
    if(discountObj.displayName !== ""){
      resultantSub = resultantSub - discountObj.amount;
    }
    let handlingTax = (handlingFeeObj.amount * taxObj.handlingFeeTax)/100;
    let normalTax = (resultantSub * taxObj.defaultTax)/100;
    orderObj.tax = handlingTax + normalTax
    orderObj.tax = Math.round((orderObj.tax + Number.EPSILON) * 100) / 100
    return orderObj;
  }

  calculateTotal(orderObj, discountObj, handlingObj, shippingObj){
    if(discountObj.displayName !== ""){
      return Math.round(orderObj.subTotal + orderObj.tax+ shippingObj.tax + handlingObj.amount - discountObj.amount)
    }
    else{
      return Math.round(orderObj.subTotal + orderObj.tax+ shippingObj.tax + handlingObj.amount)
    }
  }

  calculateShippingAmount(orderObj, shippingObj, taxObj, discountObj){
    let resultantSubtotal = orderObj.subTotal;
    if(discountObj.displayName !== ""){
      resultantSubtotal = orderObj.subTotal - discountObj.amount;
    }
    if(shippingObj.type === 'Percentage'){
      shippingObj.amount = (resultantSubtotal * shippingObj.amount)/100;
      shippingObj.amount = Math.round(shippingObj.amount);
      shippingObj.tax = (taxObj.shippingTax * shippingObj.amount)/100;
      shippingObj.tax = Math.round((shippingObj.tax + Number.EPSILON) * 100) / 100
      return shippingObj;
    }
    else{
      shippingObj.amount = shippingObj.amount;
      shippingObj.tax = (taxObj.shippingTax * shippingObj.amount)/100
      shippingObj.tax = Math.round((shippingObj.tax + Number.EPSILON) * 100) / 100;
      return shippingObj;
    }
  }

  calculatederivedCost(orderObj, shippingObj, taxObj, discountObj, handlingObj, productList){

    let productTax = 0;
    let handlingTax = 0;
    if(discountObj.displayName !== ""){
      let resultAmount = orderObj.subTotal - discountObj.amount;
      productTax = Math.round(((resultAmount * taxObj.defaultTax)/100 + Number.EPSILON) * 100) / 100
    }
    else{
      productTax = Math.round(((orderObj.subTotal * taxObj.defaultTax)/100 + Number.EPSILON) * 100) / 100
    }
    handlingTax = Math.round(((taxObj.shippingTax * handlingObj.amount)/100 + Number.EPSILON) * 100) / 100

    // DERIVED COST
    let deliveryCostObject = {
      subtotal_ex_tax:0,
      subtotal_inc_tax:0,
      shipping_cost_ex_tax:0,
      shipping_cost_inc_tax:0,
      handling_cost_ex_tax:0,
      handling_cost_inc_tax:0,
      wrapping_cost_ex_tax:0,
      wrapping_cost_inc_tax:0,
      subtotal_tax:0,
      discount_amount:0,
      total_ex_tax:0,
      total_inc_tax:0
    }

    deliveryCostObject.subtotal_ex_tax = orderObj.subTotal;
    deliveryCostObject.subtotal_inc_tax = orderObj.subTotal + productTax;
    deliveryCostObject.shipping_cost_ex_tax = shippingObj.amount;
    deliveryCostObject.shipping_cost_inc_tax = shippingObj.amount + shippingObj.tax;
    deliveryCostObject.handling_cost_ex_tax = handlingObj.amount;
    deliveryCostObject.handling_cost_inc_tax = Math.round(((handlingObj.amount + handlingTax) + Number.EPSILON) * 100) / 100;
    deliveryCostObject.subtotal_tax = productTax;
    deliveryCostObject.discount_amount = discountObj.amount;
    deliveryCostObject.total_ex_tax = orderObj.subTotal +  shippingObj.amount + handlingObj.amount;
    deliveryCostObject.total_inc_tax = orderObj.total + shippingObj.amount;

    // BASE COST OBJECT
    let baseObj = {
      base_shipping_cost : 0,
      shipping_cost_tax : 0,
      shipping_cost_tax_class_id : 0,
      base_handling_cost : 0,
      base_handling_cost_type : "",
      handling_cost_tax : 0,
      handling_cost_tax_class_id : 0,
      base_wrapping_cost : 0,
      wrapping_cost_tax : 0,
      wrapping_cost_tax_class_id : 0
    }

    if(this.localService.get('TAXIDS')){
      let taxIdsObj = JSON.parse(this.localService.get('TAXIDS'));
      baseObj = {
        base_shipping_cost : shippingObj.amount,
        shipping_cost_tax : shippingObj.tax,
        shipping_cost_tax_class_id : taxIdsObj.shippingTaxId,
        base_handling_cost : handlingObj.amount,
        base_handling_cost_type : handlingObj.type,
        handling_cost_tax : handlingTax,
        handling_cost_tax_class_id : taxIdsObj.handlingTaxId,
        base_wrapping_cost : 0,
        wrapping_cost_tax : 0,
        wrapping_cost_tax_class_id : 0
      }

    }

    // ORDER TAXES
    let ordertaxrates = JSON.parse(this.localService.get('ORDERTAXLABELS'))
    let ordertaxes = [];

    let orderTaxObj = {
      tax_rate_id:"",
      tax_class_id:"",
      tax_class_name:"",
      type_of_tax:"",
      rate:0,
      priority:0,
      priority_amount:0,
      line_amount:0,
      order_product_id:0,
      line_item_type:""
    }

    productList.forEach(product => {
      let ordertxobj = {
        tax_rate_id:"",
        tax_class_id:"",
        tax_class_name:"",
        type_of_tax:"",
        rate:0,
        priority:0,
        priority_amount:0,
        line_amount:0,
        order_product_id:0,
        line_item_type:""
      };
      ordertxobj.tax_rate_id = ordertaxrates.rateId.toString();
      ordertxobj.tax_class_id = ordertaxrates.productTaxId.toString();
      ordertxobj.tax_class_name = ordertaxrates.productTaxClass;
      ordertxobj.type_of_tax = 'Manual Tax';
      ordertxobj.rate = taxObj.defaultTax;
      ordertxobj.priority = 0;
      if(discountObj.displayName !== ""){
        let discamount = (discountObj.percentage * product.cost * product.quantity)/100
        discamount = (Math.round((discamount + Number.EPSILON) * 100) / 100);
        let productSubtotal = (product.cost * product.quantity) - discamount;
        ordertxobj.priority_amount = Math.round((((productSubtotal * taxObj.defaultTax)/100) + Number.EPSILON) * 100) / 100;
        ordertxobj.line_amount = Math.round((((productSubtotal * taxObj.defaultTax)/100) + Number.EPSILON) * 100) / 100;
      }
      else{
        ordertxobj.priority_amount = Math.round((((product.cost * product.quantity * taxObj.defaultTax)/100) + Number.EPSILON) * 100) / 100;
        ordertxobj.line_amount = Math.round((((product.cost * product.quantity * taxObj.defaultTax)/100) + Number.EPSILON) * 100) / 100;
      }

      ordertxobj.order_product_id = product.product_id
      ordertxobj.line_item_type = "item";
      ordertaxes.push(ordertxobj);
    });

    if(shippingObj.display !== ""){
      let shippingtaxclsObj = {
        tax_rate_id:"",
        tax_class_id:"",
        tax_class_name:"",
        type_of_tax:"",
        rate:0,
        priority:0,
        priority_amount:0,
        line_amount:0,
        order_product_id:0,
        line_item_type:""
      };
      shippingtaxclsObj.tax_rate_id = ordertaxrates.rateId.toString();
      shippingtaxclsObj.tax_class_id = ordertaxrates.shippingTaxId.toString();
      shippingtaxclsObj.tax_class_name = ordertaxrates.shippingTaxClass;
      shippingtaxclsObj.type_of_tax = 'Manual Tax';
      shippingtaxclsObj.rate = taxObj.shippingTax;
      shippingtaxclsObj.priority = 0;
      shippingtaxclsObj.priority_amount = Math.round((shippingObj.tax + Number.EPSILON) * 100) / 100;
      shippingtaxclsObj.line_amount = Math.round((shippingObj.tax + Number.EPSILON) * 100) / 100;
      shippingtaxclsObj.order_product_id = 0
      shippingtaxclsObj.line_item_type = "shipping";
      ordertaxes.push(shippingtaxclsObj);
    }

    let handlingfeeclsObj = {
      tax_rate_id:"",
      tax_class_id:"",
      tax_class_name:"",
      type_of_tax:"",
      rate:0,
      priority:0,
      priority_amount:0,
      line_amount:0,
      order_product_id:0,
      line_item_type:""
    };
    handlingfeeclsObj.tax_rate_id = ordertaxrates.rateId.toString();
    handlingfeeclsObj.tax_class_id = ordertaxrates.handlingTaxId.toString();
    handlingfeeclsObj.tax_class_name = ordertaxrates.handlingTaxClass;
    handlingfeeclsObj.type_of_tax = 'Manual Tax';
    handlingfeeclsObj.rate = taxObj.handlingFeeTax;
    handlingfeeclsObj.priority = 0;
    handlingfeeclsObj.priority_amount = handlingTax;
    handlingfeeclsObj.line_amount = handlingTax;
    handlingfeeclsObj.order_product_id = 0
    handlingfeeclsObj.line_item_type = "handling";
    ordertaxes.push(handlingfeeclsObj);

    return {"delivery":deliveryCostObject, "base":baseObj, "order_rates":ordertaxes};
  }


}
