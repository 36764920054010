<main role="main">
    <div class="container">
        <br>
        <h4 class="text-primary">My Orders</h4>
        <div class="my-orders">
            <div class="box-orders" style="text-align: center;" *ngIf='!data.length'>
                <h5>Details not found.</h5>
            </div>
            <div style="overflow-x:auto;">
                <table class="table box-orders"  *ngIf='data.length'>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Order Date</th>
                            <th>Total Price</th>
                            <th>Order Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let list of data;let i=index">
                            <td (click)="getOrderDetails($event)" style="cursor: pointer;" id={{list.id}}>{{list.id}}</td>
                            <td>{{list.created_on}}</td>
                            <td>₹{{list.order_review.order_summary.order_total_amount}}</td>
                            <td>{{list.status}}</td>
                            <td>
                                <button class="btn btn-md btn-primary mb-1" *ngIf='list.system_label=="Pending" || list.system_label=="Awaiting Payment" || list.system_label=="Confirmed" || list.system_label=="Processing"'
                                    (click)='cancelOrder(i)'>Cancel</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row" *ngIf='total_count > 5'>
                <div class="col-md-12 ml-auto mr-auto">
                    <div class="float-right">
                        <app-pagination [totalRecords]="total_count" [recordsPerPage]="5"
                            (onPageChange)="displayActivePage($event)"></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

</main>