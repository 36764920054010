import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
// import { AlertsService } from '../../shared/component/alerts/alerts.service';
import { TermsAndConditionService } from '../terms-and-condition/terms-and-condition.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';
// import { LocalStorageService } from '../../auth-services/local-storage.service';
// import { AuthTokenService } from '../../auth-services/auth-token.service';

@Component({
  selector: 'app-privacy-poicy',
  templateUrl: './privacy-poicy.component.html',
})
export class PrivacyPoicyComponent implements OnInit {

  // showContent:any = [];

  content: any;

  data:any = []

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  // data = [{title:"terms",content:"This is title"},{title:"condition",content:"this is condition"}]

  showContent:any = []
  constructor(
    private router: Router,
    private termsAndConditionService: TermsAndConditionService,
    private appService: AppService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    // private alertService: AlertsService,
    private activatedRoute: ActivatedRoute,
    // private localService: LocalStorageService,
    // private authTokenService: AuthTokenService
  ) { }

  ngOnInit(): void {
    this.showContent=[false,false]

    window.scrollTo(0,0)

    this.getProfileDetails()
  }

  show1(e:any){
    console.log(e)
    if(this.showContent[e]===true){
      this.showContent[e]=false;
    }else{
      this.showContent[e]=true;
    }
    console.log(this.showContent[e])
  }

  getProfileDetails() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_ALL_PRIVACY_POLICY).subscribe(
      resp => {
        if (resp.result.data[0].length !== 0) {
          this.data = resp.result.data[0]
          this.content = this.data.content;

          document.getElementById('privacy-content').innerHTML = this.content;
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          // this.alertService.error('Session Time Out! Please login Again', this.options)
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
        // else if (err.error.message) {
        //   this.alertService.error(err.error.message, this.options)
        // }
        // else {
        //   this.alertService.error('Something bad happened. Please try again!', this.options);
        // }
      }
    )
  }


}

