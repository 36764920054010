import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {BestProductsComponent} from './best-products/best-products.component'
import {FeaturedCollectionsComponent} from './featured-collections/featured-collections.component'
import {ProductBoxOneComponent} from '../shared-two/product/product-box-one/product-box-one.component'
import { ProductBoxOnePipe } from '../shared-two/product/product-box-one/product-box-one.pipe';



@NgModule({
  declarations: [
    BestProductsComponent,
    FeaturedCollectionsComponent,
    ProductBoxOneComponent,
    ProductBoxOnePipe
   
      ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule
  ],
  exports: [
  CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BestProductsComponent,
    FeaturedCollectionsComponent,
    ProductBoxOneComponent
   
  ]
})
export class SharedModule { }
