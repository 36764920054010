<app-alerts *ngIf="showAlerts"></app-alerts>



<section>
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="margin-bottom: 20px;">
      <!-- <div class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
            <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
                <img style="max-height: 400px;min-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="...">
              </div>
      </div> -->
       <div *ngIf="CONFIG.title != 'Tams'" class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
        <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
            <img style="max-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="...">
          </div>
     </div>
      <div *ngIf="CONFIG.title === 'Tams'" class="carousel-inner"  style="box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;">
        <div class="carousel-item" [class.active]="i==0"  *ngFor="let carousel1 of carouselData; let i=index;">
            <img style="max-height: 400px;" [src]="carousel1.image_source" class="d-block w-100" alt="...">
            <div class="text-center text-primary natural-main-text" style="margin-top:21px;">
                <h4>{{carousel1.alt_text}}</h4>
            </div>
          </div>
     </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span style="background-color: #ccda78; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/previous.png"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span style="background-color: #ccda78; border-radius: 50%; padding: 10px 15px; opacity: 1 !important;"><img src="../../assets/images/vendor/next.png"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
</section>
<section class="container inq-home-sec-st">
    <div *ngIf="CONFIG.title != 'Tams'" class="text-center mb-5">
        <h4>Welcome to {{CONFIG.name}}</h4>
    </div>
    <div *ngIf="CONFIG.title == 'Tams'" class="text-center mb-5">

      <div *ngIf="CONFIG.title == 'Tams'" class="text-center mb-5">
        <h4 class="text-primary">12 Hours Naturally Fermenting Flours</h4>
    </div>

  </div>
    <ng-container>
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let product of products; let i=index;">
                <ng-template carouselSlide>
                    <div class="home-page">
                        <div class="cardBox">
                            <div class="card">
                                <div class="product-card ">
                                    <div class="front">
                                        <div id="innerpartFront">
                                            <img height="150" [src]="product.assets[0].image_source"
                                                onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                                (click)="productDetails(product.id)" *ngIf='product.assets[0].image_source!=""' alt="No Image">
                                            <img height="150" src="assets/images/vendor/No_Image_old.png"
                                                style="cursor: pointer;" (click)="productDetails(product.id)"
                                                *ngIf='!product.assets[0].image_source || product.assets[0].image_source==""' alt="No Image">
                                            <h5 class="text-bold " style="cursor: pointer;"
                                                (click)="productDetails(product.id)">  {{metaDataMapService.findData(product,fields[1].nestedinfo)}}</h5>
                                            <p class="text-center">{{product.parentcategories[0].name}}</p>
                                        </div>
                                    </div>
                                    <div class="back">
                                        <div id="innerpart">
                                            <h3 class="text-bold textblack1">{{product.name}}</h3>
                                            <p class="textblack">{{product.description.description}}</p>
                                            <h5 class="text-center textblack">{{product.parentcategories[0].name}}</h5>
                                            <p class="textbrrown">&#x20b9; {{product.pricing.raw.price}}</p>
                                            <p class="inq-bg-primary py-1 text-white">5 Points</p>
                                            <button class="mt-2" (click)="addtoCart(i)"
                                                *ngIf="appService.cartProducts.results.length == 0">ADD TO CART </button>
                                            <div *ngIf="appService.cartProducts.results.length > 0">
                                                <div class="mt-2" *ngIf="cartData.indexOf(product.id) > -1">
                                                    <div
                                                        *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                        <!-- <br> -->
                                                        <div class="inq-cart-bw-st" style="padding-top: 10%;"
                                                            *ngIf="cart.product_id===product.id">
                                                            <div class="quantity-btn">
                                                                <span class="inq-sp-1">
                                                                    <i class="fa fa-minus" (click)="decreaseItem(i)"
                                                                        style="font-size:12px"></i></span>
                                                                <span class="inq-sp-2" style="color: #ffffff;">
                                                                    {{cart.quantity}} </span>
                                                                <span class="inq-sp-3">
                                                                    <i class="fa fa-plus" (click)="increaseItem(i)"
                                                                        style="font-size:12px"></i></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <button class="mt-2" (click)="addtoCart(i)"
                                                    *ngIf="cartData.indexOf(product.id) === -1">ADD TO CART</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-------------------------->
                                <div >
                                    <div class="tams-card" *ngIf="CONFIG.title != 'Tams'">
                                        <img class="card-img-top" style="cursor: pointer;"
                                            (click)="productDetails(product.id)" height="185" [src]="product.assets[0].image_source"
                                            alt="Card image cap">
                                        <div class="card-body text-center">
                                            <h5 class="text-bold" style="cursor: pointer;"
                                                (click)="productDetails(product.id)"> {{metaDataMapService.findData(product,fields[1].nestedinfo)}}</h5>
                                            <p class="textbrrown">&#x20b9; {{product.pricing.raw.price}}</p>
                                            <button class="btn add-cart-btn" (click)="addtoCart(i)"
                                                *ngIf="cartData.indexOf(product.id) === -1">ADD TO CART</button>

                                            <div class="mt-2" *ngIf="cartData.indexOf(product.id) > -1">
                                                <div *ngFor="let cart of appService.cartProducts.results;let i = index">

                                                    <div class="added-to-cart" *ngIf="cart.product_id===product.id">

                                                        <div>
                                                            <p>
                                                                <span><i class="fa fa-minus mr-2 minus custom-pointer"
                                                                        (click)="decreaseItem(i)"></i></span>
                                                                <span class="inq-amt-st">{{ cart.quantity }}</span><i
                                                                    class="fa fa-plus ml-2 plus custom-pointer"
                                                                    (click)="increaseItem(i)"></i><span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!---------------------->
                                <!-- ---------Prodict TAMS listing-->
                                <div *ngIf="CONFIG.title == 'Tams'">
                                    <div class="product-list-cards" style="color: lightgray;">
                                        <div>
                                            <!------------tams img------>
                                            <img [src]="product.assets[0].image_source" id="img-list"
                                                (click)="productDetails(product.id)"
                                                onerror="this.onerror=null;this.src='assets/images/tams/NoImage.png';"
                                                alt="{{ product.name}}" class="product-list-image">
                                        </div>
                                        <div class="product-list-description">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <p class="product-list-tile">
                                                        {{metaDataMapService.findData(product,fields[1].nestedinfo)}}
                                                    </p>
                                                </div>
                                                <div>

                                                    <p class="product-list-price"  *ngIf="!product.showPricse">
                                                        ₹{{product.variant.option_values[0].pricing.raw.price}}.00</p>
                                                <p class="product-list-price"*ngIf="product.showPricse && product.product_type=='Variable'" >₹{{product.pricee}}.00</p>

                                                        <!-- <p class="product-list-price"*ngIf="!product.showPricse">₹{{product.price}}.00</p> -->
                                                        <!-- <p class="product-list-price"*ngIf="!product.showPricse" style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:5px !important">&nbsp;  ₹{{product.pricing.raw.price_range.split('-')[1] + '.00'}}</p> -->
                                                        <!-- <p class="product-list-price"*ngIf="product.showPricse && (product.product_type=='Variable')" >₹{{product.pricee}}.00</p> -->
                                                        <p class="product-list-price"*ngIf="product.showPricse &&( product.product_type =='Simple' ||product.product_type =='Variation')" >₹{{product.pricing.raw.price}}.00</p>
                                                        <!-- <p class="product-list-price"  *ngIf="product.product_type=='Simple' ||product.product_type =='Variation' "style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price + '.00'}}</p>  -->
                                                        <p class="product-list-price"  *ngIf="product.product_type=='Variable'"style="font-size: 11px !important; font-weight: 500 !important;color: rgb(78, 77, 77) !important;padding-left:8px !important">₹{{product.pricing.raw.price_range_from}}-{{product.pricing.raw.price_range_to}}</p>

                                                </div>
                                            </div>
                                            <!----------variants------>
                                            <app-shared-variants [product]="product" [products]="products" (selectEmitter)="showPrice($event)"></app-shared-variants>

                                            <!-- <div>

                                                <label class='note'  style="font-size: 11px !important; font-weight: 500 !important;color: black !important;">Note: Please select option to add item in
                                                    cart</label>

                                            </div> -->

                                            <!---------------------reviews---------------->
                                            <div class="d-flex justify-content-between mt-1">
                                                <div class="inner-star-icon pd-1">
                                                    <span [class]="product && product.reviews && product.reviews.average >= 1 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 2 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 3 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span [class]="product && product.reviews && product.reviews.average >= 4 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <span  [class]="product && product.reviews && product.reviews.average >= 5 ? 'fa fa-star checked' : 'fa fa-star unchecked'"></span>
                                                    <a routerLink="/product-review/{{product.id}}" style="font-size: 11px !important; font-weight: 500 !important;"> {{product && product.reviews ? product.reviews.total_reviews : 0}} reviews</a>
                                                </div>
                                                <div>
                                                    <!--------- button---------->

                                                    <!-------------Add to cart-->
                                                    <button class=" cartButton"
                                                        style="font-size: small;font-weight: bold;width:80px;background-color: transparent !important;border:1px solid #09404b !important;color: #09404b !important;"
                                                        (click)="addToCarts(product)"
                                                        *ngIf="(product.product_type != 'Variable'&& appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variable' && appService.productIdinCart.indexOf(product.checkVariantInCart) == -1 )||(product.product_type == 'Simple' && appService.productIdinCart.indexOf(product.id) == -1  ) || (product.product_type == 'Variation' && appService.productIdinCart.indexOf(product.id) == -1  )">ADD
                                                        <span> <i class="fa fa-shopping-cart"></i></span></button>
                                                    <!---------view cart button-->
                                                    <div
                                                        *ngIf="appService.productIdinCart.indexOf(product.id) != -1 || appService.productIdinCart.indexOf(product.checkVariantInCart) != -1 ">
                                                        <div
                                                            *ngFor="let cart of appService.cartProducts.results;let i = index">
                                                            <div class=" viewCart"
                                                                style="font-size: small;font-weight: bold;padding-left:4px;padding-right:4px;"
                                                                *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart">
                                                                <span>
                                                                    <i class="fa fa-minus mr-3"
                                                                        (click)="decreaseItem(i)"
                                                                        style="font-size:12px;cursor:pointer;"></i></span>
                                                                <span class="inq-sp-2"
                                                                    style="color: white;">{{cart.quantity}}</span>
                                                                <span>
                                                                    <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                                        style="font-size:12px;cursor:pointer;"></i></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!--------------------------------------------------------->
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </ng-container>

    <div class="row inq-secs-mt-5" id="how_to_prepare">
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.about_st">
            <div class="text-center">
                <div class="inq-home-about-st">
                    <img src="{{item.image}}">
                    <h5>{{item.title}}</h5>
                    <div class=""></div>
                    <p class="pt-4">{{item.text}}</p>
                </div>
            </div>
        </div>

    </div>

</section>
<section *ngIf="CONFIG.title !== 'Tams'">
    <div class="row inq-secs-mt-5 no-gutters" >
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.gutters">
            <div class="inq-home-ser-st">
                <img src="{{item.image}}">
            </div>
        </div>
    </div>
</section>
<section *ngIf="CONFIG.title === 'Tams'">
    <div class="row inq-secs-mt-5 no-gutters" >
        <div class="col-md-3" *ngFor="let item of CONFIG.HOME_SETTINGS.gutters">
            <div class="inq-home-ser-st">
                <img src="{{item.image}}" height="220">
            </div>
        </div>
    </div>
</section>
<section class="container">
    <div class="row justify-content-center">
        <div class="col-md-5">
            <div class="inq-testi-st text-center inq-secs-mt-5" *ngFor="let item of CONFIG.HOME_SETTINGS.testimonials">
                <h5>{{item.title}}</h5>
                <p class="mt-3" [innerHTML]="item.comment"></p>
                <p>- {{item.name}} -</p>
            </div>
        </div>
    </div>
</section>
<div class="inq-onl-del-st" *ngIF="CONFIG.HOME_SETTINGS.float_button.show">
    <img src="assets/images/tams/online.svg" class="online-image" routerLink="/product" alt="online">
    <img src="assets/images/havenSampoorna/online.svg" class="online-image1" routerLink="/product" alt="online">
</div>
