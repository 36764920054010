import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sf-myorders',
  templateUrl: './sf-myorders.component.html'
})
export class SfMyordersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
