import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {ENDPOINTS} from './app.config';
import {LocalStorageService} from './auth-service/local-storage.service';
import {v4 as uuid4} from 'uuid';
import {Router,NavigationStart} from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  loggedin_user:boolean = false;
  userName:string = "";
  userLoggedIn:boolean = false;
  totalCartItems:number = 0;
  cartProducts = {
    results: []
  };
  productIdinCart = [];
  cartSubTotal = 0;
  showSubTotal = false;
  currentUrl : string = "";
  
  constructor(
    private http: HttpClient,
    private localService : LocalStorageService,
    private router : Router
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      if (event.url === '/checkout'){
        this.currentUrl = 'checkout';
      }
      else{
        this.currentUrl = '';
      }
    });
   }

  postApiCall(dataObj: any, api_endpoint: any): Observable<any>{
    return this.http.post(api_endpoint, dataObj, { headers: new HttpHeaders().set('Content-Type', 'application/json')});
  }

  getApiCall(dataObj:any, api_endpoint: any): Observable<any>{
    let requestParams = new HttpParams();
    requestParams.set("domain_name", api_endpoint.domain_name);
    requestParams.set("oid", api_endpoint.oid);
    requestParams.set("amount", api_endpoint.amount);
    return this.http.get(api_endpoint, {headers: new HttpHeaders().set('Content-Type', 'application/json'), params: requestParams})
  }

  sendCartDetails(cart_data)
  {
    return this.http.post(ENDPOINTS.CART_KAFKA_UPSERT, cart_data, { headers: new HttpHeaders().set('Content-Type', 'application/json')});
  }

  createSession()
  {
    this.localService.set('session_id',uuid4());
    this.localService.set('session_expiry',new Date().getTime() + 24*60*1000)
  }

  removeSession()
  {
    this.localService.remove('session_id');
    this.localService.remove('session_expiry');
    this.localService.set('cart',JSON.stringify([]));
    this.totalCartItems = 0;
    this.cartProducts.results = [];
    this.productIdinCart = [];
    this.cartSubTotal = 0;
  }

  trackSession()
  {
    if(this.localService.get('session_expiry') && this.localService.get('session_expiry') != "" && this.localService.get('session_id'))
    {
      let expiry = Number(this.localService.get('session_expiry'))

      if (new Date().getTime() > expiry)
      {
        //LOGGED IN SO REMOVE CART
        if(this.localService.get('UserId') && this.localService.get('UserId') != "" && this.localService.get("InCheckout") != "true")
        {
        this.removeSession();
        this.createSession();
        }
        //GUEST USER CART NO REMOVE
        else
        {
          this.createSession();
        }
      }
    }
    else
    {
      this.createSession();
    }
  }
}
