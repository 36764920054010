import { Injectable } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { LocalStorageService } from '../../auth-service/local-storage.service';
import { CustomerLoginStorageService } from '../checkout-login/customer-login-storage.service';
import { OrderInitiationService } from './order-initiation.service';
import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { CheckoutTaxService } from '../checkout-tax.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class OrderInitiationValuesService {

  constructor(
    private checkoutApiService: CheckoutApiService,
    private localStorageService: LocalStorageService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private orderInitiationService: OrderInitiationService,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private checkoutSettingsService: CheckoutSettingsService,
    private checkoutTaxService: CheckoutTaxService
  ) { }

  // GET CUSTOMER DETAILS
  getCustomerDetails(){
    return new Promise ((resolve, reject) => {
      if(this.customerLoginStorageService.getCustomerData()){
        let customerData = this.customerLoginStorageService.getCustomerData();
        this.orderInitiationService.orderObj.customer.customer_id = customerData.userid;
        this.orderInitiationService.orderObj.customer.customer_name = customerData.userName
        this.orderInitiationService.orderObj.customer.customer_type = customerData.type
        this.orderInitiationService.orderObj.customer.is_email_opt_in = customerData.subscribed
        this.orderInitiationService.orderObj.customer.phone_number = customerData.mobileNo
        this.orderInitiationService.orderObj.customer_id = customerData.userid
        resolve(true);
      }
      else{
        resolve(true);
      }
    })
  }

  // GET PRODUCT DETAILS
  getProductDetails(){
    return new Promise((resolve, reject) => {
      if(this.checkoutInitialdataService.getCartItems()){
        this.checkoutInitialdataService.getCartItems();
        let cartItems:any = this.checkoutInitialdataService.cartProducts;
        this.orderInitiationService.orderObj.shipping.items_total = cartItems.length;
        let itemsQuantity = 0;
        cartItems.forEach(item => {
          itemsQuantity = itemsQuantity + item.quantity;
        });
        this.orderInitiationService.orderObj.shipping.items_total_quantity = itemsQuantity;
        this.checkoutSettingsService.getTaxSettings().then(
          respData => {
            let res:any = respData;
            if(res){
              this.checkoutSettingsService.getTaxRates().then(
                respData1 => {
                  let res1:any = respData1;
                  let productList = [];
                  this.checkoutTaxService.getProductTaxList(cartItems, this.checkoutSettingsService.taxclasses).then(
                    respData2 => {
                      let productTaxList:any = respData2;
                      cartItems.forEach(cartItem => {
                        let productObj = {
                          product_id:"",
                          product_name:"",
                          sku:"",
                          quantity:0,
                          image_source:"",
                          price:0,
                          price_inc_tax:0,
                          price_ex_tax:0,
                          discount_percentage:0,
                          discount_amount:0,
                          tax_percentage:0,
                          tax_amount:0,
                          total_amount:0,
                          variant:{
                            variant_id:"",
                            variant_name:"",
                            options:{
                              option_key:"",
                              option_value:""
                            }
                          }
                        }
                        let productTaxDetails = productTaxList.find(productTax => productTax.pid === cartItem.product_id);
                        productObj.product_id = cartItem.product_id;
                        productObj.product_name = cartItem.product_name;
                        productObj.sku = cartItem.sku;
                        productObj.quantity = cartItem.quantity;
                        productObj.image_source = cartItem.image_source;
                        productObj.price = cartItem.cost;
                        productObj.price_ex_tax = Number(cartItem.cost) * Number(cartItem.quantity);
                        let priceIncludingTax = (Number(cartItem.cost) * Number(cartItem.quantity) * productTaxDetails.taxrate)/100;
                        productObj.price_inc_tax = (Math.round((priceIncludingTax + Number.EPSILON) * 100) / 100) + productObj.price_ex_tax;
                        productObj.tax_percentage = productTaxDetails.taxrate;
                        let producttax = (productObj.price_ex_tax * productTaxDetails.taxrate)/100;
                        productObj.tax_amount = (Math.round((producttax + Number.EPSILON) * 100) / 100);
                        productObj.total_amount = productObj.price_ex_tax + productObj.tax_amount;
                        productList.push(productObj);
                      });
                    }
                  )
                  this.orderInitiationService.orderObj.product_details.products = productList;
                  resolve(true);
                }
              )
            }
          }
        )
      }
    })
  }

  // GET CART ID
  getCartId(){
    return new Promise((resolve, reject) => {
      if(this.localStorageService.get('session_id')){
        let requestData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
            "find": {
              "session_id":this.localStorageService.get('session_id')
            }
          }
        }
        this.checkoutApiService.checkoutAPICall(ENDPOINTS.GETALLCARTITEMS, requestData).then(
          respData => {
            let res:any = respData;
            if(res.success){
              if(res.data.length > 0){
                this.orderInitiationService.orderObj.other_details.cart_id = res.data[0].id;
                resolve(true)
              }
            }
          }
        )
      }
    })
  }

  // GET ORDER STATUS VALUES
  getOrderStatus(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_ORDERSTATUS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            let orderapiData:any = res.data;
            orderapiData.forEach(element => {
              if(element.system_label.toUpperCase() === 'PENDING'){
                this.orderInitiationService.orderObj.status = element.custom_label;
                this.orderInitiationService.orderObj.status_id = element.id;
                this.orderInitiationService.new_value.status = element.custom_label;
                resolve(true)
              }
            });
          }
        }
      )
    })
  }

  // CURRENCY LABELS
  getCurrencyLabels(){
    return new Promise ((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_CURRENCIES).then(
        respData => {
          let res:any = respData;
          if(res.success){
            if(res.data.length > 0){
              let currencyDetails = res.data[0];
              this.orderInitiationService.orderObj.payment.order_currency_code = currencyDetails.currency_code;
              this.orderInitiationService.orderObj.payment.base_currency_code = currencyDetails.currency_code;
              this.orderInitiationService.orderObj.payment.currency_exchange_rate = parseFloat(currencyDetails.static_conversion_rate);
              resolve(true);
            }
            else{
              resolve(true);
            }
          }
        }
      )
    })
  }


}
