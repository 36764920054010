<header>
  <div class="main-navbar">
    <div id="mainnav">
      <div class="toggle-nav" (click)="navMainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [class.opennav]="mainMenuToggle">

        <li class="back-btn">
          <div class="mobile-back text-right" (click)="navMainMenuToggle()">
            <i class="fa fa-angle-left pl-2" aria-hidden="true"></i> &nbsp;
            <span>Back</span>

          </div>
        </li>

        <li [ngClass]="{'close-submenu': menuItem.active === true}" [class]="menuItem.type"
          *ngFor="let menuItem of allMenuItem" [class.mega-menu]="menuItem.megaMenu">
          <!-- Sub -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'sub'"
            (click)="toggletMainActive(menuItem)">
            {{ menuItem.title | titlecase}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- noChild -->
          <a href="javascript:void(0)" class="nav-link level-1" *ngIf="menuItem.type === 'noChild'"
            (click)="nosubCategory(menuItem.link)">
            {{ menuItem.title | titlecase}}

          </a>
          <!-- Link -->
          <a href="javascript:void(0)" (click)="openLink(menuItem.link, menuItem.param)" class="level-1 nav-link" *ngIf="menuItem.type === 'link'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'extTabLink'">
            {{ menuItem.title | titlecase}}

            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" class="level-1 nav-link" *ngIf="menuItem.type === 'nosub'">
            {{ menuItem.title | titlecase}}

          </a>

          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <li [ngClass]="{'close-submenu': childrenItem.active === true}"
              *ngFor="let childrenItem of menuItem.children">
              <!-- Sub -->
              <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem)">
                {{ childrenItem.name | titlecase}}
                <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.name}}</span>
                <span class="sub-arrow" *ngIf="childrenItem.children"></span>
              </a>

            </li>
          </ul>

     
  </li>
  </ul>
  </div>
  </div>
</header>