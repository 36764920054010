import { Component, OnInit,Input, EventEmitter, Output } from '@angular/core';
import { AppService } from '../../app.service';


@Component({
  selector: 'app-shared-variants',
  templateUrl: './shared-variants.component.html',
})
export class SharedVariantsComponent implements OnInit {

  @Input() product;
  @Input() products;
  @Input() slide;
  @Input() slidesStore;
  @Output() selectEmitter : EventEmitter<any> = new EventEmitter();
  public appService:AppService;
  

  constructor() {
    // public appService:AppService,
    
   }

  ngOnInit(): void {
    this.slidesStore=this.products
    this.slide=this.product
  }

  showPrice(index,item,pId){
    this.selectEmitter.emit([item,index,pId])
  }

}

