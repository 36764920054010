<!DOCTYPE html>
<html>

<head>
    <title>Return and Policy</title>
</head>

<body>
    <div class="paddingTB60">
        <div class="container-fluid text-center">
            <h3>Return & Exchange Policy</h3>
            <hr>
        </div>
        <div style="margin-left: 5px;" id="return-content"></div>
    </div>
</body>

</html>