
<div class="row">
    <ng-container *ngFor="let item of collections; let i = index">
            <div 
           [class]="getClass(i)"
             >
             <div class="tab-image image-1" >
                    <a (click)="categoryListing(item.title)" >
                    <div class="background-image" style="background-image: url({{item.image}})">
                        <div class="earthy-feature-collections"></div>
                        </div>
                    <div class="bg-content">
                            <h2 class="titleproper" *ngIf="item.title" style="font-size: 1.3rem; text-transform: uppercase; font-weight: 500;">{{item.title}}</h2>
                            <p class="titleproper" *ngIf="item.subtitle" style="font-size: 1.3rem">{{item.subtitle}}</p>
                    </div>
                </a>
            </div>
            </div>
    </ng-container>
    </div>
    