import { Component, OnInit, AfterViewInit,ChangeDetectorRef } from '@angular/core';
import {CartService} from './cart-page.service';
import {AppService} from '../app.service';
import {ENDPOINTS, ORG_DETAILS} from '../app.config';
import { NavbarService} from '../navbar/navbar.service';
import {LocalStorageService} from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { DatePipe } from '@angular/common';


import {Router, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

import {config} from '../../configs/config';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html'
})
export class CartPageComponent implements OnInit {

  showAlerts = true;
  totalPrice = 0;
  banner = "";
  storeClosed = false;
  config= config;
  imageNotFound=config.noImageAvailable
  acceptanceTiming:any;

  slidesStore = [];
  existingCartProduts = [];

  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    navSpeed: 300,
    autoplayHoverPause : true,
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
  }

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(private cartService : CartService,public appService : AppService,
    private route:ActivatedRoute,
    private navbarService: NavbarService,private localService : LocalStorageService,private alertService : AlertsService,private datePipe : DatePipe,private router : Router,private cdr: ChangeDetectorRef,private sanitizer: DomSanitizer) {
      let status = this.router.getCurrentNavigation().extras.state;

      if (status !== undefined )
      {

        if (status['shop'] == false)
        {
          this.storeClosed = true;
        }
      }
    }

  ngOnInit(): void {

    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.calculateTotal();
    this.navbarService.updateAdminFormEvent('clearSearchTerm');

    this.getPromotionBanner();

    this.getAcceptanceTimings();

    this.getRelatedProductsAPI();

    this.loadExistingCart();

  }

  ngAfterViewInit(): void{

    if(this.storeClosed === true){

      this.alertService.error("Restaurant opening soon", this.options);
      this.cdr.detectChanges();
    }

  }

  addToCart(product:any)
  {
    this.alertService.clear();

    let tempArray = this.appService.cartProducts.results;

    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      return
    }
    this.cartService.addToCart(product);
    this.loadExistingCart()
    this.calculateTotal();
  }

  increaseItem(index)
  {
    this.alertService.clear();

    let tempArray = this.appService.cartProducts.results;

    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      return
    }

    tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }

  decreaseItem(index)
  {
    if(this.appService.cartProducts.results[index].quantity === 1)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.loadExistingCart()
        return
    }
    let tempArray = this.appService.cartProducts.results;
    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }
  ClearCart(){
    this.appService.cartProducts.results.splice(0,this.appService.cartProducts.results.length)
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }
  removeItem(index)
  {
    this.appService.cartProducts.results.splice(index,1);
    this.appService.totalCartItems = this.cartService.totalCartItems();
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }

  calculateTotal()
  {

    if(this.appService.cartProducts.results.length > 0)
    {
      this.totalPrice = 0;
      this.appService.cartProducts.results.forEach((obj)=>
      {
          this.totalPrice += (obj.cost * obj.quantity);
      })
    }
    else
    {
      this.totalPrice = 0;
    }
  }

  getPromotionBanner()
  {
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {

            }
        }

    }

    this.appService.postApiCall(obj,ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            this.banner = resp.result.data[0]['eligible_banner_message']
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);

          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)

        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )


  }

  getAcceptanceTimings(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
      resp => {
        if(resp.success === 1 && resp.result.data.length > 0){

          this.acceptanceTiming = resp.result.data[0].order_acceptance_timing;
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }

  goToCheckout()
  {
    var today = moment();
    this.showAlerts = true;

    var timeAvailable :boolean ;

    if(this.acceptanceTiming === [])
    {
      this.router.navigate(['/checkout-new'])
      return
    }
    else
    {

        this.acceptanceTiming.forEach(element => {
            let from = moment(today.format('YYYY-MM-DD') +" "+ element.from,"YYYY-MM-DD hh:mm A" )
            let to = moment(today.format('YYYY-MM-DD') +" "+ element.to,"YYYY-MM-DD hh:mm A")

            if (today >= from && today <= to)
            {
              timeAvailable = true
              return
            }
        });
    }
    if(timeAvailable === true)
    {
      this.router.navigate(['/checkout-new'])
    }
    else
    {
      this.alertService.error('Restaurant opening soon',this.options)
    }

  }

    // RELATED PRODUCTS API
    getRelatedProductsAPI(){
      let cartItems = this.appService.cartProducts.results;
      let categories = [];
      let productIds = []
      cartItems.forEach(element => {
        categories.push(element.category);
        productIds.push(element.product_id);
      });

      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "category": categories,
            "productid": productIds
          },
          "pagination": false
        }
      }

      this.appService.postApiCall(requestData, ENDPOINTS.RELATEDPRODUCT_API).subscribe(
        resp => {
          if(resp.success === 1){
            let relatedProducts = resp.result.data;

            if(relatedProducts.length > 0){
              relatedProducts.forEach(element => {
                element.showHint=true;
             
               });
             

              relatedProducts.forEach(relatedP => {
                let slideObj = {
                  id:0,
                  img:'',
                  alt:'',
                  item:'',
                  type: '',
                  backtitle: '',
                  backpara:'',
                  backtype: '',
                  price:'',
                  priceRange:'',
                  points:'5 Points',
                  button:'ADD TO CART',
                  name : "",
                  brand : {},
                  pricing : {},
                  categories : [],
                  assets : [],
                  variant:[],
                  reviews:[],
                  prodType:'',
                  product_type:'',

                }
                slideObj.id = relatedP.id
                slideObj.img = relatedP.assets[0].image_source
                slideObj.alt = relatedP.name
                slideObj.item = relatedP.name
                slideObj.type = relatedP.parentcategories[0].name
                slideObj.backtitle = relatedP.name
                slideObj.backpara = relatedP.description.description
                slideObj.backtype = relatedP.parentcategories[0].name
                slideObj.price = relatedP.pricing.raw.price
                slideObj.priceRange = relatedP.pricing.raw.price_range
                slideObj.name = relatedP.name,
                slideObj.brand = relatedP.brand
                slideObj.pricing = relatedP.pricing
                slideObj.categories = relatedP.categories,
                slideObj.assets = relatedP.assets,
                slideObj.variant=relatedP.variant,
                slideObj.reviews=relatedP.reviews,
                slideObj.product_type=relatedP.type,

                this.slidesStore.push(slideObj);
              });
           this.slidesStore =this.slidesStore.filter(element=>{
             return element.variant!=undefined
           })
              this.slidesStore.forEach(element => {
                if((element.product_type=='Variable')&&(element.variant != undefined)){
                element.sizeVariable=element.variant.option_values[0];
                element.checkVariantInCart=element.variant.option_values[0].id;
                element.showPricse =false;
                element.showVariant=true;
                }
                else{
                  element.showPricse = true;
                  element.showVariant=false;
                  element.isDisabled=false;
                }
             
              });
            }

            if(this.slidesStore.length === 2){
              this.slidesStore.push(this.slidesStore[0]);
              this.slidesStore.push(this.slidesStore[1]);
            }
            else if(this.slidesStore.length === 1){
              this.slidesStore.push(this.slidesStore[0]);
              this.slidesStore.push(this.slidesStore[0]);
              this.slidesStore.push(this.slidesStore[0]);
            }
            else if(this.slidesStore.length === 3){
              this.slidesStore.push(this.slidesStore[0]);
            }

          }
          else{
            if(resp.message){
              // this.alertService.error(resp.message, this.options);
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options);
          }

        })
    }

    //FOR RELATED PRODUCTS
    loadExistingCart()
    {
      this.existingCartProduts= [];
      this.appService.cartProducts.results.forEach(value=>
        {
          this.existingCartProduts.push(value.product_id);
        })
    }

    productListing(){
		this.router.navigate([`/productListing`], { queryParams: { terms:'',type: 'last90days' }, relativeTo: this.route, skipLocationChange: false });

    }

    navigateToDetails(product_id)
    {
      this.router.navigate([`product-details/${product_id}`])
    }
    emitter(e){
      console.log(e)
    }
    showPrice(e){
    let item=e[0];
      let productId=e[2];
      this.slidesStore.forEach(element => {
      
        if(element.id === productId){
          element.showHint=false;
           element.checkVariantInCart=item.id;
           element.sizeVariable = item;
           element.pricee=item.pricing.raw.price
       element.showPricse=true;
       element.isactive=item.option;
       element.isDisabled=false;
      
        }else{
          // element.showPricse=false;
        }
      });   
     }
    

addToCarts(item) {
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    return
  }
  if(item.product_type == 'Simple'){

    this.cartService.addToCart(item);
    
  }
  else if(item.product_type == 'Variation'){

    this.cartService.addToCart(item);
    
  }
else if (item.product_type =='Variable'){
  item.sizeVariable.brand={
    name:"",
   }
   item.sizeVariable.categories=[{
     categories:"",
     id:1
   }]
   item.sizeVariable.assets=item.assets;
   item.sizeVariable.name = item.name + ' - ' + item.sizeVariable.option
    item.sizeVariable.parentcategories=[]
  this.cartService.addToCart(item.sizeVariable)
}
this.loadExistingCart()
    this.calculateTotal();
}

}
