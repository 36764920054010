import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetaDataMapService {

  constructor() { }

  findData(data, location){
    let objects = location.path.split(".");
    let value = data;
    if(location.type == "object"){
      objects.forEach(object => {
        value = value[object];
      });
    }
    else if (location.type == "array") {
      // value = [];
      objects.forEach(object => {
        value = value[object];
      });
      // data[objects[0]].forEach(item => {
      //   value.push(item);
      // });
    }
    return value;
  }

  datamap(metada: any, data: any) {
    let newData = {};
    metada.groups.forEach(group => {
      let groupFields = {};
      group.fields.forEach(field => {
        groupFields[field.fieldname] = this.findData(data, field.nestedinfo);
      });
      newData[group.groupid] = {
        group_name : group.groupname,
        fields: groupFields
      };
    });
    return newData;
  }
  
}
