import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import * as metadata from '../../assets/data/default/header-footer.json';
// import { HeaderService } from '../../header/header.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

	under_carriage: boolean = true;

	public mainMenuToggle = false;

	allCategories = [];
	dynamicMenuItems = {};

	allMenuItem = metadata.menu;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
	) { }



	ngOnInit(): void {
		this.getAllCategories();
	}

	navMainMenuToggle(): void {
		this.mainMenuToggle = !this.mainMenuToggle;
	}

	toggletNavActive(item) {

		this.router.navigate([`/productListing`], { queryParams: { terms: [item.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
		item.active = !item.active;
	}

	toggletMainActive(item) {

		this.router.navigate([`/productListing`], { queryParams: { terms: [item.title], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
		item.active = !item.active;
	}

	getAllCategories() {

		let reqData = {
			"domain_name": ORG_DETAILS.DOMAIN_NAME,
			"user_id": null,
			"extras": {
				"find": {
				},
				"pagination": false,
				"paginationDetails": {
					"limit": 1,
					"pagesize": 25
				},
				"sorting": true,
				"sortingDetails": {
					"sortfield": "id",
					"sortorder": -1
				}
			}
		}
		this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
			resp => {

				if(resp && resp.success == 1)
				{
				this.allCategories = resp.result.data;
				this.allCategories.forEach((element)=>
				{
					this.allMenuItem.forEach((item)=>
					{
						if((item.title.toLocaleLowerCase())== element.name.toLocaleLowerCase())
						{
							item.children = element.subcategories
						}
					})
				})
				}
			},
			err => {
				if (err.error.error.message) {
					console.log(err.error.error.message)
				}
				else {
					console.log('Something bad happened; Please try again!')
				}
			})
	}

	openLink(link, param)
	{
    if(param['gotoPrepare']&& this.router.url == '/?gotoPrepare=true')
        {
            var elmnt = document.getElementById('how_to_prepare');
            elmnt.scrollIntoView();
        }
		this.router.navigate([link], {queryParams: param, relativeTo:this.route,  skipLocationChange: false });
	}


	nosubCategory(category) {
		// this.router.navigate([category]);

		this.router.navigate(['/']).then(()=>{
			this.router.navigate([category]);
		})
	}

}
