<section>
    <app-alerts></app-alerts>
    <div class="product-details-wrapper">
        <div class="details-top container" id="product_breadcrumbs">
            <div class="aside-group">
            </div>
        </div>
        <div class="row container details-wrapper">
            <div class="col-sm-6">
                <div class="slider">
                    <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar
                        class="homepage-banner-slider rounder-corner-nav product-slick"
                        >
                      <ng-container >
                            <ng-template carouselSlide >
                                <div class="productDetailImage">
                                <!-- <img [src]="mainImage" id="brokenImage" (error)="finndBroken()"> -->

                                <!-------set default and preview image for tams no zoom-->
                                <div *ngIf="!image_preview && !video_preview  && config.title == 'Tams'">
                                    <lib-ngx-image-zoom [thumbImage]="mainImage"
                                        [fullImage]="mainImage" [enableLens]=true [lensWidth]=200
                                        [lensHeight]=200></lib-ngx-image-zoom>
                                </div>

                                <div *ngIf="image_preview && config.title == 'Tams'">
                                    <lib-ngx-image-zoom [thumbImage]="mainImagePreview"
                                    [fullImage]="mainImagePreview" [enableLens]=true [lensWidth]=200
                                    [lensHeight]=200></lib-ngx-image-zoom>
                                </div>
                                    <!-----------set default image with preview-->
                                    <div *ngIf="!image_preview && !video_preview && config.title != 'Tams'">
                                        <lib-ngx-image-zoom [thumbImage]="mainImage"
                                        [fullImage]="mainImage" [enableLens]=true [lensWidth]=375
                                        [lensHeight]=325></lib-ngx-image-zoom>
                                    </div>

                                    <div *ngIf="image_preview && config.title != 'Tams'">
                                        <lib-ngx-image-zoom [thumbImage]=mainImagePreview
                                        [fullImage]=mainImagePreview [enableLens]=true [lensWidth]=375
                                        [lensHeight]=325></lib-ngx-image-zoom>
                                    </div>
                                    <!--------video preview-->
                                    <div *ngIf="video_preview">
                                        <iframe [src]="mainViewVideo"  width="600" height="350" title="YouTube video player" frameborder="0"
                                            allow="autoplay; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    </div>
                            <!-- <img [src]="mainImage" id="brokenImage" (error)="finndBroken()"> -->



                                   <hr>
                                   <div class="rounder-corner-nav">
                                    <owl-carousel-o [options]="customOptions">
                                            <!-----------video--------->

                                        <ng-container *ngFor="let item of ImageVideoPreview">
                                            <ng-template carouselSlide>
                                                <div (click)="videoPreview(item)"   >
                                                    <iframe style="pointer-events: none;"
                                                     [src]="item.safeLink"
                                                        width="80" height="80" title="YouTube video player"
                                                        frameborder="0"
                                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowfullscreen></iframe>
                                                </div>
                                             </ng-template>
                                        </ng-container>
                                                <!------------------image---------------->

                                        <ng-container  *ngFor="let item of imageAssets;let i =index">
                                            <ng-template carouselSlide  *ngIf="item.image_source != ''" class="image-highlight" >

                                                <img [src]="item.image_source"  class="image-preview-product"
                                                style="width: 80px;height: 80px;"  [class.active]="isActive===i"
                                                    (click)="imagePreview(item,i)">
                                            </ng-template>
                                        </ng-container>

                                    </owl-carousel-o>
                                    </div>



                                </div>
                            </ng-template>
                        </ng-container>


                    </owl-carousel-o>



                </div>
            </div>
            <!-------------->
            <!--bread crumbs-->
            <div class="col-sm-6 example " id="size" *ngIf="newProductDetails">
                <div class="titleCase-product">
                    <!-- <h2 class="text-primary" id="titleProduct">Product Detail</h2> -->
                    <div class="breadCrumb-productTitle"><span class="home-breadcrumb" id="breadCrumbProductDetails"
                            routerLink="">Home</span>
                        <span *ngIf="config.title == 'EarthyWorthy'" class="customer-breadcrumb" id="breadCrumbProductDetails" (click)="productListing()" [routerLink]="config.product_path"> <i
                                class="px-2" id="breadCrumbProductDetails">/</i>Products</span>
                                <span *ngIf="config.title != 'EarthyWorthy'" class="customer-breadcrumb" id="breadCrumbProductDetails"  [routerLink]="config.product_path"> <i
                                    class="px-2" id="breadCrumbProductDetails">/</i>Products</span>
                        <span id="breadCrumbProductDetails"><i class="px-2" id="breadCrumbProductDetails">/</i>Product
                            Detail</span>
                    </div>
                </div>
                <div>
                    <!----------------product title price-------------------->
                    <div class="col-left" *ngIf="groups[0].showondetailpage">
                        <div class="product-detail">
                            <h2 class="product-name" id="prodName" style="font-weight:bolder;" *ngIf="groups[0].fields[0].visibility">
                                <!-- {{newProductDetails.name | titlecase }} -->
                                {{metaDataMapService.findData(newProductDetails, groups[0].fields[0].nestedinfo) |
                                titlecase }}
                                <div class="col-right">
                                    <div class="price-wrapper">
                                        <h4 class="priceRange" *ngIf="newProductDetails.product_type == 'Variable'">&#8377;{{newProductDetails.pricing.raw.price_range_from}}-{{newProductDetails.pricing.raw.price_range_to}}&nbsp;
                                                <!-----rating and reviews------------------>
                                                <div style="display: inline;" *ngIf="groups[3].showondetailpage">
                                                    <ngb-rating [max]="5"  [(rate)]="currentRating" [readonly]="true"></ngb-rating>
                                                   <a style="font-size:small !important;" routerLink="/product-review/{{newProductDetails.id}}"
                                                       *ngIf="groups[3].fields[1].visibility">{{reviews}} customer reviews</a>
                                                       <!-----------mini review----->
                                                       <!-------->
                                              </div>
                                        </h4>
                                            <h4 class="priceRange" *ngIf="newProductDetails.product_type == 'Simple' || newProductDetails.product_type == 'Variation'">&#8377;{{newProductDetails.pricing.raw.price +'.00'}}&nbsp;

                                             <!-----rating and reviews------------------>
                                            <div style="display: inline;" *ngIf="groups[3].showondetailpage">
                                                <ngb-rating [max]="5"  [(rate)]="currentRating" [readonly]="true"></ngb-rating>
                                               <a style="font-size:small !important;" routerLink="/product-review/{{newProductDetails.id}}"
                                                   *ngIf="groups[3].fields[1].visibility">{{reviews}} customer reviews</a>
                                                   <!-----------mini review----->
                                                   <!-------->
                                          </div>

                                        </h4>
                                            </div>

                                        </div>
                            </h2>
                            <hr>
                          </div>
                            <div class="breadcurmb" >
                                <div class="normalSize" [innerHTML]="shortDescription">
                                <hr>
                            </div>

                        </div>
                        <hr>
                    </div>

                    <!------variants------>
                    <div class="col-right" id="simple" *ngIf="showSize">
                        <div class="col-right">
                            <p class="sizeText">{{variantName}}</p>
                        </div>
                        <br>
                        <div class="col-right btn-group " *ngFor="let items of variants">
                            <button type="button" class="sizebox" id="button"
                                [class.active]="activeMenu === items.option" class="btn btn-primary sizebox"
                                (click)="onConfirm(items)" value="items.id">
                                {{items.option}}
                            </button>
                            <hr>
                        </div>
                    </div>
                    <!-----price of variant-->
                    <div class="col-right" *ngIf="showPrice">
                        <h1 class="product-price">
                            <span class="price">&#8377;{{priceOfVariant}}</span>
                        </h1>
                        <hr>
                    </div>
                    <!---------note message---------->
                    <div class="col-right" *ngIf="showSize && showHint">

                        <label class='hint-message addSize' id="hint">Note: Please select option to add item in
                            cart</label>

                    </div>
                    <div class="col-right" *ngIf="groups[1].showondetailpage">
                        <div class="add-to">
                            <!--------- button---------->
                            <!-------------Add to cart-->
                            <button class="btn btn-primary inq-btn-st ml-1 cartButton" [disabled]="isDisabled"
                                 (click)="addToCart()"
                                *ngIf="(appService.productIdinCart.indexOf(newProductDetails.id) == -1 && !showSize) || (showSize && appService.productIdinCart.indexOf(checkVariantInCart) == -1 )">{{groups[1].fields[0].labelname}}
                                <span> <i class="fa fa-shopping-cart"></i></span></button>
                            <!---------view cart button-->
                            <div *ngIf="appService.productIdinCart.indexOf(newProductDetails.id) != -1 || appService.productIdinCart.indexOf(checkVariantInCart) != -1 ">
                            <div *ngFor="let cart of appService.cartProducts.results;let i = index">
                            <div class="btn btn-primary inq-btn-st ml-1 viewCart"     *ngIf="cart.product_id === newProductDetails.id || cart.product_id === checkVariantInCart" >


                                <span>
                                    <i class="fa fa-minus mr-2" (click)="decreaseItem(i)"
                                        style="font-size:12px;cursor:pointer;"></i></span>
                                <span class="inq-sp-2"
                                    style="color: white;">{{cart.quantity}}</span>
                                <span>
                                    <i class="fa fa-plus ml-2" (click)="increaseItem(i)"
                                        style="font-size:12px;cursor:pointer;"></i></span>
                           </div>

                            </div>
                            </div>

                            <!-- <button class="btn btn-primary inq-btn-st ml-1 viewCart" routerLink="/cart"
                                *ngIf="appService.productIdinCart.indexOf(newProductDetails.id) != -1 || appService.productIdinCart.indexOf(checkVariantInCart) != -1 ">{{groups[1].fields[1].labelname}}<span>
                                    <i class="fa fa-shopping-cart"></i></span></button> -->
                            <!----wishlist button------->
                            <!-- <button class="btn btn-primary inq-btn-st ml-1 wishlist-button"
                                *ngIf="groups[1].fields[2].visibility">{{groups[1].fields[2].labelname}} <span> <i
                                        class="fa fa-heart"></i></span>
                            </button> -->
                            <hr>
                        </div>
                    </div>
                    <!--------------------------Delivery option----------------->
                    <div class="col-right col-md-12" *ngIf="groups[2].showondetailpage">
                        <div class="delivery-option">
                            <form [formGroup]="detailForm" >
                                <h6>DELIVERY OPTIONS <span> <i class="fa fa-truck"></i></span></h6>
                                <input id="zipcode" class="zipcode" formControlName="zipcode"   [class.is-invalid]="validateForm && f.zipcode.errors" type="text" value="" style="height: 40px;">

                                <button class="btn btn-primary inq-btn-st ml-1" (click)="checkServiceAvailablity()">Check<span *ngIf="showSpinner" class="spinner-border spinner-border-sm" style="margin-left: 20px !important;padding-top: 10px !important;"></span></button>
                                <br>
                                <div class="invalid-feedback" *ngIf="f.zipcode.errors">
                                    <div *ngIf="f.zipcode.errors.minlength">Invalid Pincode</div>
                                    <div *ngIf="f.zipcode.errors.pattern">Invalid Pincode</div>
                                    <div *ngIf="f.zipcode.errors.maxlength">Invalid Pincode</div>
                                </div>
                                <label class='hint-message note zipcode-nonavailable' *ngIf="notAvailableLocation">We are currently not delivering at this location</label>
                                <label class='hint-message note zipcode-available' *ngIf="availableLocation">Delivery available
                                    <br>
                                 <span class="hint-message addSize" style="font-size:large;color: black;font-weight: normal;" > Get it by </span><span class="estd-delivery">{{estdDelivery}}</span></label><br>


                            </form>
                        </div>
                        <hr>
                    </div>
                    <!----------------------------------------------------------->
                    <!------delivery option details--->
                    <!-- <div class="col-right" *ngIf="groups[2].showondetailpage">
                        <div class="delivery-option">
                            <form [formGroup]="detailForm" >
                                <h6>DELIVERY OPTIONS <span> <i class="fa fa-truck"></i></span></h6>
                                <div class="input-group col-8">
                                    <input type="number" formControlName="zipcode"    class="form-control">
                                    <div class="invalid-feedback" *ngIf="f.zipcode.errors">
                                        <div *ngIf="f.zipcode.errors.minlength">Invalid Pincode</div>
                                        <div *ngIf="f.zipcode.errors.pattern">Invalid Pincode</div>
                                        <div *ngIf="f.zipcode.errors.maxlength">Invalid Pincode</div>
                                    </div>
                                    <div class="input-group-prepend">
                                        <button class="btn btn-primary inq-btn-st ml-1" (click)="checkZipcode()">Check</button>
                                    </div>
                                </div>
                                <label class='hint-message note' *ngIf="notAvailableLocation" style="color:#ef1d3c;font-weight: bold ;">We are currently not delivering at this location</label>
                                <label class='hint-message note' *ngIf="availableLocation" style="color:green;font-weight: bold ">Delivery available</label><br>

                                <label class='hint-message note'>Note: Enter PIN code to check availability</label>

                            </form>
                        </div>
                        <hr>
                    </div> -->
                </div>
                <!----------how to store and cook -->
                <div *ngIf="newProductDetails.description.instruction_description != '' " >
                <div class="row col-left" >
                    <div class="col-md-3" >
                    </div>
                    <div class="col-md-7" id="instruction">
                      {{newProductDetails.description.instruction_title}}
                        </div>

                    </div>

                      <div class="row col-left" *ngIf="newProductDetails.description.instruction_description != '' ">
                    <div class="col-md-3">
                        <img [src]="mainImage" class="detail-preview" >
                    </div>
                    <div class="col-md-9" >

                        <div class="inner-html-list"  [innerHTML]="instructionDesc"></div>
                        </div>

                    </div>

                <hr>
                </div>
                <!-----------------How to store-->
                <div class="row" *ngIf="config.title == 'Tams'">
                  <div class="col-md-12" *ngIf="showStorage">
                    <div class="col-lg-12  storage" >
                        <div class="card-header"  >
                         How To Store
                        </div>
                        <div class="col-sm-12" >
                            <p>{{newProductDetails.additional_info.how_to_store}}</p>
                        </div>
                      </div>
                      <hr>
                  </div>

                  </div>

                     <!-----------------points-->
                <div class="row"  *ngIf="newProductDetails.description.bullet_points != ''">
                    <div class="col-md-8">
                <div class="col-lg-12 "style=" color:#3b8c9c;font-weight:900;"  >
                    <div class="col-sm-12" >
                    <div class="innerHTML" [innerHTML]="bulletPoints"></div>
                    </div>
                  </div>
                  <hr>
                  </div>

                  </div>

                  <!-----product detailss-->
                  <div class="col-right" *ngIf="groups[4].showondetailpage && config.title != 'Tams'">
                    <h6>PRODUCT DETAILS</h6>
                    <div class="product-detail">
                        <div class="product-addtional-details">
                            <ul class="one-column">
                                <li class="normalSize" *ngFor="let field of groups[4].fields">
                                    <label class="list-label">{{field.labelname}}: </label>
                                    <label class="list-value"
                                        *ngIf="field.nestedinfo.type == 'object'">{{metaDataMapService.findData(newProductDetails,
                                        field.nestedinfo)}} </label>
                                    <label class="list-value"
                                        *ngIf="field.nestedinfo.type == 'array' && field.labelname == 'Category'">{{descriptionData}}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <hr *ngIf="config.title != 'Tams'">

<!------------------------additional info---------->


            </div>



        </div>
        <hr>
        <!-------tabs-------------------------->
        <div class="product-details-tabs" *ngIf="groups[5].showondetailpage">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-link headingTab" id="{{'nav-' + field.labelname + 'tab'}}"
                        *ngFor="let field of groups[5].fields" data-toggle="tab" href="#nav-home" role="tab"
                        aria-controls="nav-home" aria-selected="true"
                        (click)="changeTab(field.labelname)">{{field.labelname}}</a>

                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade" *ngFor="let field of groups[5].fields" id="{{'nav-' + field.labelname}}"
                    role="tabpanel" aria-labelledby="nav-home-tab">
                    <!----------general info-->
                    <div *ngIf="field.labelname == 'General Information' && field.nestedinfo!=undefined && showData ">
                         <div class="container"  *ngFor="let item of groups[6].fields">
                        <div class="row" >
                          <div class="col-md-3 general-info-label">
                            {{item.labelname}}
                          </div>
                          <div class="col-md-4 general-info">
                            {{metaDataMapService.findData(newProductDetails,
                                item.nestedinfo)}}
                          </div>

                        </div>
                      </div></div>
                      <!----nutri facts-->
                      <div  *ngIf="field.labelname == 'Additional Information'">
                         <span class="product-additional-info">{{attributeTitle}}</span>
                          <table >

                            <thead>

                                <tr class="product-table-head" >
                                <th  *ngFor="let heads of tableHead;" class="align">{{heads}}</th>
                                </tr>

                            </thead>

                            <tbody>

                                <tr  class="productTableRow" *ngFor="let bodys of tableData;let i = index">

                                    <td  *ngFor="let body of bodys">{{body}}</td>



                                </tr>

                            </tbody>

                        </table>

                      </div>


                      <!-----reviews------>
                      <div *ngIf="field.labelname == 'Reviews' ">
                        <div class="container">
                       <div class="row" >
                           <div class="col-md-12">
                         <app-product-review></app-product-review>
                       </div>
                       </div>
                     </div></div>
                     <!--------------->

                       <!-----manufacture------>
                       <div *ngIf="field.labelname == 'Manufacture Details' && newProductDetails.additional_info.manufacture!= ''">
                        <div class="container">
                       <div class="row" >
                           <div class="col-md-7">
                       {{newProductDetails.additional_info.manufacture}}
                       </div>
                       </div>
                     </div></div>
                     <!--------------->


                     <div *ngIf="field.labelname != 'General Information'&& field.labelname!='Additional Information' && field.labelname!='Reviews' && field.labelname!='Manufacture Details' && field.nestedinfo!=undefined && showData"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Description' && config.title=='EarthyWorthy'"  [innerHTML]="shortDescription"></div>
                     <div class="inner-html-list" *ngIf="field.labelname == 'Additional Informations' && config.title=='EarthyWorthy'"  [innerHTML]="shortDescription"></div>

                    <!-- <div class="innerHTML" [innerHTML]="innnerhtmlData"></div> -->


                </div>

            </div>
        </div>

    </div>
</section>
