<header class="sticky-header shadow-sm">
    <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != ''">
        <div class="text-center">
            <p>
                <span [innerHtml]="promotionBanner"></span>
                <!-- <span>
                    <a href="#"><strong> More Details</strong></a>
                </span> -->
            </p>
            <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                    </a></span></p> -->
            <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
        </div>
    </div>
    <div class="inq-hmid-st inq-row">
    <div class="container" *ngIf="config.title == 'Haven'">
        <div class="inq-hmid1-st">


            <ul class="nav nav-tabs">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                        aria-haspopup="true" aria-expanded="false"><i class="fa fa-map-marker"></i>{{selectedValue}}</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item"  *ngFor="let item of branches" (click)="branchChange(item)" href="#">{{item.name}}</a>

                        <!-- <a class="dropdown-item" href="#"> Kodambakkam</a>
                        <a class="dropdown-item" href="#"> Tambaram</a> -->
                    </div>
                </li>
            </ul>
        </div>
        <div class="inq-hmid2-st">
            <a> <i class="fa fa-phone"></i> +91 9092482482</a>
        </div>
    </div>
    </div>
    <app-navbar></app-navbar>
    <hr class="inq-hr-st">
</header>
<app-alerts *ngIf="showalertmsg"></app-alerts>
    <div class="container" id="desktopMenu"  *ngIf="config.title =='Haven'">
        <div class="inq-menu-st row justify-content-center ">
            <nav class="navbar navbar-expand-lg navbar-light ">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span><span>Sampoorna Menu</span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item"  id={{item.id}} *ngFor="let item of menus">
                            <a class="nav-link menu-text" (click)="setClass(item.id)" routerLink="{{item.link}}">{{item.label}}</a>
                        </li>
                        <!-- <li class="nav-item" id="menus">
                            <a class="nav-link menu-text" (click)="setClass('menus')" [routerLink]="['/product']">Menus</a>
                        </li>
                        <li class="nav-item" id="tableBooking">
                            <a class="nav-link menu-text" (click)="setClass('tableBooking')" routerLink="table-booking">Table
                                booking</a>
                        </li>
                        <li class="nav-item" id="giftAMeal">
                            <a class="nav-link menu-text" (click)="setClass('giftAMeal')" routerLink="/gift-meal">Gift a meal</a>
                        </li>
                        <li class="nav-item" id="about">
                            <a class="nav-link menu-text" (click)="setClass('about')" routerLink="about-us">About</a>
                        </li>
                        <li class="nav-item" id="contact">
                            <a class="nav-link menu-text" (click)="setClass('contact')" routerLink="contact-us">Contact</a>
                        </li> -->
                    </ul>
                </div>
            </nav>
        </div>
    </div>
    <div id="desktopMenu" class="container" *ngIf="config.title =='Tams'">
      <div class="inq-menu-st row justify-content-center">
          <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                  data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                  class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
              <div id="navbarNav" class="collapse navbar-collapse">
                  <ul class="navbar-nav">
                      <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                              href="#/">Home</a></li>
                      <li class="nav-item" id="products"><a class="nav-link menu-text" ng-reflect-router-link="product"
                              href="#/product">FLOURS</a></li>
                      <li class="nav-item" id="prepare" style="cursor: pointer;"><a class="nav-link menu-text" ng-reflect-router-link="product"
                                  (click)="openLink()">HOW TO PREPARE</a></li>
                      <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                              href="#/about-us">About Us</a></li>
                      <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                              href="#/contact-us">Contact Us</a></li>
                  </ul>
              </div>
          </nav>
      </div>
  </div>
    <hr class="inq-hr1-st">
