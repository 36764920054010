import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Output, EventEmitter, Input } from '@angular/core';

import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutOrderdetailsService } from './checkout-orderdetails.service';
import { OrderSummaryInitialService } from '../order-summary/order-summary-initial.service';
import { CheckoutOrdersdetailsStorageService } from './checkout-ordersdetails-storage.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { CheckoutOrderdetailsEventsService } from './checkout-orderdetails-events.service';
import { CheckoutOrderdetailsOrderService } from './checkout-orderdetails-order.service';
import { CheckoutPaymentApiService } from '../checkout-payment/checkout-payment-api.service';
import { CheckoutPaymentService } from '../checkout-payment/checkout-payment.service';
import { CheckoutShippingEventsService } from '../checkout-shipping/checkout-shipping-events.service';
import { CheckoutShippingApiService } from '../checkout-shipping/checkout-shipping-api.service';

@Component({
  selector: 'app-checkout-orderdetails',
  templateUrl: './checkout-orderdetails.component.html'
})
export class CheckoutOrderdetailsComponent implements OnInit {

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  cartProducts = [];

  showDeliverInstructions:boolean = false;
  showOrderComments:boolean = false;
  validateorderInstructionsForm = false;
  validateordernotesForm = false;
  orderInstructionsForm:FormGroup;
  orderNotesForm:FormGroup;
  editorderData:boolean = true;

  orderValidationData = {
    minOrderAmount:0,
    maxOrderQuantity:0,
    minOrderQuantity:0
  }

  constructor(
    private checkoutInitialdataService: CheckoutInitialdataService,
    private formBuilder: FormBuilder,
    private checkoutOrderdetailsService: CheckoutOrderdetailsService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private checkoutOrdersdetailsStorageService: CheckoutOrdersdetailsStorageService,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private checkoutOrderdetailsEventsService: CheckoutOrderdetailsEventsService,
    private checkoutOrderdetailsOrderService: CheckoutOrderdetailsOrderService,
    private checkoutPaymentApiService: CheckoutPaymentApiService,
    private checkoutPaymentService: CheckoutPaymentService,
    private checkoutShippingEventsService: CheckoutShippingEventsService,
    private checkoutShippingApiService: CheckoutShippingApiService
    ) { }

  ngOnInit(): void {
    this.checkoutOrderdetailsEventsService.clearEvent();
    this.checkoutOrderdetailsEventsService.currentData.subscribe(
      currentData => {
        if(currentData === "resetOrder"){
          this.resetOrderDetails();
        }
        if(currentData === "editOrder"){
          this.editOrderDetails();
        }
      }
    )
    this.getOrderValidations();
    this.getOrderCommentSettings();
    this.getProductList();
    this.orderInstructionsForm = new FormGroup({
      orderInstructions: this.formBuilder.control('',[Validators.maxLength(1000)]),
    })

    this.orderNotesForm = new FormGroup({
      orderNotes: this.formBuilder.control('',[Validators.maxLength(1000)]),
    })
  }

  get f(){
    return this.orderInstructionsForm.controls;
  }

  get f1(){
    return this.orderNotesForm.controls;
  }

  resetOrderDetails(){
    this.editorderData = false;
  }

  getProductList(){
    this.checkoutInitialdataService.getCartItems().then(
      respData => {
        let res:any = respData;
        if(res){
          this.cartProducts = this.checkoutInitialdataService.cartProducts;
        }
      }
    )
  }

  saveOrderDetails(){
    let total_quantities = 0
    this.cartProducts.forEach(
      product => {
        total_quantities = total_quantities + (product.quantity);
      }
    )
    if(total_quantities < this.orderValidationData.minOrderQuantity && this.orderValidationData.minOrderQuantity > 0){
      this.notify.emit({type:"alert", msg: "Sorry, to order atleast you should add "+this.orderValidationData.minOrderQuantity+ "items.", msgType:"error"})
    }
    else if(this.checkoutCalculationsService.orderTotal < this.orderValidationData.minOrderAmount && this.orderValidationData.minOrderAmount > 0){
      this.notify.emit({type:"alert", msg: "Minimum Amount to order is"+this.orderValidationData.minOrderAmount, msgType:"error"})
    }
    else{
      if(this.orderInstructionsForm.valid && this.orderNotesForm.valid){
        this.validateorderInstructionsForm = false;
        this.validateordernotesForm = false;
        this.checkoutOrdersdetailsStorageService.setOrderInstractions({
          orderInstrcutions: this.orderInstructionsForm.value.orderInstructions,
          orderComments: this.orderNotesForm.value.orderNotes
        })
        this.checkoutOrderdetailsOrderService.setDeliveryNotes(this.orderInstructionsForm.value.orderInstructions, this.orderNotesForm.value.orderNotes)
        this.editorderData = false;
        this.getPaymentMethods();
        this.notify.emit({step:"next"})
      }
      else{
        if(this.orderInstructionsForm.invalid){
          this.validateorderInstructionsForm = true;
        }
        if(this.orderNotesForm.invalid){
          this.validateordernotesForm = true;
        }
      }
    }
  }

  editOrderDetails(){
    this.editorderData = true;
    this.notify.emit({name:"closeRemaining"})
  }

  decrementQuantity(product, index){
    if(product.quantity == 1){
      this.cartProducts.splice(index, 1)
      this.checkoutOrderdetailsService.updateCart(this.cartProducts)
      this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
      if(this.cartProducts.length === 0){
        this.notify.emit("emptyCart");
      }
    }
    else{
      this.cartProducts[index].quantity = this.cartProducts[index].quantity - 1;
      this.checkoutOrderdetailsService.updateCart(this.cartProducts);
      this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
    }
    if(this.cartProducts.length === 0){
      this.notify.emit("emptyCart");
    }
    else{
      if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
        this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
      } 
    }
  }

  incrementQuantity(product, index){
    let total_quantities = 0
    this.cartProducts.forEach(
      product => {
        total_quantities = total_quantities + (product.quantity);
      }
    )
    if(total_quantities >= this.orderValidationData.maxOrderQuantity && this.orderValidationData.maxOrderQuantity > 0){
      this.notify.emit({type:"alert", msg: "Sorry, you can not add more than "+ this.orderValidationData.maxOrderQuantity + " items.", msgType:"error"})
    }
    else{
      this.cartProducts[index].quantity = this.cartProducts[index].quantity + 1;
      this.checkoutOrderdetailsService.updateCart(this.cartProducts);
      this.orderSummaryInitialService.updateAdminFormEvent("updateProducts");
      if(this.checkoutShippingApiService.selectedLogistics === "DELHIVERY"){
        this.checkoutShippingEventsService.updateAdminFormEvent("deliveryCharges");
      }
    }
  }

  getOrderCommentSettings(){
    this.checkoutOrderdetailsService.getCheckoutSettings().then(
      respData => {
        let res:any = respData;
        console.log("checkout",res)
        this.showOrderComments = res;
      })
  }

  orderDetailsExists(){
    if(this.checkoutOrdersdetailsStorageService.getOrderInstructions()){
      this.orderInstructionsForm.patchValue({
        orderInstructions: this.checkoutOrdersdetailsStorageService.getOrderInstructions().orderInstrcutions
      })
      this.orderNotesForm.patchValue({
        orderNotes: this.checkoutOrdersdetailsStorageService.getOrderInstructions().orderComments
      })
    }
  }

  getOrderValidations(){
    // GET ORDER VALIDATION VALUES
    this.checkoutInitialdataService.getOrderValidations().then(
      respData => {
        let res:any = respData;
        this.orderValidationData = res;
      }
    )
  }
  getPaymentMethods(){

    this.checkoutPaymentApiService.getPaymentMethods().then(

      respData => {

        let paymentMethods = [];

        let res:any = respData[0];

        if(res.offline_methods && res.offline_methods.length > 0){

          res.offline_methods.forEach(element => {

            paymentMethods.push(element);

          });

        }

        if(res.online_methods && res.online_methods.length > 0){

          res.online_methods.forEach(element => {

            paymentMethods.push(element);

          });

        }



        if(paymentMethods.length === 1){

          this.checkoutPaymentService.updateAdminFormEvent("submitPayment");

          // this.hidePaymentSection = true;

        }



      })

  }
}
