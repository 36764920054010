import { Injectable } from '@angular/core';

import { CheckoutInitialdataService } from '../checkout-initialdata.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutShippingOrderService {

  shippingAddress = {
    first_name:"",
    last_name:"",
    company_name:"",
    entire_location:"",
    address1:"",
    landmark:"",
    city:"",
    country:"",
    country_code:"",
    state:"",
    zipcode:"",
    email:"",
    phone_number:"",
    latitude:"",
    longitude:""
  }

  billingAddress = {
    first_name:"",
    last_name:"",
    company_name:"",
    entire_location:"",
    address1:"",
    landmark:"",
    city:"",
    country:"",
    country_code:"",
    state:"",
    zipcode:"",
    email:"",
    phone_number:"",
    latitude:"",
    longitude:""
  }

  shippingMethodObj = {
    name:"",
    delivery_charge:""
  }

  constructor(
    private checkoutInitialdataService: CheckoutInitialdataService
  ) { }

  setShippingandBillingAddress(shippingAddress, issame, isBilling){
    if(issame && isBilling){
      this.shippingAddress = this.billingAddress = shippingAddress
    }
    else{
      this.shippingAddress = shippingAddress;
    }
  }

  setBillingAddress(billingAddress){
    this.billingAddress = billingAddress;
  }

  setShippingMethod(shippingMethod, deliveryCharge, type){
    if(shippingMethod === "Flat Rate"){
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            this.shippingMethodObj.name = shippingMethod;
            if(type === "PER ORDER"){
              this.shippingMethodObj.delivery_charge = deliveryCharge
            }
            else if(type === "PER ITEM"){
              let deliverCharge = (deliveryCharge * this.checkoutInitialdataService.cartProducts.length);
              this.shippingMethodObj.delivery_charge = deliverCharge.toString();
            }
          }
        }
      )
    }
    else{
      this.shippingMethodObj.name = shippingMethod;
      this.shippingMethodObj.delivery_charge = deliveryCharge;
    }
    
  }
}
