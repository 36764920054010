import { Injectable } from '@angular/core';
import { AES, enc, mode } from 'crypto-js';
import * as crypto from 'crypto-js';

import { OrderUpdationValuesService } from './order-updation-values.service';
import { OrderInitiationValuesService } from './order-initiation-values.service';
import { OrderUpdationService } from './order-updation.service';
import { CheckoutLocalStorageService } from '../checkout-local-storage.service';
import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import {AppService} from '../../app.service';

@Injectable({
  providedIn: 'root'
})
export class OrderUpdationApiService {

  constructor(
    private orderUpdationValuesService: OrderUpdationValuesService,
    private orderUpdationService: OrderUpdationService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
    private checkoutApiService: CheckoutApiService,
    private checkoutSettingsService: CheckoutSettingsService,
    private orderInitiationValuesService: OrderInitiationValuesService,
    private appService: AppService
  ) { }

  getOrderUpdationObj(){
    return new Promise((resolve, reject) => {
      this.orderUpdationValuesService.getCustomerDetails().then(
        respData => {
          let res:any = respData;
          if(res){
            this.orderUpdationValuesService.getcheckoutDeliveryOrderService().then(
              respData1 => {
                let res1:any = respData1;
                if(res1){
                  this.orderUpdationValuesService.getShippingDetails().then(
                    respData2 => {
                      let res2:any = respData2;
                      this.orderUpdationValuesService.getProductDetails().then(
                        respData3 => {
                          let res3:any = respData3;
                          if(res3){
                            this.orderUpdationValuesService.getCurrencyLabels().then(
                              respData4 => {
                                let res4:any = respData4;
                                if(res4){
                                  this.orderUpdationValuesService.getOrderStatus().then(
                                    respData5 => {
                                      let res5:any = respData5;
                                      if(res5){
                                        this.orderUpdationValuesService.getOrderDetails().then(
                                          respData6 => {
                                            let res6:any = respData6;
                                            if(res6){
                                              this.orderUpdationValuesService.getOrderSummary().then(
                                                respData7 =>{
                                                  let res7:any = respData7;
                                                  this.orderUpdationValuesService.getCartId().then(
                                                    respData8 => {
                                                      let res8:any = respData8;
                                                      if(res8){
                                                        resolve(true);
                                                      }
                                                    }
                                                  )
                                                }
                                              )
                                            }
                                          }
                                        )
                                      }
                                    }
                                  )
                                }
                              }
                            )
                          }
                        }
                      )
                    }
                  )
                }
              }
            )
          }
        }
      )
    })
  }

  updateOrder(paymentType, title){
    return new Promise((resolve, reject) => {
      this.orderUpdationService.orderObj.orderConfirmDate = new Date();
            if(this.orderUpdationService.orderObj.delivery.delivery_type === ""){
              if(title.toUpperCase() === "PAY IN STORE"){
                this.orderUpdationService.orderObj.delivery.delivery_type = this.checkoutSettingsService.deliveryType.pickupin_store;
              }
              else{
                this.orderUpdationService.orderObj.delivery.delivery_type = this.checkoutSettingsService.deliveryType.delivery;
              }
            }
            if(paymentType === "Offline Method"){
              this.orderUpdationService.orderObj.status = this.orderUpdationService.new_value1.status;
              this.orderUpdationService.orderObj.system_label = this.orderUpdationService.new_value1.system_label;
              this.orderUpdationService.orderObj.status_id = this.orderUpdationService.confirmationId;
              // ENCRYPTION
              // var now = new Date();
              // var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
              // let _key = enc.Utf8.parse(this.appService.apkkey);
              let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
              let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
              let encrypted =AES.encrypt (
              JSON.stringify({
                "domain_name": ORG_DETAILS.DOMAIN_NAME,
                "user_id": ORG_DETAILS.USER_ID,
                "payload": {
                    "order": this.orderUpdationService.orderObj,
                    "new_value": this.orderUpdationService.new_value1,
                    "old_value": this.orderUpdationService.old_value,
                    "timestamp": new Date().getTime()
                },
                "extras": {
                    "find": {
                        "id": Number(this.checkoutLocalStorageService.getOrderId())
                    }
                  }
                }), _key, {
                  iv: _iv,
                  format: crypto.format.Hex,
                  mode: crypto.mode.CTR,
                  padding: crypto.pad.NoPadding
              }).toString();
                let orderUpdateoBJ = {
                  payload: encrypted
                }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, orderUpdateoBJ).then(
                respData =>{
                  let res:any = respData;
                  if(res.success){
                    resolve(true);
                  }
                }
              )
            }
            else{
              // ENCRYPTION
              // var now = new Date();
              // var utc_now = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
              // let _key = enc.Utf8.parse(this.appService.apkkey);
              let _key = enc.Utf8.parse("@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp");
              let _iv =  enc.Utf8.parse('\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f');
              let encrypted =AES.encrypt (
              JSON.stringify(
                {
                  "domain_name": ORG_DETAILS.DOMAIN_NAME,
                  "user_id": ORG_DETAILS.USER_ID,
                  "payload": {
                      "order": this.orderUpdationService.orderObj,
                      "new_value": this.orderUpdationService.new_value,
                      "old_value": this.orderUpdationService.old_value,
                      "timestamp": new Date().getTime()
                  },
                  "extras": {
                      "find": {
                          "id": Number(this.checkoutLocalStorageService.getOrderId())
                      }
                    }
                  }
              ), _key, {
                  iv: _iv,
                  format: crypto.format.Hex,
                  mode: crypto.mode.CTR,
                  padding: crypto.pad.NoPadding
              }).toString();
                let orderUpdateoBJ = {
                  payload: encrypted
                }
              this.checkoutApiService.checkoutAPICall(ENDPOINTS.ORDER_UPDATION, orderUpdateoBJ).then(
                respData =>{
                  let res:any = respData;
                  if(res.success){
                    resolve(true);
                  }
                }
              )
            }
    })
  }
}
