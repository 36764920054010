import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeSlider } from '../shared/data/slider';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { AppService } from '../app.service';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { NavbarService} from '../navbar/navbar.service';
import {CartService} from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import { HomeService } from './home.service';
import { config } from '../../configs/config';
import * as metadata from '../../assets/data/default/product-listing.json'
import { MetaDataMapService } from '../meta-data-map.service';
// import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
  CONFIG = config;
  featureProductsApiData:any;
  recommededProducts:any = []
  public cartData=[];
  public productList=[];
  products=[];
  fields = metadata.groups[0].fields;
  carouselData:any = [];
  swapData:any;
  slidesStore:any = [];
  showHint:boolean;
  customOptions: OwlOptions = {


    // navContainerClass: 'owl-nav',
    autoplay: true,
    loop: false,
    navSpeed: 300,
    autoplayHoverPause : true,
    // navClass: [ 'owl-prev', 'owl-next' ],
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
  }
  showAlerts = true;
  totalLength:any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(
    public appService: AppService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private navbarService: NavbarService,
    private cartService : CartService,
    private localService: LocalStorageService,
    private alertService : AlertsService,
    private homeService: HomeService,
    public metaDataMapService: MetaDataMapService,
    private route:ActivatedRoute
) { }
  public HomePageBannerSlider: any =  HomeSlider;

  ngOnInit(): void {
    // console.log(this.CONFIG.HOME_SETTINGS.banner)

    this.cartService.updateCartItem(this.appService.cartProducts.results);
    var req_feature_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 300
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(req_feature_product_data, ENDPOINTS.GET_FEATURED_PRODUCTS).subscribe(
      resp => {
        this.featureProductsApiData = resp;
        this.recommededProducts = this.featureProductsApiData.result.data;
        // this.products=this.recommededProducts
        console.log(this.products)
        this.productList=[];

        // this.totalLength = this.recommededProducts.result.total ? this.recommededProducts.result.total : 0;
        this.recommededProducts.forEach(element => {
          if(element.product_type != 'Variation'){
            this.products.push(element)
            }
         if(element.product_type == 'Variable' || element.type == 'Variable'){
          element.sizeVariable=element.variant.option_values[0];
          element.checkVariantInCart=element.variant.option_values[0].id;
          element.showHint=true;

           element.showPricse =false;
           element.showVariant=true;
           element.price=element.pricing.raw.price_range;
          //  element.isDisabled=true;


         }else{
           element.showPricse = true;
           element.showVariant=false;
           element.price=element.pricing.raw.price;
           element.isDisabled=false;

         }
        });
        if(this.products.length === 2){
          this.products.push(this.products[0]);
          this.products.push(this.products[1]);
        }
        else if(this.products.length === 1){
          this.products.push(this.products[0]);
          this.products.push(this.products[0]);
          this.products.push(this.products[0]);
        }
        else if(this.products.length === 3){
          this.products.push(this.products[0]);
        }

        for(let i=0;i<this.recommededProducts.length;i++){

          this.slidesStore.push({
            id:this.recommededProducts[i].id,
            img:this.recommededProducts[i].assets[0].image_source,
            alt:'Image_1',
            item:this.recommededProducts[i].name,
            type: this.recommededProducts[i].product_type,
            backtitle: this.recommededProducts[i].name,
            backpara:this.recommededProducts[i].description.description,
            backtype: this.recommededProducts[i].product_type,
            price:this.recommededProducts[i].pricing.raw.price,
            priceRange:this.recommededProducts[i].pricing.raw.price_range,
            points:'5 Points',
            button:'ADD TO CART',



          })
        }

      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
      this.navbarService.updateAdminFormEvent('clearSearchTerm');
      this.homeService.currentData.subscribe(
        currentData => {
          this.cartData=[]
          if (currentData === 'cartUpdate') {
            for(let i =0;i<this.appService.cartProducts.results.length;i++){
              console.log("cartProductsss"+this.appService.cartProducts.results[i].product_id);
              this.cartData.push(this.appService.cartProducts.results[i].product_id);
            }
          }
        }
      )



  }

  ngAfterViewInit(): void {
    this.getCarouseldetails();
    this.cdRef.detectChanges();
    this.route.queryParams.subscribe((param)=>

    {

      if(this.route.snapshot.queryParams != {} && this.route.snapshot.queryParams.gotoPrepare == 'true')

      {

        var elmnt = document.getElementById('how_to_prepare');
        //setTimeout(elmnt.scrollIntoView,2000);
        elmnt.scrollIntoView();

      }

    })
  }
  productDetails(id){
    this.router.navigate(['/product-details',id], {skipLocationChange: false })
  }
  navigateTo(){
    this.router.navigate(['/product'])
  }
  increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    return
  }
  tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

decreaseItem(index)
{
  if(this.appService.cartProducts.results[index].quantity === 1)
  {
      this.appService.cartProducts.results.splice(index,1);
      this.cartData = [];
      for(let i =0;i<this.appService.cartProducts.results.length;i++){
        this.cartData.push(this.appService.cartProducts.results[i].product_id);
      }
      this.appService.totalCartItems = this.cartService.totalCartItems();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      // for(let i =0;i<this.cartData.length;i++){
      //   console.log("decreasee"+this.cartData[i]);
      // }
      // for(let i =0;i<this.appService.cartProducts.results.length;i++){
      //   console.log("cartdecreasee"+this.appService.cartProducts.results[i].product_id);
      // }
      return

  }
  let tempArray = this.appService.cartProducts.results;
  tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}
showPrice(e){
  let item=e[0];
  let productId=e[2];
  this.products.forEach(element => {

    if(element.id === productId){
      element.showHint=false;
       element.checkVariantInCart=item.id;
       element.sizeVariable = item;
       element.pricee=item.pricing.raw.price
   element.showPricse=true;
   element.isactive=item.option;
   element.isDisabled=false;

    }else{
      // element.showPricse=false;
    }
  });
 }

emitter(e){
  console.log(e)
}
addToCarts(item) {
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0
  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  {
    this.showAlerts = true;
    window.scrollTo(0,0);
    this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    return
  }
  if(item.product_type == 'Simple'){

    this.cartService.addToCart(item);

  }
  else if(item.product_type == 'Variation'){

    this.cartService.addToCart(item);

  }
else if (item.product_type =='Variable'){

  item.sizeVariable.brand={
    name:"",
   }
   item.sizeVariable.categories=[{
     categories:"",
     id:1
   }]
   item.sizeVariable.assets=item.assets;
   item.sizeVariable.name = item.name + ' - ' + item.sizeVariable.option


   item.sizeVariable.parentcategories=[]


  this.cartService.addToCart(item.sizeVariable)
  console.log(this.appService.cartProducts.results)
  console.log(this.appService.productIdinCart)
}
}


  addtoCart(item){
    this.alertService.clear();
    let tempArray = this.appService.cartProducts.results;
    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      return
    }

    this.cartService.addToCart(this.recommededProducts[item])
    for(let i =0;i<this.appService.cartProducts.results.length;i++){
      console.log("cartProductsss"+this.appService.cartProducts.results[i].product_id);
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
  }
  getCarouseldetails(){
    // REQUEST DATA
    let request_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
          "find": {}
      }
    }
    // MAKE AN API CALL
    this.appService.postApiCall(request_data, ENDPOINTS.STORE_GALLERY).subscribe(
      resp => {
        let respData = resp.result.data[0];
        // console.log(resp)
        if(respData.carousel){
          if(respData.carousel.slides.length > 0){
            if(respData.carousel.swap_period && respData.carousel.swap_period !== ""){
              this.HomePageBannerSlider['autoplayTimeout'] = Number(respData.carousel.swap_period)
            }
            this.carouselData = respData.carousel.slides;
            this.swapData = respData.carousel;
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }





}
