import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sf-login',
  templateUrl: './sf-login.component.html'
})
export class SfLoginComponent implements OnInit {

  showPswd: Boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showPassword() {
    this.showPswd = !this.showPswd;
  }

}
