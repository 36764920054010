<section>
    <div id="myorders">
        <span class="sf-breadcrumb"><a [routerLink]="['/home']">Home</a> / My Orders</span>
        <h4 class="mt-3 mb-2">My Orders</h4>
        <div class="row">
            <div class="col-md-3 filter-section">
                <p class="filter-heading">Filters</p>
                <p class="filter-subheading">ORDER STATUS</p>
                <div>
                    <div>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="filter-label">
                            On the Way
                        </label>
                    </div>
                    <div>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        <label class="filter-label">
                            Delivered
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
