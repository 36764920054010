import { Injectable } from '@angular/core';
import { LocalStorageService } from '../auth-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {

  cartItems:any;
  NOTESKEY = "OrderNotes";
  INSTRUCTIONSKEY = "Instrcutions"

  constructor(
    private localService : LocalStorageService
  ) {
   }

  getCartItems():any{
    if(this.localService.get('cart')){
      this.cartItems = JSON.parse(this.localService.get('cart'))
      return this.cartItems;
    }
    else{
      return "";
    }
  }

  getOrderValidations():any{
    let orderValidationData = {
      minOrderAmount:0,
      maxOrderQuantity:0,
      minOrderQuantity:0
    }

    if(this.localService.get('min_order_amt')){
      orderValidationData.minOrderAmount = Number(this.localService.get('min_order_amt'))
    }
    if(this.localService.get('max_cart_qty')){
      orderValidationData.maxOrderQuantity = Number(this.localService.get('max_cart_qty'))
    }
    if(this.localService.get('min_order_qty')){
      orderValidationData.minOrderQuantity = Number(this.localService.get('min_order_qty'))
    }

    return orderValidationData;
    
  }

  // GET TAX RATES
  getTaxRatesLocal(){
    return {
      handlingFeeTax: Number(this.localService.get('HandlingTax')),
      defaultTax: Number(this.localService.get('NormalTax')),
      shippingTax: Number(this.localService.get('ShippingTax'))
    }
  }

  // SET ORDER DETAILS
  setOrderNotes(notes){
    this.localService.set(this.NOTESKEY, notes);
  }

  getOrderNotes(){
    if(this.localService.get(this.NOTESKEY)){
      return this.localService.get(this.NOTESKEY);
    }
    else{
      return "";
    }
  }

  setOrderInstrutions(instructions){
    this.localService.set(this.INSTRUCTIONSKEY, instructions)
  }

  getOrderInstrutions(){
    if(this.localService.get(this.INSTRUCTIONSKEY)){
      return this.localService.get(this.INSTRUCTIONSKEY)
    }
    else{
      return "";
    }
  }

}
