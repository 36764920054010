<div class="customer-address-page">
    <div class="card" style="height: auto;">
        <div class="card-header">
            <button type="button" class="btn btn-lg btn-outline-success btn-block" (click)="addNewAddress()">+ Add New Address</button>
        </div>
        <div class="card-body">
            <div *ngIf="addAddressFlag">
                <div class="row">
                    <div class="col-md-4">
                        <div class="btn"><h5>Add New Address</h5></div>
                    </div>
                    <div class="col-md"></div>
                    <div class="col-md-1">
                        <button class="btn border border-outline" (click)="cancelAddresses()">X</button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-5">
                        <label for="enterlocation">Enter Location<span class="required">*</span></label>
                        <div class="row">
                            <div class="col-md-10">
                                <input ngx-google-places-autocomplete [options]='options' id="enterlocation" name="address" [(ngModel)]="address" class="form-control" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
                            </div>
                            <div class="col-md-2">
                                <span class="input-group-text" (click)="resetLocation()">
                                    <i style="padding-right: 25px;margin-right: 8px;" class="fa fa-location-arrow fa-lg"></i>
                                </span> 
                            </div>
                        </div>
                               
                        <div class="text-danger" *ngIf="errors.address.error">{{errors.address.message}}<br></div><br>
                        <label for="flat">Flat No/Building Name/Street Name<span class="required">*</span></label>
                        <input type="text" id="flat" name="flat" [(ngModel)]="flat" class="form-control" placeholder="Flat No/Building Name/Street Name">
                        <div class="text-danger" *ngIf="errors.flat.error">{{errors.flat.message}}<br></div><br>
                        <label for="landmark">Landmark (Optional)</label>
                        <input type="text" id="landmark" name="landmark" [(ngModel)]="landmark" class="form-control" placeholder="Landmark (optional)" ><br>
                        <label for="city">City</label>
                        <input type="text" id="landmark" readonly name="city" [(ngModel)]="city" class="form-control" placeholder="Enter City" ><br>
                        <label for="mobile">Mobile Number<span class="required">*</span></label>
                        <input type="text" id="mobile" class="form-control" name="mobile" [(ngModel)]="mobile" placeholder="Enter Mobile Number">
                        <div class="text-danger" *ngIf="errors.mobile.error">{{errors.mobile.message}}<br></div><br>
                        <label for="mail">Mail<span class="required">*</span></label>
                        <input type="text" id="mail" class="form-control" name="mail" [(ngModel)]="mail" placeholder="Enter Mail">
                        <div class="text-danger" *ngIf="errors.mail.error">{{errors.mail.message}}<br></div> <br>
                        <label for="name">Full Name<span class="required">*</span></label>
                        <input type="text" id="name" class="form-control" name="name" [(ngModel)]="name" placeholder="Enter Full Name">
                        <div class="text-danger" *ngIf="errors.name.error">{{errors.name.message}}<br></div><br>
                        <input type="checkbox" name="isdefault" [(ngModel)]="isdefault"> Its my Default Shipping Address<br>
                    </div>
                    <div class="col-md-7">
                        <agm-map [latitude]="lat" [longitude]="lng"  [streetViewControl]=false [zoom]="zoom" (mapClick)="selectLocation($event)">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                            <button>Current Location</button>
                        </agm-map>
                    </div>
                </div>
                 <div class="text-danger" *ngIf="isDeliveyAvailable">
                    Delivery not available in the selected location
                </div>
                
                <br><br>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <div *ngIf="isDeliveyAvailable">
                            <button class="btn btn-block btn-primary disabled"  >Save</button>
                        </div>
                        <div *ngIf="!isDeliveyAvailable">
                            <button class="btn btn-block btn-primary"  (click)="saveAddress()">Save</button>
                        </div>               
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-block btn-secondary" (click)="cancelSave()">Cancel</button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <br>
            </div>
    
            <div *ngIf="!addAddressFlag">
                <h5>Saved Address</h5>
                <div class="row mt-2" *ngFor="let element of availableSavedAddress ;let i= index">
                    <div class="col-md-1">
                        <input type="radio" name="selectedAddress" [checked]="element.id === checkedAddressIndex" (click)="setSelectedAddress(element)">
                    </div>
                    <div class="col-md-10">
                        <div class="card">
                            <div class="card-body">
                                <b>Name :</b><span class="custom-font">  {{element.first_name}} </span><br>
                                <span *ngIf="element.address1 !== ''"><b>Flat No :</b><span class="custom-font"> {{element.address1}} </span><br></span>
                                <span *ngIf="element.landmark !== ''"><b>Landmark :</b><span class="custom-font">  {{element.landmark}} </span><br></span>
                                <b>Obtained Address :</b> <br>
                                <span class="custom-font">&nbsp;&nbsp;{{element.entire_location}} </span><br>
                                <b>Mobile Number :</b><span class="custom-font"> &nbsp; {{element.phone_number}} </span><br>
                                <b>Email Id :</b><span class="custom-font"> {{element.email}} </span>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-block" (click)="editAddress(i)" data-toggle="modal" data-target="#myModal">Edit</button>
                                    </div>
                                    <!-- <div class="col-md-8"></div> -->
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-block" (click)="deleteAddress(i)">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>