import { Component, OnInit,ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NavbarService} from '../navbar/navbar.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertsService } from '../shared/alerts/alerts.service';
import { AppService } from '../app.service';

import { ENDPOINTS, ORG_DETAILS } from '../app.config';

import { CustomerService } from './customer.service';
import { DeliverInfoService } from './deliver-info.service';
import { OrderDetailsService } from './order-details.service';
import { CalculationsService } from './calculations.service';
import { CartService } from '../cart-page/cart-page.service';
import { AssignOrderInitialsService } from './assign-order-initials.service';
import { OrderInitiationService } from './order-initiation.service';
import { OrderUpdationService } from './order-updation.service';
import { AssignOrderUpdatesService } from './assign-order-updates.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import { HeaderService } from '../header/header.service';


import { ShippingAddressService } from '../shipping-address/shipping-address.service';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  providers: [DatePipe]
})
export class CheckoutComponent implements OnInit {

  // VENDOR CODE
  isShown = false;
  shipping = false;
  orderDetails = false;
  initial = true;
  DeliveryInformation = false;
  statusClass = '';
  Initial = 'current' ;
  nextClass = 'next';
  nextOne = '';
  nextTwo = '';
  nextThree = '' ;
  nextFour = '';
  customOptions: OwlOptions = {
    // navContainerClass: 'owl-nav',
    autoplay: true,
    loop: true,
    navSpeed: 300,
    autoplayHoverPause : true,
    // navClass: [ 'owl-prev', 'owl-next' ],
    // navText: [ '<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-left></i>' ],
    navText: ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
  }

  datePickerConfig = {
    format : "yyyy-MM-DD",
    min : moment().format("YYYY-MM-DD")
  }

  @ViewChild('dayPicker') datePicker: DatePickerComponent;

  

  // RELATED PRODUCTS
  slidesStore = [
  ]
  relatedProducts = [];

  // OUR CODE
  // ALERT SPECIFIC THINGS
  deliveryAlert:boolean = false;
  // TAB BUTTONS
  enable_deliveytab = false;
  enable_shippingtab = false;
  enable_ordertab = false;
  enable_paymenttab = false;

  // SPINNERS
  continueSpinner:boolean = false;
  paymentSpinner:boolean = false;
  // COMMON KEYS
    // BOOLEANS
    showalertmsg1: boolean = false;
    OrderMsg: boolean = false;
    showalertmsg3: boolean = false;
    showalertmsg: boolean = false;
    // ALERT SERVICE SETTINGS
    options = {
      autoClose: true,
      keepAfterRouteChange: false
    };
  // CUSTOMER TAB RELATED

    // FORMS
    guestForm:FormGroup;
    displayForm:FormGroup;
    otpLoginForm:FormGroup;
    passwordLoginForm:FormGroup;
    loginForm:FormGroup;

    // FORM VALIDATION KEYS
    validateGuestForm:boolean = false;
    vaidateotpLoginForm:boolean = false;
    validatepasswordLoginForm:boolean = false;
    validateLoginForm:boolean = false;
    existUser:boolean = false;

    // KEYS
    // BOOLEANS
    freezeGuest:boolean = false;
    otpLogin:boolean = true;
    showOtpLogin:boolean = false;
    showPswd: Boolean = false;
    showpasswordSetupForm:boolean = true;

    // INITIALISATIONS
    customerForm:string = "guestForm";
    countryCodes = [
      {'name': "IN +91", 'value': "+91"}
    ]
    loginuserData = {
      mobileNo:"",
      userId:0,
      UserName:""
    }

  // DELIVERY INFORMATION TAB
    // FORMS
    deliveryInformationForm:FormGroup;

    // FORM VALIDATION KEYS
    validatedeliveryInformationForm:boolean = false;

    // KEYS
    // BOOLEANS
    readOnlyDelivery:boolean = false;
    validDistanceAddress:boolean = true;

    // INITIALISATIONS
    preferredTimings:any;
    orderTypes:string = 'delivery';
    deliveryInfo = {
      orderType:"",
      restaurent:"",
      preferredType:"",
      preferredDate:"",
      preferredTime:"",
    }
    selectedRestarent:any="";
    restaurentAddressList = [];
    mindeliveryDate:any = "";

    // TIMING SLOTS
    besantTimeSlots = [];
    velacheryTimeSlots = [];
    filterTimings = [];

  // ORDER DETAILS
    // FORMS
    orderInstructionsForm:FormGroup;

    // VALIDATIONS
    validateorderInstructionsForm = false;

    // CART ITEMS
    cartData:any;
    updatedCartData:any;

    // HANDLING FEE
    handlingFee = {
      displayName:"",
      amount:0,
      type:"",
      value:0
    }

    // TAX RATES
    taxRates = {
      handlingFeeTax:0,
      defaultTax:0,
      shippingTax:0
    }

    // DELIVERY INSTRUCTIONS
    deliveryInstructionsData = {
      orderNotes:"",
      orderInstructions:""
    }

    // DELIVERY CHARGES
    deliveryChrageData = {
      display:"",
      type:"",
      amount:0,
      tax:0
    }

    // ORDER SUMMARY BASIC
    orderdetails = {
      subTotal:0,
      tax:0,
      total:0,
      taxes: 0
    }


    deliveryChrageData1 = {
      display:"",
      type:"",
      amount:0,
      tax:0
    }

    orderSummary = {
      sub_total : 0,
      packing_cost : 0,
      shipping_cost : 0,
      tax : 0,
      discount_amount:0,
      order_total_amount : 0,
      handling_display:"",
      discount_display:""
    }

    // enable_shipping:boolean = true

    // DISCOUNT OBJECT
    discountData = {
      displayName:"",
      percentage:0,
      amount:0,
    }

    // ADDRESS HOLD LABEL
    enableAddress:boolean = true;

    // MESSAGE FOR DISCOUNT
    bannerMessage = "";

  // SHIPPING ADDRESS
  shippingAddress:any="";

  // PAMENTRELATED
  hash1:any;
  formattedDate:any;
  paymentSettingData = {
    storename:"",
    currency:"",
    timezone:"",
    mode:"",
    hash_algorithm:"",
    responseSuccessURL:"",
    responseFailURL:"",
    authenticateTransaction:"",
    PaymentgatewayURL:"",
    MobieNo:"",
    orderId:""
  }

  paymentMethods:any = "";

  paymentInsertionData = {
    customer_id : "",
    order_id : "",
    payment_method : "",
    amount : ""
  }

  // PAYMENT TAB RELATED
  paymentMethodsForm:FormGroup;

  // ACCETANCE TIMING RELATED
  acceptanceTiming: any;
  timeslot = 30;
  date:any;
  today:any;
  slots = [];
  slotRange = [];
  firstSlot = "";

  // PICK INSTORE VALUES
  pickInStoreValue = {
    displayName:"",
    instructions:""
  };

  cashonDeliveryData:any = {};
  pickinstoreData:any = {};
  showPickinStore:boolean = false;
  showCashonDevlivery:boolean = false;
  iciciData:any = {};
  payID:any;

  selectedpaymentMethod = "online"

  // ORDER VALIDATIONS
  orderValidationData = {
    minOrderAmount:0,
    maxOrderQuantity:0,
    minOrderQuantity:0
  }
  orderQuantity:any;
  flatRate:any = 0;

  existingCartProduts = [];
  costAndProductId = {};

  userMobileNumber : string;
  otpResendDuration : number;
  oldNumber : string;
  branchvalue:any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertsService,
    public appService: AppService,
    private localService: LocalStorageService,
    private customerService: CustomerService,
    private deliveryInfoService:DeliverInfoService,
    private orderDetailsService:OrderDetailsService,
    private calculationsService:CalculationsService,
    private shippingAddressService:ShippingAddressService,
    private cartService:CartService,
    private assignOrderInitialsService:AssignOrderInitialsService,
    private orderInitiationService:OrderInitiationService,
    private assignOrderUpdatesService:AssignOrderUpdatesService,
    private orderUpdationService:OrderUpdationService,
    private navbarService: NavbarService,
    private datePipe: DatePipe,
    private headerService: HeaderService

  ) {
    this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.today = moment();
   }

  ngOnInit(): void {
    this.headerService.currentValues.subscribe(data=>
      {
        this.branchvalue=data
        this.branchAddressCard(data)
  
        })
    this.deliveryAlert = true;
    this.OrderMsg = true;
    this.showalertmsg3 = true;
    this.loadExistingCart();
    this.getRelatedProductsAPI();
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.taxRates = this.orderDetailsService.getTaxRatesLocal();
    // PRIORITY CALLS
    this.acceptanceTimings();
    this.getCartItems();
    this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
    this.getHandingFee();
    this.getPaymentMethods();
    this.getOutletData();
    // CUSTOMER TAB FORMS
    this.guestForm = new FormGroup({
      guest_mobile: this.formBuilder.control('',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.required])
    })

    this.otpLoginForm = new FormGroup({
      otp: this.formBuilder.control('',[Validators.required])
    })

    this.passwordLoginForm = new FormGroup({
      otpp: this.formBuilder.control('',[Validators.required]),
      passwordp:this.formBuilder.control('',[Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPswd:this.formBuilder.control('',[Validators.required])
    },{validators:CheckoutComponent.checkPswd})

    this.loginForm = new FormGroup({
      passwordl:this.formBuilder.control('',[Validators.required]),
    })

    this.displayForm = new FormGroup({
      display_email: this.formBuilder.control('',[])
    })

    // DELIVERY INFORMATION TABS
    this.deliveryInformationForm = new FormGroup({
      outlet: this.formBuilder.control('',[Validators.required]),
      orderType: this.formBuilder.control('delivery',[Validators.required]),
      preferredTime: this.formBuilder.control('',[Validators.required]),
      deliverDate: this.formBuilder.control('',[]),
      deliverTime: this.formBuilder.control('',[])
    })

    // ORDER INSTRUCTION FORM
    this.orderInstructionsForm = new FormGroup({
      orderComments: this.formBuilder.control('',[Validators.maxLength(1000)]),
      orderInstructions: this.formBuilder.control('',[Validators.maxLength(1000)]),
    })

    // PAYMENT METHOD TAB RELATED
    this.paymentMethodsForm = new FormGroup(
      {
        paymentMethod: this.formBuilder.control('online',[])
      }
    )

    // TODAY'S DATE
    var dtToday = new Date();
    var month:any = dtToday.getMonth() + 1;
    var day:any = dtToday.getDate();
    var year = dtToday.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    this.mindeliveryDate = year + '-' + month + '-' + day;

    // GET LOCAL STORAGE ITEMS
    this.getLocalStorageItems();
    this.getIntialData();
    // this.getInitialCalculations();
    if(this.orderInitiationService.getOrderId() === ""){
      this.initiateOrder();
    }
  }
  // OUR CODE
  // COMMON
    // GET CART ITEMS
    getCartItems(){
      // CART ITEMS
      let cartdetails:any = this.orderDetailsService.getCartItems()
      if(cartdetails !== ""){
        this.cartData = this.updatedCartData = cartdetails;
      }
      this.orderQuantity = 0;
      this.cartData.forEach(item => {
        this.orderQuantity = item.quantity + this.orderQuantity;
      });
      this.orderValidationData = this.orderDetailsService.getOrderValidations();
    }
    // LOCAL STORAGE CHECK
    getLocalStorageItems(){
      // CUSTOMER DATA
      let customerData = this.customerService.getCustomerData();
      if(customerData !== ''){
        this.enable_deliveytab = true;
        this.Initial = 'current';
        this.nextOne = 'next';
        this.displayForm.controls.display_email.setValue(customerData.Mobile);
        this.assignOrderUpdatesService.updateCustomerdetails(customerData.userId, customerData.Mobile, customerData.userName);
        this.changeCustomForm('displayForm');
      }

      // DELIVERY INFO
      // RESTAURENT DATA
      let restaurentData1:any = this.deliveryInfoService.getRestaurentData();
      if(restaurentData1 !== ""){
        this.restaurentAddressList = restaurentData1;
      }
      let deliverInfo:any = this.deliveryInfoService.getDeliveryInfo();
      // SET THE DELIVERY INFO FORM DATA
      if(deliverInfo !== ''){
        this.acceptanceTiming = JSON.parse(this.localService.get('acceptanceTimings'));
        this.timeslot = Number(this.localService.get("timeslot"));

        this.enable_shippingtab = true;
        this.Initial = 'current';
        this.nextOne = 'next';
        this.nextTwo = 'next';
        this.readOnlyDelivery = true;
        this.deliveryInformationForm.patchValue({
          outlet : Number(deliverInfo.restaurent),
          orderType : deliverInfo.orderType,
          preferredTime  : deliverInfo.preferredType,
          deliverDate  : deliverInfo.preferredDate,
          deliverTime  : deliverInfo.preferredTime
        })
        this.preferredTimings = deliverInfo.preferredType;
        this.deliveryInfo = deliverInfo;
        if(deliverInfo.orderType === "takeaway"){
          this.enable_shippingtab = false;
          this.nextTwo = "";
          this.paymentMethodsForm.controls.paymentMethod.setValue("payinstore");
          this.selectedpaymentMethod = "payinstore";
          this.assignOrderUpdatesService.updatepaymentdetails(this.pickinstoreData, this.payID);
        }
        this.date = deliverInfo.preferredDate;
        // TO GET THE TIME SLOTS
        // this.findSlots();

         // UPDATING ORDER COLLECTION OBJECT WITH DELIVERY INFO
         let deliverInfoObj = {
          delivery_type:"",
          delivery_preferred_time:"",
          delivery_date:"",
          delivery_time:""
        }

        if(deliverInfo.orderType === 'delivery'){
          deliverInfoObj.delivery_type = "Delivery"
        }
        else{
          deliverInfoObj.delivery_type = "Takeaway"
        }

        if(deliverInfo.preferredType === 'now'){
          deliverInfoObj.delivery_preferred_time = "Now";
          deliverInfoObj.delivery_date = new Date().toString();
          deliverInfoObj.delivery_time = new Date().toLocaleTimeString();
        }
        else{
          deliverInfoObj.delivery_preferred_time = "Later";
          deliverInfoObj.delivery_date = this.deliveryInformationForm.value.deliverDate,
          deliverInfoObj.delivery_time = this.deliveryInformationForm.value.deliverTime
        }

        this.assignOrderUpdatesService.getOrderDevliveryInfo(deliverInfoObj)
        this.dateChanged();
        // this.deliveryInformationForm.controls.deliverTime.setValue("");
        if(this.slotRange.length === 0){
          if(this.deliveryInformationForm.value.preferredTime === "now")
          {
            this.deliveryInformationForm.controls.preferredTime.setValue('');
          }
          this.deliveryInformationForm.controls.deliverTime.setValue('');
          this.deliveryInformationForm.controls.deliverDate.setValue('');
        }
        else{
          let slotExist:boolean = false;
          this.slotRange.forEach(slot => {
            if(slot == deliverInfo.preferredTime){
              slotExist = true;
              this.deliveryInformationForm.controls.deliverTime.setValue(deliverInfo.preferredTime);
            }
          });

          if(!slotExist){
            this.deliveryInformationForm.controls.deliverTime.setValue('');
          }
        }

      }
      // GET THE SELECTED RESTAURENT
      let selectedRst = this.deliveryInfoService.getSlectedRestaurentData();
      if(selectedRst !== ''){
        this.selectedRestarent = selectedRst;
        this.assignOrderUpdatesService.updateOutletDetails(this.selectedRestarent);
      }
      // GET THE DISCOUNT DETAILS IF ANY
      if(deliverInfo.preferredDate && deliverInfo.preferredDate !== ""){
        this.getDiscountData(deliverInfo.preferredDate, deliverInfo.preferredTime);
      }

      // GET ADDRESS IF SELECTED
      if(this.shippingAddressService.getSelectedAddress() !== ""){
        this.assignOrderUpdatesService.getShippindDetails(this.shippingAddressService.getSelectedAddress());
        this.enable_ordertab = true;
        this.enable_paymenttab = true;
        this.Initial = 'current';
        this.nextOne = 'next';
        this.nextTwo = 'next';
        this.nextThree = 'next';
        this.nextFour = 'next';
      }

      // ORDER INSTRUCTION DETAILS
      if(this.orderDetailsService.getOrderNotes() !== ''){
        let notes = this.orderDetailsService.getOrderNotes();
        this.orderInstructionsForm.controls.orderComments.setValue(notes);
      }
      if(this.orderDetailsService.getOrderInstrutions() !== ''){
        let ins = this.orderDetailsService.getOrderInstrutions();
        this.orderInstructionsForm.controls.orderInstructions.setValue(ins);
      }
      if(this.enable_shippingtab){
        this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData1);
      }

    }

    // PRELOAD DATA METHODS
    getIntialData(){
      // CALL ONLY IF DATA NOT IN LOCAL
      if(this.deliveryInfoService.getDeliveryInfo() === ""){
        this.getOutletData();
      }
      // this.getOutletData();

    }

  // CUSTOMER TAB
    // FORM CONTROLS
    get f(){
      return this.guestForm.controls;
    }

    get f1(){
      return this.loginForm.controls;
    }

    get f3(){
      return this.displayForm.controls;
    }

    get f4(){
      return this.otpLoginForm.controls;
    }

    get f5(){
      return this.passwordLoginForm.controls;
    }
    // CONFIRM PASSWORD VALIDATIONS
    static checkPswd(group:AbstractControl):{[key:string]:boolean}{
      let pass = group.get('passwordp').value;
      let confirmPass = group.get('confirmPswd').value;
      return pass === confirmPass ? null : { passwdNotMatch: true }
    }

    validpasswd():void{
      if(this.passwordLoginForm.hasError('passwdNotMatch')) {
        this.passwordLoginForm.controls['confirmPswd'].setErrors({'incorrect':true})
      }
    }

    // SHOW AND HIDE PASSWORD
    showPassword() {
      this.showPswd = !this.showPswd;
    }
    branchAddressCard(event){
      this.restaurentAddressList.forEach(element => {
        if(event == element.name){
          this.selectedRestarent=element
          let address_id = element.id;
          if(address_id){
            this.selectedRestarent = this.restaurentAddressList.filter(address => address.id === parseInt(address_id))
            this.selectedRestarent = this.selectedRestarent[0] ;
            this.assignOrderUpdatesService.updateOutletDetails(this.selectedRestarent);
            this.deliveryInfoService.setSelectedRestaurentData(this.selectedRestarent);
            // this.getTimesSlots();
            this.timeslot = Number(this.selectedRestarent.time_slot.substring(0, 2));
            this.localService.set("timeslot", this.timeslot);
            // this.getTimesSlots();
          }
          else{
            this.selectedRestarent = '';
          }
          
        }
      });
      
      }
  
    assignDisplayFormValue(displayVal){
      this.displayForm.controls.display_email.setValue(displayVal);
      this.changeCustomForm('displayForm');
    }

    changeCustomForm(customForm:string){
      this.customerForm = customForm;
      if(customForm === 'guestForm'){
        this.clearForms();
      }
    }

    changeTabClick(tabName){
      this.enable_deliveytab = true;
      if(tabName === "delivery-tab"){
        this.toggleShow(0 , '102')
      }
      if(tabName === "shipping-tab"){
        this.enable_shippingtab = true;
        this.shippingAddressService.updateAdminFormEvent('getData');
      }
      document.getElementById(tabName).click()
    }

    clearForms(){
      this.loginForm.reset();
      this.displayForm.reset();
      this.otpLoginForm.reset();
      this.passwordLoginForm.reset();
      this.vaidateotpLoginForm = false;
      this.validatepasswordLoginForm = false;
      this.validateLoginForm = false;
    }

    resendCode(){
      if(this.otpResendDuration === undefined)
      {
        this.sendOtptoUser(this.guestForm.value.guest_mobile);
        this.alertService.success('OTP has been sent Successfully!', this.options);
      }
      else if(this.otpResendDuration < new Date().getTime())
      {
        this.sendOtptoUser(this.guestForm.value.guest_mobile);
        this.alertService.success('OTP has been sent Successfully!', this.options);
      }
      else
      {
        this.alertService.error("OTP already sent to this number "+ this.userMobileNumber, this.options)
      }
    }

    changeLoginMethod(methodType){
      this.clearForms();
      if(methodType === "otp"){
        this.otpLogin = true;

        if(this.oldNumber !== undefined && this.oldNumber !== this.userMobileNumber)
      {

        this.sendOtptoUser(this.guestForm.value.guest_mobile)
        this.oldNumber = this.guestForm.value.guest_mobile.toString();
      }
      else if(this.otpResendDuration === undefined)
      {

        this.sendOtptoUser(this.guestForm.value.guest_mobile)
      }
      else if(this.otpResendDuration < new Date().getTime())
      {

        this.sendOtptoUser(this.guestForm.value.guest_mobile)
      }

      }
      else if(methodType === "password"){
        this.otpLogin = false;
      }
    }

    editGuest(){
      this.freezeGuest = false;
      this.otpLogin = true;
      this.showOtpLogin = false;
      this.showpasswordSetupForm = true;
      this.continueSpinner = false;
      this.oldNumber = this.guestForm.value.guest_mobile;

      this.clearForms();
    }

    // PROCESSING METHODS
    saveGuest(){
      this.showalertmsg1 = true;
      this.continueSpinner = true;
      if(this.guestForm.valid){
        let mobileNumber = this.guestForm.value.guest_mobile
        this.validateGuestForm = false;
        this.loginuserData.mobileNo = this.guestForm.value.guest_mobile;
        let reqData = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": ORG_DETAILS.USER_ID,
          "extras": {
              "find": {
                  "phone_number": this.guestForm.value.guest_mobile.toString()
              }
          }
      }
        // API CALL TO FIND EXISTING/NEW USER
        this.appService.postApiCall(reqData, ENDPOINTS.GETALL_USERS).subscribe(
          resp => {
            if(resp.success === 1){
              if(resp.result.data.length > 0){
                this.existUser = true;
                if(resp.result.data[0].password){
                  this.showpasswordSetupForm = false;
                }
                this.loginuserData.userId = resp.result.data[0].id;

                //get entered mobile number
                if (resp.result.data[0].password && resp.result.data[0].password !== "") {
                  this.showOtpLogin = true;
                  this.freezeGuest = true;
                  this.otpLogin = false;
                }
                else {
                  if (this.userMobileNumber !== this.guestForm.value.guest_mobile.toString()) {
                    this.sendOtptoUser(mobileNumber);

                  }
                  else if (this.otpResendDuration && this.otpResendDuration < new Date().getTime()) {
                    this.sendOtptoUser(mobileNumber);
                  }
                  else {
                    window.scrollTo(0, 0);
                    this.alertService.error("OTP already sent to this number " + this.userMobileNumber, this.options)
                  }

                  this.showOtpLogin = true;
                  this.freezeGuest = true;
                  this.otpLogin = true;

                }
                this.userMobileNumber = this.guestForm.value.guest_mobile.toString();
              }
              else
              {
                this.sendOtptoUser(mobileNumber);
                this.showOtpLogin = true;
                this.freezeGuest = true;
                this.otpLogin = false;
                this.showpasswordSetupForm = true;
                this.userMobileNumber = mobileNumber.toString();
                this.oldNumber = this.userMobileNumber;
              }

            }
            else{
              this.continueSpinner = false;
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }
          },
          err => {
            this.continueSpinner = false;
            if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })
      }
      else{
        this.validateGuestForm = true;
        this.continueSpinner = false;
      }
    }

    sendOtptoUser(mobileNo){
      this.showalertmsg1 = true;
      let requestData = {
        "data": {
            "otp": {
                "countrycode": "+91",
                "mobile": mobileNo,
                "otppurpose": "login"
            }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.SEND_OTP).subscribe(
        resp => {
          this.continueSpinner = false;
          if(resp.success === 1){
            this.showOtpLogin = true;
            this.freezeGuest = true;
            //set resend duration
            this.otpResendDuration = new Date().getTime() + 5*60*1000;

          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          this.continueSpinner = false;
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }

    OTPLogin(){
      this.showalertmsg1 = true;
      if(this.otpLoginForm.valid){
        this.showalertmsg1 = true;
        this.vaidateotpLoginForm = false;
        let requestData = {
          "data": {
              "otp": {
                  "userotp": this.otpLoginForm.controls.otp.value,
                  "mobile": this.guestForm.controls.guest_mobile.value
              }
          }
      }
        this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
          resp => {
            if(resp.success === 1){
              this.commonOTPLogin(false);
            }
            else{
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }
          },
          err => {
            if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })

      }
      else{
        this.vaidateotpLoginForm = true;
      }

    }

    passwordLogin(){
      this.showalertmsg1 = true;
      if(this.passwordLoginForm.valid){
        this.validatepasswordLoginForm = false;
        this.showalertmsg1 = true;
        let requestData = {
          "data": {
              "otp": {
                  "userotp": this.passwordLoginForm.controls.otpp.value,
                  "mobile": this.guestForm.controls.guest_mobile.value
              }
          }
      }
        this.appService.postApiCall(requestData, ENDPOINTS.VERIFY_OTP).subscribe(
          resp => {
            if(resp.success === 1){
              this.commonOTPLogin(true);
            }
            else{
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }
          },
          err => {
            if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })
      }
      else{
        this.validatepasswordLoginForm = true;
      }
    }

    commonOTPLogin(passwordExist){
      let requestData
      if(this.loginuserData.userId !== 0 && passwordExist){
        requestData = {
          username: this.guestForm.value.guest_mobile.toString(),
          password:this.passwordLoginForm.controls.passwordp.value
        }
      }
      else if (this.loginuserData.userId !== 0 && !passwordExist){
        requestData = {
          username: this.guestForm.value.guest_mobile.toString()
        }
      }
      else{
        requestData = {
          username: this.guestForm.value.guest_mobile.toString(),
          id:this.loginuserData.userId
        }
      }

      this.appService.postApiCall(requestData, ENDPOINTS.OTP_LOGIN).subscribe(
        resp => {
          if(resp.success === 1){
            this.assignDisplayFormValue(this.guestForm.controls.guest_mobile.value);
            this.setUserDetails(resp);
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })

    }

    verifyPasswordLogin(){
      this.showalertmsg1 = true;
      if(this.loginForm.valid){
        this.validateLoginForm = false;
        let requestData = {
          username: this.guestForm.value.guest_mobile.toString(),
          password:this.loginForm.value.passwordl,
          id: this.loginuserData.userId
        }
        this.appService.postApiCall(requestData, ENDPOINTS.LOGIN).subscribe(
          resp => {
            if(resp.success === 1){
              this.assignDisplayFormValue(this.guestForm.controls.guest_mobile.value);
              this.setUserDetails(resp);
            }
            else{
              if(resp.message){
                this.alertService.error(resp.message, this.options)
              }
              else{
                this.alertService.error('Something bad happened; Please try again!', this.options)
              }
            }
          },
          err => {
            if(err.error.statusCode === 401){
              this.alertService.error('Please enter a valid password', this.options)
            }
            else if (err.error.error.message) {
              this.alertService.error(err.error.error.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          })
      }
      else{
        this.validateLoginForm = true;
      }
    }

  // DELIVERY INFORMATION TAB
    // FORM CONTROLS
    get f8(){
      return this.deliveryInformationForm.controls;
    }

    get f9(){
      return this.orderInstructionsForm.controls;
    }

    // NORMAL
    enableEditDeliveyInfo(){
      this.readOnlyDelivery = false;
    }
    getPreferredTimings(event){
      this.preferredTimings = event.target.value;
      if(this.preferredTimings === "now"){
        this.deliveryInformationForm.controls.deliverDate.setValue("");
        this.deliveryInformationForm.controls.deliverTime.setValue("");
        // this.dateChanged();

        this.discountData = {
          displayName:"",
          percentage:0,
          amount:0,
        }
        this.discountData = this.calculationsService.calculateDiscount(this.discountData, this.orderdetails);
        this.updateOrderCalculations();
      }
      // this.changeHandlingFee()
    }

    assignSelectdAddress(event){
      let address_id = event.target.value;
      if(address_id){
        this.selectedRestarent = this.restaurentAddressList.filter(address => address.id === Number(address_id))
        this.selectedRestarent = this.selectedRestarent[0] ;
        this.assignOrderUpdatesService.updateOutletDetails(this.selectedRestarent);
        this.deliveryInfoService.setSelectedRestaurentData(this.selectedRestarent);
        // this.getTimesSlots();
        this.timeslot = Number(this.selectedRestarent.time_slot.substring(0, 2));
        this.localService.set("timeslot", this.timeslot);
        // this.getTimesSlots();
      }
      else{
        this.selectedRestarent = '';
      }
    }

    // PROCESSING METHODS
    getOutletData(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
        resp => {
          if(resp.success === 1){
            this.restaurentAddressList = resp.result.data
            this.deliveryInfoService.setRestaurentData(this.restaurentAddressList);
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }

    saveDeliveryInfo(){
      if(this.deliveryInformationForm.value.preferredTime === 'later'){
        if(!this.deliveryInformationForm.value.deliverDate){
          this.validatedeliveryInformationForm = true;
          this.deliveryInformationForm.controls['deliverDate'].setErrors({'incorrect':true})
        }
        if(!this.deliveryInformationForm.value.deliverTime){
          this.validatedeliveryInformationForm = true;
          this.deliveryInformationForm.controls['deliverTime'].setErrors({'incorrect':true})
        }
      }
      else {
        if(!this.isSoltAvailabalityNow()){
          // console.log("isSoltAvailabalityNow: ", this.isSoltAvailabalityNow());
          this.validatedeliveryInformationForm = true;
          this.deliveryInformationForm.controls['preferredTime'].setErrors({'incorrect':true})
        }
      }
      if(this.deliveryInformationForm.valid){
        if(this.deliveryInformationForm.value.deliverDate !== ''){
          this.getDiscountData(this.deliveryInformationForm.value.deliverDate,this.deliveryInformationForm.value.deliverTime)
        }

        this.validatedeliveryInformationForm = false;
        this.deliveryInfo = {
            orderType:this.deliveryInformationForm.value.orderType,
            restaurent:this.deliveryInformationForm.value.outlet,
            preferredType:this.deliveryInformationForm.value.preferredTime,
            preferredDate:this.deliveryInformationForm.value.deliverDate,
            preferredTime:this.deliveryInformationForm.value.deliverTime
        }

        // UPDATING ORDER COLLECTION OBJECT WITH DELIVERY INFO
        let deliverInfoObj = {
          delivery_type:"",
          delivery_preferred_time:"",
          delivery_date:"",
          delivery_time:""
        }

        if(this.deliveryInformationForm.value.orderType === 'delivery'){
          deliverInfoObj.delivery_type = "Delivery"
        }
        else{
          deliverInfoObj.delivery_type = "Takeaway"
        }

        if(this.deliveryInformationForm.value.preferredTime === 'now'){
          deliverInfoObj.delivery_preferred_time = "Now";
          deliverInfoObj.delivery_date = new Date().toString();
          deliverInfoObj.delivery_time = new Date().toLocaleTimeString();
        }
        else{
          deliverInfoObj.delivery_preferred_time = "Later";
          deliverInfoObj.delivery_date = this.deliveryInformationForm.value.deliverDate,
          deliverInfoObj.delivery_time = this.deliveryInformationForm.value.deliverTime
        }

        this.assignOrderUpdatesService.getOrderDevliveryInfo(deliverInfoObj)


        this.deliveryInfoService.setDeliveryInfo(this.deliveryInfo);
        this.readOnlyDelivery = true;
        this.orderTypes = this.deliveryInformationForm.value.orderType;
        if(this.deliveryInformationForm.value.orderType === 'takeaway'){
          this.paymentMethodsForm.controls.paymentMethod.setValue("payinstore");
          this.selectedpaymentMethod = "payinstore";
          this.assignOrderUpdatesService.updatepaymentdetails(this.pickinstoreData, this.payID);
        }
        else{
          this.paymentMethodsForm.controls.paymentMethod.setValue("online");
          this.selectedpaymentMethod = "online";
        }
        if(this.orderTypes === 'delivery'){
          this.enable_shippingtab = true;
          if(this.shippingAddress !== ""){
            this.shippingAddressService.updateAdminFormEvent("checkDistance");
          }
          this.changeTabClick('shipping-tab');
          this.toggleShow(0 ,'103');
          // DISABLING SHIPPING
          if(this.deliveryChrageData.display !== ""){
            this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData);
          }
        }
        else{
          this.changeTabClick('orderDetails-tab');
          this.toggleShow(0 ,'104');
          this.nextTwo = '';
           // DISABLING SHIPPING
          this.enable_shippingtab = false;
          this.deliveryChrageData1 = {
            display:"",
            type:"",
            amount:0,
            tax:0
          }
          if(this.deliveryChrageData.display !== ""){
            this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData1);
          }

        }
        this.deliveryInfoService.setSelectedRestaurentData(this.selectedRestarent);
      }
      else{
        this.validatedeliveryInformationForm = true;
      }
    }

    acceptanceTimings(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {}
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.ACCEPTANCE_TIMINGS).subscribe(
        resp => {
          if(resp.success === 1){
            // let orderTimings = resp.result.data[0].delivery_acceptance_timing;
            this.acceptanceTiming = resp.result.data[0];
            this.localService.set('acceptanceTimings', JSON.stringify(this.acceptanceTiming));
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options)
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options)
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        })
    }

    // ORDER SUMMARY
    changeTbz(tabId){
      if(tabId === "shipping-tab"){
        this.shippingAddressService.updateAdminFormEvent('getData');
        document.getElementById(tabId).click();
        this.topFunction();
      }
      if(tabId === "shipping-tab1"){
        if(!this.enable_shippingtab){
          document.getElementById('delivery-tab').click();
          this.topFunction();
        }
        else{
          this.shippingAddressService.updateAdminFormEvent('getData');
          document.getElementById('shipping-tab').click();
          this.topFunction();
        }
      }
      else if(tabId === "payment-tab"){
        this.showalertmsg1 = true;
        if(this.orderValidationData.minOrderAmount !== 0){
          if(this.enable_shippingtab && (this.orderValidationData.minOrderAmount > (this.orderdetails.total + this.deliveryChrageData.amount))){
            this.alertService.error("Minimum Order Amount is ₹"+this.orderValidationData.minOrderAmount, this.options)
          }
          else if(!this.enable_shippingtab && (this.orderValidationData.minOrderAmount > this.orderdetails.total)){
            this.alertService.error("Minimum Order Amount is ₹"+this.orderValidationData.minOrderAmount, this.options)
          }
          else{
            this.enable_paymenttab = true;
            this.toggleShow('Order' , '105');
            document.getElementById(tabId).click();
          }
        }
        else{
          this.enable_paymenttab = true;
          document.getElementById(tabId).click();
        }
        this.topFunction();
      }
      else if(tabId === "orderDetails-tab"){
        this.toggleShow('Order' , '104');
        document.getElementById(tabId).click();
        this.topFunction();
      }
      else{
        document.getElementById(tabId).click();
        this.topFunction();
      }
    }

    // PROCESSING METHODS
    // AMOUNT RELATED
    // GET HANDLIGN FEE
    getHandingFee(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_SHIPMENTMETHODS).subscribe(
        resp => {
          let handlingFeeData = resp.result.data[0]
          if(resp.success === 1){
            if(this.orderTypes === 'delivery'){
              this.handlingFee.value = Number(handlingFeeData.flat_rate.handling_fees.handling_fee);
              this.handlingFee.displayName = handlingFeeData.flat_rate.handling_fees.display_name;
              this.handlingFee.type = handlingFeeData.flat_rate.handling_fees.fee_type;
              this.handlingFee.amount = this.calculationsService.calculateHandlingFee(this.handlingFee, this.orderdetails, this.discountData)
              this.getInitialCalculations();
            }
            else if(this.orderTypes === 'takeaway' && handlingFeeData.pickup_in_store){
              if(handlingFeeData.pickup_in_store.handling_fees.handling_fee !== ""){
                this.handlingFee.value = Number(handlingFeeData.pickup_in_store.handling_fees.handling_fee)
                this.handlingFee.displayName = handlingFeeData.pickup_in_store.handling_fees.display_name;
                this.handlingFee.type = handlingFeeData.pickup_in_store.handling_fees.fee_type;
                this.handlingFee.amount = this.calculationsService.calculateHandlingFee(this.handlingFee, this.orderdetails, this.discountData);
                this.getInitialCalculations();
              }
            }
            else{
              this.handlingFee.value = Number(handlingFeeData.flat_rate.handling_fees.handling_fee);
              this.handlingFee.displayName = handlingFeeData.flat_rate.handling_fees.display_name;
              this.handlingFee.type = handlingFeeData.pickup_in_store.handling_fees.fee_type;
              this.handlingFee.amount = this.calculationsService.calculateHandlingFee(this.handlingFee, this.orderdetails, this.discountData)
              this.getInitialCalculations();
            }

            if(handlingFeeData.pickup_in_store){
              this.pickInStoreValue.displayName = handlingFeeData.pickup_in_store.display_name;
              this.pickInStoreValue.instructions = handlingFeeData.pickup_in_store.instruction;
            }

          }
          else{
            this.alertService.error(resp.message, this.options)
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options)
            // console.log(err.error.error.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
    }

    // getTaxRates(){
    //   let requestData = {
    //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //     "user_id": ORG_DETAILS.USER_ID,
    //     "extras": {
    //       "find": {
    //       }
    //     }
    //   }
    //   this.appService.postApiCall(requestData, ENDPOINTS.GET_TAXZONES).subscribe(
    //     resp => {
    //       if(resp.success === 1){
    //         let taxRates = resp.result.data[0];
    //         taxRates.tax_rates[0].tax_class_rates.forEach(element => {
    //           if(element.tax_class_name === 'Handling Fee tax'){
    //             this.taxRates.handlingFeeTax = Number(element.tax_class_rate_percent)
    //           }
    //           else if(element.tax_class_name.search('Default Tax Class') != -1){
    //             this.taxRates.defaultTax = Number(element.tax_class_rate_percent)
    //           }
    //         });
    //       }
    //       else{
    //         this.alertService.error(resp.message, this.options)
    //       }
    //     },
    //     err => {
    //       if (err.error.error.message) {
    //         this.alertService.error(err.error.error.message, this.options)
    //         // console.log(err.error.error.message)
    //       }
    //       else{
    //         console.log('Something bad happened; Please try again!')
    //       }
    //     })
    // }

    getInitialCalculations(){
      this.orderdetails = this.calculationsService.caluculateTotalTax(this.taxRates, this.orderdetails, this.handlingFee, this.discountData);
      this.orderdetails.taxes =  Math.round((this.orderdetails.tax + this.deliveryChrageData.tax + Number.EPSILON) * 100) / 100;

      this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData);
    }

    updateOrderCalculations(){
      this.discountData = this.calculationsService.calculateDiscount(this.discountData, this.orderdetails);
      this.handlingFee.amount = this.calculationsService.calculateHandlingFee(this.handlingFee, this.orderdetails, this.discountData);
      this.orderdetails = this.calculationsService.caluculateTotalTax(this.taxRates, this.orderdetails, this.handlingFee, this.discountData);
      this.orderdetails.taxes =  Math.round((this.orderdetails.tax + this.deliveryChrageData.tax + Number.EPSILON) * 100) / 100;
      this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData);
    }

    updatingCarts(index,operation){
      this.OrderMsg = true;
      if(operation === 'add'){
        if(this.orderValidationData.maxOrderQuantity !== 0){
          if(this.orderValidationData.maxOrderQuantity <= this.orderQuantity){
            this.alertService.error("Maximum quantity to be allowed to Order is "+ this.orderValidationData.maxOrderQuantity, this.options)
          }
          else{
            this.orderQuantity = this.orderQuantity + 1;
            this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity + 1
            this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
            this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
            this.cartService.updateCartItem(this.updatedCartData);
            this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
            this.updateOrderCalculations();
          }
        }
        else{
          this.orderQuantity = this.orderQuantity + 1;
          this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity + 1
          this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
          this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
          this.cartService.updateCartItem(this.updatedCartData);
          this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
          this.updateOrderCalculations();
        }

      }
      else if(operation === 'sub'){
        if(this.orderValidationData.minOrderQuantity !== 0){
          if(this.orderValidationData.minOrderQuantity > this.orderQuantity){
            this.alertService.error("Minimum quantity to be allowed to Order is "+ this.orderValidationData.minOrderQuantity, this.options)
          }
          else{
            if(this.orderQuantity === 1){
              this.alertService.error("Atleast one item should be there to place the order", this.options)
            }
            else{
              this.orderQuantity = this.orderQuantity - 1;
              this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity - 1
              if(this.updatedCartData[index].quantity === 0){
                this.updatedCartData = this.updatedCartData.filter((product,i) => index !== i)
                this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
                this.cartService.updateCartItem(this.updatedCartData);
                this.updateOrderCalculations();
              }
              this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
              this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
              this.cartService.updateCartItem(this.updatedCartData);
              this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
              this.updateOrderCalculations();
              }
          }
        }
        else{
          if(this.orderQuantity === 1){
            this.alertService.error("Atleast one item should be there to place the order", this.options)
          }
          else{
            this.orderQuantity = this.orderQuantity - 1;
            this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity - 1
            if(this.updatedCartData[index].quantity === 0){
              this.updatedCartData = this.updatedCartData.filter((product,i) => index !== i)
              console.log('updated Cart Length', this.updatedCartData.length)
              this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
              this.updateOrderCalculations();
            }
            this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
            this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
            this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
            this.cartService.updateCartItem(this.updatedCartData);
            this.updateOrderCalculations();
            }
        }

      }
      this.loadExistingCart();
    }

    // SHIPPING ADDRESS
    onNotify(event){
      if(event === "remove"){
        this.shippingAddress = "";
        this.deliveryChrageData = {
          display:"",
          type:"",
          amount:0,
          tax:0
        }
        this.deliveryInfoService.removeRestaurentInfo()
        this.deliveryChrageData = this.calculationsService.calculateShippingAmount(this.orderdetails, this.deliveryChrageData, this.taxRates, this.discountData);
        this.orderdetails.taxes =  Math.round((this.orderdetails.tax + this.deliveryChrageData.tax + Number.EPSILON) * 100) / 100;
        this.assignOrderUpdatesService.getShippingMethods("Flat Rate", this.deliveryChrageData.amount);
        this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData)
      }
      else if(event === 'back'){
        this.changeTbz('delivery-tab');
      }
      else if(event.Type === "Address"){
        this.validDistanceAddress = true;
        this.shippingAddress = event.value;
        this.assignOrderUpdatesService.getShippindDetails(this.shippingAddress);
      }
      else if(event.Type === "DeliveryCharge"){
        this.validDistanceAddress = true;
        if(event.value.type === ""){
          this.deliveryChrageData = event.value;
          this.deliveryChrageData = this.calculationsService.calculateShippingAmount(this.orderdetails, this.deliveryChrageData, this.taxRates, this.discountData);
          this.orderdetails.taxes =  Math.round((this.orderdetails.tax + this.deliveryChrageData.tax + Number.EPSILON) * 100) / 100;
          this.assignOrderUpdatesService.getShippingMethods("Flat Rate", this.deliveryChrageData.amount);
          this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData)
        }
        else{
          this.flatRate = event.value.amount;
          this.calculateFlatShippingCharges(this.updatedCartData, event.value, this.taxRates);
        }
      }
      else if(event === "pass"){
        this.validDistanceAddress = true;
        this.changeTbz('orderDetails-tab');
        this.toggleShow('Order' , '104');
        this.enable_ordertab = true;
      }
      else if(event.Type === "eraseAddress"){
        this.shippingAddress = event.value;
        this.validDistanceAddress = false;
        // this.deliveryChrageData = {
        //   display:"",
        //   type:"",
        //   amount:0,
        //   tax:0
        // }
        // this.deliveryInfoService.removeRestaurentInfo()
        // this.deliveryChrageData = this.calculationsService.calculateShippingAmount(this.orderdetails, this.deliveryChrageData, this.taxRates, this.discountData);
        // this.orderdetails.taxes =  Math.round((this.orderdetails.tax + this.deliveryChrageData.tax + Number.EPSILON) * 100) / 100;
        // this.assignOrderUpdatesService.getShippingMethods("Flat Rate", this.deliveryChrageData.amount);
        // this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData)
      }
    }

    // PAYMENT RELATED
    processOrder(){
      if(!this.validateDeliveryTimings() && !this.validateAmount()){
        if(this.orderTypes === 'takeaway'){
          this.assignOrderUpdatesService.getShippingMethods(this.pickInStoreValue.displayName, "");
          this.assignOrderUpdatesService.removeShippingObj();
        }
        let orderSummaryDetailsObj
        if(this.enable_shippingtab){
          orderSummaryDetailsObj = this.calculationsService.calculatederivedCost(this.orderdetails, this.deliveryChrageData, this.taxRates, this.discountData, this.handlingFee, this.updatedCartData);
          this.assignOrderUpdatesService.getderivedCostDetails(orderSummaryDetailsObj);
          this.orderSummary.sub_total = this.orderdetails.subTotal;
          this.orderSummary.tax = this.orderdetails.taxes;
          this.orderSummary.packing_cost = this.handlingFee.amount;
          this.orderSummary.shipping_cost =  this.deliveryChrageData.amount;
          this.orderSummary.discount_amount = this.discountData.amount;
          this.orderSummary.order_total_amount = this.orderdetails.total + this.deliveryChrageData.amount;
          this.orderSummary.discount_display = this.discountData.displayName;
          this.orderSummary.handling_display = this.handlingFee.displayName
          this.assignOrderUpdatesService.getOrderSummary(this.orderSummary);
        }
        else{
          orderSummaryDetailsObj = this.calculationsService.calculatederivedCost(this.orderdetails, this.deliveryChrageData1, this.taxRates, this.discountData, this.handlingFee, this.updatedCartData);
          this.assignOrderUpdatesService.getderivedCostDetails(orderSummaryDetailsObj);
          this.orderSummary.sub_total = this.orderdetails.subTotal;
          this.orderSummary.tax = this.orderdetails.taxes;
          this.orderSummary.packing_cost = this.handlingFee.amount;
          this.orderSummary.shipping_cost =  this.deliveryChrageData1.amount;
          this.orderSummary.discount_amount = this.discountData.amount;
          this.orderSummary.discount_display = this.discountData.displayName;
          this.orderSummary.handling_display = this.handlingFee.displayName
          this.orderSummary.order_total_amount = this.orderdetails.total + this.deliveryChrageData1.amount;
          this.assignOrderUpdatesService.getOrderSummary(this.orderSummary);
        }

        this.assignOrderUpdatesService.getItemDetails();
        this.assignOrderUpdatesService.getProductData(this.discountData);
        this.paymentSpinner = true;
        this.paymentSettingData.MobieNo = this.customerService.getCustomerData().Mobile;
        this.paymentSettingData.orderId = this.orderInitiationService.getOrderId().toString();
        if(this.orderInstructionsForm.valid){
          this.validateorderInstructionsForm = false;
          this.deliveryInstructionsData.orderNotes = this.orderInstructionsForm.value.orderComments
          this.deliveryInstructionsData.orderNotes = this.orderInstructionsForm.value.orderInstructions;
          this.assignOrderUpdatesService.getDeliverydetails(this.orderInstructionsForm.value.orderInstructions, this.orderInstructionsForm.value.orderComments);
          this.orderDetailsService.setOrderNotes(this.orderInstructionsForm.value.orderComments);
          this.orderDetailsService.setOrderInstrutions(this.orderInstructionsForm.value.orderInstructions);
          if(this.selectedpaymentMethod === "online"){
            this.getICICIHashCode();
          }
          else if(this.selectedpaymentMethod === "payinstore"){
            this.assignOrderUpdatesService.updatepaymentdetails(this.pickinstoreData, this.payID);
            this.updateOrder()
          }
          else if(this.selectedpaymentMethod === "cod"){
            this.assignOrderUpdatesService.updatepaymentdetails(this.cashonDeliveryData, this.payID);
            this.updateOrder()
          }
        }
        else{
          this.validateorderInstructionsForm = true;
        }
      }
    }

    getPaymentMethods(){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_PAYMENTMETHODS).subscribe(
        resp => {
          if(resp.success === 1){
            this.paymentMethods = resp.result.data[0].online_methods.icici.settings.payment_integrator_reference_id
            this.getPaymentSettings(this.paymentMethods);
            this.payID = resp.result.data[0].id;
            this.assignOrderUpdatesService.updatepaymentdetails(resp.result.data[0].online_methods.icici,resp.result.data[0].id);
            this.iciciData = resp.result.data[0].online_methods.icici;
            if(resp.result.data[0].offline_methods){
              if(resp.result.data[0].offline_methods.pay_in_store){
                this.showPickinStore = true;
                this.pickinstoreData = resp.result.data[0].offline_methods.pay_in_store
              }
              if(resp.result.data[0].offline_methods.cash_on_delivery){
                this.showCashonDevlivery = true;
                this.cashonDeliveryData = resp.result.data[0].offline_methods.cash_on_delivery;
              }
            }
          }
        },
        err => {
          if (err.error.error.message) {
            // console.log(err.error.error.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
    }

    getPaymentSettings(paymentMethod){
      let requestData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
          "find": {
            "id": paymentMethod
          }
        }
      }
      this.appService.postApiCall(requestData, ENDPOINTS.GET_PAYMENTSETTINGS).subscribe(
        resp => {
          let settingsData = resp.result.data[0].attributes;
          settingsData.forEach(element => {
            if(element.key === 'PaymentgatewayURL'){
              this.paymentSettingData.PaymentgatewayURL = element.value
            }
            if(element.key === 'storename'){
              this.paymentSettingData.storename = element.value
            }
            if(element.key === 'currency'){
              this.paymentSettingData.currency = element.value
            }
            if(element.key === 'timezone'){
              this.paymentSettingData.timezone = element.value
            }
            if(element.key === 'mode'){
              this.paymentSettingData.mode = element.value
            }
            if(element.key === 'hash_algorithm'){
              this.paymentSettingData.hash_algorithm = element.value
            }
            if(element.key === 'responseSuccessURL'){
              this.paymentSettingData.responseSuccessURL = element.value
            }
            if(element.key === 'responseFailURL'){
              this.paymentSettingData.responseFailURL = element.value
            }
            if(element.key === 'authenticateTransaction'){
              this.paymentSettingData.authenticateTransaction = element.value
            }
          });
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
    }

    getICICIHashCode(){
      const datepipe: DatePipe = new DatePipe('en-IN')
      let formattedDate = datepipe.transform(new Date(), 'yyyy:MM:dd-HH:mm:ss');
      this.formattedDate = formattedDate;
      let totalAmount;
      if(!this.enable_shippingtab){
        totalAmount = this.orderdetails.total
      }
      if(this.enable_shippingtab){
        totalAmount = this.orderdetails.total + this.deliveryChrageData.amount
      }
      let requestData = {
        "txndatetime":formattedDate,
        "chargetotal":totalAmount.toString()
      }
      this.appService.postApiCall(requestData, ENDPOINTS.ICICPAYMENTHASH).subscribe(
        resp => {
          this.hash1 = resp.hash;
          this.insertPaymentData();
        },
        err => {
          this.paymentSpinner = false;
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
    }

    insertPaymentData(){
      this.paymentInsertionData.customer_id = this.customerService.getCustomerData().userId.toString();
      this.paymentInsertionData.order_id = this.orderInitiationService.getOrderId().toString(),
      this.paymentInsertionData.payment_method = this.paymentMethods,
      this.paymentInsertionData.amount = (this.orderdetails.total + this.deliveryChrageData.amount).toString();
      this.appService.postApiCall(this.paymentInsertionData, ENDPOINTS.PAYMENTDATAINSERTION).subscribe(
        resp => {
          if(resp.success === 1){
            this.paymentSpinner = false;
            let orderUpdateoBJ = {
              "domain_name": ORG_DETAILS.DOMAIN_NAME,
              "user_id": ORG_DETAILS.USER_ID,
              "payload": {
                  "order": this.orderUpdationService.orderUpdationObject,
                  "new_value": this.orderUpdationService.new_value,
                  "old_value": this.orderUpdationService.old_value
              },
              "extras": {
                  "find": {
                      "id": Number(this.orderInitiationService.getOrderId())
                  }
              }
          }

          // console.log("Finale Order Object",JSON.stringify(orderUpdateoBJ))

          this.appService.postApiCall(orderUpdateoBJ, ENDPOINTS.ORDER_UPDATION).subscribe(
            resp => {
              if(resp.success === 1){
                document.getElementById('icicibtn').click()
              }
              else{
                if(resp.message){
                  this.alertService.error(resp.message, this.options);
                }
                else{
                  this.alertService.error('Something bad happened; Please try again!', this.options);
                }
              }
            },
            err => {
              if (err.error.error.message) {
                this.alertService.error(err.error.error.message, this.options);
              }
              else{
                console.log('Something bad happened; Please try again!')
              }
            })
            // console.log("Finale Order Object",JSON.stringify(orderUpdateoBJ))
            // document.getElementById('icicibtn').click();
          }
        },
        err => {
          this.paymentSpinner = false;
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
    }

  // DISCOUNT DATA
  getDiscountData(ddate, dtime){
    dtime = dtime.split(" am - ").join(":AM-");
    dtime = dtime.split(" am").join(":AM");
    dtime = dtime.split(" pm - ").join(":PM-");
    dtime = dtime.split(" pm").join(":PM");
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {},
        "details": {
          "deliver_date": ddate,
          "deliver_time": dtime
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_DISCOUNTS).subscribe(
      resp => {
        if(resp.success === 1){
          if(resp.result.data !== 0){
            this.discountData.displayName = resp.result.data.promotion_type;
            this.discountData.percentage = resp.result.data.discount_percentage;
            this.bannerMessage = resp.result.data.congratulations_banner_message;
            this.discountData = this.calculationsService.calculateDiscount(this.discountData, this.orderdetails);
            this.updateOrderCalculations();
          }
          else if(resp.result.data === 0){
            this.discountData = {
              displayName:"",
              percentage:0,
              amount:0,
            }
            this.discountData = this.calculationsService.calculateDiscount(this.discountData, this.orderdetails);
            this.updateOrderCalculations();
          }
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  getOrderNotes(type){
    if(type === 'notes'){
      this.orderDetailsService.setOrderNotes(this.orderInstructionsForm.value.orderComments);
    }
    else{
      this.orderDetailsService.setOrderInstrutions(this.orderInstructionsForm.value.orderInstructions);
    }
  }

  // VENDOR CODE
  toggleShow(data , target) {

    if (data == 'Shipping') {

      this.initial = false;
      this.orderDetails = false;
      this.shipping = true;
      this.DeliveryInformation = false;
    } else if (data == 'Order') {
      this.initial = false;
      this.shipping = false;
      this.orderDetails = true;
      this.DeliveryInformation = true;
    } else {
      this.initial = true;
      this.shipping = false;
      this.orderDetails = false;
      this.DeliveryInformation = false;
    }
    if (target === '102' && this.Initial == 'current') {
      this.Initial = 'next';
      this.nextOne = 'current';
    } else if (target === '103' && this.Initial == 'next' &&  this.nextOne == 'current') {
      this.shippingAddressService.updateAdminFormEvent('getData');
      this.nextOne = 'next';
      this.nextTwo = 'current';
    } else if (target === '104' && this.Initial == 'next' &&  this.nextOne == 'next' &&  this.nextTwo =='current') {
      this.nextOne = 'next';
      this.nextTwo = 'next';
      this.nextThree = 'current';
    }  else if (target === '105' && this.Initial == 'next' &&  this.nextOne == 'next' &&  this.nextTwo == 'next' &&  this.nextThree == 'current') {
      this.nextOne = 'next';
      this.nextTwo = 'next';
      this.nextThree = 'next';
      this.nextFour = 'current';
    }

    if(target === '102'){
      this.nextOne = 'current';
      if(this.Initial !== ''){
        this.Initial = 'next'
      }
      if(this.nextThree !== ''){
        this.nextThree = 'next'
      }
      if(this.nextTwo !== ''){
        this.nextTwo = 'next'
      }
      if(this.nextFour !== ''){
        this.nextFour = 'next'
      }

    }
    else if(target === '103'){
      this.nextTwo = 'current';
      if(this.Initial !== ''){
        this.Initial = 'next'
      }
      if(this.nextThree !== ''){
        this.nextThree = 'next'
      }
      if(this.nextOne !== ''){
        this.nextOne = 'next'
      }
      if(this.nextFour !== ''){
        this.nextFour = 'next'
      }

    }
    else if(target === '104'){
      this.nextThree = 'current';
      if(this.Initial !== ''){
        this.Initial = 'next'
      }
      if(this.nextTwo !== ''){
        this.nextTwo = 'next'
      }
      if(this.nextOne !== ''){
        this.nextOne = 'next'
      }
      if(this.nextFour !== ''){
        this.nextFour = 'next'
      }
    }
    else if(target === '101'){
      this.Initial = 'current';
      if(this.nextThree !== ''){
        this.nextThree = 'next'
      }
      if(this.nextTwo !== ''){
        this.nextTwo = 'next'
      }
      if(this.nextOne !== ''){
        this.nextOne = 'next'
      }
      if(this.nextFour !== ''){
        this.nextFour = 'next'
      }
    }
    else if(target === '105'){
      this.nextFour = 'current';
      if(this.nextThree !== ''){
        this.nextThree = 'next'
      }
      if(this.nextTwo !== ''){
        this.nextTwo = 'next'
      }
      if(this.nextOne !== ''){
        this.nextOne = 'next'
      }
      if(this.Initial !== ''){
        this.Initial = 'next'
      }
    }
  }

  // ORDER RELATED
  initiateOrder(){
    this.assignOrderInitialsService.getIntialOrderValues();
    let reqData =
    {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "order": this.orderInitiationService.orderInitialObject,
        "new_value":this.orderInitiationService.new_value,
        "old_value":this.orderInitiationService.old_value,
      },
      "extras": {
        "find": {
          "id": ""
        }
      }
    }

    this.appService.postApiCall(reqData, ENDPOINTS.ORDER_UPDATION).subscribe(
      resp => {
        if(resp.success === 1){
          let orderData = resp.result.data[0].id;
          this.orderInitiationService.setOrderId(orderData)
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options);
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options);
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
    }

    // UPDATE ORDER
    updateOrder(){
      this.orderUpdationService.orderUpdationObject.status = this.orderUpdationService.new_value1.status;
      this.orderUpdationService.orderUpdationObject.system_label = this.orderUpdationService.new_value1.system_label;
      this.orderUpdationService.orderUpdationObject.status_id = this.orderUpdationService.confirmationId;
      let orderUpdateoBJ = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "payload": {
            "order": this.orderUpdationService.orderUpdationObject,
            "new_value": this.orderUpdationService.new_value1,
            "old_value": this.orderUpdationService.old_value
        },
        "extras": {
            "find": {
                "id": Number(this.orderInitiationService.getOrderId())
            }
        }
      }
      this.appService.postApiCall(orderUpdateoBJ, ENDPOINTS.ORDER_UPDATION).subscribe(
        resp => {
          if(resp.success === 1){
            this.router.navigate([`/confirmation`], {queryParams: {id: this.orderInitiationService.getOrderId()}, relativeTo:this.route,  skipLocationChange: false });
          }
          else{
            if(resp.message){
              this.alertService.error(resp.message, this.options);
            }
            else{
              this.alertService.error('Something bad happened; Please try again!', this.options);
            }
          }
        },
        err => {
          if (err.error.error.message) {
            this.alertService.error(err.error.error.message, this.options);
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
    }

  dateChanged() {
    this.deliveryInformationForm.controls.deliverTime.setValue('');
    this.slots = [];
    this.slotRange = [];
    if(this.deliveryInformationForm.value.preferredTime == "later"){
      this.date = this.deliveryInformationForm.value.deliverDate;
    }
    else{
      this.date = moment().format("YYYY-MM-DD");
    }
    this.today = moment();
    this.findSlots();
  }

  findSlots() {
    // console.log("find slots", this.acceptanceTiming, this.date, this.today);
    if (this.date == this.today.format("YYYY-MM-DD")) {
      this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
        this.slots = [];
        var from = moment(this.date + " " + shift.from,"YYYY-MM-DD hh:mm A");
        var to = moment(this.date + " " + shift.to,"YYYY-MM-DD hh:mm A");
        if (this.today >= from && this.today <= to) {
          while (from <= to) {
            if (from >= this.today) {
              // console.log(from.format("HH:mm"));
              this.createTimeSlotRange(from.format("hh:mm a"));
              this.slots.push(from.format("hh:mm a"));
            }
            from = from.add(this.timeslot, 'minutes');
          }
        }
        else if (this.today <= to) {
          while (from <= to) {
            // console.log(from.format("HH:mm"));
            this.createTimeSlotRange(from.format("hh:mm a"));
            this.slots.push(from.format("hh:mm a"));
            from = from.add(this.timeslot, 'minutes');
          }
        }
      });
    }
    else if (moment(this.date + " 00:00","YYYY-MM-DD hh:mm") >= this.today){
      this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
        this.slots = [];
        var from = moment(this.date + " " + shift.from,"YYYY-MM-DD hh:mm A");
        var to = moment(this.date + " " + shift.to,"YYYY-MM-DD hh:mm A");
        console.log("from - ", from)
        console.log("to - ", to)
        while (from <= to) {
            console.log(from.format("HH:mm"));
            this.createTimeSlotRange(from.format("hh:mm a"));
            this.slots.push(from.format("hh:mm a"));
            from = from.add(this.timeslot, 'minutes');
        }
      });
    }
    else{
      console.log("invalid dates");
    }
  }

  isSoltAvailabalityNow(){
    if (this.date == this.today.format("YYYY-MM-DD")) {
      var flag = false;
      this.acceptanceTiming.delivery_acceptance_timing.forEach(shift => {
        var from = moment(this.date + " " + shift.from,"YYYY-MM-DD hh:mm A");
        var to = moment(this.date + " " + shift.to,"YYYY-MM-DD hh:mm A");
        if (this.today >= from && this.today <= to && !flag) {
          flag = true;
        }
      });
      return flag;
    }
    else {
      return false;
    }
  }

  createTimeSlotRange(slot){
    if (this.slots.length > 0){
      let slotString = this.slots[this.slots.length - 1]
      // console.log(slotString)
      this.slotRange.push(slotString)
    }
    else{
      this.firstSlot = slot;
    }
  }

  // RELATED PRODUCTS API
  getRelatedProductsAPI(){
    let cartItems = this.orderDetailsService.getCartItems();
    let categories = [];
    let productIds = []
    cartItems.forEach(element => {
      categories.push(element.category);
      productIds.push(element.product_id);
    });

    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "category": categories,
          "productid": productIds
        },
        "pagination": false
      }
    }

    this.appService.postApiCall(requestData, ENDPOINTS.RELATEDPRODUCT_API).subscribe(
      resp => {
        if(resp.success === 1){
          let relatedProducts = resp.result.data;
          this.relatedProducts = relatedProducts
          if(relatedProducts.length > 0){
            relatedProducts.forEach(relatedP => {
              let slideObj = {
                id:0,
                img:'',
                alt:'',
                item:'',
                type: '',
                backtitle: '',
                backpara:'',
                backtype: '',
                price:'',
                points:'5 Points',
                button:'ADD TO CART'
              }
              slideObj.id = relatedP.id
              slideObj.img = relatedP.assets[0].image_source
              slideObj.alt = relatedP.name
              slideObj.item = relatedP.name
              slideObj.type = relatedP.parentcategories[0].name,
              slideObj.backtitle = relatedP.name
              slideObj.backpara = relatedP.description.description
              slideObj.backtype = relatedP.parentcategories[0].name,
              slideObj.price = relatedP.pricing.raw.price
              this.slidesStore.push(slideObj);
            });
          }

          if(this.slidesStore.length === 2){
            this.slidesStore.push('');
            this.slidesStore.push('');
          }
          else if(this.slidesStore.length === 1){
            this.slidesStore.push('');
            this.slidesStore.push('');
            this.slidesStore.push('');
          }
          else if(this.slidesStore.length === 3){
            this.slidesStore.push('');
          }

        }
        else{
          if(resp.message){
            // this.alertService.error(resp.message, this.options);
          }
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options);
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  updatetoCart(prodId){
    this.showalertmsg3 = true;
    if(this.orderQuantity === this.orderValidationData.maxOrderQuantity){
      this.alertService.error("Maximum quantity to be allowed to Order is "+ this.orderValidationData.maxOrderQuantity, this.options)
    }
    else{
      let product;
      this.relatedProducts.forEach(element => {
        if(element.id === prodId){
          this.cartService.addToCart(element);
          this.cartData = this.updatedCartData = this.orderDetailsService.getCartItems();
          this.getCartItems();
          this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
          this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
          this.updateOrderCalculations();
        }
      });
    }
    this.loadExistingCart();
  }

  setUserDetails(loginObj){
    this.customerService.setCustomerData(this.loginuserData.mobileNo, loginObj.user.id)
    this.assignOrderUpdatesService.updateCustomerdetails(loginObj.user.id,this.loginuserData.mobileNo, loginObj.user.username)
    this.localService.set('domainName', loginObj.user.domain_name);
    if(loginObj.user.username === "" || loginObj.user.username === "undefined undefined" || !loginObj.user.username){
      this.localService.set('UserName', 'New User');
      this.appService.userName = 'New User';
    }
    else{
      this.localService.set('UserName', loginObj.user.username);
      this.appService.userName = loginObj.user.username;
    }
    this.localService.set('UserId', loginObj.user.id);
    this.appService.loggedin_user = true;
    this.appService.userLoggedIn = true;
  }

  // PAYMENT METHOD RELATED
  getPaymentMethod(pmethod){
   this.selectedpaymentMethod = pmethod;
   if(this.selectedpaymentMethod === "cod"){
    this.assignOrderUpdatesService.updatepaymentdetails(this.cashonDeliveryData, this.payID);
   }
   else if(this.selectedpaymentMethod === "payinstore"){
    this.assignOrderUpdatesService.updatepaymentdetails(this.pickinstoreData, this.payID);
   }
   else if(this.selectedpaymentMethod === "online"){
    this.assignOrderUpdatesService.updatepaymentdetails(this.iciciData, this.payID);
   }
  }

  validateDeliveryTimings(){
    if(this.deliveryInformationForm.value.preferredTime === "")
    {
      this.changeTbz('delivery-tab')
      this.toggleShow(0 , '102')
      this.alertService.error('Please select preferred time', this.options);
      return true;
    }
    else if(this.deliveryInformationForm.value.preferredTime === 'later'){
      if(this.deliveryInformationForm.value.deliverDate === "" || this.deliveryInformationForm.value.deliverTime === ""){
        this.changeTbz('delivery-tab')
        this.toggleShow(0 , '102')
        this.alertService.error('Please select the Data and time', this.options);
        return true;
      }
      else if((this.orderTypes === 'delivery' && !this.validDistanceAddress) || (this.orderTypes === 'delivery' && this.shippingAddress === "")){
        this.changeTbz('shipping-tab')
        this.toggleShow(0 , '103')
        return true;
      }
      else{
        return false;
      }
    }
    else if(this.orderTypes === 'delivery' && this.shippingAddress === ""){
      this.changeTbz('shipping-tab')
      this.toggleShow(0 , '103')
      this.alertService.error("Delivery is not available for the selected address having longer distance. Please choose another address", this.options)
      return true;
    }
    
    else{
      return false;
    }

  }

  validateAmount(){
    if(this.orderValidationData.minOrderAmount !== 0){
      if(this.enable_shippingtab && (this.orderValidationData.minOrderAmount > (this.orderdetails.total + this.deliveryChrageData.amount))){
        this.changeTbz('orderDetails-tab')
        this.toggleShow('Order' , '104')
        this.alertService.error("Minimum Order Amount is ₹"+this.orderValidationData.minOrderAmount, this.options)
        return true;

      }
      else if(!this.enable_shippingtab && (this.orderValidationData.minOrderAmount > this.orderdetails.total)){
        this.changeTbz('orderDetails-tab')
        this.toggleShow('Order' , '104')
        this.alertService.error("Minimum Order Amount is ₹"+this.orderValidationData.minOrderAmount, this.options)
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }

  calculateFlatShippingCharges(cartItems, flatRateInfo, taxObj){
    let flatRates = flatRateInfo;
    if(flatRateInfo.type === "Per Item"){
      flatRates.amount = cartItems.length * this.flatRate;
    }
    else if(flatRateInfo.type === "Per Order"){
      flatRates.amount = flatRates.amount
    }
    this.deliveryChrageData = flatRates;
    let taxd = (taxObj.shippingTax * this.deliveryChrageData.amount)/100;
    this.deliveryChrageData.tax = Math.round((taxd + Number.EPSILON) * 100) / 100
    this.orderdetails.taxes =  Math.round((this.orderdetails.tax + this.deliveryChrageData.tax + Number.EPSILON) * 100) / 100;
    this.assignOrderUpdatesService.getShippingMethods("Flat Rate", this.deliveryChrageData.amount);
    this.orderdetails.total = this.calculationsService.calculateTotal(this.orderdetails, this.discountData, this.handlingFee, this.deliveryChrageData)
  }

  //FOR RELATED PRODUCTS
  loadExistingCart()
  {
    this.existingCartProduts= [];
    this.costAndProductId = {};
    this.appService.cartProducts.results.forEach(value=>
      {
        this.existingCartProduts.push(value.product_id);
        this.costAndProductId[value.product_id] = value.quantity
      })
  }

  //UPDATE RELATED PRODUCTS
  updateRelatedProducts(product_id,operation)
  {
    let index = -1;

    this.appService.cartProducts.results.forEach((value,inx)=>
    {
      if(value.product_id === product_id)
      {
        index = inx;
        return
      }
    })


    this.OrderMsg = true;
      if(operation === 'add'){
        if(this.orderValidationData.maxOrderQuantity !== 0){
          if(this.orderValidationData.maxOrderQuantity <= this.orderQuantity){
            this.alertService.error("Maximum quantity to be allowed to Order is "+ this.orderValidationData.maxOrderQuantity, this.options)
          }
          else{
            this.orderQuantity = this.orderQuantity + 1;
            this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity + 1
            this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
            this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
            this.cartService.updateCartItem(this.updatedCartData);
            this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
            this.updateOrderCalculations();
          }
        }
        else{
          this.orderQuantity = this.orderQuantity + 1;
          this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity + 1
          this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
          this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
          this.cartService.updateCartItem(this.updatedCartData);
          this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
          this.updateOrderCalculations();
        }

      }
      else if(operation === 'sub'){
        if(this.orderValidationData.minOrderQuantity !== 0){
          if(this.orderValidationData.minOrderQuantity > this.orderQuantity){
            this.alertService.error("Minimum quantity to be allowed to Order is "+ this.orderValidationData.minOrderQuantity, this.options)
          }
          else{
            if(this.orderQuantity === 1){
              this.alertService.error("Atleast one item should be there to place the order", this.options)
            }
            else{
              this.orderQuantity = this.orderQuantity - 1;

              if(this.updatedCartData[index].quantity === 1)
              {
                this.updatedCartData.splice(index,1);
                this.cartService.updateCartItem(this.updatedCartData);
                this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
                this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
                this.updateOrderCalculations();
                this.loadExistingCart();
                return
              }
              this.updatedCartData[index].quantity =  this.updatedCartData[index].quantity - 1
              if(this.updatedCartData[index].quantity === 0){
                this.updatedCartData = this.updatedCartData.filter((product,i) => index !== i)
                this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
                this.updateOrderCalculations();
              }
              this.orderdetails.subTotal = this.calculationsService.calculateSubtotal(this.updatedCartData);
              this.updatedCartData[index].sub_total = Number(this.updatedCartData[index].quantity) * Number(this.updatedCartData[index].cost)
              this.cartService.updateCartItem(this.updatedCartData);
              this.calculateFlatShippingCharges(this.updatedCartData, this.deliveryChrageData, this.taxRates);
              this.updateOrderCalculations();
              }
          }
        }
      }

      this.loadExistingCart();
  }

  topFunction() {
    window.scrollTo({top: 70, behavior: 'smooth'});
  }

}

