import { Component, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, Form } from '@angular/forms';

import { CheckoutShippingService } from './checkout-shipping.service';
import { CheckoutShippingApiService } from './checkout-shipping-api.service';
import { ENDPOINTS,ORG_DETAILS } from '../../app.config';
import { CheckoutApiService } from '../checkout-api.service';
import { OrderSummaryInitialService } from '../order-summary/order-summary-initial.service';
import { CheckoutShippingStorageService } from './checkout-shipping-storage.service';
import { CheckoutShippingEventsService } from './checkout-shipping-events.service';
import { CheckoutShippingOrderService } from './checkout-shipping-order.service';
import { CheckoutLocalStorageService } from '../checkout-local-storage.service';

const numPattern = /^[0-9]*$/;
const emailPattern = /^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i
const namePattern = /^[a-zA-Z\s.]*$/;


@Component({
  selector: 'app-checkout-shipping',
  templateUrl: './checkout-shipping.component.html'
})
export class CheckoutShippingComponent implements OnInit {

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  latitude;
  longitude;
  zoom;
  geocoder;

  PICKDROP_LABEL = "PICKDROP";
  FLATRATE_LABEL = "FLATRATE";

  validAddress:any = "";
  options = {
              types: [],
              componentRestrictions: { country: 'IN' },
            };
  customerAddresses = [];
  validateShippingForm:boolean = false;
  location_bloked:boolean = false;
  is_deliverable:boolean = true;
  addingAddress:boolean = false;
  editAddress:boolean = false;
  sameAsShipping:boolean = true;
  readOnlyAddress:boolean = false;
  billingAddressEnable:boolean = false;
  editAddressId:any;
  selectedAddress:any = {};
  selectedAddressId:any;
  selectedBillingAddress:any;
  selectedBillingAddressId:any;
  pickupLatitude:any;
  pickupLongitude:any;
  shippingForm: FormGroup;
  is_exist_deliverable:boolean = false;
  initialLoad:boolean = false;

  enabledistanceValidation:any = false;
  distance:any = 0;
  outLetZipcode:any = "";

  constructor(
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private checkoutShippingService: CheckoutShippingService,
    private checkoutApiService: CheckoutApiService,
    private checkoutShippingApiService: CheckoutShippingApiService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private checkoutShippingStorageService: CheckoutShippingStorageService,
    private checkoutShippingEventsService: CheckoutShippingEventsService,
    private checkoutShippingOrderService: CheckoutShippingOrderService,
    private checkoutLocalStorageService: CheckoutLocalStorageService,
  ) { }

  ngOnInit(): void {

    this.checkoutShippingEventsService.clearEvent();
    this.checkoutShippingEventsService.currentData.subscribe(
      currentData => {
        if(currentData === "resetShipping"){
          this.resetShipping();
        }
        else if(currentData === "getAddress"){
          this.getAddresses();
        }
        else if(currentData === "editShipping"){
          this.editShippingAddress();
        }
        else if(currentData === "deliveryCharges"){
          this.deliverHere();
        }
      }
    )

    this.shippingForm = new FormGroup({
      location: this.formBuilder.control('',[Validators.required]),
      flat_no: this.formBuilder.control('',[Validators.required]),
      landmark: this.formBuilder.control('',[]),
      city: this.formBuilder.control('',[]),
      state:this.formBuilder.control('',[]),
      country: this.formBuilder.control('',[]),
      zipcode: this.formBuilder.control('',[]),
      mobile: this.formBuilder.control('', [Validators.required, Validators.pattern(numPattern), Validators.maxLength(10), Validators.minLength(10)]),
      email: this.formBuilder.control('', [Validators.pattern(emailPattern), Validators.maxLength(50)]),
      full_name: this.formBuilder.control('', [Validators.required, Validators.pattern(namePattern), Validators.maxLength(50)]),
      default_shipping: this.formBuilder.control(false, []),
      latitude: this.formBuilder.control('', []),
      longitude: this.formBuilder.control('', [])
    })

    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder
    });
    this.zoom = 15;

    this.billingAddressEnabled();
    this.getAddresses();
    // this.deliverDataExists();
  }

  get f(){
    return this.shippingForm.controls;
  }

  resetShipping(){
    this.readOnlyAddress = true;
  }

  billingAddressEnabled(){
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.CHECKOUT_SETTINGS).then(
      respData => {
        let res:any = respData;
        if(res.success){
          if(res.data[0].is_billing_address_enabled){
            this.billingAddressEnable = res.data[0].is_billing_address_enabled
          }
          else{
            this.billingAddressEnable = false;
          }
        }
      }
    )
  }

  selectLocation(event) {
    this.latitude = event.coords.lat;
    this.longitude  = event.coords.lng;
    this.zoom = 15;
    this.getLocationdetails();
  }

  saveAddress(){
    if(this.shippingForm.value.location !== this.validAddress){
      this.validateShippingForm = true;
      this.shippingForm.controls['location'].setErrors({'incorrect1':true});
    }
    if(this.shippingForm.value.mobile === "0000000000"){
      this.validateShippingForm = true;
      this.shippingForm.controls['mobile'].setErrors({'incorrect':true});
    }
    var re = /'/gi;
    var re1 = / /gi;
    let name = this.shippingForm.value.full_name;
    if(name !== ""){
      name = name.replace(re, "")
      name = name.replace(re1, "")
      if(name.length === 0){
        this.validateShippingForm = true;
        this.shippingForm.controls['full_name'].setErrors({'incorrect':true});
      }
    }
    if(this.shippingForm.valid){
      this.validateShippingForm = false;
      var newAddress = {
        customer_id:this.checkoutShippingService.getCustomerDetails(),
        alias:"",
        first_name:this.shippingForm.value.full_name,
        last_name:"",
        company_name:"",
        entire_location:this.shippingForm.value.location,
        address1:this.shippingForm.value.flat_no,
        landmark: this.shippingForm.value.landmark,
        city: this.shippingForm.value.city,
        state: this.shippingForm.value.state,
        country: this.shippingForm.value.country,
        country_code:"104",
        zipcode: this.shippingForm.value.zipcode,
        email: this.shippingForm.value.email,
        phone_number: this.shippingForm.value.mobile,
        is_preferred_address:this.shippingForm.value.default_shipping,
        address_type:"",
        longitude:this.longitude,
        latitude:this.latitude
      }
      this.addAddress(newAddress);
    }
    else{
      this.validateShippingForm = true;
    }
  }

  cancelSave(){
    this.editAddress = false;
    this.addingAddress = false;
    this.editAddressId = null;
  }

  getCurrentLocation(){
    this.longitude = null;
    this.latitude = null;
    navigator.geolocation.getCurrentPosition(position => {
      this.location_bloked = false;
      this.latitude = position.coords.latitude
      this.longitude = position.coords.longitude
      this.getLocationdetails();
    }, error => {
      if(error.code === 1){
        this.location_bloked = true;
      }
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    })
    
  }

  getLocationdetails(){
    this.geocoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14;
          this.validAddress = results[0].formatted_address;
          this.shippingForm.controls.location.setValue(results[0].formatted_address);
          results[0].address_components.forEach(element => {
            if (element.types.includes("administrative_area_level_2")) {
              this.shippingForm.controls.city.setValue(element.long_name);
            }
            if (element.types.includes("administrative_area_level_1")) {
              this.shippingForm.controls.state.setValue(element.long_name);
            }
            if (element.types.includes("country")){
              this.shippingForm.controls.country.setValue(element.long_name);
            }
            if (element.types.includes("postal_code")){
              this.shippingForm.controls.zipcode.setValue(element.long_name);
            }
          });
          this.isDeliverable().then(
            respData => {
              let res:any = respData;
              if(this.enabledistanceValidation){
                if(res > this.distance){
                  this.is_deliverable = false;
                  this.shippingForm.controls['location'].setErrors({'incorrect':true});
                }
                else{
                  this.is_deliverable = true;
                }
              }
              else{
                this.is_deliverable = true;
              }
            }
          )
        }
      }
    })
  }

  isDeliverable(){
    return new Promise((resolve, reject) => {
      this.checkoutShippingService.getSelectedRestaurent().then(
        respData => {
          let res:any = respData;
          if(res){
            var latitude:number = this.pickupLatitude = res.latitude;
            var longitude:number = this.pickupLongitude = res.longitude;
            this.enabledistanceValidation = res.enabled;
            this.distance = res.distance;
            this.outLetZipcode = res.pinCode;
            var restaurent = new google.maps.LatLng(latitude, longitude);
            var selectedLocation = new google.maps.LatLng(this.latitude, this.longitude);
            const distance = google.maps.geometry.spherical.computeDistanceBetween(restaurent, selectedLocation);
            let dimensions = distance / 1000;
            this.checkoutShippingApiService.distance = dimensions;
            resolve(dimensions);
          }
        })
    })
  }

  changeAddress(address:any){
    this.shippingForm.controls.location.setValue(address.formatted_address);
    this.latitude = address.geometry.location.lat()
    this.longitude = address.geometry.location.lng()
    this.getLocationdetails();
    this.isDeliverable().then(
      respData => {
        let res:any = respData;
        if(this.enabledistanceValidation){
           if(res > this.distance){
            this.is_deliverable = false;
            this.shippingForm.controls['location'].setErrors({'incorrect':true});
          }
          else{
            this.is_deliverable = true;
          }
        }
        else{
          this.is_deliverable = true;
        }
      }
    )
  }

  addAddress(address){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "payload": {
        "create_address": address
      }
    }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.CREATE_CUSTOMER_ADDRESSES, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){
          this.notify.emit({type:"alert",msgType:"success", msg:res.message});
          this.addingAddress = false;
          this.getAddresses();
        }
      }
    )

  }

  getAddresses(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          "customer_id": this.checkoutShippingService.getCustomerDetails()
        },
        "pagination": false,
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_CUSTOMER_ADDRESSES, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){
          if(res.data.length > 0){
            this.customerAddresses = res.data;
            this.addingAddress = false;
            this.checkDefaultAddress();
            if(this.checkoutShippingService.getCustomerDetails() !== null){
              this.initialLoad = true;
            }
          }
          else{
            this.addingAddress = true;
            if(this.checkoutShippingService.getCustomerDetails() !== null){
              this.initialLoad = true;
            }
          }
        }
      }
    )
  }

  setSelectedAddress(address){
    this.selectedAddress = address;
    this.selectedAddressId = address.id;
    this.sameAsShipping = true;
    this.longitude = address.longitude;
    this.latitude = address.latitude;
    this.is_exist_deliverable = false;
  }

  setSelectedBillingAddress(address){
    this.selectedBillingAddressId = address.id;
    this.selectedBillingAddress = address;
    this.sameAsShipping = false;
  }

  openAddressForm(){
    this.addingAddress = true;
    this.is_exist_deliverable = false;
  }

  editCustomerAddress(address){
    this.editAddress = true;
    this.editAddressId = address.id;
    this.shippingForm.patchValue({
      location: address.entire_location,
      flat_no: address.address1,
      landmark: address.landmark,
      city: address.city,
      state:address.state,
      country: address.country,
      zipcode: address.zipcode,
      mobile: address.phone_number,
      email: address.email,
      full_name: address.first_name,
      default_shipping: address.is_preferred_address,
      latitude: address.latitude,
      longitude:address.longitude 
    })
    this.longitude = address.longitude;
    this.latitude = address.latitude;
    this.validAddress = address.entire_location;
    this.geocoder.geocode({ 'location': { lat: address.latitude, lng: address.longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14
        }
        else {}
      }
    })
  }

  updateAddress(){
    if(this.shippingForm.value.location !== this.validAddress){
      this.validateShippingForm = true;
      this.shippingForm.controls['location'].setErrors({'incorrect1':true});
    }
    if(this.shippingForm.value.mobile === "0000000000"){
      this.validateShippingForm = true;
      this.shippingForm.controls['mobile'].setErrors({'incorrect':true});
    }
    var re = /'/gi;
    var re1 = / /gi;
    let name = this.shippingForm.value.full_name;
    if(name !== ""){
      name = name.replace(re, "")
      name = name.replace(re1, "")
      if(name.length === 0){
        this.validateShippingForm = true;
        this.shippingForm.controls['full_name'].setErrors({'incorrect':true});
      }
    }
    if(this.shippingForm.valid){
      this.validateShippingForm = false;
      var updatedAddress = {
        customer_id:this.checkoutShippingService.getCustomerDetails(),
        alias:"",
        first_name:this.shippingForm.value.full_name,
        last_name:"",
        company_name:"",
        entire_location:this.shippingForm.value.location,
        address1:this.shippingForm.value.flat_no,
        landmark: this.shippingForm.value.landmark,
        city: this.shippingForm.value.city,
        state: this.shippingForm.value.state,
        country: this.shippingForm.value.country,
        country_code:"104",
        zipcode: this.shippingForm.value.zipcode,
        email: this.shippingForm.value.email,
        phone_number: this.shippingForm.value.mobile,
        is_preferred_address:this.shippingForm.value.default_shipping,
        address_type:"",
        longitude:this.longitude,
        latitude:this.latitude
      }
      this.updateCustomerAddress(updatedAddress, this.selectedAddressId);
    }
    else{
      this.validateShippingForm = true;
    }
  }

  updateCustomerAddress(address, addressId){
    let requestData = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "payload":{
        "update_address": address
      },
      "extras": {
            "find":
                {
                    "id": addressId
                }
        }
  }
  this.checkoutApiService.checkoutAPICall(ENDPOINTS.UPDATE_CUSTOMER_ADDRESSES, requestData).then(
    respData => {
      let res:any = respData;
      if(res.success === 1){
        this.notify.emit({type:"alert",msgType:"success", msg:res.message});
        this.addingAddress = false;
        this.getAddresses();
        this.editAddress = false;
        this.editAddressId = "";
      }
    }
  )
}

showBillingAddress(){
  if(!this.sameAsShipping){
    this.selectedBillingAddressId = "";
    this.selectedBillingAddress = null;
  }
}

// deliverDataExists(){
//   if(this.checkoutShippingStorageService.getShippingAddress()){
//     this.selectedAddress = this.checkoutShippingStorageService.getShippingAddress();
//     this.selectedAddressId = this.checkoutShippingStorageService.getShippingAddress().id;
//     this.sameAsShipping = this.checkoutShippingStorageService.getSameasBillingAddress().value;
//     if(!this.sameAsShipping && this.billingAddressEnabled){
//       this.selectedBillingAddress = this.checkoutShippingStorageService.getBillingAddress();
//       this.selectedBillingAddressId = this.checkoutShippingStorageService.getBillingAddress().id;
//     }
//     this.latitude = this.checkoutShippingStorageService.getShippingAddress().latitude;
//     this.longitude = this.checkoutShippingStorageService.getShippingAddress().longitude;
//     this.readOnlyAddress = true;
//     this.deliverHere();
//   }
// }

editShippingAddress(){
  this.readOnlyAddress = false;
  this.notify.emit({name:"closeRemaining"})
}

deliverHere(){
  this.isDeliverable().then(
    respData => {
      let res:any = respData;
      if(this.enabledistanceValidation && res > this.distance)
        {
          this.is_exist_deliverable = true;
        
          this.is_deliverable = false;
          this.shippingForm.controls['location'].setErrors({'incorrect':true});
      }
      else{
        this.is_deliverable = true;
        this.readOnlyAddress = true;
        this.checkoutShippingOrderService.setShippingandBillingAddress(this.selectedAddress, this.sameAsShipping, this.billingAddressEnable);
        if(!this.sameAsShipping && this.billingAddressEnable){
          this.checkoutShippingOrderService.setBillingAddress(this.selectedBillingAddress);
        }
        
        // this.checkoutShippingStorageService.setShippingAddress(this.selectedAddress);
        // this.checkoutShippingStorageService.setSameasBillingAddress({value:this.sameAsShipping});
        // this.checkoutShippingStorageService.setBillingAddress(this.selectedBillingAddress);
        this.checkoutShippingApiService.getFreeshippingSettings().then(
          respData1 => {
            let res1:any = respData1;
            if(res1.freeShipping){
              this.orderSummaryInitialService.updateAdminFormEvent({
                name:"shippingCharges",
                value:{
                  type:"Free Shipping",
                  data:res1,
                  delivery:{
                      "sendFrom": "Chennai",
                      "pickupLatitude": this.pickupLatitude,
                      "pickupLongitude": this.pickupLongitude,
                      "dropLatitude": this.selectedAddress.latitude.toString(),
                      "dropLongitude": this.selectedAddress.longitude.toString(),
                      "distance":res
                  }
                }
              })
            }
            else{
              this.checkoutShippingApiService.getFreeShippingFromProducts().then(
                respData2 => {
                  let res2:any = respData2;
                  if(res2){
                    this.orderSummaryInitialService.updateAdminFormEvent({
                      name:"Free Shipping Products",
                      value:{
                        type:"Free Shipping Products",
                        delivery:{
                            "sendFrom": "Chennai",
                            "pickupLatitude": this.pickupLatitude,
                            "pickupLongitude": this.pickupLongitude,
                            "dropLatitude": this.selectedAddress.latitude.toString(),
                            "dropLongitude": this.selectedAddress.longitude.toString(),
                            "distance":res
                        }
                      }
                    })
                  }
                  else{
                    this.checkoutShippingApiService.getShippingCarrierSettings().then(
                      respDatas => {
                        let ress:any = respDatas;
                        this.checkoutShippingApiService.selectedLogistics = ress;
                        if(ress === "PICKDROP"){
                          let deliverData = {
                            "sendFrom": "Chennai",
                            "pickupLatitude": this.pickupLatitude,
                            "pickupLongitude": this.pickupLongitude,
                            "dropLatitude": this.selectedAddress.latitude.toString(),
                            "dropLongitude": this.selectedAddress.longitude.toString(),
                            "distance":res
                          }
                          this.checkoutShippingApiService.getPickdropcharges(deliverData).then(
                            respData3 =>{
                              let res3:any = respData3;
                              if(res3 !== ""){
                                this.orderSummaryInitialService.updateAdminFormEvent({
                                  name:"pickdropCharges",
                                  value:{
                                    type:"pickdrop",
                                    data:res3,
                                    delivery:{
                                        "sendFrom": "Chennai",
                                        "pickupLatitude": this.pickupLatitude,
                                        "pickupLongitude": this.pickupLongitude,
                                        "dropLatitude": this.selectedAddress.latitude.toString(),
                                        "dropLongitude": this.selectedAddress.longitude.toString(),
                                        "distance":res
                                    }
                                  }
                                })
                              }
                              else{
                                this.checkoutShippingApiService.getFlatRateCharges().then(
                                  respData4 => {
                                    let res4:any = respData4;
                                    this.checkoutShippingOrderService.setShippingMethod("Flat Rate", res4.rate, res4.type);
                                    this.orderSummaryInitialService.updateAdminFormEvent({
                                      name:"flatratecharges",
                                      value:{
                                        type:"flatrate",
                                        data:res4,
                                        delivery:{
                                            "sendFrom": "Chennai",
                                            "pickupLatitude": this.pickupLatitude,
                                            "pickupLongitude": this.pickupLongitude,
                                            "dropLatitude": this.selectedAddress.latitude.toString(),
                                            "dropLongitude": this.selectedAddress.longitude.toString(),
                                            "distance":res
                                        }
                                      }
                                    })
                                  }
                                )
                              }
                            }
                          )
                        }
                        else if(ress === "DUNZO"){
                          let dunzoObj = {
                            oid: this.checkoutLocalStorageService.getOrderId().toString(),
                            pickup:{
                              latitude: this.pickupLatitude,
                              longitude: this.pickupLongitude,
                            },
                            drop:{
                              latitude: this.selectedAddress.latitude,
                              longitude: this.selectedAddress.longitude,
                            }
                          }
                          this.checkoutShippingApiService.getDunzoCharges(dunzoObj).then(
                            drespData => {
                              let dresp:any = drespData;
                              if(dresp !== ""){
                                this.orderSummaryInitialService.updateAdminFormEvent({
                                  name:"dunzoCharges",
                                  value:{
                                    type:"dunzo",
                                    data:dresp,
                                    delivery:dunzoObj
                                  }
                                })
                              }
                              else{
                                this.checkoutShippingApiService.getFlatRateCharges().then(
                                  respData4 => {
                                    let res4:any = respData4;
                                    this.checkoutShippingOrderService.setShippingMethod("Flat Rate", res4.rate, res4.type);
                                    this.orderSummaryInitialService.updateAdminFormEvent({
                                      name:"flatratecharges",
                                      value:{
                                        type:"flatrate",
                                        data:res4,
                                        delivery:{
                                          "weight":"500",
                                          "oZip":this.outLetZipcode,
                                          "sZip":this.selectedAddress.zipcode
                                        }
                                      }
                                    })
                                  }
                                )
                              }
                            }
                          )
                        }
                        else if(ress === "DELHIVERY"){
                          this.checkoutShippingApiService.getDelhiveryCharges("500", this.outLetZipcode, this.selectedAddress.zipcode).then(
                            dhRespData => {
                              let dhRes:any = dhRespData;
                              if(dhRes !== ""){
                                {
                                  this.orderSummaryInitialService.updateAdminFormEvent({
                                    name:"delhiveryCharges",
                                    value:{
                                      type:"delhivery",
                                      data:dhRes,
                                      delivery:{
                                        "weight":"500",
                                        "oZip":this.outLetZipcode,
                                        "sZip":this.selectedAddress.zipcode
                                      }
                                    }
                                  })
                                }
                              }
                              else{
                                this.checkoutShippingApiService.getFlatRateCharges().then(
                                  respData4 => {
                                    let res4:any = respData4;
                                    this.checkoutShippingOrderService.setShippingMethod("Flat Rate", res4.rate, res4.type);
                                    this.orderSummaryInitialService.updateAdminFormEvent({
                                      name:"flatratecharges",
                                      value:{
                                        type:"flatrate",
                                        data:res4,
                                        delivery:{
                                          "weight":"500",
                                          "oZip":this.outLetZipcode,
                                          "sZip":this.selectedAddress.zipcode
                                        }
                                      }
                                    })
                                  }
                                )
                              }
                            }
                          )
                        }
                        else{
                          this.checkoutShippingApiService.getFlatRateCharges().then(
                            respData5 => {
                              let res5:any = respData5;
                              this.checkoutShippingOrderService.setShippingMethod("Flat Rate", res5.rate, res5.type);
                              this.orderSummaryInitialService.updateAdminFormEvent({
                                name:"flatratecharges",
                                value:{
                                  type:"flatrate",
                                  data:res5,
                                  delivery:{
                                      "sendFrom": "Chennai",
                                      "pickupLatitude": this.pickupLatitude,
                                      "pickupLongitude": this.pickupLongitude,
                                      "dropLatitude": this.selectedAddress.latitude.toString(),
                                      "dropLongitude": this.selectedAddress.longitude.toString(),
                                      "distance":res
                                  }
                                }
                              })
                            }
                          )
                        }
                      }
                    )
                    
                  }
                }
              )
            }
          }
        )
        this.notify.emit({"step":"next"})
      }
    }
  )
}

checkDefaultAddress(){
  if(this.customerAddresses.length > 0){
    this.customerAddresses.forEach(
      address => {
        if(address.is_preferred_address){
          this.selectedAddress = address;
          this.selectedAddressId = address.id;
          this.latitude = address.latitude;
          this.longitude = address.longitude;
        }
      }
    )
  }
}

}
