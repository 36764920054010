import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderSummaryOrderService {

  constructor() { }

  freeShipping = false;

  orderObj = {
    subTotal:0,
    productTax:0,
    handlingFee:0,
    handlingFeeTax:0,
    shippingCharges:0,
    shippingChargesTax:0,
    totalTax:0,
    discountAmount:0,
    discountTax:0,
    handlingCostType:"",
    discountType:"",
    discountPercentage:0,
    totalAmount:0,
    shippingMethodName:""
  }

  orderSummary = {
    sub_total : 0,
    packing_cost : 0,
    shipping_cost : 0,
    tax : 0,
    discount_amount:0,
    order_total_amount : 0,
    handling_display:"",
    discount_display:""
  }

  setFreeShipping(freeShipping){
    this.freeShipping = freeShipping;
  }

 
}
