import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',

})
export class ProductBoxOneComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  @Input() product: any;
  ngOnInit(): void {
  }

  sendProductDetails(id){
    this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
  }
 
}
