import { Component, OnInit } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutCalculationsService } from '../checkout-calculations.service';
import { CheckoutInitialdataService } from '../checkout-initialdata.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { CheckoutTaxService } from '../checkout-tax.service';
import { OrderSummaryInitialService } from './order-summary-initial.service';
import { OrderSummaryOrderService } from './order-summary-order.service';
import { CheckoutShippingApiService } from '../checkout-shipping/checkout-shipping-api.service';
import { ENDPOINTS } from '../../app.config';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html'
})
export class OrderSummaryComponent implements OnInit {

  PERCENTAGE_LABEL = "PERCENTAGE";
  FIXEDAMOUNT_LABLE = "FIXED AMOUNT";

  SummaryData = {
    subTotal:{
      displayName:"SubTotal",
      amount:0,
      show:false
    },
    itemCount:0,
    handlingFee:{
      displayName:"",
      amount:0,
      show:false
    },
    discount:{
      displayName:"",
      amount:0,
      show:false
    },
    shippingCharges:{
      displayName:"",
      amount:0,
      show:false,
      free:false
    },
    tax:{
      displayName:"Tax",
      amount:0,
      show:false
    },
    total:{
      displayName:"Total",
      amount:0,
      show:false
    }
  }

  discountData = {
    type:"",
    rate:0,
    displaymsg:""
  }

  HanglingFee = {
    name:"",
    type:"",
    rate:0
  }

  discountTax:boolean = false;
  shippingTax:boolean = false;
  bannerMsg:any = "";
  deliveryData:any;
  minimumOrderAmount:any;

  constructor(
    private checkoutApiService: CheckoutApiService,
    private checkoutCalculationsService: CheckoutCalculationsService,
    private checkoutInitialdataService: CheckoutInitialdataService,
    private checkoutSettingsService: CheckoutSettingsService,
    private checkoutTaxService: CheckoutTaxService,
    private orderSummaryInitialService: OrderSummaryInitialService,
    private orderSummaryOrderService: OrderSummaryOrderService,
    private checkoutShippingApiService: CheckoutShippingApiService
  ) { }

  ngOnInit(): void {
    this.orderSummaryInitialService.clearEvent();
    this.getInitialData();
    this.orderSummaryInitialService.currentData.subscribe(currentData => {
      let data:any = currentData;
      if(data.name === "discount"){
        this.calculateDiscountAmount(data.value);
      }
      else if(data.name === "handlingFee"){
        this.calculateHandlingFee(data.value);
      }
      else if(data.name === "shippingCharges"){
        this.calculateShippingCharges(data.value);
      }
      else if(data.name === "Free Shipping Products"){
        this.freeShippingProducts(data.value);
      }
      else if(data.name === "pickdropCharges"){
        this.pickdropCharges(data.value);
      }
      else if(data.name === "flatratecharges"){
        this.flatRateCharges(data.value);
      }
      else if(data.name === "delhiveryCharges"){
        this.delhiveryCharges(data.value);
      }
      else if(data.name === "dunzoCharges"){
        this.dunzooCharges(data.value);
      }
      else if(data === "updateProducts"){
        this.updateAmounts();
        if(this.discountData.type !== ""){
          this.calculateDiscountAmount({discount : this.discountData.rate,
            type : this.discountData.type,
            displayName : this.discountData.displaymsg,
            displayMsg : this.bannerMsg})
        }
      }
      else if(data.name === "removeShipping"){
        this.removeDeliveryCharges();
      }
      else if(data.name === "removeDiscount"){
        this.removeDiscount();
      }
    })
  }

  getInitialData(){
    // GET CART ITEMS
    this.checkoutInitialdataService.getCartItems().then(
      respData => {
        let res:any = respData;
        if(res){
          this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
            respData1 => {
              let res1:any = respData1;
              this.SummaryData.subTotal.amount = res1;
              this.SummaryData.subTotal.show = true;
              this.SummaryData.itemCount = this.checkoutInitialdataService.cartProducts.length;
            }
          )
        }
      }
    )
    // CALCULATE HANDLING FEE
    this.checkoutSettingsService.getShippingSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          this.HanglingFee = {
            name:this.checkoutSettingsService.initialHanglingFee.name,
            type:this.checkoutSettingsService.initialHanglingFee.type,
            rate:this.checkoutSettingsService.initialHanglingFee.rate
          }
          this.SummaryData.handlingFee.displayName = this.checkoutSettingsService.initialHanglingFee.name;
          this.checkoutCalculationsService.calculateHandlingFee(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.checkoutSettingsService.initialHanglingFee.type, this.checkoutSettingsService.initialHanglingFee.rate).then(
            respData1 => {
              let res1:any = respData1;
              this.SummaryData.handlingFee.amount = res1;
              if(this.SummaryData.handlingFee.amount > 0){
                this.SummaryData.handlingFee.show = true;
              }
              else{
                this.SummaryData.handlingFee.show = false;
              }
            }
          )
        }
      }
    )
    // CALCULATE TAX 
    this.checkoutSettingsService.getTaxSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          this.checkoutSettingsService.getTaxRates().then(
            respData1 => {
              let res1:any = respData1;
              if(res1){
                this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                  respData2 => {
                    let res2:any = respData2;
                    this.checkoutCalculationsService.calculateTotalTax(res2, this.SummaryData.handlingFee.amount, this.checkoutSettingsService.handlingFeeTax.rate, this.SummaryData.discount.amount, this.SummaryData.shippingCharges.amount, this.discountTax, this.shippingTax, this.checkoutSettingsService.shippingTax.rate, this.checkoutSettingsService.productTax.rate).then(
                      respData2 => {
                        let res2:any = respData2;
                        this.SummaryData.tax.amount = res2;
                        this.SummaryData.tax.show = true;
                        this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                        if(this.SummaryData.total.amount > 0){
                          this.SummaryData.total.show = true;
                        }
                        else{
                          this.SummaryData.total.show = false;
                        }
                        this.orderSummaryOrderService.orderSummary = {
                          sub_total : this.SummaryData.subTotal.amount,
                          packing_cost : this.SummaryData.handlingFee.amount,
                          shipping_cost : this.SummaryData.shippingCharges.free ? 0 : this.SummaryData.shippingCharges.amount,
                          tax : this.SummaryData.tax.amount,
                          discount_amount: this.SummaryData.discount.amount,
                          order_total_amount : this.SummaryData.total.amount,
                          handling_display:this.SummaryData.handlingFee.displayName,
                          discount_display:this.SummaryData.discount.displayName
                        }
                      }
                    )

                  }
                )
              }
            }
          )
        }
      }
    )
  }

  calculateDiscountAmount(discountDetails){
    this.discountData.rate = discountDetails.discount;
    this.discountData.type = discountDetails.type;
    this.discountData.displaymsg = discountDetails.displayName;
    this.bannerMsg = discountDetails.displayMsg;
    if(discountDetails.type === this.PERCENTAGE_LABEL){
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
              respData1 => {
                let res1:any = respData1;
                this.orderSummaryOrderService.orderObj.discountPercentage = discountDetails.discount;
                this.checkoutCalculationsService.calculateDiscount(res1, discountDetails.discount).then(
                  respData2 => {
                    let res2:any = respData2;
                    this.SummaryData.discount.amount = res2;
                    this.orderSummaryOrderService.orderObj.discountAmount = this.SummaryData.discount.amount;
                    this.orderSummaryOrderService.orderObj.discountType = discountDetails.type;
                    this.SummaryData.discount.displayName = discountDetails.displayName;
                    if(this.SummaryData.discount.amount > 0){
                      this.SummaryData.discount.show = true;
                    }
                    else{
                      this.SummaryData.discount.show = false;
                    }
                    this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                    this.updateAmounts();
                  }
                )
              }
            )
          }
        }
      )
    }
    else{
      this.SummaryData.discount.amount = discountDetails.discount;
      this.orderSummaryOrderService.orderObj.discountAmount = discountDetails.discount;
      this.orderSummaryOrderService.orderObj.discountType = discountDetails.type;
      if(this.SummaryData.discount.amount > 0){
        this.SummaryData.discount.displayName = discountDetails.displayName;
        this.SummaryData.discount.show = true;
        this.updateAmounts();
        this.checkDiscountTax();
      }
      else{
        this.SummaryData.discount.displayName = "";
        this.SummaryData.discount.show = false;
      }
    }
  }

  calculateHandlingFee(handlingFeeDetails){
    this.HanglingFee = handlingFeeDetails;
    this.updateAmounts();
  }

  calculateShippingCharges(shippingDetails){
    if(shippingDetails.type === "Free Shipping"){
      if(shippingDetails.data.minOrder === "" || shippingDetails.data.minOrder === 0){
        this.SummaryData.shippingCharges.free = true;
        this.SummaryData.shippingCharges.displayName = shippingDetails.data.displayName
        this.SummaryData.shippingCharges.show = true;
        this.updateAmounts();
      }
      else if(shippingDetails.data.minOrder > 0){
        this.deliveryData = shippingDetails.delivery;
        this.minimumOrderAmount = shippingDetails.data.minOrder;
        this.checkforFreeShipping();
      }
    }
  }

  checkforFreeShipping(){
    if(this.minimumOrderAmount <= (this.SummaryData.subTotal.amount - this.SummaryData.discount.amount)){
      this.SummaryData.shippingCharges.free = true;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      this.updateAmounts();
    }
    else{
      // DELIVERY CHRAGES
      this.SummaryData.shippingCharges.free = false;
      this.checkoutShippingApiService.getDeliveryCharges().then(
        respData => {
          let res:any = respData;
          if(res.deliverType !== "Flat Rate"){
            this.SummaryData.shippingCharges.displayName = "Delivery Charges";
            this.SummaryData.shippingCharges.show = true;
            this.SummaryData.shippingCharges.amount = res.deliveryCharge;
            this.updateAmounts();
          }
          else{
            this.checkoutShippingApiService.getFlatRateCharges().then(
              respData1 => {
                let res1:any = respData1;
                let flatData = {
                  delivery:{},
                  data:res1
                }
                this.flatRateCharges(flatData);
              }
            )
          }
        }
      )
    }
  }

  freeShippingProducts(deliveryObj){
    this.deliveryData = deliveryObj.delivery;
    this.SummaryData.shippingCharges.free = true;
    this.SummaryData.shippingCharges.displayName = "Delivery Charges";
    this.SummaryData.shippingCharges.show = true;
  }

  pickdropCharges(pickdropdetails){
    if(pickdropdetails.data > 0){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      this.SummaryData.shippingCharges.amount = pickdropdetails.data;
      this.updateAmounts();
    }
    else{
      this.SummaryData.shippingCharges.show = false;
    }
  }

  delhiveryCharges(delihiverydetails){
    if(delihiverydetails.data){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      this.SummaryData.shippingCharges.amount = delihiverydetails.data;
      this.updateAmounts();
    }
    else{
      this.SummaryData.shippingCharges.show = false;
    }
  }

  dunzooCharges(dunzodetails){
    if(dunzodetails.data != undefined){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = "Delivery Charges";
      this.SummaryData.shippingCharges.show = true;
      this.SummaryData.shippingCharges.amount = dunzodetails.data;
      this.updateAmounts();
    }
    else{
      this.SummaryData.shippingCharges.show = false;
    }
  }


  flatRateCharges(flatRateObj){
    this.deliveryData = flatRateObj.delivery;
    let flatData: any = flatRateObj.data;
    if(flatData.type.toUpperCase() === "PER ORDER" && flatData.rate > 0){
      this.SummaryData.shippingCharges.free = false;
      this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
      this.SummaryData.shippingCharges.amount = flatData.rate;
      this.SummaryData.shippingCharges.show = true;
      this.updateAmounts();
      this.checkShippingTax();
    }
    else if(flatData.type.toUpperCase() === "PER ITEM" && flatData.rate > 0){
      this.checkoutInitialdataService.getCartItems().then(
        respData => {
          let res:any = respData;
          if(res){
            this.SummaryData.shippingCharges.free = false;
            this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
            this.SummaryData.shippingCharges.amount = flatData.rate * this.checkoutInitialdataService.cartProducts.length;
            this.SummaryData.shippingCharges.show = true;
            this.updateAmounts();
            this.checkShippingTax();
          }
        }
      )
    }
  }

  removeDeliveryCharges(){
    this.SummaryData.shippingCharges.show = false;
    this.SummaryData.shippingCharges.displayName = "";
    this.SummaryData.shippingCharges.amount = 0;
    this.SummaryData.shippingCharges.free = false;
    this.shippingTax = false;
    this.updateAmounts();
  }
  
  checkShippingTax(){
    this.checkoutSettingsService.getTaxSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
            this.shippingTax = true;
            this.updateAmounts();
          }
          else{
            this.shippingTax = false;
            this.updateAmounts();
          }
        }
      }
    )
  }

  removeDiscount(){
    if(this.SummaryData.discount.show){
      this.orderSummaryOrderService.orderObj.discountPercentage = 0;
      this.SummaryData.discount.amount = 0;
      this.orderSummaryOrderService.orderObj.discountAmount = 0;
      this.orderSummaryOrderService.orderObj.discountType = "";
      this.SummaryData.discount.displayName = "";
      this.SummaryData.discount.show = false;
      this.discountTax = false;
      this.bannerMsg = "";
      this.discountData.rate = 0;
      this.discountData.displaymsg = "";
      this.discountData.type = "";
      this.updateAmounts();
    }
  }

  checkDiscountTax(){
    this.checkoutSettingsService.getTaxSettings().then(
      respData => {
        let res:any = respData;
        if(res){
          if(this.checkoutSettingsService.taxCalculationSettings.apply_discount_on_prices.toUpperCase() === "EXCLUDING TAX"){
            this.discountTax = true;
            this.updateAmounts();
          }
          else{
            this.discountTax = false;
            this.updateAmounts();
          }
        }
      }
    )
  }

  updateAmounts(){
    this.orderSummaryOrderService.setFreeShipping(this.SummaryData.shippingCharges.free);
    this.orderSummaryOrderService.orderObj.shippingCharges = this.SummaryData.shippingCharges.amount;
    // GET CART ITEMS
    this.checkoutInitialdataService.getCartItems().then(
      respData => {
        let res:any = respData;
        if(res){
          this.SummaryData.itemCount = this.checkoutInitialdataService.cartProducts.length;
          // SUBTOTAL
          this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
            respData1 => {
              let res1:any = respData1;
              this.SummaryData.subTotal.amount = res1;
              // HANDLING FEE
              this.checkoutSettingsService.getShippingSettings().then(
                respData2 => {
                  let res2:any = respData2;
                  if(res){
                    this.SummaryData.handlingFee.displayName = this.checkoutSettingsService.initialHanglingFee.name;
                    this.checkoutCalculationsService.calculateHandlingFee(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.HanglingFee.type, this.HanglingFee.rate).then(
                      respData3 => {
                        let res3:any = respData3;
                        this.SummaryData.handlingFee.amount = res3;
                        if(this.SummaryData.handlingFee.amount > 0){
                          this.SummaryData.handlingFee.show = true;
                        }
                        else{
                          this.SummaryData.handlingFee.show = false;
                        }
                        // TAX CALCULATION
                        this.checkoutSettingsService.getTaxSettings().then(
                          respData4=> {
                            let res4:any = respData4;
                            if(res4){
                              this.checkoutSettingsService.getTaxRates().then(
                                respData5 => {
                                  let res5:any = respData5;
                                  if(res5){
                                    this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                                      respData6 => {
                                        let res6:any = respData6;
                                        this.checkoutCalculationsService.calculateTotalTax(res6, this.SummaryData.handlingFee.amount, this.checkoutSettingsService.handlingFeeTax.rate, this.SummaryData.discount.amount, this.SummaryData.shippingCharges.amount, this.discountTax, this.shippingTax, this.checkoutSettingsService.shippingTax.rate, this.checkoutSettingsService.productTax.rate).then(
                                          respData7 => {
                                            let res7:any = respData7;
                                            this.SummaryData.tax.amount = res7;
                                            this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                                            if(this.SummaryData.total.amount > 0){
                                              this.SummaryData.total.show = true;
                                            }
                                            else{
                                              this.SummaryData.total.show = false;
                                            }
                                            this.orderSummaryOrderService.orderSummary = {
                                              sub_total : this.SummaryData.subTotal.amount,
                                              packing_cost : this.SummaryData.handlingFee.amount,
                                              shipping_cost : this.SummaryData.shippingCharges.free ? 0 : this.SummaryData.shippingCharges.amount,
                                              tax : this.SummaryData.tax.amount,
                                              discount_amount: this.SummaryData.discount.amount,
                                              order_total_amount : this.SummaryData.total.amount,
                                              handling_display:this.SummaryData.handlingFee.displayName,
                                              discount_display:this.SummaryData.discount.displayName
                                            }
                                            this.updateAmounts1();                                           
                                          }
                                        )
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          }
                        )
                      }
                    )
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  updateAmounts1(){
    this.checkeFreeShipping().then(
      respData => {
        let res:any = respData;
        if(res){
          this.orderSummaryOrderService.setFreeShipping(this.SummaryData.shippingCharges.free);
          this.orderSummaryOrderService.orderObj.shippingCharges = this.SummaryData.shippingCharges.amount;
          // GET CART ITEMS
          this.checkoutInitialdataService.getCartItems().then(
            respData => {
              let res:any = respData;
              if(res){
                this.SummaryData.itemCount = this.checkoutInitialdataService.cartProducts.length;
                // SUBTOTAL
                this.checkoutCalculationsService.calculateSubTotal(this.checkoutInitialdataService.cartProducts).then(
                  respData1 => {
                    let res1:any = respData1;
                    this.SummaryData.subTotal.amount = res1;
                    // HANDLING FEE
                    this.checkoutSettingsService.getShippingSettings().then(
                      respData2 => {
                        let res2:any = respData2;
                        if(res){
                          this.SummaryData.handlingFee.displayName = this.checkoutSettingsService.initialHanglingFee.name;
                          this.checkoutCalculationsService.calculateHandlingFee(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.HanglingFee.type, this.HanglingFee.rate).then(
                            respData3 => {
                              let res3:any = respData3;
                              this.SummaryData.handlingFee.amount = res3;
                              if(this.SummaryData.handlingFee.amount > 0){
                                this.SummaryData.handlingFee.show = true;
                              }
                              else{
                                this.SummaryData.handlingFee.show = false;
                              }
                              // TAX CALCULATION
                              this.checkoutSettingsService.getTaxSettings().then(
                                respData4=> {
                                  let res4:any = respData4;
                                  if(res4){
                                    this.checkoutSettingsService.getTaxRates().then(
                                      respData5 => {
                                        let res5:any = respData5;
                                        if(res5){
                                          this.checkoutTaxService.getProductTaxList(this.checkoutInitialdataService.cartProducts, this.checkoutSettingsService.taxclasses).then(
                                            respData6 => {
                                              let res6:any = respData6;
                                              this.checkoutCalculationsService.calculateTotalTax(res6, this.SummaryData.handlingFee.amount, this.checkoutSettingsService.handlingFeeTax.rate, this.SummaryData.discount.amount, this.SummaryData.shippingCharges.amount, this.discountTax, this.shippingTax, this.checkoutSettingsService.shippingTax.rate, this.checkoutSettingsService.productTax.rate).then(
                                                respData7 => {
                                                  let res7:any = respData7;
                                                  this.SummaryData.tax.amount = res7;
                                                  this.SummaryData.total.amount = this.checkoutCalculationsService.calculateTotal(this.SummaryData.subTotal.amount, this.SummaryData.discount.amount, this.SummaryData.handlingFee.amount, this.SummaryData.shippingCharges.amount, this.SummaryData.tax.amount);
                                                  if(this.SummaryData.total.amount > 0){
                                                    this.SummaryData.total.show = true;
                                                  }
                                                  else{
                                                    this.SummaryData.total.show = false;
                                                  }
                                                  this.orderSummaryOrderService.orderSummary = {
                                                    sub_total : this.SummaryData.subTotal.amount,
                                                    packing_cost : this.SummaryData.handlingFee.amount,
                                                    shipping_cost : this.SummaryData.shippingCharges.free ? 0 : this.SummaryData.shippingCharges.amount,
                                                    tax : this.SummaryData.tax.amount,
                                                    discount_amount: this.SummaryData.discount.amount,
                                                    order_total_amount : this.SummaryData.total.amount,
                                                    handling_display:this.SummaryData.handlingFee.displayName,
                                                    discount_display:this.SummaryData.discount.displayName
                                                  }                                           
                                                }
                                              )
                                            }
                                          )
                                        }
                                      }
                                    )
                                  }
                                }
                              )
                            }
                          )
                        }
                      }
                    )
                  }
                )
              }
            }
          )
        }
      }
    )
  }

  checkeFreeShipping(){
    return new Promise((resolve, reject) => {
      if(this.minimumOrderAmount > 0){
        if(this.minimumOrderAmount <= (this.SummaryData.subTotal.amount - this.SummaryData.discount.amount)){
          this.SummaryData.shippingCharges.free = true;
          this.SummaryData.shippingCharges.displayName = "Delivery Charges";
          this.SummaryData.shippingCharges.show = true;
          this.SummaryData.shippingCharges.amount = 0;
          resolve(true);
        }
        else{
          // DELIVERY CHRAGES
          this.SummaryData.shippingCharges.free = false;
          this.checkoutShippingApiService.getDeliveryCharges().then(
            respData => {
              let res:any = respData;
              if(res.deliverType !== "Flat Rate"){
                this.SummaryData.shippingCharges.displayName = "Delivery Charges";
                this.SummaryData.shippingCharges.show = true;
                this.SummaryData.shippingCharges.amount = res.deliveryCharge;
                this.orderSummaryOrderService.orderObj.shippingMethodName = "";
                resolve(true);
              }
              else{
                this.checkoutShippingApiService.getFlatRateCharges().then(
                  respData1 => {
                    let res1:any = respData1;
                    let flatData: any = res1;
                    this.orderSummaryOrderService.orderObj.shippingMethodName = "Flat Rate";
                    if(flatData.type.toUpperCase() === "PER ORDER" && flatData.rate > 0){
                      this.SummaryData.shippingCharges.free = false;
                      this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
                      this.SummaryData.shippingCharges.amount = flatData.rate;
                      this.SummaryData.shippingCharges.show = true;
                      this.checkoutSettingsService.getTaxSettings().then(
                        respData => {
                          let res:any = respData;
                          if(res){
                            if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                              this.shippingTax = true;
                              resolve(true);
                            }
                            else{
                              this.shippingTax = false;
                              resolve(true);
                            }
                          }
                        }
                      )
                    }
                    else if(flatData.type.toUpperCase() === "PER ITEM" && flatData.rate > 0){
                      this.checkoutInitialdataService.getCartItems().then(
                        respData => {
                          let res:any = respData;
                          if(res){
                            this.SummaryData.shippingCharges.free = false;
                            this.SummaryData.shippingCharges.displayName = flatData.diaplayName;
                            this.SummaryData.shippingCharges.amount = flatData.rate * this.checkoutInitialdataService.cartProducts.length;
                            this.SummaryData.shippingCharges.show = true;
                            this.checkoutSettingsService.getTaxSettings().then(
                              respData => {
                                let res:any = respData;
                                if(res){
                                  if(this.checkoutSettingsService.taxCalculationSettings.shipping_prices.toUpperCase() === "EXCLUDING TAX"){
                                    this.shippingTax = true;
                                    resolve(true);
                                  }
                                  else{
                                    this.shippingTax = false;
                                    resolve(true);
                                  }
                                }
                              }
                            )
                          }
                        }
                      )
                    }
                  })
              }
          })
        }
      }
      else{
        resolve(false);
      }
    })
    
  }

}
