import { Injectable } from '@angular/core';

import { CheckoutSettingsService } from './checkout-settings.service';


@Injectable({
  providedIn: 'root'
})
export class CheckoutTaxService {

  constructor(
    private checkoutSettingsService : CheckoutSettingsService
  ) { }

  productTaxList = [];

  getProductTaxList(productList, taxClasses){
    return new Promise((resolve, reject) => 
    {
      this.productTaxList = [];
      productList.forEach(product => {
        let productTax = {
          pid:0,
          taxId:0,
          taxClassName:"",
          taxrate:0,
          quantity:0,
          price:0
        };
        if(product.product.tax_class_id){
          let taxClass = taxClasses[0].tax_class_rates.find( taxClass => taxClass.tax_class_id === Number(product.product.tax_class_id));
          if(taxClass){
            productTax.pid = product.product.id;
            productTax.taxId = taxClass.tax_class_id;
            productTax.taxClassName = taxClass.tax_class_name;
            productTax.taxrate = taxClass.tax_class_rate_percent;
            productTax.quantity = product.quantity;
            productTax.price = product.cost;
            this.productTaxList.push(productTax)
          }
        }
        else{
          productTax.pid = product.product.id;
          productTax.taxId = this.checkoutSettingsService.productTax.id;
          productTax.taxClassName = this.checkoutSettingsService.productTax.name;
          productTax.taxrate = this.checkoutSettingsService.productTax.rate;
          productTax.quantity = product.quantity;
          productTax.price = product.cost;
          this.productTaxList.push(productTax)
        }
         
      });
      resolve(this.productTaxList);
    })
  }
}
