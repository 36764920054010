<app-alerts *ngIf="showalertmsg"></app-alerts>
<div class="box">
  <h2 class="heading text-center">Sign-In</h2>
  <div *ngIf="customerForm === 'guestForm'">
    <div class="col-md-12">
      <form [formGroup]="guestForm" class="otp-form">
        <div class="form-group">
          <input type="text" class="form-control" formControlName="guest_mobile" placeholder="Mobile Number"
            [class.is-invalid]="validateGuestForm && f.guest_mobile.errors" [readonly]="freezeGuest">
          <div class="invalid-feedback" *ngIf="f.guest_mobile.errors">
            <div *ngIf="f.guest_mobile.errors.required">Please enter the Mobile Number.</div>
            <div *ngIf="f.guest_mobile.errors.pattern">Please enter a valid Mobile Number.</div>
          </div>
        </div>
      </form>
      <div class="inq-mch-con-btn-st d-flex justify-content-end">
        <button *ngIf="!freezeGuest" class="btn btn-primary inq-btn-st" (click)="saveGuest()">CONTINUE</button>
        <button *ngIf="freezeGuest" class="btn btn-primary inq-btn-st mb-1" (click)="editGuest()">EDIT</button>
      </div>
    </div>
    <!-- </div> -->
    <div *ngIf="showpasswordSetupForm">
      <div class="mt-2" [class]="showOtpLogin ? 'reveal-if-active' : 'hide-if-inactive'">
        <div class="btn-group col-md-12 custome-nospace" role="group">
          <button type="button" class="btn btn-secondary custom-brn-grp" [class.btn-primary]="!otpLogin"
            [class.btn-secondary]="otpLogin" (click)="changeLoginMethod('password')">Password Login</button>
            <button type="button" class="btn custom-brn-grp" [class.btn-primary]="otpLogin"
            [class.btn-secondary]="!otpLogin" (click)="changeLoginMethod('otp')">OTP Login</button>
        </div>
        <div
          [class]="otpLogin ? 'reveal-if-active col-md-12 custome-nospace' : 'hide-if-inactive col-md-12  custome-nospace'">
          <form [formGroup]="otpLoginForm" class="otp-form">
            <div class="form-group">
              <input type="text" class="form-control" formControlName="otp" placeholder="Enter OTP"
                [class.is-invalid]="vaidateotpLoginForm && f4.otp.errors">
              <div class="invalid-feedback" *ngIf="f4.otp.errors">
                <div *ngIf="f4.otp.errors.required">Please enter the OTP.</div>
                <div *ngIf="f4.otp.errors.incorrect">Please enter the valid OTP</div>
              </div>
            </div>
          </form>
          <div class="mt-3 mb-3" >
            <p class="pl-4">Didn't get the code? <strong><a class="loginLink" (click)=resendCode()>Resend Code</a>
              </strong>
            </p>
          </div>
          <div>
            <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="OTPLogin()">Sign In</button>
          </div>
        </div>
        <div
          [class]="!otpLogin ? 'reveal-if-active col-md-12 custome-nospace' : 'hide-if-inactive col-md-12  custome-nospace'">
          <form *ngIf='!newPasswordSetUp' [formGroup]="passwordLoginForm" class="otp-form">
            <div class="form-group">
              <div class="input-group">
                <input [type]="showPswd ? 'text' : 'password'" class="form-control" formControlName="passwordp"
                  placeholder="Password" [class.is-invalid]="validatepasswordLoginForm && f5.passwordp.errors">
                <div class="input-group-append">
                  <span class="input-group-text" (click)="showPassword()"><i
                      [class]="showPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
                </div>
                <div class="invalid-feedback" *ngIf="f5.passwordp.errors">
                  <div *ngIf="f5.passwordp.errors.required">Please Enter Password.</div>
                  <div *ngIf="f5.passwordp.errors.minlength">Password atleast should have 6 charachters</div>
                  <div *ngIf="f5.passwordp.errors.maxlength">Password should not have more than 20 charachters</div>
                </div>
              </div>
            </div>
          </form>
          <form *ngIf='newPasswordSetUp' [formGroup]="newPasswordLoginForm" class="otp-form">
            <div class="form-group">
              <input type="text" class="form-control" formControlName="otpp" placeholder="Enter OTP"
                [class.is-invalid]="validatenewPasswordLoginForm && f6.otpp.errors">
              <div class="invalid-feedback" *ngIf="f6.otpp.errors">
                <div *ngIf="f6.otpp.errors.required">Please enter the OTP.</div>
                <div *ngIf="f6.otpp.errors.incorrect">Please enter the valid OTP</div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input [type]="showPswd ? 'text' : 'password'" class="form-control" formControlName="passwordp"
                  placeholder="Password" [class.is-invalid]="validatenewPasswordLoginForm && f6.passwordp.errors">
                <div class="input-group-append">
                  <span class="input-group-text" (click)="showPassword()"><i
                      [class]="showPswd ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i></span>
                </div>
                <div class="invalid-feedback" *ngIf="f6.passwordp.errors">
                  <div *ngIf="f6.passwordp.errors.required">Please Enter Password.</div>
                  <div *ngIf="f6.passwordp.errors.minlength">Password atleast should have 6 charachters</div>
                  <div *ngIf="f6.passwordp.errors.maxlength">Password should not have more than 20 charachters</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" formControlName="confirmPswd" placeholder="Confirm Password"
                [class.is-invalid]="validatenewPasswordLoginForm && f6.confirmPswd.errors" (blur)="validpasswd()">
              <div class="invalid-feedback" *ngIf="f6.confirmPswd.errors">
                <div *ngIf="f6.confirmPswd.errors.required">Please enter Confirm Password.</div>
                <div *ngIf="f6.confirmPswd.errors.incorrect">Confirm password should match with New Password</div>
              </div>
            </div>
          </form>
          <div class="mt-3 mb-3" *ngIf='newPasswordSetUp'>
            <p class="pl-4">Didn't get the code? <strong><a class="loginLink" (click)=resendCode()>Resend Code</a>
              </strong>
            </p>
          </div>
          
          <div *ngIf='!newPasswordSetUp'>
            <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="passwordLogin()">Sign
              In</button>
          </div>
          <div *ngIf='newPasswordSetUp'>
            <button class="btn btn-block btn-primary otp-form custome-nospace" (click)="newPasswordLogin()">Sign
              In</button>
          </div>
        </div>
        <div class="mt-3 mb-3 text-center" >
            <strong>  <a class="link-style" routerLink="/forgot-password">Forgot Password?</a>
            </strong>
        </div>
      </div>
    </div>
  </div>
</div>