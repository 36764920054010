<!-- content -->
<!-- <div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="text-center">
            <h1>Products</h1>
            <div><span class="home-breadcrumb" routerLink="">Home</span>
                <span> <i class="fa fa-long-arrow-right px-2"></i> Products</span>
            </div>
        </div>
    </div>
</div> -->
<div class="container inq-mt-st">
    <section class="row" id="productRow">
        <div class="col-md-3">
            <div class="inq-menu-left-st">
                <div class="collection-filter">
                    <div class="total-items" id="totalItems">
                        <p><span><b>Items found</b></span> - {{totalItemsOrProducts}} </p>
                    </div>
                    <app-product-filter [filters]="facets" [priceRange]="pricefilters" [categoriesList]="categoryList">
                    </app-product-filter>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="inq-menu-right-st">
                <h2 class="text-primary" id="productListHead">Products</h2>
                <div class="breadCrumbListing"><span class="home-breadcrumb" routerLink="">Home</span>
                    <span><i class="px-2">/</i> Products</span>
                </div>
                <div *ngIf="totalLength>0" class="thumb">
                    <div *ngFor="let product of filterItemsOfType() |  paginate: { itemsPerPage: countPerPage, currentPage: currentPageInPagination, totalItems: totalLength };let i = index">
                        <div class="row inq-pro-sec-st listProduct" >
                            <div class="earthy-card">
                                <div class="earthy-image">
                                    <span class="wishlist-heart" ><i class="fa fa-heart fa-lg"(click)="addWishList(product)" [ngStyle]="{'color':product.select ? '#BDC62B' : '#dcdcdc' }"  aria-hidden="true"></i></span>   
                                    <div *ngIf="product.assets!=undefined && product.assets.length!=0 && product.assets[0].image_source">
                                        <img [src]="product.assets[0].image_source" id="img-list"
                                            (click)="productDetails(product.id)"
                                            onerror="this.onerror=null;this.src='assets/images/vendor/No_Image_old.png';"
                                            alt="{{ product.name}}" class="img-fluid product-image">
                                        <div *ngIf="product.assets.length===0 || !product.assets[0].image_source">
                                            <img src="assets/images/No_Image_old.png"  id="img-list"
                                                (click)="productDetails(product.id)" class="img-fluid product-image">
                                        </div>
                                    </div>
                                </div>
                                <div class="earthy-description text-center productTitleList" >
                                    <div class="product-description earthy-product-title"  id="img-list"
                                    (mouseenter)="mouseEnter(product.id) "
                                    (mouseleave)="mouseLeave(product.id)" id='{{product.id}}'
                                    ngbPopover="{{metaDataMapService.findData(product,
                                            fields[1].nestedinfo)}}" container="body"
                                    *ngIf="fields[1].visibility">
                                        <div>{{metaDataMapService.findData(product,fields[1].nestedinfo)}}</div>
                                        <div>
                                            <ngb-rating style="color: #373b3f !important; font-size: 11px !important;" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a style="color: #373b3f !important; font-size: 11px !important;">432 ratings|123 reviews</a>

                                            <!-- <ngb-rating   class="rating" [max]="5" [(rate)]="currentRating" [readonly]="true"></ngb-rating> <a class="reviewListing"style="color: #373b3f !important; font-size: 11px !important;" >432 ratings|123 reviews</a> -->
                                        </div>
                                        <div class="earthy-worthy-price" id="priceListing">
                                            <div *ngIf="fields[3].visibility && product.product_type!='Variable'">₹{{metaDataMapService.findData(product,
                                                fields[3].nestedinfo)}}.00</div>
                                            <div *ngIf="product.product_type=='Variable' && product.type=='Variable'">₹{{product.pricing.raw.price_range
                                                ? product.pricing.raw.price_range:
                                                product.pricing.raw.price + '.00'}}</div>
                                        </div>
                                    </div>
                                    <div class="earthy-cart-btn">
                                        <button    *ngIf="(appService.productIdinCart.indexOf(product.id) == -1 && product.product_type!='Variable') && (appService.productIdinCart.indexOf(product.id) == -1 && product.type!='Variable')" class="btn btn-primary earthy-btn" (click)="addToCart(product)" >Add to Cart</button>
                                        <button    *ngIf="product.product_type=='Variable' ||product.type=='Variable'" class="btn btn-primary earthy-btn" (click)='productDetails(product.id)'>Select Options</button>
                                        <!-- <button     class="btn btn-secondary listing"   *ngIf="(appService.productIdinCart.indexOf(product.id) != -1 && product.product_type!='Variable' && appService.productIdinCart.indexOf(product.id) > -1)"  routerLink="/cart">View Cart</button> -->
                                        <div *ngFor="let cart of appService.cartProducts.results;let i = index"> 
                                        <div class="btn btn-primary inq-btn-st ml-1 viewCart"      *ngIf="cart.product_id === product.id || cart.product_id === product.checkVariantInCart" >
                             
                                
                                            <span>
                                                <i class="fa fa-minus mr-3" (click)="decreaseItem(i)"
                                                    style="font-size:12px;cursor:pointer;"></i></span>
                                            <span class="inq-sp-2"
                                                style="color: white;">{{cart.quantity}}</span>
                                            <span>
                                                <i class="fa fa-plus ml-3" (click)="increaseItem(i)"
                                                    style="font-size:12px;cursor:pointer;"></i></span>
                                       </div>
                                       </div>
                                        
                                    </div>
                                 </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="totalLength < 1">
                    <img src="assets/images/noresult.jpeg" class="img-fluid1">
                </div>
            </div>
            <!-- <pagination-controls *ngIf="totalLength>0" class="my-pagination" (pageChange)="pageChange($event)"
                id="pagination-productList"></pagination-controls> -->
        </div>
    </section>
</div>
<!-- content -->