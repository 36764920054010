import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { ENDPOINTS, ORG_DETAILS } from '../app.config';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import {CartService} from '../cart-page/cart-page.service';
import { LocalStorageService } from '../auth-service/local-storage.service';
import {AlertsService} from '../shared/alerts/alerts.service';
import * as metadata from '../../assets/data/default/product-listing.json'
import { MetaDataMapService } from '../meta-data-map.service';
import { ProductFilterComponent } from '../product-filter/product-filter.component'
import { ProductListingService } from '../product-listing/product-listing.service';
import { ProductFilterService } from '../product-filter/product-filter.service';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html'
})
export class ProductListingComponent implements OnInit {
  @ViewChild (ProductFilterComponent) productfilter : ProductFilterComponent

  fields = metadata.groups[0].fields;
  public brandValues:any = [];
  public categoryList:any = []
  public allInOneData:any = [];
  public allInOneData1:any = [];
  public include=false
  public days90=false
  public days30=false
  brandPush:any;
  cate:any;
  clearCategoryData:any;
  selectedIndex: number = null;
  subCategories: any;
  categoryType: any;
  public active;
  public cartData=[];
  public cartQuantity=[];
  public searchCategory;
  totalProducts:any = 0;
  showAlerts = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  facets;
  products;
  breadcrumbs = 'Home > Ground Engaging Tools > <span class="active"><b>KHIN Teeth</b></span>';
  pricefilters = {
    minPrice: 0,
    maxPrice: 1000
  };
  public minPrice = 0;
  public maxPrice = 1000;
  public productsApiData:any;
  public categoryApiData:any;
  public countPerPage = 20;
  public pageNumber = 0;
  public totalNumberOfProducts;
  public currentPageInPagination = 1;
  public itemsPerPage: number;
  public totalLength;
  public page;
  public filtersApiData;
  public brandProductsApiData;
  public category: string[];
  public filter='no';
  public search='no';
  public searchKey;
  public searchApiData;
  public brand='no';
  public last90Days='no';
  public combine = 'no';
  public outOfStockProductsApiData;
  public outOfStock;
  public last90DaysProductsApiData;
  public dataFilter='az';
  public days= 'no';
  pagenation:any;
  latest_products:any = "";
  brad:any;
  searchTypeValue:any;
  ninetyDaysLastCheck:any;
  totalItemsOrProducts:any = 0;
  categoryTobeShown:any = 'All Categories';
  currentRating = 4.5;

  constructor(
    public appService:AppService,
    private router: Router,
    private route: ActivatedRoute,
    private cartService : CartService,
    private localService: LocalStorageService,
    private alertService : AlertsService,
    private productListingService: ProductListingService,
    public metaDataMapService: MetaDataMapService,
    private productFilterService: ProductFilterService,
  ) { }

  ngOnInit(){
    
    
      // this.getFilterByProduct(this.currentPageInPagination);
     this.productListingService.clearEvent();
     this.cartService.updateCartItem(this.appService.cartProducts.results)

    this.brandPush=[]
    this.cate=[]
    this.brad=[]
    this.pagenation=false
    // CLEAR ALL FUNCTIONALITY
    this.productListingService.currentData.subscribe(currentData => {
      if(currentData === 'clearAll'){
        if(this.latest_products === 'latest'){
          this.refreshLast90Days(1);
        }
        else{
          let topCategories = [];
          if(this.categoryList.length > 0){
            this.categoryList.forEach(element => {
              topCategories.push(element.name);
            });
            this.refreshProducts(topCategories);
          }
        }
        
      } else{
      
  

  // FOR CLEAR ALL FUNCTIONLITY TO COVER LATEST PRODUCTS
  if(this.route.snapshot.queryParamMap){
    if(this.route.snapshot.queryParamMap.get('type') === "last90days"){
      this.latest_products="latest";
    }
  }
  
  
  this.route.queryParams.subscribe(params => {
    // SCROLL TO THE TOP
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 20; // For Chrome, Firefox, IE and Opera
    if (params.type=='filter') {
      this.clearCategoryData=[]
      this.latest_products = "";
      this.combine='no';
      this.search='no';
      this.last90Days='no';
      this.ninetyDaysLastCheck = false;
      this.cate=[]
      this.route.queryParamMap.subscribe(params => this.category = params.getAll('terms'));
      this.categoryTobeShown = this.category[0];
      this.cate=this.category
      if(this.productfilter !== undefined){
         this.productfilter.brands=[]
        this.productfilter.cate=[]
        this.productfilter.stockDetails=[]
        this.productfilter.clearCheckBox()
        this.productfilter.active='';
      }
      this.currentPageInPagination = 1;
      this.getFilterByProduct(this.currentPageInPagination);
    }
    else if (params.type=='searchresults') {
      this.latest_products = "";
      this.clearCategoryData=[]
      // this.breadCrumbService.updateAdminFormEvent('');
      this.combine='no';
      this.ninetyDaysLastCheck = false;
      this.filter='no';
      this.last90Days='no';
      if(this.productfilter !== undefined){
         this.productfilter.brands=[]
        this.productfilter.cate=[]
        this.productfilter.stockDetails=[]
        this.productfilter.clearCheckBox()
        this.productfilter.active='';
      }
      this.cate=[]
      this.brad=[]
      this.route.queryParamMap.subscribe(params => {
        this.searchKey = params.getAll('terms')[0]
        this.categoryTobeShown = params.getAll('terms')[0];
        this.searchTypeValue = params.getAll('terms')[1]}
        );
      if(this.searchTypeValue){
        if(this.searchTypeValue === "Brand"){
          this.brad=this.searchKey
        }else{
          this.cate=[this.searchKey]
        }
      }else{
        this.cate=[this.searchKey]
      }
      
   
    this.currentPageInPagination = 1;
      this.getSearchResult(this.currentPageInPagination);
    }
   

  });

  this.route.queryParams.subscribe(params => {
    if (params.type=='last90days') {
      this.categoryTobeShown = 'Latest Prodcuts'
      this.latest_products = "latest"
      this.combine='no';
      this.filter='no';
      this.search='no';
      this.days='yes';
      this.ninetyDaysLastCheck = true; 
      if(this.productfilter !== undefined){
        this.productfilter.brands=[]
       this.productfilter.cate=[]
       this.productfilter.stockDetails=[]
       this.productfilter.clearCheckBox()
      }
    
      this.getProductByLast90Days(this.currentPageInPagination);
    }
  });
  


  this.route.queryParams.subscribe(params => {
    if (params.type=='combine') {
      this.search='no';
      this.filter='no';
      this.last90Days='no';
      
      this.route.queryParamMap.subscribe(params => this.allInOneData = params.getAll('terms'));
      this.route.queryParamMap.subscribe(params => this.allInOneData1 = params.getAll('terms'));
      this.getFilterByProductAll(this.currentPageInPagination);
    }
  });

    for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
      this.cartData.push(this.appService.cartProducts.results[i].product_id);
    }
  }
})
  }
  addWishList(product:any) {
    product.select = !product.select;
  }
  alphabetsFilter() {
      if(this.filter=='yes' && this.combine!=='yes' && this.search!=='yes'){
        this.getFilterByProduct(this.currentPageInPagination);
      } else if(this.search=='yes' && this.combine!=='yes' && this.filter!=='yes'){
        this.getSearchResult(this.currentPageInPagination);
      } else if(this.last90Days=='yes' && this.combine!=='yes' && this.search!=='yes' && this.filter!=='yes'){
        this.getProductByLast90Days(this.currentPageInPagination);
      } else if(this.combine=='yes' && this.dataFilter=='az' && this.search!=='yes' && this.last90Days!=='yes'){ 
        this.getFilterByProductAll(this.currentPageInPagination);
      }
    
  }

  pageChange(event) {
    this.currentPageInPagination = event;
      if(this.filter=='yes' && this.combine!=='yes' && this.search!=='yes' && this.last90Days!=='yes'){
        this.getFilterByProduct(this.currentPageInPagination);
      } else if(this.search=='yes' && this.combine!=='yes' && this.filter!=='yes' && this.last90Days!=='yes'){
        this.getSearchResult(this.currentPageInPagination);
      } else if(this.last90Days=='yes' && this.combine!=='yes' && this.search!=='yes' && this.filter!=='yes'){
        this.getProductByLast90Days(this.currentPageInPagination);
      } else if(this.combine=='yes' && this.dataFilter=='az' && this.search!=='yes' && this.last90Days!=='yes' && this.filter!=='yes'){ 
        this.pagenation=true;
        this.getFilterByProductAll(this.currentPageInPagination);
      }else if(this.combine=='yes' && this.dataFilter=='za' && this.search!=='yes' && this.last90Days!=='yes' && this.filter!=='yes'){ 
        this.getFilterByProductAllZToA(this.currentPageInPagination);
      }
  }

 //GET ALL BRANDS PRODUCTS
 getAllBrandProducts(event: number){
  this.currentPageInPagination = event;
  this.page = (event-1);
  if(this.dataFilter=='az'){
    var req_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit":(event),
          "pagesize": 20
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": -1
        },
        "subcategoryfilter":false
      }
    }
  } else if(this.dataFilter=='za'){
    var req_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit":(event),
          "pagesize": 20
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "name",
          "sortorder": +1
        },
        "subcategoryfilter":false
      }
    }
  }

 this.appService.postApiCall(req_product_data, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
   resp => {
     this.productsApiData = resp;
     this.products = this.productsApiData.result.data;
     this.totalNumberOfProducts = this.productsApiData.result.total;
    //  this.totalLength = this.products ? this.products.totalLength : 0;
     this.totalLength = this.productsApiData.result.total ? this.productsApiData.result.total : 0;
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
}

 //GET FILTER PRODUCTS
 getFilterByProduct(event: number){
   
  this.currentPageInPagination = event;
  this.filter='yes';

 if(this.dataFilter=='az'){
  var req_filter_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
     // "brand":[this.allInOneData[0]],
      "category":this.category,
    "last90days":false,
    "last30days":false,
    "includeoutofstock":false,
   
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":20
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  "subcategoryfilter":false
  
  }
 } 
 } else if(this.dataFilter=='za'){
  var req_filter_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
     // "brand":[this.allInOneData[0]],
      "category":this.category,
    "last90days":false,
    "last30days":false,
    "includeoutofstock":false,
   
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":20
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":+1
  },
  "subcategoryfilter":false
  
  }
 }
 }


 this.appService.postApiCall(req_filter_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
     this.filtersApiData = resp;
     this.products = this.filtersApiData.result.data;
     this.totalNumberOfProducts = this.filtersApiData.result.total;
     this.categoryList = this.filtersApiData.result.filters[0].categories;
     this.facets =  this.filtersApiData.result.filters[1].brands;
     this.totalItemsOrProducts = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
     this.totalProducts = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
     this.totalLength = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
    
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
}


getFilterByProductAll(event: number){
  this.combine='yes';
  this.include=false;
  this.days30=false;
  this.days90=false;
 
 if(this.allInOneData[2]){
   this.include=false;
   this.days30=false;
   this.days90=false;
  for(var i=0;i<this.allInOneData[2].split(',').length;i++){
    if(this.allInOneData[2].split(',')[i] =='Include Out of Stock'){
     this.include=true;
    }else if(this.allInOneData[2].split(',')[i] =='Last 90 days'){
     this.days90=true;
    }else if(this.allInOneData[2].split(',')[i] =='Last 30 days'){
    this.days30=true;
    }else{
     this.include=false;
     this.days30=false;
     this.days90=false;
    }

    
  }
 }

 this.currentPageInPagination = event;
 if(this.allInOneData.length === 0){
   this.allInOneData[0]=''
   this.allInOneData[1]=''
   
 }else{
  if(this.allInOneData[1].length === 0){
   
    this.allInOneData[1]=this.cate
  }else{
    
    if(Array.isArray(this.allInOneData[1]) )
    {
     
    }
    else
    {
      this.allInOneData[1]=[this.allInOneData[1]]
    }
   
  }

 }
 
 var brandData= JSON.parse(JSON.stringify(this.allInOneData));

 if(this.pagenation !=true){
  this.allInOneData[0] = brandData[0].split(',');
 }
 if(this.latest_products==="latest" && this.productfilter.active === ""){
  this.days90 = true;
  this.allInOneData[1] = [];
  }
  else if(this.productfilter && this.productfilter.active === ""){
  

 
   if(this.clearCategoryData.length >0){
    this.allInOneData[1] = this.clearCategoryData;
   }
    
  
  }
 

 if(this.allInOneData[0].length === 1){
  this.allInOneData[0].forEach(element => {
   
    if(!element){
      if(this.brad.length >0){
        this.allInOneData[0]=[this.brad]
      }
      
    }
  });
}

if(this.days90===false){
  if(this.ninetyDaysLastCheck === true){
    this.days90=true
  }else{
    this.days90=false
  }
}
var sortField="created_on"
var sortOrderValue = 1


var req_filter_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
"user_id": null,
"extras"    :{
"find":{
  "brand":this.allInOneData[0]!="" && this.allInOneData[0]!=undefined && this.allInOneData[0]!=null?this.allInOneData[0]:[],
  "category":this.allInOneData[1],
  "last90days":this.days90,
  "last30days":this.days30,
  "includeoutofstock":this.include,
 
},
"pagination":true,
"paginationDetails":{
    "limit":(event),
    "pagesize":20
},
"sorting":true,
"sortingDetails":{
    "sortfield":"name",
  "sortorder": this.dataFilter === "az"? -1 : +1
},
"subcategoryfilter":false
}
}

this.appService.postApiCall(req_filter_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
  resp => {
  //  this.allInOneData=[];
  this.pagenation=false;
    this.filtersApiData = resp;
    this.products = this.filtersApiData.result.data;
    this.totalNumberOfProducts = this.filtersApiData.result.total;
    if(this.categoryList.length ===0){
      this.categoryList = this.filtersApiData.result.filters[0].categories;
      
    }
  if(this.facets && this.facets.length === 0){
     this.facets =  this.filtersApiData.result.filters[1].brands;
  }
  if(this.allInOneData[0].length === 1){
    this.allInOneData[0].forEach(element => {
     
      if(!element){
        this.facets =  this.filtersApiData.result.filters[1].brands;
      }
    });
  }
    this.totalItemsOrProducts = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
    //  this.totalLength = this.products ? this.products.totalLength : 0;
    this.totalLength = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;

  },
  err => {
    if (err.error.error.message) {
      console.log(err.error.error.message)
    }
    else{
      console.log('Something bad happened; Please try again!')
    }
  })
}


getFilterByProductAllZToA(event: number){
  this.combine='yes';
  this.include=false;
  this.days30=false;
  this.days90=false;
 if(this.allInOneData1[2]){
   this.include=false;
   this.days30=false;
   this.days90=false;
  for(var i=0;i<this.allInOneData1[2].split(',').length;i++){
    if(this.allInOneData1[2].split(',')[i] =='Include Out of Stock'){
     this.include=true;
    }else if(this.allInOneData1[2].split(',')[i] =='Last 90 days'){
     this.days90=true;
    }else if(this.allInOneData1[2].split(',')[i] =='Last 30 days'){
    this.days30=true;
    }else{
     this.include=false;
     this.days30=false;
     this.days90=false;
    }

    
  }
 }

 this.currentPageInPagination = event;
 if(this.allInOneData1.length === 0){
   this.allInOneData1[0]=''
   this.allInOneData1[1]=''
   
 }else{
  if(this.allInOneData1[1].length === 0){
    this.allInOneData1[1]=this.cate
  }else{
    // this.allInOneData1[1]=[this.allInOneData1[1]]
    this.allInOneData1[1]=this.allInOneData1[1]
  }
 }

  var req_filter_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
"user_id": null,
"extras"    :{
"find":{
  // "brand":this.allInOneData1[0]!="" && this.allInOneData1[0]!=undefined && this.allInOneData1[0]!=null?this.allInOneData1[0]:[],
  "brand":this.allInOneData1[0],
  "category":this.allInOneData1[1],
  "last90days":this.days90,
  "last30days":this.days30,
  "includeoutofstock":this.include,
 
},
"pagination":true,
"paginationDetails":{
    "limit":(event),
    "pagesize":20
},
"sorting":true,
"sortingDetails":{
    "sortfield":"id",
  "sortorder":+1
},
"subcategoryfilter":false
}
}

this.appService.postApiCall(req_filter_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
  resp => {
    this.allInOneData1=[];
    this.filtersApiData = resp;
    this.products = this.filtersApiData.result.data;
    this.totalNumberOfProducts = this.filtersApiData.result.total;
    this.totalItemsOrProducts = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;
    this.totalLength = this.filtersApiData.result.total ? this.filtersApiData.result.total : 0;

  },
  err => {
    if (err.error.error.message) {
      console.log(err.error.error.message)
    }
    else{
      console.log('Something bad happened; Please try again!')
    }
  })
}

//GET PRODUCTS BY LAST90DAYS
getProductByLast90Days(event: number){
  this.currentPageInPagination = event;
  this.last90Days='yes';
  

  if(this.dataFilter=='az'){
   var req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
 "user_id": null,
 "extras"    :{
 "find":{
     "brand":[],
    "category":[],
   "last90days":true,
   "last30days":false,
   "includeoutofstock":false,
  
 },
 "pagination":true,
 "paginationDetails":{
     "limit":(event),
     "pagesize":20
 },
 "sorting":true,
 "sortingDetails":{
     "sortfield":"name",
   "sortorder":-1
 },
 
 }
 }
  } else if(this.dataFilter=='za'){
   var req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
   "user_id": null,
   "extras"    :{
   "find":{
       "brand":[],
      "category":[],
     "last90days":true,
     "last30days":false,
     "includeoutofstock":false,
    
   },
   "pagination":true,
   "paginationDetails":{
       "limit":(event),
       "pagesize":20
   },
   "sorting":true,
   "sortingDetails":{
       "sortfield":"name",
     "sortorder":+1
   },
   
   }
   }
  }
 

 this.appService.postApiCall(req_last90days_product_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
     this.last90DaysProductsApiData = resp;
     this.products = this.last90DaysProductsApiData.result.data;
     this.totalNumberOfProducts = this.last90DaysProductsApiData.result.total;
     this.categoryList = this.last90DaysProductsApiData.result.filters[0].categories;
     this.facets =  this.last90DaysProductsApiData.result.filters[1].brands;
     this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
    
     if(this.facets.length > 0){
       this.facets.forEach(element => {
         this.brandPush.push(element.name)
       });
 
       this.productFilterService.updateAdminFormEvent(this.brandPush);
       
     }
     this.totalLength = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
     
   },
   err => {
     if (err.error.error.message) {
       console.log(err.error.error.message)
     }
     else{
       console.log('Something bad happened; Please try again!')
     }
   })
 }
 
  //GET SEARCH RESULT
  getSearchResult(event: number){
   this.currentPageInPagination = event;
   this.search='yes';
   if(this.dataFilter=='az'){
     var req_search_data = {    
       "domain_name":ORG_DETAILS.DOMAIN_NAME,
       "user_id": null,
       "extras"    :{
       "find":{
         "search":this.searchKey,
       },
       "pagination":true,
       "paginationDetails":{
           "limit":(event),
           "pagesize":20
       },
       "sorting":true,
       "sortingDetails":{
           "sortfield":"name",
         "sortorder":-1
       }
       }
      }
   } else if(this.dataFilter=='za'){
     var req_search_data = {    
       "domain_name":ORG_DETAILS.DOMAIN_NAME,
       "user_id": null,
       "extras"    :{
       "find":{
         "search":this.searchKey,
       },
       "pagination":true,
       "paginationDetails":{
           "limit":(event),
           "pagesize":20
       },
       "sorting":true,
       "sortingDetails":{
           "sortfield":"name",
         "sortorder":+1
       }
       }
      }
   }
 
 
  this.appService.postApiCall(req_search_data, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
    resp => {
      this.searchApiData = resp;
      this.products = this.searchApiData.result.data;
      this.totalNumberOfProducts = this.searchApiData.result.total;
      this.categoryList = this.searchApiData.result.filters[0].categories;
     this.facets =  this.searchApiData.result.filters[1].brands;
     this.totalItemsOrProducts = this.searchApiData.result.total ? this.searchApiData.result.total : 0;
      this.totalLength = this.searchApiData.result.total ? this.searchApiData.result.total : 0;
 
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else{
        console.log('Something bad happened; Please try again!')
      }
    })
 }
 //GET PRODUCTS BY SORT
 getProductByLast90DaysSorting(event: number){
   this.currentPageInPagination = event;
   var req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
   "user_id": null,
   "extras" :{
   "find":{
   "brand":[],
   "category":[],
   "last90days":true,
   "last30days":false,
   "includeoutofstock":false,
   },
   "pagination":true,
   "paginationDetails":{
   "limit":(event),
   "pagesize":20
   },
   "sorting":true,
   "sortingDetails":{
   "sortfield":"name",
   "sortorder":this.dataFilter === "az"? -1 : +1
   },
   }
   }
   this.appService.postApiCall(req_last90days_product_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
   resp => {
   this.last90DaysProductsApiData = resp;
   this.products = this.last90DaysProductsApiData.result.data;
   this.totalNumberOfProducts = this.last90DaysProductsApiData.result.total;
   this.totalItemsOrProducts = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
  
   this.totalLength = this.last90DaysProductsApiData.result.total ? this.last90DaysProductsApiData.result.total : 0;
   },
   err => {
   if (err.error.error.message) {
   console.log(err.error.error.message)
   }
   else{
   console.log('Something bad happened; Please try again!')
   }
   })
   }
 
   setPage(page: number) {
    
   }
 
   updateFilter(tags: any) {}
 
   // TO REFRESH ALL PRODUCTS ON CLEAR ALL FUNCTION
   refreshProducts(topCategories){
     this.clearCategoryData=topCategories;
     this.currentPageInPagination = 1;
     var req_filter_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
       "user_id": null,
       "extras"    :{
       "find":{
         // "brand":[this.allInOneData[0]],
           "category":topCategories,
         "last90days":false,
         "last30days":false,
         "includeoutofstock":false,
       
       },
       "pagination":true,
       "paginationDetails":{
           "limit":1,
           "pagesize":20
       },
       "sorting":true,
       "sortingDetails":{
           "sortfield":"name",
         "sortorder": this.dataFilter === "az"? -1 : +1
       },
       "subcategoryfilter":false
       
       }
     } 
 
     this.appService.postApiCall(req_filter_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
       resp => {
         this.products = resp.result.data; 
         this.totalLength = resp.result.total ? resp.result.total : 0;
         this.facets = resp.result.filters[1].brands;
         this.totalItemsOrProducts = resp.result.total ? resp.result.total : 0;      
       },
       err => {
         if (err.error.error.message) {
           console.log(err.error.error.message)
         }
         else{
           console.log('Something bad happened; Please try again!')
         }
       })
       }
 
   // TO REFRESH ALL PRODUCTS ON CLEAR ALL FUNCTION
  refreshLast90Days(event: number){
   this.currentPageInPagination = event;
   this.last90Days='yes';
   if(this.dataFilter=='az'){
    var req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
  "user_id": null,
  "extras"    :{
  "find":{
      "brand":[],
     "category":[],
    "last90days":true,
    "last30days":false,
    "includeoutofstock":false,
   
  },
  "pagination":true,
  "paginationDetails":{
      "limit":(event),
      "pagesize":20
  },
  "sorting":true,
  "sortingDetails":{
      "sortfield":"name",
    "sortorder":-1
  },
  
  }
   }
     } else if(this.dataFilter=='za'){
    var req_last90days_product_data = {"domain_name":ORG_DETAILS.DOMAIN_NAME,
    "user_id": null,
    "extras"    :{
    "find":{
        "brand":[],
       "category":[],
      "last90days":true,
      "last30days":false,
      "includeoutofstock":false,
     
    },
    "pagination":true,
    "paginationDetails":{
        "limit":(event),
        "pagesize":20
    },
    "sorting":true,
    "sortingDetails":{
        "sortfield":"name",
      "sortorder":+1
    },
    
    }
    }
     }
  
  
     this.appService.postApiCall(req_last90days_product_data, ENDPOINTS.GET_FILTER_PRODUCTS).subscribe(
    resp => {
      this.products = resp.result.data;
      this.facets = resp.result.filters[1].brands;
       this.totalItemsOrProducts = resp.result.total ? resp.result.total : 0;
       this.totalLength = resp.result.total ? resp.result.total : 0;
    },
    err => {
      if (err.error.error.message) {
        console.log(err.error.error.message)
      }
      else{
        console.log('Something bad happened; Please try again!')
      }
     })
  }

addToCart(item)
{
  this.cartService.addToCart(item);
  for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
    this.cartData.push(this.appService.cartProducts.results[i].product_id);
  }
}
increaseItem(index)
{
  this.alertService.clear();
  let tempArray = this.appService.cartProducts.results;
  let cartQty = 0

  tempArray.forEach((item)=>
  {
      cartQty += item.quantity;
  })

  if(Number(this.localService.get('max_cart_qty')) <= cartQty)
  { 
    this.showAlerts = true;
    window.scrollTo(0,0);
    this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
    return
  }
  tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}

decreaseItem(index)
{
  if(this.appService.cartProducts.results[index].quantity === 1)
  {
      this.appService.cartProducts.results.splice(index,1);
      this.cartData = [];
      for(let i =0;i<this.appService.cartProducts.results.length;i++){ 
        this.cartData.push(this.appService.cartProducts.results[i].product_id);
      }
      this.appService.totalCartItems = this.cartService.totalCartItems();
      this.cartService.updateCartItem(this.appService.cartProducts.results);
      return
      
  }
  let tempArray = this.appService.cartProducts.results;
  tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
  tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
  this.appService.cartProducts.results = tempArray;
  this.appService.totalCartItems = this.cartService.totalCartItems();
  this.cartService.updateCartItem(this.appService.cartProducts.results);
}




clearAll()
  {
    this.productfilter.clearAll()
  }
  productDetails(id){
    this.router.navigate(['/product-details',id], { relativeTo:this.route,  skipLocationChange: false })
  }
  mouseEnter(id) {
    document.getElementById(id).click();
  }

  mouseLeave(id) {
    document.getElementById(id).click();
    }
  filterItemsOfType(){
    return this.products.filter(x => x.type != 'Variation');
}
}
