import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../auth-service/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CheckoutOrdersdetailsStorageService {

  ORDER_INSTRUCTIONA_LABEL = "Order Instructions"

  constructor(
    private localStorageService: LocalStorageService,
  ) { }

  setOrderInstractions(instructionsObj){
    this.localStorageService.set(this.ORDER_INSTRUCTIONA_LABEL, JSON.stringify(instructionsObj))
  }

  getOrderInstructions(){
    if(this.localStorageService.get(this.ORDER_INSTRUCTIONA_LABEL)){
      return JSON.parse(this.localStorageService.get(this.ORDER_INSTRUCTIONA_LABEL));
    }
    else{
      return null;
    }
  }

  removeOrderInstrctuions(){
    if(this.localStorageService.get(this.ORDER_INSTRUCTIONA_LABEL)){
      this.localStorageService.remove(this.ORDER_INSTRUCTIONA_LABEL);
    }
  }
  
}
