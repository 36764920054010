<div class="aboutus-container">
    <div class="about-section paddingTB60" *ngIf="config.title != 'Tams'">
        <div class="container">
            <div class="row">
                <div class="">
                    <div class="about-title clearfix">
                        <h2 class="aboutus-title">WELCOME TO Haven Sampoorna</h2>
                        <p class="about-paddingB">
                        <div style="margin-left: 5px;" id= "aboutus-content"></div>
                          <!-- <p>Who We Are..</p>
      <br>
      <p style="font-weight: bolder;">Be worthy, think earthy !</p>
      <p>We are one amongst you, a research based sustainable brand, our vision “Unshakable Commitment to Sustainability”. </p>
      <p>Earthy worthy End to End Sustainability Program has led to creation of the greenest apparel ever made.</p>
      <p>Our mission was to make luxury affordable; we have made that possible by bringing Sustainable Fashion to you with Unique designs created by independent world class Artist.</p>
      <p>Come join our Moto “An earthyworthy Tshirt for every Person”. Earthy to wear, worthy to pay.</p> -->
                          
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="about-section paddingTB60">
          <div class="container">
              <div class="row">
                  <div class="">
                      
                  </div>
              </div>
          </div>
      </div>
      <div class="about-section paddingTB60" *ngIf="config.title == 'Tams'">
          <div class="container">
              <div class="row">
                  <div class="">
                      <div class="about-title clearfix">
                          <h2 class="aboutus-title">WELCOME TO TAMS</h2>
                          <p class="about-paddingB">
        <br>

        <div style="margin-left: 5px;" id= "aboutus-content"></div>
        <!-- <p>If you are a person who loves  idli  and concerned about the tiresome process of making idli batter at home or worried  about the purity of the batter bought from the shop, ILY IDLI FLOUR is the right product for you!</p>
      
       
      
      <p>The Naturally fermenting patented ILY idli flour is made with top graded , quality approved  Rice and Urad dal at a very  hygenic environment with no hand contact in the process of manufacturing. No preservatives, No   chemicals ,  No additives , No artificial colours  are added to the flour and it is not recombined in any form in the process.</p>
      
       
      
      <p>We have enormous satisfaction and joy in presenting  the traditional fermentation method of making Idli’s  with ILY naturally fermenting  flour with six month shelf life.</p>
                             -->
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="about-section paddingTB60">
            <div class="container">
                <div class="row">
                    <div class="">
                        
                    </div>
                </div>
            </div>
        </div>
</div>
