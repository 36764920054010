import { Injectable } from '@angular/core';

import { CheckoutDeliveryStorageService } from '../checkout-delivery/checkout-delivery-storage.service';
import { CustomerLoginStorageService } from '../checkout-login/customer-login-storage.service';
import { CheckoutShippingApiService } from './checkout-shipping-api.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutShippingService {

  constructor(
    private checkoutDeliveryStorageService: CheckoutDeliveryStorageService,
    private customerLoginStorageService: CustomerLoginStorageService,
    private checkoutShippingApiService: CheckoutShippingApiService
  ) { }

  getSelectedRestaurent(){
    return new Promise((resolve, reject) => {
      if(this.checkoutDeliveryStorageService.getDeliveryInfo()){
        let selectedRestaurents = this.checkoutDeliveryStorageService.getDeliveryInfo();
        resolve ({latitude: selectedRestaurents.outlet_latitude, longitude: selectedRestaurents.outlet_longitude, enabled: selectedRestaurents.enabled, distance: selectedRestaurents.distance, pinCode:selectedRestaurents.pincode});
      }
      else{
        this.checkoutShippingApiService.getRestaurentdetailsforShipping().then(
          respData => {
            let res:any = respData;
            resolve({latitude: res.latitude, longitude: res.longitude, enabled: res.enable_distance_radius, distance: res.distance_radius, pinCode: res.zipcode})
          }
        )
      }
    })
  }

  getCustomerDetails(){
    if(this.customerLoginStorageService.getCustomer()){
      return this.customerLoginStorageService.getCustomer().customer_id;
    }
    else{
      return null;
    }
  }

  
}
