import { Injectable } from '@angular/core';

import { CheckoutApiService } from '../checkout-api.service';
import { CheckoutSettingsService } from '../checkout-settings.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class CheckoutDeliveryService {

  outlets:any = null;
  handlingFeeInfo:any = null;
  deliveryInfo = {
    enableDelivery:true,
    deliveryDisplayName:"",
    enablePickinStore:true,
    pickinStoreDisplayName:"",
    handlingFee:{
      delivery:{
        rate:0,
        name:"",
        type:""
      },
      pickinstore:{
        rate:0,
        name:"",
        type:""
      }
    }
  }

  constructor(
    private checkoutApiService: CheckoutApiService,
    private checkoutSettingsService: CheckoutSettingsService
  ) { }

  getOutletData(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_OUTLETS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            this.outlets = res.data;
            resolve(res.data);
          }
        }
      )
    })
  }

  getHandlingFeeInfo(){
    return new Promise((resolve, reject) => {
      if(this.checkoutSettingsService.shippingMethods){
        this.handlingFeeInfo = this.checkoutSettingsService.shippingMethods;
        if(this.handlingFeeInfo.delivery_details.no_deliveries_only_pickup){
          this.deliveryInfo.enableDelivery = false;
        }
        this.deliveryInfo.deliveryDisplayName = this.handlingFeeInfo.delivery_details.display_name;
        if(this.handlingFeeInfo.flat_rate.handling_fees){
          this.deliveryInfo.handlingFee.delivery.name = this.handlingFeeInfo.flat_rate.handling_fees.display_name;
          this.deliveryInfo.handlingFee.delivery.rate = this.handlingFeeInfo.flat_rate.handling_fees.handling_fee;
          this.deliveryInfo.handlingFee.delivery.type = this.handlingFeeInfo.flat_rate.handling_fees.fee_type;
        }
        if(this.handlingFeeInfo.pickup_in_store){
          if(this.handlingFeeInfo.pickup_in_store.is_enabled){
            this.deliveryInfo.enablePickinStore = true;
            this.deliveryInfo.pickinStoreDisplayName = this.handlingFeeInfo.pickup_in_store.display_name;
          }
          else{
            this.deliveryInfo.enablePickinStore = false;
          }
          if(this.handlingFeeInfo.pickup_in_store.handling_fees){
            this.deliveryInfo.handlingFee.pickinstore.name = this.handlingFeeInfo.pickup_in_store.handling_fees.display_name;
            this.deliveryInfo.handlingFee.pickinstore.rate = Number(this.handlingFeeInfo.pickup_in_store.handling_fees.handling_fee);
            this.deliveryInfo.handlingFee.pickinstore.type = this.handlingFeeInfo.pickup_in_store.handling_fees.fee_type;
          }
        }
        else{
          this.deliveryInfo.enablePickinStore = false;
        }
        
        resolve(this.deliveryInfo)
      }
      else{
        this.checkoutSettingsService.getShippingSettings().then(
          respData => {
            let res:any = respData;
            if(res){
              if(this.checkoutSettingsService.shippingMethods){
                this.handlingFeeInfo = this.checkoutSettingsService.shippingMethods;
                if(this.handlingFeeInfo.delivery_details.no_deliveries_only_pickup){
                  this.deliveryInfo.enableDelivery = false;
                }
                this.deliveryInfo.deliveryDisplayName = this.handlingFeeInfo.delivery_details.display_name;
                if(this.handlingFeeInfo.flat_rate.handling_fees){
                  this.deliveryInfo.handlingFee.delivery.name = this.handlingFeeInfo.flat_rate.handling_fees.display_name;
                  this.deliveryInfo.handlingFee.delivery.rate = this.handlingFeeInfo.flat_rate.handling_fees.handling_fee;
                  this.deliveryInfo.handlingFee.delivery.type = this.handlingFeeInfo.flat_rate.handling_fees.fee_type;
                }
                if(this.handlingFeeInfo.pickup_in_store){
                  if(this.handlingFeeInfo.pickup_in_store.is_enabled){
                    this.deliveryInfo.enablePickinStore = true;
                    this.deliveryInfo.pickinStoreDisplayName = this.handlingFeeInfo.pickup_in_store.display_name;
                  }
                  else{
                    this.deliveryInfo.enablePickinStore = false;
                  }
                  if(this.handlingFeeInfo.pickup_in_store.handling_fees){
                    this.deliveryInfo.handlingFee.pickinstore.name = this.handlingFeeInfo.pickup_in_store.handling_fees.display_name;
                    this.deliveryInfo.handlingFee.pickinstore.rate = Number(this.handlingFeeInfo.pickup_in_store.handling_fees.handling_fee);
                    this.deliveryInfo.handlingFee.pickinstore.type = this.handlingFeeInfo.pickup_in_store.handling_fees.fee_type;
                  }
                }
                else{
                  this.deliveryInfo.enablePickinStore = false;
                }
                
                resolve(this.deliveryInfo)
              }
            }
          }
        )
      }
    })
  }

  getAcceptanceTimings(){
    return new Promise((resolve, reject) => {
      this.checkoutApiService.checkoutAPICall(ENDPOINTS.ACCEPTANCE_TIMINGS).then(
        respData => {
          let res:any = respData;
          if(res.success){
            resolve(res.data[0]);
          }
        }
      )
    })
  }

  getDiscountData(ddate, dtime, dhours){
   return new Promise((resolve, reject) => {
    dtime = dtime.split(" am - ").join(":AM-");
    dtime = dtime.split(" am").join(":AM");
    dtime = dtime.split(" pm - ").join(":PM-");
    dtime = dtime.split(" pm").join(":PM");
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {},
        "details": {
          "deliver_date": ddate,
          "deliver_time": dtime,
          "hours":dhours
        }
      }
    }
    this.checkoutApiService.checkoutAPICall(ENDPOINTS.GET_DISCOUNTS, requestData).then(
      respData => {
        let res:any = respData;
        if(res.success){
          if(res.data){
            if(res.data.discount_percentage){
              resolve({discount: res.data.discount_percentage, type: "PERCENTAGE", displayMsg: res.data.congratulations_banner_message, displayName:res.data.promotion_type})
            }
            else if(res.data.discount_amount){
              resolve({discount: res.data.discount_amount, type: "FIXED AMOUNT", displayMsg: res.data.congratulations_banner_message, displayName:res.data.promotion_type})
            }
            else{
              resolve ("")
            }
          }
          else{
            resolve("");
          }
        }
        else{
          resolve("");
        }
      }
    )
   })
  }

}
