export const config = {
    title: 'Tams',
    name: "TAMS",
    image: '',
    logo: 'assets/images/tams/ILY-GREEN_LOGO2.jpg',
    DOMAIN_NAME : 'https://www.tamsfoods.com',
    USER_ID : 18,
    STORE_ID: 1,
    ORGANIZATION_ID:1,
    default_product_image: "",
    product_path:"/product",
    showTableBookingBtn:false,
    noImageAvailable:"assets/images/tams/NoImage.png",

    HOME_SETTINGS: {
      banner: [
        {
          name: "Home Banner",
          image: "assets/images/vendor/banner.png",
          quote: "<h3>Good Food <br> Good Feelings</h3><p>with <span>TAMS</span></p>",
          button: {
            name: "CHECK PRODUCTS",
            link: "product"
          }
        }
      ],
      about_st: [
        {
          title: "Direct to Home",
          // image: "assets/images/tams/home1.svg",
          image: "assets/images/tams/homenew1.png",
          // text: "Get your Flour mixes delivered straight to your home by ordering here"
          text: "Get your iLY products delivered directly to your home"
        },
        {
          title: "Patented Technology",
          // image: "assets/images/vendor/home3.svg",
          image: "assets/images/tams/homenew3.jpg",
          // text: "iLY’s state of the art technologies provides the longer shelf life for iLY flour mixes for upto six months"
          text: "Six month shelf life. Just store it in an airtight container"
        },
        {
          title: "Easy Batter Preperation",
          // image: "assets/images/vendor/home4.svg",
          image: "assets/images/tams/homenew4.png",
          // text: "Just add the required amount of water and ferment for 12 hours and your iLY idly and dosa batter is ready"
          text: "Add 1 1/4 to 1 1/2 cup of water for 1 cup flour. Ferment it for 12 hours"
        },
        {
          title: "High Quality Ingredients",
          // image: "assets/images/vendor/home2.svg",
          image: "assets/images/tams/homenew2.png",
          // text: "iLY uses the best raw materials to prepare the best ready mix available in the market"
          text: "iLY guarantees the use of 100% natural quality raw materials"

        },
      ],
      gutters: [
        {
          image: "assets/images/tams/ily 1.jpg"
        },
        {
          image: "assets/images/tams/ily 3.jpg"
        },
        {
          image: "assets/images/tams/ily 4.jpg"
        },
        {
          image: "assets/images/tams/ily 5.jpg"
        }
      ],
      testimonials: [
        {
          title: "love from iLY family",
          comment: "I got a very quick of delivery. The Product is awesome. <br>It's excellent quality and value for your money.Highly recommended. Good job team iLY",
          name: "Swati Savera"
        }
      ],
      float_button: {
        show: false,
        image: "assets/images/tams/online.svg",
        link: "/product"
      }
    },
    menu: [
      {
        id: "home",
        label: "Home",
        link: "/"
      },
      {
        id: "products",
        label: "FLOURS",
        link: "product"
      },
      {
        id: "about",
        label: "About Us",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact Us",
        link: "contact-us"
      }
    ],
    footer: [
      {
        id: "about",
        label: "About",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact",
        link: "contact-us"
      },
      {
        id: "faq",
        label: "FAQ",
        link: "FAQs"
      },
      {
        id: "termsandconditions",
        label: "Terms & conditions",
        link: "terms-and-condition"
      },
      {
        id: "privacypolicy",
        label: "Privacy Policy",
        link: "privacy-policy"
      },
      {
        id: "cancellationPolicy",
        label: "Cancellation Policy",
        link: "cancellation-policy"
      },
      {
        id: "shippinganddelivery",
        label: "Shipping & Delivery",
        link: "shipping-and-delivery"
      },
      {
        id: "returnPolicy",
        label: "Return & Exchange Policy",
        link: "return-policy"
      }
    ]
  };
