<div class="container-fluid visit-store">
    <ul>
        <li>
            <h3>Visit our latest products</h3>
        </li>
        <li>
            <a class="btn btn-secondary" (click)="getLatestProducts()" >Know More &nbsp; <i
                    class="fa-angle-right fa"></i></a>
        </li>
    </ul>
</div>
<footer class="container-fluid footer">
    <div class="container">
        <div class="footer-links footer-row-one">
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">ONLINE SHOPPING</h4>
                <ul >
                    <li *ngFor="let online of onLineShopping;" (click)="navigateToProduct(online.link)"><a style="color: #375673 !important;font-weight:450"
                            class="custom-point">{{online.name}}</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">QUICK LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/">Home</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">About Us</a></li>
                    <!-- <li (click)="getBrochure()"><a >Brochure</a></li> -->
                </ul>
            </div>
            <div class="footer-col">
                <h4 class="sm-bold" style="color: #212529;">USEFUL LINKS</h4>
                <ul>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="about-us">Contact Us</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">FAQ</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">T&amp;C</a></li>
                    <li><a style="color: #375673 !important;font-weight:450" routerLink="/home">Terms of Use</a></li>
                </ul>
            </div>
            <div class="footer-col contact-section address">
                <h4 class="sm-bold" style="color: #212529;">CONTACT US</h4>
                <p style="color: #375673 !important;"><i class="fa-map-marker fa"></i>
                    Green Cotton Company<br>
                    No. 8, BM – 441,<br>
                    1st Block HRBR Layout,<br>
                    Bangalore – 560043,<br>
                    Karnataka.
                </p>
                <p><i class="fa-phone  fa"></i>
                    <a style="color: #375673 !important;font-weight:450">+91 8884500900</a>
                </p>
                <p><i class="fa-globe  fa"></i>
                    <a style="color: #375673 !important;font-weight:450" href="enquiry@earthyworthy.in" target="_blank">enquiry@earthyworthy.in</a>
                </p>
            </div>
        </div>
    </div>
</footer>

<footer>
    <div class="footer-row-two">
        <div class="s-icons">
            <ul class="social-icons">
                <li>
                    <a href="https://fr-fr.facebook.com/earthyworthyIN" target="_blank">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div *ngIf="copyRightText && copyRightText !== ''">
            <div class="copy">
                © {{copyRightText}}
            </div>
        </div>
    </div>
</footer>