import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../app.service';
import { ENDPOINTS } from '../app.config';
import { ActivatedRoute, Router, NavigationEnd, Params,NavigationExtras } from '@angular/router';

// import { BreadcrumbsService } from '../breadcrumbs/breadcrumbs.service';
import { ProductFilterService } from './product-filter.service';
import { ProductListingService } from '../product-listing/product-listing.service';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html'
})
export class ProductFilterComponent implements OnInit {

  public value;
  active = 'dontcheck';
  public  allData:any;
  public facet:any;
  public day90;
  public day60;
  public outOfStock;
  stockDetails:any=[];
  isChecked =true;
  public values1;
  public values2;
  public values3;
   intialVal:boolean = true;



// categories:any = 
// [
//               {
//                   "name": "category1",
//                   "subcategories": [
//                       {
//                           "name": "subcategory1",
//                           "subcategories": [
//                               {
//                                   "name": "subcategory1.2",
//                                   "subcategories": [
//                                     {
//                                         "name": "subcategory1.2.1",
//                                         "subcategories": [
//                                           {
//                                               "name": "subcategory1.2.1.1" ,
//                                               "subcategories": [
//                                                 {
//                                                     "name": "subcategory1.2.1.1.1",                                               
//                                                 }
//                                               ] 
                                                                                            
//                                           }
//                                         ]
//                                     }
//                                   ]
//                               }
//                           ]
//                       },
//                       {
//                           "name": "subcategory2"
//                       }
//                   ]
//               },
//               {
//                   "name": "category2"
//               },
//               {
//                   "name": "category3"
//               }
//           ]
  @Input() filters: any;
  @Input() priceRange: any;
  @Input() categoriesList: any;
  @Input() days: any;

  public total;
  public collapse: boolean = false;
  public checked: boolean = false;
  public tempArr: any = { "brands": [] };
  public brands:any = [];
  public stock;
  public cate = [];
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    // private breadCrumbsService: BreadcrumbsService,
    private productFilterService: ProductFilterService,
    private listingService: ProductListingService
  ) { }

  ngOnInit(): void {
      if (this.days=='yes') {
       this.values2=true;
      }
  
      if(this.filters){
        this.total=this.filters.length;
        console.log("this.totalll"+this.total);
      }
     
  //  if(this.filters.length > 0){
  //   this.filters.facets.forEach(element => {
  //     this.brands.push(element.name)
  //   })
  // }....

  // this.productFilterService.currentData.subscribe(currentData => {
  //   if(this.brands.length === 0){
  //     this.brands = currentData
  //   }        
  // })
      
  
     
  }

  appliedFilter(values:any,facet) {
    console.log(values.currentTarget.checked);
    this.value = values.currentTarget.checked;
    if(this.value==true){
      this.brands.push(facet.name);
      this.callListingScreen()
      
    }else{
     for(var i =0;i<this.brands.length;i++){
       if(this.brands[i]==facet.name){
        this.brands.splice(i,1);
       }
     }
     this.callListingScreen()
      
    }
    console.log(facet.label);
    
    // this.router.navigate([`/listing`], {queryParams: { terms:this.brands, type:'brandValue' }, relativeTo:this.route,  skipLocationChange: false });

  }

  onChange(values:any,name):void {
    console.log(values.currentTarget.checked);
    this.stock = values.currentTarget.checked;
    if(this.stock==true){
      if(name=='Last 90 days'){
        this.stockDetails.push(name);
        if(this.stockDetails.length > 1){
          for(let i =0;i<this.stockDetails.length;i++){
            if(this.stockDetails[i]=='Last 30 days'){
              this.stockDetails.splice(i,1)
            }
          }
        }
      }else if(name == 'Last 30 days'){
        this.stockDetails.push(name);
        if(this.stockDetails.length > 1){
          for(let i =0;i<this.stockDetails.length;i++){
            if(this.stockDetails[i]=='Last 90 days'){
              this.stockDetails.splice(i,1)
            }
          }
          // this.stockDetails.forEach(element => {
          //   if(element == 'Last 90 days')
          //   this.stockDetails.splice(element)
          // });
        }
      }else{
        this.stockDetails.push(name);
      }
      
      this.callListingScreen()
    }else{
      for(var i =0;i<this.stockDetails.length;i++){
        if(this.stockDetails[i]==name){
         this.stockDetails.splice(i,1);
        }
      }
      this.callListingScreen()
       
    }
   
    
  }
  

  openContent() {
    this.collapse = !this.collapse;
    this.checked = true;
  }


  updateFilter(event){

  }

  checkedevt(values:any, name){
    // console.log(values,name)
    // this.breadCrumbsService.updateAdminFormEvent(values);
    this.intialVal = false;
    this.active = name;
 
   this.cate=[name]
   console.log("in set data***"+this.cate);
   this.callListingScreen()
  }
callListingScreen(){

this.allData=[{
  brand:this.brands,
  
  categories1:this.cate,


  stock:this.stockDetails
}
  
  
  
  
  
]
console.log("jk "+JSON.stringify(this.cate))
  this.router.navigate([`/productListing`], {queryParams: { terms:[[this.brands],[this.cate],[this.stockDetails]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
}

clearCheckBox(){
  
  let a = document.getElementById('day90')as HTMLInputElement
      if(a.checked){
        a.checked = false;
      }
      let b = document.getElementById('day30')as HTMLInputElement
      if(b.checked){
        b.checked = false;
      }
      let c = document.getElementById('outOfStock')as HTMLInputElement
      if(c.checked){
        c.checked = false;
      }
    
}
check90Days(){
  let a = document.getElementById('day90')as HTMLInputElement
 
   a.checked=true;
  this.stockDetails.push('Last 90 days');
}
changeCheckecd(values:any,name){
  console.log(values.currentTarget.checked);
  this.value = values.currentTarget.checked;
  if(this.value == true){
    if(name == 'Last 90 days'){
      let c = document.getElementById('day30')as HTMLInputElement
     
        c.checked = false;
     // }
    }else{
      let b = document.getElementById('day90')as HTMLInputElement
     
        b.checked = false;
    }
   
   
   
  }
 

}
clearAll(){
//   console.log(this.brands)
  if(this.brands.length > 0){
    this.brands.forEach(element => {
      let a = document.getElementById(element)as HTMLInputElement
      if(a.checked){
        a.checked = false;
      }
    });
    
  }
  

   this.brands=[];
   this.stockDetails=[];
    for(let i =0;i < this.filters.length;i++){
      this.filters[i].value = false; 
  }
  this.values1 = false;
  this.values2 = false;
  this.values3 = false;

// CLEAR ALL EVENT
this.listingService.updateAdminFormEvent('clearAll');
// this.breadCrumbsService.updateAdminFormEvent('');

this.active = '';
}
}
