import { Component, OnInit } from '@angular/core';
import { NavbarService} from '../../navbar/navbar.service';
import {config} from '../../../configs/config';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
// import { TermsAndConditionService } from '../terms-and-condition/terms-and-condition.service';
import { ENDPOINTS, ORG_DETAILS } from '../../app.config';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html'
})
export class AboutUsComponent implements OnInit {
  config = config;
  constructor(private navbarService: NavbarService,private router: Router,
    private appService: AppService,
    ) { }

  ngOnInit(): void {
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.getAboutUs()
  }

  getAboutUs() {
    let requestBody = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        },
        "pagination": true,
        "paginationDetails": {
          "limit": 0,
          "pageSize": 10
        },
        "sorting": true,
        "sortingDetails": {
          "email": -1
        }
      }
    }
    // MAKE A SERVICE CALL HERE...
    this.appService.postApiCall(requestBody, ENDPOINTS.GET_ABOUT_US).subscribe(
      resp => {
        if (resp.result.data[0].length !== 0) {
          console.log(resp.result.data[0].content)
          document.getElementById('aboutus-content').innerHTML = resp.result.data[0].content;
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          this.router.navigate([`/login-new`], { skipLocationChange: false });
        }
      }
    )
  }

}
