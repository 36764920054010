<app-alerts *ngIf="showAlerts"></app-alerts>
<section class="container">
    <p class="logo-header mt-3">Gift a Experience</p>
    <p class="text-black">There is no better gift in today’s time where everyone seems to have almost everything they desire. Gifting a meal is but a very noble gesture. One thing any one of us will say enough is for food . That’s why we name our restaurant Haven sampoorna – sampoorna means fulfillment. So if you want to give a treat for your near and dear when you are travelling or living abroad is allow us to make them happy with the gesture of gifting a good meal to them. Let us know your requirement and we will do the needful . Thank you.</p>
    <p class="sub-header mt-3">Gift Form</p>
    <div class="row mt-2">
        <div class="col-sm-7">
            <form class="gift-form" [formGroup]="Default1">
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Name <span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter Name"
                            [class.is-invalid]="validateForm && D1.name.errors" formControlName="name" />
                        <div class="invalid-feedback" *ngIf="D1.name.errors">
                            <div *ngIf="D1.name.errors.required">Name is required.</div>
                            <div *ngIf="D1.name.errors.minlength">Name is too short.</div>
                            <div *ngIf="D1.name.errors.maxlength">Name is too long.</div>
                            <div *ngIf="D1.name.errors.pattern">Name is invalid.</div>
                        </div>
                    </div>
                </div>
                <!--end of form-group row -->
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Email Address<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="email" placeholder="Enter Your Email"
                            [class.is-invalid]="validateForm && D1.email_address.errors"
                            formControlName="email_address" />
                        <div class="invalid-feedback" *ngIf="D1.email_address.errors">
                            <div *ngIf="D1.email_address.errors.required">Email Address is required.</div>
                            <div *ngIf="D1.email_address.errors.pattern">Email Address is invalid.</div>
                            <div *ngIf="D1.email_address.errors.maxlength">Email Address is too long.</div>
                        </div>
                    </div>
                </div>
                <!--end of form-group row -->
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Mobile Number<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter Your Mobile Number"
                            [class.is-invalid]="validateForm && D1.mobile_number.errors"
                            formControlName="mobile_number" />
                        <div class="invalid-feedback" *ngIf="D1.mobile_number.errors">
                            <div *ngIf="D1.mobile_number.errors.required">Mobile Number is required.</div>
                            <div *ngIf="D1.mobile_number.errors.minlength">Mobile Number is too short.</div>
                            <div *ngIf="D1.mobile_number.errors.maxlength">Mobile Number is too long.</div>
                            <div *ngIf="D1.mobile_number.errors.pattern">Mobile Number is invalid.</div>

                        </div>
                    </div>
                </div>
                <!--end of form-group row -->
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Date<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <!-- <input type="date" formControlName="date" class="form-control"
                            [class.is-invalid]="validateForm && D1.date.errors" /> -->

                            <dp-date-picker class="form-control" theme="dp-material" [config]="datePickerConfig"  placeholder="Please select the date"  formControlName="date" #dayPicker [class.is-invalid]="validateForm && D1.date.errors" ></dp-date-picker>

                        <div class="invalid-feedback" *ngIf=D1.date.errors>
                            <div *ngIf="D1.date.errors.required">Date is required.</div>
                            <div *ngIf="D1.date.errors.previous_date">Gift a experience should be booked 5 days in advance.</div>
                        </div>
                    </div>
                </div>
                <!--end of form-group row -->
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Time<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <!-- <input class="form-control" type="input" (click)="pickTime()" placeholder="Time"
                            [class.is-invalid]="validateForm && D1.time.errors" formControlName="time" /> -->
                            <select class="custom-select" placeholder="Time" [class.is-invalid]="validateForm && D1.time.errors"
                                formControlName="time">
                                <option value="">Please select the time.</option>
                                <option *ngFor="let times of slots;">{{times}}</option>
                            </select>
                        <div class="invalid-feedback" *ngIf="D1.time.errors">
                            <div *ngIf="D1.time.errors.required">Time is required.</div>
                            <div *ngIf="D1.time.errors.previous_time">Previous time is not allowed.</div>
                        </div>
                    </div>
                </div>
                <!--end of form-group row -->
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">No of Persons<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <input class="form-control" type="input" placeholder="Enter No of Persons"
                            [class.is-invalid]="validateForm && D1.no_of_persons.errors"
                            formControlName="no_of_persons" />
                        <div class="invalid-feedback" *ngIf="D1.no_of_persons.errors">
                            <div *ngIf="D1.no_of_persons.errors.required">Number of Persons is required.</div>
                            <div *ngIf="D1.no_of_persons.errors.minlength">Number of Persons is too short.</div>
                            <div *ngIf="D1.no_of_persons.errors.maxlength">Cannot accomodate.</div>
                            <div *ngIf="D1.no_of_persons.errors.pattern">Number of Persons is invalid.</div>

                        </div>
                    </div>
                </div>
                <!--end of form-group row -->
                <div class="form-group row">

                    <div class="col-md-4">
                        <label class="form-label">Address<span class="required">*</span></label>
                    </div>
                    <div class="col-md-7">
                        <textarea class="form-control" type="textarea" placeholder="Enter the Address"
                            [class.is-invalid]="validateForm && D1.address.errors" formControlName="address"></textarea>
                        <div class="invalid-feedback" *ngIf="D1.address.errors">
                            <div *ngIf="D1.address.errors.required">Address is required.</div>
                            <div *ngIf="D1.address.errors.minlength">Address is too short.</div>
                            <div *ngIf="D1.address.errors.maxlength">Address is too long.</div>

                        </div>
                    </div>
                </div>
                <!--end of form-group row -->

                <div class="text-center">
                    <button class="btn btn-primary buttons mb-1" (click)="saveForm()">SEND</button>
                </div>

                <!--end of button-->
            </form>

            <!--end of form-->
        </div>
        <div class="col-md-5">
            <div class="card1" style="border: none !important;">
                <h2 class="card-txt">Services Offered</h2>
                <ol style="padding-left: 10px;" >
                    <li *ngFor="let list of giftMealServices; let i=index"><span>{{i+1}}. </span>{{list.description}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>
