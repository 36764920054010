<section>
    <div class="header-section">
        <div class="d-flex justify-content-start">
            <div class="logo-section">
                <img class="logo-images" [src]="logoImage" alt="Logo Image" (click)="navigateHome()">
            </div>
            <div class="banner-section">
                <p class="text-white" [innerHTML] = "bannerMessage"></p>
            </div>
        </div>
        
    </div>
</section>
